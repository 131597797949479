import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Col, Row, Table, Tooltip } from 'reactstrap';
import { BuyerManagementContext } from '../../../../../apis/admin';
import { warning } from '../../../../assets/img';
import StatusContainer from './StatusContainer';
import {
  DATE_FORMAT_API,
  PACKAGING_STATUS,
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../../shared/constants/constants';
import Scanner from './Scanner';
import { CustomModal } from '../../../../../shared/components';
import { useDeviceDetect } from '../../../../../shared/hooks';
import BuyerName from '../BuyerName';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import AlreadyScannedModal from '../AlreadyScannedModal';
import { successMp3, successOgg, successWav } from '../../../../assets/audio';

function OrdersOfVehicle({
  selectedVehicle,
  isRefresh,
  setIsRefresh,
  selectedBaseName,
  selectedDate,
  selectedDeliverySlot,
}) {
  const { t } = useTranslation();
  var audioElement = document.getElementById('qrScannerAudioControl');
  const buyerManagementContext = useContext(BuyerManagementContext);
  const { isMobile } = useDeviceDetect();
  const {
    getOrdersOfEachVehicle,
    updatePackageStatus,
    updatePackageStatusInLoading,
  } = buyerManagementContext;
  const [orderList, setOrderList] = useState([]);
  const [isShowStatusInfo, setIsShowStatusInfo] = useState(false);
  const [isStartScan, setIsStartScan] = useState(false);
  const [error, setError] = useState('');
  const [unavailableMessage, setUnavailableMessage] = useState('');
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    isPendingManualProcumentItems,
    setIsPendingManualProcumentItems,
  ] = useState(false);

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false);
      setUnavailableMessage('');
      onHandlegetOrdersOfEachVehicle();
    }
  }, [isRefresh]);

  const onHandlegetOrdersOfEachVehicle = () => {
    getOrdersOfEachVehicle(
      selectedVehicle.id,
      selectedBaseName?.id,
      selectedDeliverySlot.id,
      formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
    ).then(res => {
      if (res.err) {
        return;
      } else {
        if (res.res?.length === 0) {
          setUnavailableMessage(
            t('ALL_THE_PACKAGES_ARE_RELEASED_FOR_DISPATCH'),
          );
        }
        setOrderList(res.res);
      }
    });
  };

  const onHandleRequest = () => {
    if (selectedPackages?.length === 0) {
      setError('Please select a Package');
      setTimeout(function() {
        setError('');
      }, 5000);
    } else {
      let notRequestedIds = [];
      selectedPackages.forEach(item => {
        if (item.loadStatus === PACKAGING_STATUS.NOT_REQUESTED.value) {
          notRequestedIds.push(item.packageId);
        }
      });
      if (notRequestedIds.length === 0) {
        setError('Please select a primary package that needs to be requested');
        setTimeout(function() {
          setError('');
        }, 5000);
      } else {
        updatePackageStatus(
          notRequestedIds,
          PACKAGING_STATUS.REQUESTED.id,
        ).then(res => {
          if (res.err) {
            return;
          } else {
            setSelectedPackages([]);
            onHandlegetOrdersOfEachVehicle();
          }
        });
      }
    }
  };

  const modalToggle = () => {
    setIsStartScan(false);
  };

  const onHandleComplete = () => {
    if (
      orderList.filter(
        item =>
          item.isManualProcurement &&
          !(
            item.loadStatus === PACKAGING_STATUS.SCANNED.value ||
            item.loadStatus === PACKAGING_STATUS.LOADED.value
          ),
      ).length > 0
    ) {
      setIsPendingManualProcumentItems(true);
    } else {
      onHandleDispatch();
    }
  };

  const onHandleDispatch = () => {
    let scannedIds = [];
    orderList.forEach(item => {
      if (item.loadStatus === PACKAGING_STATUS.SCANNED.value) {
        scannedIds.push(item.packageId);
      }
    });
    updatePackageStatusInLoading(
      selectedVehicle.id,
      scannedIds,
      PACKAGING_STATUS.LOADED.id,
      formatDate(selectedDate, null, UK_DATE_FORMAT, DATE_FORMAT_API),
      selectedDeliverySlot.id,
      selectedBaseName?.id,
    ).then(res => {
      if (res.err) {
        return;
      } else {
        onHandlegetOrdersOfEachVehicle();
      }
    });
    setIsPendingManualProcumentItems(false);
  };

  const onHandleScan = qrValue => {
    // let qrId = qrValue.split('_')[1];
    let qrId = qrValue;
    const tempResult = orderList.filter(item => item.supplierQRCodeId === qrId);
    if (tempResult.length === 0 || !qrId) {
      setError('The QR code is invalid.');
      setTimeout(function() {
        setError('');
      }, 5000);
    } else {
      orderList?.forEach(item => {
        if (item.supplierQRCodeId === qrId) {
          if (
            item.loadStatus === PACKAGING_STATUS.SCANNED.value ||
            item.loadStatus === PACKAGING_STATUS.LOADED.value
          ) {
            setIsModalOpen(true);
            setSelectedOrder(item);
          } else {
            // let tempOrderList = [...orderList];
            // tempOrderList.filter(
            //   pack => pack.packageId === item.packageId,
            // )[0].loadStatusId = PACKAGING_STATUS.SCANNED.id;
            // tempOrderList.filter(
            //   pack => pack.packageId === item.packageId,
            // )[0].loadStatus = PACKAGING_STATUS.SCANNED.value;
            // setOrderList(tempOrderList);
            audioElement.play();
            onHandleUpdateStatusOfaPackage(
              item.packageId,
              PACKAGING_STATUS.SCANNED.id,
            );
          }
        }
      });
    }
  };

  const onHandleUpdateStatusOfaPackage = (packageId, statusId) => {
    updatePackageStatus([packageId], statusId).then(res => {
      if (res.err) {
        return;
      } else {
        onHandlegetOrdersOfEachVehicle();
      }
    });
  };

  const onPendingManualProcumentItemsModalClose = () => {
    setIsPendingManualProcumentItems(false);
  };

  const onPackageSelect = selectedPackage => {
    let tempselectedPackages = [...selectedPackages];
    if (tempselectedPackages.includes(selectedPackage)) {
      const filteredvalues = tempselectedPackages.filter(
        item => item.packageId !== selectedPackage.packageId,
      );
      setSelectedPackages(filteredvalues);
    } else {
      tempselectedPackages.push(selectedPackage);
      setSelectedPackages(tempselectedPackages);
    }
  };

  return (
    <div className="loading-container">
      {error && <small className="error-message mt-1">{error}</small>}
      <div className="orders-of-vehicles">
        {orderList?.length === 0 ? (
          <div className="py-5 custom-empty-message">
            <p>{unavailableMessage}</p>
          </div>
        ) : (
          <>
            <div className="tableFixHead">
              <Table bordered>
                <thead>
                  <tr className="pe-none">
                    <th className="head th-rank">{t('RANK')}</th>
                    <th className="head th-buyer-name">{t('BUYER_NAME')}</th>
                    <th className="head th-tag-number">{t('TAG_NUMBER')}</th>
                    <th className="head th-primary-packages">
                      {t('PRIMARY_PACKAGES')}
                    </th>
                    <th className="head th-no-of-items">{t('NO_OF_ITEMS')}</th>
                    <th className="head th-no-of-items">{t('SUPPLIER')}</th>
                    <th className="head th-manual-proc"></th>
                  </tr>
                </thead>
                <tbody>
                  {orderList.map((item, index) => (
                    <tr
                      className={`${
                        item.loadStatus
                          ? item.loadStatus
                          : 'not-ready-for-loading-color'
                      } ${
                        !item.packageId ||
                        item.loadStatus ===
                          PACKAGING_STATUS.SENT_BY_SUPPLIER.value
                          ? 'cursor-arrow'
                          : 'cursor-pointer'
                      } ${selectedPackages.includes(item) && 'selected-row'}`}
                      key={index}
                      onClick={() => {
                        item.packageId &&
                          item.loadStatus !==
                            PACKAGING_STATUS.SENT_BY_SUPPLIER.value &&
                          onPackageSelect(item);
                      }}
                    >
                      <td className="td-rank">
                        {item.rank}
                        {/* <img
                          src={star}
                          width="14"
                          height="14"
                          className="star-icon"
                        /> */}
                      </td>
                      <td className="td-buyer-name">
                        {isMobile ? (
                          item.buyerName.length > 6 ? (
                            <BuyerName
                              buyerName={item.buyerName}
                              index={index}
                            />
                          ) : (
                            item.buyerName
                          )
                        ) : (
                          item.buyerName
                        )}{' '}
                        <span className="d-sm-none td-rank-color">
                          <br />R {item.rank}
                        </span>
                      </td>
                      <td className="td-tag-number">
                        {item.tagNumber}
                        <span className="d-sm-none td-supplier-color">
                          <br />
                          {item.supplierName?.length > 6 ? (
                            <BuyerName
                              buyerName={item.supplierName}
                              index={item.packageId}
                            />
                          ) : (
                            item.supplierName
                          )}
                        </span>
                      </td>
                      <td className="td-primary-packages">
                        {item.primaryPackages}
                      </td>
                      <td className="td-no-of-items">{item.noOfItems}</td>
                      <td className="td-supplier">{item.supplierName}</td>
                      <td className="td-manual-proc text-center">
                        {item.isManualProcurement && <>&#9733;</>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <CustomModal
              isOpen={isStartScan}
              toggle={modalToggle}
              isScrollable={true}
              title="QR Scanner"
              modalstyleClassName="modal-style"
              modalContentContainer="modal-container"
              modalHeaderClassName="modal-header"
              modalheaderTopic="modal-header-topic"
              modalBodyClassName="small"
            >
              <Scanner
                setStartScan={setIsStartScan}
                onHandleScan={onHandleScan}
              />
            </CustomModal>

            <div className="button-container">
              <Row>
                <Button
                  className="warning-icon-button"
                  onClick={() => setIsShowStatusInfo(!isShowStatusInfo)}
                  onBlur={() => setIsShowStatusInfo(false)}
                >
                  <img
                    src={warning}
                    width="28"
                    height="28"
                    className="warning-icon"
                    id="loadingStatusContainer"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={isShowStatusInfo}
                    autohide={false}
                    target="loadingStatusContainer"
                    toggle={() => setIsShowStatusInfo(!isShowStatusInfo)}
                  >
                    <StatusContainer />
                  </Tooltip>
                </Button>
              </Row>
              <Row>
                <Col md={6} sm={0}></Col>
                <Col md={2} sm={12}>
                  <Button
                    className="btn-outline-primary"
                    disabled={
                      orderList.filter(
                        order =>
                          order.primaryPackages === null ||
                          order.loadStatus === PACKAGING_STATUS.SCANNED.value ||
                          order.loadStatus === PACKAGING_STATUS.LOADED.value,
                      ).length === orderList.length
                    }
                    onClick={() => setIsStartScan(!isStartScan)}
                  >
                    Scan
                  </Button>
                </Col>
                <Col md={2} sm={12}>
                  <Button
                    className="btn-success"
                    disabled={selectedPackages?.length === 0}
                    onClick={onHandleRequest}
                  >
                    {t('REQUEST')}
                  </Button>
                </Col>
                <Col md={2} sm={12}>
                  <Button
                    className="btn-primary"
                    disabled={
                      orderList.filter(
                        order =>
                          order.loadStatusId !== PACKAGING_STATUS.SCANNED.id &&
                          !order.isManualProcurement,
                      ).length > 0
                    }
                    onClick={onHandleComplete}
                  >
                    {t('COMPLETE')}
                  </Button>
                </Col>
              </Row>
            </div>
            {isModalOpen && (
              <AlreadyScannedModal
                qrCode={selectedOrder?.supplierQRCodeId}
                setSelectedOrder={setSelectedOrder}
                setIsModalOpen={setIsModalOpen}
                isCompletedConsolidatedScan={true}
                isCompletedItemCountScan={false}
              />
            )}
            <CustomModal
              isOpen={isPendingManualProcumentItems}
              toggle={onPendingManualProcumentItemsModalClose}
              isScrollable={true}
              enableFooter={false}
              modalstyleClassName="modal-style"
              modalContentContainer="modal-container"
              modalHeaderClassName="modal-header"
              modalheaderTopic="modal-header-topic m-auto"
              modalFooterClassName="modal-footer"
              modalBodyClassName="small"
            >
              <>
                <p className="dispatch-modal my-4">
                  {t(
                    'ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITHOUT_THE_MANUAL_PROCUMENT_ITEMS',
                  )}
                </p>
                <div className="d-flex justify-content-around mx-5">
                  <Button
                    outline
                    color="primary"
                    type="submit"
                    className="form-submit  w-40"
                    onClick={() => onHandleDispatch()}
                  >
                    {t('YES')}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    className="form-submit w-40"
                    onClick={() => onPendingManualProcumentItemsModalClose()}
                  >
                    {t('NO')}
                  </Button>
                </div>
              </>
            </CustomModal>
          </>
        )}
      </div>
      <audio className="d-none" id="qrScannerAudioControl" controls>
        <source src={successMp3} type="audio/mpeg" />
        <source src={successOgg} type="audio/ogg" />
        <source src={successWav} type="audio/wav" />
      </audio>
    </div>
  );
}

OrdersOfVehicle.propTypes = {
  selectedVehicle: PropTypes.object,
  isRefresh: PropTypes.bool,
  setIsRefresh: PropTypes.func,
  selectedDate: PropTypes.object,
  selectedDeliverySlot: PropTypes.object,
  selectedBaseName: PropTypes.object,
};

export default OrdersOfVehicle;
