import React, { useState, useEffect, useContext } from 'react';
import InnerBody from '../../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import { CustomModal } from '../../../../../shared/components';
import { Switch } from '../../../../../shared/components';
import {
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { BuyerManagementContext } from '../../../../../apis/admin';
import moment from 'moment';
import { CalendarBlue } from '../../../../assets/icons';
import { CustomCalendar, Text } from '../../../../../shared/components';
import { Button } from 'reactstrap';
import { LoadingContext } from '../../../../../apis/LoadingContext';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import {
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../../shared/constants/constants';
//import useOutsideAlerter from '../../../../../shared/hooks/useOutSideAlert';
function RequiringVerification() {
  const { t } = useTranslation();
  const [
    isRequiringVerificationPopup,
    setIsRequiringVerificationPopup,
  ] = useState(false);
  const [openCalendar, setIsOpenCalendar] = useState(false);
  const [deliverySlotOptions, setDeliverySlotOptions] = useState([]);
  const [baseNameOptions, setBaseNameOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDeliverySlot, setIsDeliverySlot] = useState(false);
  const deliverySlotToggle = () => setIsDeliverySlot(!isDeliverySlot);

  const [isDeliveryBaseName, setIsDeliveryBaseName] = useState(false);
  const deliveryBaseNameToggle = () =>
    setIsDeliveryBaseName(!isDeliveryBaseName);
  const [error, setError] = useState('');
  const [
    requriementVeriAvailability,
    setRequriementVeriAvailability,
  ] = useState(true);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const buyerManagementContext = useContext(BuyerManagementContext);
  const {
    getDeliverySlots,
    getBaseNameByDeliverySlot,
    getPurchaseOrders,
    updatePurchaseOrder,
  } = buyerManagementContext;
  const {
    deliverySlotsResponse,
    baseNamesResponse,
    purchaseOrdersResponse,
  } = buyerManagementContext.state;
  const [clickedItem, setClickedItem] = useState(null);
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const [toggleAvailabilityResponse, setToggleAvailabilityResponse] = useState(
    null,
  );

  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;

  useEffect(() => {
    if (selectedDate) {
      getDeliverySlots(
        formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      );
      setSelectedDeliverySlot(null);
      setSelectedBaseName(null);
      setPurchaseOrders([]);
      setError('');
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDeliverySlot?.id) {
      getBaseNameByDeliverySlot(selectedDeliverySlot.id);
      setSelectedBaseName(null);
      setPurchaseOrders([]);
      setError('');
    }
  }, [selectedDeliverySlot]);

  useEffect(() => {
    if (selectedBaseName?.id) {
      getPurchaseOrders(
        selectedDeliverySlot.id,
        selectedBaseName.id,
        formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      );
    }
  }, [selectedBaseName]);

  useEffect(() => {
    if (baseNamesResponse?.res?.length > 0) {
      setBaseNameOptions(baseNamesResponse.res);
      setError('');
    } else {
      setBaseNameOptions([]);
      setSelectedBaseName(null);
      if (selectedDeliverySlot) {
        setError('A base name is not available for the selected slot.');
      }
    }
  }, [baseNamesResponse]);

  useEffect(() => {
    if (deliverySlotsResponse?.res?.length > 0) {
      setDeliverySlotOptions(deliverySlotsResponse.res);
      setError('');
    } else {
      setDeliverySlotOptions([]);
      setSelectedDeliverySlot(null);
      if (selectedDate) {
        setError('A delivery slot is not available for the selected date.');
      }
    }
  }, [deliverySlotsResponse]);

  useEffect(() => {
    if (purchaseOrdersResponse?.res) {
      setPurchaseOrders(purchaseOrdersResponse?.res);
    }
  }, [purchaseOrdersResponse]);

  const toggleRequiringVerification = () =>
    setIsRequiringVerificationPopup(!isRequiringVerificationPopup);

  const onChangeAvilableToggle = () => {
    setRequriementVeriAvailability(prevProps => !prevProps);
  };

  const updateProductAvailability = () => {
    const req = [
      {
        op: 'replace',
        path: 'RequireVerification',
        value: false,
      },
    ];
    updatePurchaseOrder(req, clickedItem?.purchaseOrderProductId)
      .then(result => {
        if (result?.message) {
          setToggleAvailabilityResponse({
            err: result?.message,
            isError: true,
          });
          setErrorPopupModal(true);
          return;
        }
        setToggleAvailabilityResponse({
          res: result?.message,
          isError: true,
        });
      })
      .catch(err => {
        setErrorPopupModal(true);
        setToggleAvailabilityResponse({ err, isError: true });
      });
    setIsRequiringVerificationPopup(false);
    setTimeout(() => {
      getPurchaseOrders(
        selectedDeliverySlot.id,
        selectedBaseName.id,
        formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      );
    }, 3000);
  };

  const toggleErrorPopup = () => {
    setErrorPopupModal(!errorPopupModal);
  };

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={toggleAvailabilityResponse?.err ? 'Upload failure' : ''}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {toggleAvailabilityResponse?.err}
    </CustomModal>
  );
  const requiringVerificationModal = () => {
    return (
      <CustomModal
        showClose={true}
        isOpen={isRequiringVerificationPopup}
        closeIconAction={toggleRequiringVerification}
        // toggle={toggleRequiringVerification}
        isScrollable={true}
        enableFooter={false}
        title={t('SUPPLIER_DETAILS')}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
        negativeBtnTitle="OK"
      >
        <div className="my-3 popup-suppilier-info">
          {clickedItem?.supplierCompanies?.map((supplier, index) => (
            <p style={{ textAlign: 'left' }} key={index}>
              {supplier?.id}-{supplier?.name}-{supplier?.baseMarketName}
            </p>
          ))}

          <Switch
            label={t('AVAILABILITY')}
            labelClassName="form-label"
            id="supplier-requriement-availability"
            field="availability"
            onChange={onChangeAvilableToggle}
            checked={requriementVeriAvailability}
          />
          <Button
            color="primary"
            type="submit"
            className="form-submit"
            onClick={() => {
              if (requriementVeriAvailability) {
                setIsRequiringVerificationPopup(false);
                setClickedItem(null);
                return;
              }
              updateProductAvailability();
              setRequriementVeriAvailability(true);
            }}
          >
            {t('OK')}
          </Button>
        </div>
      </CustomModal>
    );
  };

  return (
    <InnerBody
      topic={t('REQUIRING_VERIFICAION')}
      isDisable={true}
      className="requiringVerification"
    >
      {errorPopup()}
      <div className="requiring-verification-container">
        {requiringVerificationModal()}
        <Row className="px-2">
          <div className="col-lg-4 col-sm-12">
            <Text
              type="text"
              placeholder={t('SELECT_A_DELIVERY_DATE')}
              className="delivery-date"
              value={selectedDate && moment(selectedDate).format('DD/MM/YYYY')}
              rightIcon={CalendarBlue}
              id="calendar"
              field="calendar"
              onClick={() => setIsOpenCalendar(prevProps => !prevProps)}
              rightIconHandle={() => setIsOpenCalendar(prevProps => !prevProps)}
              rightIconGroupClassName="position-relative cursor-pointer"
              rightIconClassName="calendar-icon"
              showErrors={false}
              readOnly
            />
          </div>
          {openCalendar && (
            <CustomCalendar
              onClose={() => setIsOpenCalendar(false)}
              name="calendar"
              onChange={e => {
                setSelectedDate(e);
                setIsOpenCalendar(false);
              }}
              value={selectedDate && new Date(selectedDate)}
            />
          )}

          <Dropdown
            disabled={!selectedDate || deliverySlotOptions?.length == 0}
            isOpen={isDeliverySlot}
            toggle={deliverySlotToggle}
            direction="down"
            className="col-lg-4 col-sm-12 mb-3"
            style={deliverySlotOptions?.length === 0 ? { opacity: '0.6' } : {}}
          >
            <DropdownToggle
              caret
              className={
                selectedDeliverySlot === null ? 'grey-text' : 'black-text'
              }
            >
              {selectedDeliverySlot === null
                ? t('SELECT_A_DELIVERY_SLOT')
                : `${selectedDeliverySlot?.startTime} - ${selectedDeliverySlot?.endTime}`}
            </DropdownToggle>
            <DropdownMenu>
              {deliverySlotOptions?.map(item => (
                <DropdownItem
                  key={item?.id}
                  onClick={() => {
                    setSelectedDeliverySlot(item);
                  }}
                >
                  {`${item?.startTime} - ${item?.endTime}`}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>

          <Dropdown
            disabled={!selectedDeliverySlot || baseNameOptions?.length == 0}
            isOpen={isDeliveryBaseName}
            toggle={deliveryBaseNameToggle}
            direction="down"
            className="col-lg-4 col-sm-12 mb-3 "
            style={baseNameOptions?.length === 0 ? { opacity: '0.6' } : {}}
          >
            <DropdownToggle
              caret
              className={
                selectedDeliverySlot === null ? 'grey-text' : 'black-text'
              }
            >
              {selectedBaseName === null
                ? t('SELECT_A_BASE_NAME')
                : `${selectedBaseName?.name}`}
            </DropdownToggle>
            <DropdownMenu>
              {baseNameOptions?.map(item => (
                <DropdownItem
                  key={item?.id}
                  onClick={() => {
                    setSelectedBaseName(item);
                  }}
                >
                  {item?.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Row>

        {error && <small className="error-message mt-1">{error}</small>}
        {(selectedBaseName?.length === 0 || selectedBaseName === null) && (
          <div className="py-5 custom-empty-message">
            <p>{t('SELECT_A_BASE_TO_VIEW_THE_AVAILABLE_RECORDS')}</p>
          </div>
        )}
        {selectedBaseName?.id && (
          <>
            {purchaseOrders.length > 0 ? (
              <div className="tableFixHead">
                <Table bordered>
                  <tr>
                    <th>Buyer Company Name</th>
                    <th>Product Name</th>
                    <th>Required Quantity</th>
                  </tr>
                  {purchaseOrders.map(purchaseOrder => {
                    return (
                      <tr
                        key={purchaseOrder.id}
                        onClick={() => {
                          setClickedItem(purchaseOrder);
                          setIsRequiringVerificationPopup(true);
                        }}
                      >
                        <td>{purchaseOrder.buyerCompanyName}</td>
                        <td>{purchaseOrder.productName}</td>
                        <td className="requiredQuantity">
                          {purchaseOrder.requiredQuantity +
                            ' ' +
                            purchaseOrder.unitMeasure}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            ) : !loading ? (
              <div className="py-5 custom-empty-message">
                <p>{t('NO_ORDERS_AVAILABLE')}</p>
              </div>
            ) : null}
          </>
        )}
      </div>
    </InnerBody>
  );
}

export default RequiringVerification;
