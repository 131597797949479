import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

const CustomModal = props => {
  const {
    toggle,
    action,
    modalstyleClassName = '',
    modalHeaderClassName = '',
    modalContentContainer = '',
    modalheaderTopic = '',
    modalBodyClassName = '',
    modalBodyContent = '',
    modalFooterClassName = '',
    isOpen,
    title,
    negativeBtnTitle,
    positiveBtnTitle,
    children,
    isConfirmation,
    hideCancel,
    size,
    backdrop,
    isScrollable,
    enableFooter,
    alignMent = 'center',
    showClose = true,
    positiveBtnClass = '',
    negativeBtnClass = '',
    id,
    closeIconAction,
  } = props;

  return (
    <>
      <Modal
        size={size}
        className={modalstyleClassName}
        isOpen={isOpen}
        toggle={toggle}
        backdrop={backdrop}
        scrollable={isScrollable}
        centered
        id={id}
      >
        <div className={modalContentContainer}>
          {showClose && (
            <ModalHeader
              className={modalHeaderClassName}
              style={{ position: 'absolute', top: '15px', right: '45px' }}
              toggle={hideCancel ? '' : toggle ? toggle : closeIconAction}
            ></ModalHeader>
          )}

          <ModalBody
            cssModule={{ 'modal-body': `w-100 text-${alignMent}` }}
            className={modalBodyClassName}
          >
            <p className={modalheaderTopic + ' mb-3'}>{title}</p>
            <p className={modalBodyContent}>{children}</p>
          </ModalBody>
          {enableFooter ? (
            <ModalFooter
              cssModule={{ 'modal-footer': 'w-100 text-center' }}
              className={modalFooterClassName}
            >
              {isConfirmation ? (
                <Button
                  className={`btn-primary ${positiveBtnClass}`}
                  id="modal-footer-btn-save"
                  onClick={action}
                >
                  {positiveBtnTitle ? positiveBtnTitle : 'Save'}
                </Button>
              ) : null}
              {negativeBtnTitle !== 'no' ? (
                <Button
                  className={`btn-sm btn-primary ${negativeBtnClass}`}
                  id="modal-footer-btn-cancel"
                  onClick={toggle}
                >
                  {negativeBtnTitle ? negativeBtnTitle : 'Cancel'}
                </Button>
              ) : (
                ''
              )}
            </ModalFooter>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

CustomModal.propTypes = {
  toggle: PropTypes.func,
  closeIconAction: PropTypes.func,
  action: PropTypes.func,
  isScrollable: PropTypes.bool,
  modalstyleClassName: PropTypes.string,
  modalHeaderClassName: PropTypes.string,
  modalContentContainer: PropTypes.string,
  modalheaderTopic: PropTypes.string,
  modalBodyClassName: PropTypes.string,
  modalBodyContent: PropTypes.string,
  modalFooterClassName: PropTypes.string,
  hideCancel: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  negativeBtnTitle: PropTypes.string,
  positiveBtnTitle: PropTypes.string,
  children: PropTypes.any,
  isConfirmation: PropTypes.bool,
  modalStyle: PropTypes.any,
  size: PropTypes.string,
  buttonLoader: PropTypes.bool,
  isLoading: PropTypes.bool,
  enableFooter: PropTypes.bool,
  backdrop: PropTypes.string,
  alignMent: PropTypes.string,
  showClose: PropTypes.bool,
  positiveBtnClass: PropTypes.string,
  negativeBtnClass: PropTypes.string,
  id: PropTypes.string,
};

export default CustomModal;
