import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Table, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SupplierContext } from '../../../../apis/supplier';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { PACKAGING_STATUS } from '../../../../shared/constants/constants';
import { warning } from '../../../assets/img';
import StatusContainer from './StatusContainer';
import { useDeviceDetect } from '../../../../shared/hooks';

function OrderLoadingSummary({
  selectedDeliverySlotId,
  date,
  currentStep,
  setIsOrderSortingDetailPage,
  isRefresh,
  setIsRefresh,
}) {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const supplierContext = useContext(SupplierContext);
  const { getOrderLoadingSummary, updatePackageStatus } = supplierContext;
  const [items, setItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedPackageIds, setSelectedPackageIds] = useState([]);
  const [isAvalaible, setIsAvalaible] = useState(true);
  const [error, setError] = useState('');
  const [isShowStatusInfo, setIsShowStatusInfo] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [ishidden, setIsHidden] = useState(false);

  useEffect(() => {
    setCurrentIndex(0);
    handlegetOrderLoadingSummary();
    setIsOrderSortingDetailPage(false);
    setError('');
  }, [currentStep]);

  useEffect(() => {
    if (isRefresh) {
      handlegetOrderLoadingSummary();
      setError('');
      setIsRefresh(false);
    }
  }, [isRefresh]);

  const handlegetOrderLoadingSummary = () => {
    getOrderLoadingSummary(selectedDeliverySlotId, date).then(res => {
      if (res.err) {
        return;
      } else {
        if (res.res.length === 0) {
          setIsAvalaible(false);
        }
        setItems(res.res);
        handleIsDisable(res.res);
        handleHiddenSendButton(res.res);
      }
    });
  };

  const onMove = (_, current) => {
    setCurrentIndex(current);
    setError('');
    setSelectedPackageIds([]);
  };

  const onPackageSelect = packageId => {
    setError('');
    let tempselectedPackageIds = [...selectedPackageIds];
    if (tempselectedPackageIds.includes(packageId)) {
      const filteredvalues = tempselectedPackageIds.filter(
        id => id !== packageId,
      );
      setSelectedPackageIds(filteredvalues);
    } else {
      tempselectedPackageIds.push(packageId);
      setSelectedPackageIds(tempselectedPackageIds);
    }
  };

  const getOrderStatus = statusId => {
    //this should change
    switch (statusId) {
      case PACKAGING_STATUS.NOT_REQUESTED.id:
        return PACKAGING_STATUS.NOT_REQUESTED.value;
      case PACKAGING_STATUS.REQUESTED.id:
        return PACKAGING_STATUS.REQUESTED.value;
      case PACKAGING_STATUS.SENT_BY_SUPPLIER.id:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.RECEIVED.id:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.SCANNED.id:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.LOADED.id:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.UNLOAD_SCANNED.id:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.UNLOADED.id:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
    }
  };

  const handleIsDisable = allItems => {
    let tempIsDisable = [];
    allItems.forEach(item => {
      item?.packages.forEach(pack => {
        if (pack.statusId === PACKAGING_STATUS.REQUESTED.id) {
          tempIsDisable.push(false);
        }
      });
    });
    if (tempIsDisable.length > 0) {
      setIsDisable(false);
    }
  };

  const handleHiddenSendButton = allItems => {
    let tempIsHidden = [];
    allItems.forEach(item => {
      item?.packages.forEach(pack => {
        if (pack.statusId !== PACKAGING_STATUS.SENT_BY_SUPPLIER.id) {
          tempIsHidden.push(pack);
        }
      });
    });
    if (tempIsHidden.length === 0) {
      setIsHidden(true);
    }
  };

  const onHandleSend = () => {
    if (selectedPackageIds.length === 0) {
      setError('Please select the order that needs to be sent');
    } else {
      updatePackageStatus(
        selectedPackageIds,
        PACKAGING_STATUS.SENT_BY_SUPPLIER.id,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          setSelectedPackageIds([]);
          handlegetOrderLoadingSummary();
        }
      });
    }
  };
  return (
    <>
      {!isAvalaible ? (
        <div className="py-5 custom-empty-message">
          <p>{t('NOT_AVAILABLE_AT_THE_MOMENT._PLEASE_CHECK_LATER')}</p>
        </div>
      ) : (
        <>
          {items.length !== 0 && (
            <div className="order-loading-summary">
              <Splide
                options={{
                  rewind: true,
                  arrows: items.length !== 1,
                  pagination: false,
                }}
                hasTrack={false}
                onMove={(a, b, c) => onMove(a, b, c)}
              >
                <SplideTrack>
                  {items.map(vehicle => (
                    <SplideSlide key={vehicle.vehicleId}>
                      <div className="tableFixHead">
                        <Table className="table-fixed">
                          <thead>
                            <tr className="table-header">
                              <th colSpan={3}>
                                <p>
                                  {vehicle.vehicleTypeName} /{' '}
                                  {vehicle.vehicleNumber} / {vehicle.location}
                                </p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="head">
                              <td className="th-tag-no">
                                {isMobile ? t('TAG_NO') : t('TAG_NUMBER')}
                              </td>
                              <td className="th-no-of-items">
                                {t('NO_OF_ITEMS')}
                              </td>
                              <td className="th-packaging-type">
                                {isMobile
                                  ? t('PRIM_PACK')
                                  : t('PRIMARY_PACKAGING')}
                              </td>
                            </tr>
                            {vehicle.packages?.map((order, orderIndex) => (
                              <tr
                                key={orderIndex}
                                className={`body ${getOrderStatus(
                                  order.statusId,
                                )}  ${order.statusId !==
                                  PACKAGING_STATUS.REQUESTED.id && 'pe-none'}
                          ${selectedPackageIds.includes(order.packageId) &&
                            'selected-row'}`}
                                onClick={() => {
                                  if (
                                    order.statusId ===
                                    PACKAGING_STATUS.REQUESTED.id
                                  ) {
                                    onPackageSelect(order.packageId);
                                  }
                                }}
                              >
                                <td className="td-tag-no">{order.tagNo}</td>
                                <td className="td-no-of-items">
                                  {order.noOfItems}
                                </td>
                                <td className="td-packaging-type">
                                  {order.packagingType}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <Container className="arrows-horizontally-scroller p-0">
                  <div className="splide__arrows">
                    <Row>
                      <Col className="p-0">
                        <a
                          className={`splide__arrow--prev ${currentIndex ===
                            0 && 'btn-disable d-none'}`}
                          disabled={currentIndex == 0}
                        >
                          <span className="left"></span>
                        </a>
                        <a
                          className={`splide__arrow--next ${currentIndex ===
                            items?.length - 1 && 'btn-disable d-none'}`}
                          disabled={currentIndex == items?.length - 1}
                        >
                          <span className="right"></span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Splide>

              <div className="button-container">
                {/* {isShowStatusInfo && <StatusContainer />} */}
                <Row>
                  <Button
                    className="warning-icon-button"
                    onClick={() => setIsShowStatusInfo(!isShowStatusInfo)}
                    onBlur={() => setIsShowStatusInfo(false)}
                  >
                    <img
                      src={warning}
                      width="28"
                      height="28"
                      className="warning-icon"
                      id="statusContainer"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={isShowStatusInfo}
                      autohide={false}
                      target="statusContainer"
                      toggle={() => setIsShowStatusInfo(!isShowStatusInfo)}
                    >
                      <StatusContainer />
                    </Tooltip>
                  </Button>
                </Row>
                <div className="buttons">
                  <Row className="mb-1">
                    {error && (
                      <small className="error-message mt-1">{error}</small>
                    )}
                    <Col md={9} sm={0}></Col>
                    {!ishidden && (
                      <Col md={3} sm={12}>
                        <Button
                          color="primary"
                          type="submit"
                          className="btn-primary send-btn"
                          disabled={isDisable}
                          onClick={onHandleSend}
                        >
                          {t('SEND')}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

OrderLoadingSummary.propTypes = {
  date: PropTypes.any,
  selectedDeliverySlotId: PropTypes.number,
  currentStep: PropTypes.number,
  setIsOrderSortingDetailPage: PropTypes.func,
  isRefresh: PropTypes.bool,
  setIsRefresh: PropTypes.func,
};

export default OrderLoadingSummary;
