import React, { useEffect, useContext, useState, useRef } from 'react';
import { trash, search, closeX } from '../../../assets/icons';
import RightIconButton from '../shared/RightIconButton';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';
import { SupplierContext } from '../../../../apis/supplier';
import { useDeviceDetect } from '../../../../shared/hooks';
import Card from '../shared/Card';
import DropdownSelectorInventory from '../shared/DropdownSelector';
import { CustomModal } from '../../../../shared/components';
import UpdateSupplierProductModal from './UpdateSupplierProductModal';
import InnerBody from '../../../../content/InnerBody';
import UpdateConfirmationModal from './UpdateConfirmationModal';
import moment from 'moment';

const DESKTOP_VIEW_ITEMS = 6;
const MOBILE_VIEW_ITEMS = 1;
function InventoryUpdates() {
  const [currentItems, setCurrentItems] = useState([]);
  const [clickedItem, setClickedItem] = useState(null);
  const [currentBoundary, setCurrentBoundary] = useState(0);
  const [deleteItems, setDeleteItems] = useState([]);
  const [pageViewItems, setPageViewItems] = useState(MOBILE_VIEW_ITEMS);
  const [selectedFilterCategories, setSelectedFilterCategories] = useState([]);
  const [updateProductModal, setUpdateProductModal] = useState(false);
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const [saveInventoryItem, setSaveInventoryItem] = useState(null);
  const { isMobile, isTablet, isLargeDesktop } = useDeviceDetect();
  const supplierContext = useContext(SupplierContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [
    tempStoreForUpdatedInventory,
    setTempStoreForUpdatedInventory,
  ] = useState(null);
  const splideRef = useRef();
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  const [isInventorySuccessModal, setIsInventorySuccessModal] = useState(false);
  const toggleInventorySuccessModal = () => {
    setIsInventorySuccessModal(!isInventorySuccessModal);
  };
  const {
    getInventoryList,
    getFilterCategories,
    getSearchSuggestions,
    deleteInventoryItems,
    updateInventory,
    setSearchSuggestionsRes,
  } = supplierContext;
  const {
    inventoriesRes,
    filterCategoriesRes,
    searchSuggestionsRes,
    updateInventoryRes,
    deleteInventoryRes,
  } = supplierContext.state;
  const { t } = useTranslation();
  const [
    inventoryUpdateConfirmation,
    setInventoryUpdateConfirmation,
  ] = useState(false);
  useEffect(() => {
    getInventoryList(setQueryParam())
      .then(() =>
        setTimeout(() => {
          setIsLoading(false);
        }, 1000),
      )
      .catch(() => setIsLoading(false));

    getFilterCategories();
    setPageViewItems(isMobile ? MOBILE_VIEW_ITEMS : DESKTOP_VIEW_ITEMS);
  }, [pageViewItems, isMobile, isTablet]);

  useEffect(() => {
    if (deleteInventoryRes?.res) {
      refreshPage();
    }
    setDeleteItems([]);
  }, [deleteInventoryRes]);

  const refreshPage = () => {
    getInventoryList(setQueryParam(selectedFilterCategories, searchTerm));
    setCurrentBoundary(currentBoundary);
  };
  const setQueryParam = (categoryFilter, text = null) => {
    let categoryId;
    if (
      (categoryFilter === undefined ||
        // selectedFilterCategories.length === 0 ||
        categoryFilter?.length === 0 ||
        categoryFilter[0]?.id === '') &&
      (text === null || text === '')
    ) {
      return '';
    }
    if (categoryFilter?.length > 0) {
      categoryId = categoryFilter[0]?.id;
    } else {
      categoryId = selectedFilterCategories[0]?.id;
    }
    let queryString = '';
    queryString = `keyword=`;
    if (searchTerm) {
      queryString = `keyword=${searchTerm}`;
    }
    if (text !== null) {
      queryString = `keyword=${text}`;
    }
    if (categoryId === '' || categoryId === undefined || categoryId === null) {
      return queryString;
    }
    queryString = queryString + `&categoryId=`;
    if (categoryId) {
      queryString = queryString + `${categoryId}`;
    }
    return queryString;
  };
  useEffect(() => {
    if (inventoriesRes?.res) {
      setCurrentItems(
        inventoriesRes?.res?.slice(
          currentBoundary,
          currentBoundary + pageViewItems,
        ),
      );
      const temp = inventoriesRes?.res?.slice(
        currentBoundary,
        currentBoundary + pageViewItems,
      );

      if (temp?.length > 0) {
        setCurrentItems(temp);
      } else {
        if (inventoriesRes?.res?.length > 0) {
          onScroll(false);
        }
      }
    }
  }, [inventoriesRes]);

  const onScroll = next => {
    if (next) {
      setCurrentItems(
        inventoriesRes?.res.slice(
          currentBoundary + pageViewItems,
          currentBoundary + pageViewItems * 2,
        ),
      );
      setCurrentBoundary(currentBoundary + pageViewItems);
    } else {
      setCurrentItems(
        inventoriesRes?.res.slice(
          currentBoundary - pageViewItems,
          currentBoundary,
        ),
      );
      setCurrentBoundary(currentBoundary - pageViewItems);
    }
  };

  const onChecked = id => {
    if (!deleteItems.includes(id)) {
      setDeleteItems(prevProps => [...prevProps, id]);
    } else {
      setDeleteItems(prev => prev.filter(item => item !== id));
    }
  };

  const removeSearchSuggestion = () => {
    setSearchSuggestionsRes({ res: [], err: null });
  };

  const onChangeSearch = event => {
    setSearchTerm(event.target.value);
    if (event.target.value.length > 2) {
      getSearchSuggestions(
        setQueryParam(selectedFilterCategories, event.target.value),
      );
    }
  };
  const onKeyPress = event => {
    if (event.charCode === 13) {
      onSearchItems(event.target.value, selectedFilterCategories);
    }
  };

  const onSearchItems = (searchTerm, categoryFilter = []) => {
    setSearchTerm(searchTerm);
    getInventoryList(setQueryParam(categoryFilter, searchTerm));
    setCurrentBoundary(0);
    removeSearchSuggestion();
    if (isMobile) {
      splideRef.current.go(0);
    }
  };

  const toggleUpdateProduct = () => {
    setSaveInventoryItem(null);
    setUpdateProductModal(!updateProductModal);
  };
  const toggleUpdateConfirmation = () => {
    setInventoryUpdateConfirmation(!inventoryUpdateConfirmation);
  };

  const toggleErrorPopup = () => {
    setErrorPopupModal(!inventoryUpdateConfirmation);
  };

  const onSaveInventory = inventory => {
    toggleUpdateProduct();
    setSaveInventoryItem(inventory);
    if (inventory.form.availability.value) {
      updateSupplierInventory(inventory, inventory?.item?.supplierCompanyId);
    }
    if (!inventory.form.availability.value) {
      setInventoryUpdateConfirmation(true);
    }
  };

  useEffect(() => {
    if (updateInventoryRes?.res) {
      let oldQuantity = isItemAvailable(saveInventoryItem?.item)
        ? parseFloat(saveInventoryItem?.item?.quantity)
        : 0;

      const newInven =
        parseFloat(saveInventoryItem?.form?.newInventory?.value) + oldQuantity;
      setTempStoreForUpdatedInventory({
        unitType: saveInventoryItem?.item?.unitType,
        productName: saveInventoryItem?.item?.product?.name,
        availability: saveInventoryItem?.form?.availability?.value,
        newInventory: (
          newInven.toString() +
          ' ' +
          saveInventoryItem?.item?.unitType
        ).toString(),
        price: 'LKR ' + saveInventoryItem?.form?.newPrice?.value,
      });

      if (saveInventoryItem?.form?.availability?.value) {
        setTimeout(() => {
          setIsInventorySuccessModal(false);
          setTempStoreForUpdatedInventory(null);
        }, 4000);
      }
      setClickedItem(null);
      setSaveInventoryItem(null);
      refreshPage();
    }
    if (updateInventoryRes?.err) {
      setErrorPopupModal(true);
      setTimeout(() => {
        setErrorPopupModal(false);
      }, 2000);
      setClickedItem(null);
      setSaveInventoryItem(null);
    }
  }, [updateInventoryRes]);

  useEffect(() => {
    if (
      tempStoreForUpdatedInventory &&
      tempStoreForUpdatedInventory?.availability
    ) {
      toggleInventorySuccessModal();
    }
  }, [tempStoreForUpdatedInventory]);

  const updateSupplierInventory = (pro, supCompanyId) => {
    const newUpdatedItem = {
      isAvailable: pro.form.availability.value,
      supplierCompanyId: supCompanyId,
      quantity: pro.form.availability.value
        ? parseFloat(pro.form.newInventory.value)
        : pro.item.quantity,
      unitPrice: pro.form.availability.value
        ? parseFloat(pro.form.newPrice.value)
        : pro.item.unitPrice,
    };

    updateInventory(newUpdatedItem, pro?.item?.product?.id);
  };

  const handleDeleteItems = () => {
    setIsDeleteModal(true);
    const post = {
      supplierCompanyId: inventoriesRes?.res[0]?.supplierCompanyId,
      productIds: deleteItems,
    };
    deleteInventoryItems(post);
    setDeleteItems([]);
  };

  const setFilterCategories = () => {
    let tempArray = [];
    let parentCategories = [];
    filterCategoriesRes?.res?.forEach(element => {
      if (element?.parentCategoryId === null) {
        parentCategories.push(element);
      }
    });

    filterCategoriesRes?.res?.forEach(element => {
      if (element?.parentCategoryId) {
        let temp = element;
        temp['parentCategoryName'] = filterCategoriesRes?.res?.filter(
          item => item?.id === element?.parentCategoryId,
        )[0]?.name;
        tempArray.push(element);
      }
    });
    //check parent has subcategory
    parentCategories?.forEach(element => {
      const newArray = [
        {
          id: element?.id,
          name: 'All ' + element?.name,
          parentCategoryId: element?.id,
          parentCategoryName: element?.name,
        },
      ].concat(tempArray);
      tempArray = newArray;
    });
    const newArray = [
      {
        id: '',
        name: 'All Categories',
        parentCategoryId: '',
        parentCategoryName: 'All Categories',
      },
    ].concat(tempArray);
    tempArray = newArray;
    return tempArray;
  };

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={t('UPDATE_PRODUCT')}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="model-inventory-update-header"
      modalBodyClassName="small"
    >
      {updateInventoryRes?.err}
    </CustomModal>
  );

  const onSaveUnAvailableProduct = () => {
    updateSupplierInventory(
      saveInventoryItem,
      saveInventoryItem?.item?.supplierCompanyId,
    );
  };

  const isItemAvailable = item =>
    item?.isAvailable && moment().isBefore(item?.validTillDate);
  const inventroyUpdateSuccessPopup = () => (
    <CustomModal
      isOpen={isInventorySuccessModal}
      toggle={toggleInventorySuccessModal}
      isScrollable={true}
      title={t('SUCCESS')}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic mt-0 mb-3"
      modalFooterClassName="modal-footer"
      modalBodyClassName="small"
    >
      {t('NOW_YOU_HAVE_INVENTORY_UNIT_MEASURE_PRODUCT_NAME_PRICE_PER_ITEM', {
        INVENTORY: tempStoreForUpdatedInventory?.newInventory,
        PRODUCT_NAME: tempStoreForUpdatedInventory?.productName,
        PRICE_PER_ITEMS: tempStoreForUpdatedInventory?.price,
        UNIT_MEASURE: tempStoreForUpdatedInventory?.unitType,
      })}
    </CustomModal>
  );
  const deleteConfirmationPopUp = () => (
    <CustomModal
      isOpen={isDeleteModal}
      toggle={toggleDeleteModal}
      isScrollable={true}
      title={t('DELETE_CONFIRMATION')}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic mt-0 mb-3"
      modalFooterClassName="modal-footer"
      modalBodyClassName="small"
    >
      <p className="my-3">{t('DO_YOU_WANT_TO_DELETE_THIS_ITEM')}</p>
      <Button
        color="primary"
        type="submit"
        className="form-submit"
        onClick={() => {
          handleDeleteItems();
          setIsDeleteModal(prevProp => !prevProp);
        }}
      >
        {t('CONFIRM_TEXT')}
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button
        color="primary"
        type="submit"
        className="btn-second"
        onClick={() => {
          setIsDeleteModal(!isDeleteModal);
          setDeleteItems([]);
        }}
      >
        {t('CANCEL')}
      </Button>
    </CustomModal>
  );
  return (
    <InnerBody isDisable={true} topic={t('INVENTORY_UPDATES')}>
      <div
        id="supplier-inventory"
        onClick={() => {
          removeSearchSuggestion();
        }}
        style={{ padding: '0', position: 'relative' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
          className="my-2"
        >
          <div style={isMobile ? { width: '12%' } : { width: '25%' }}>
            <DropdownSelectorInventory
              displayValue="name"
              isMobile={isMobile}
              isTablet={isTablet}
              isLargeDesktop={isLargeDesktop}
              filterCategories={setFilterCategories()}
              placeholder={isMobile ? '☰' : t('ALL_CATEGORIES')}
              onSelect={cate => {
                setSelectedFilterCategories(cate);
                onSearchItems('', cate);
              }}
            />
          </div>
          <div
            style={isMobile ? { width: '88%' } : { width: '82.5%' }}
            className="search-container"
          >
            <div className="search-inner">
              <span className="divider">|</span>
              <div className="input-group" style={{ marginBottom: '0px' }}>
                <input
                  className="form-control border-end-0 border"
                  id="inventory-search-input"
                  type="text"
                  value={searchTerm}
                  onChange={onChangeSearch}
                  placeholder={t('SEARCH_AREA')}
                  onKeyPress={onKeyPress}
                />
                <span
                  style={{ paddingRight: '5px' }}
                  className="input-group-append border"
                >
                  <img
                    width="15"
                    height="100%"
                    src={closeX}
                    alt="close icon "
                    className="cursor-pointer"
                    onClick={() => setSearchTerm('')}
                  />
                </span>
              </div>
              <Button
                onClick={() =>
                  onSearchItems(searchTerm, selectedFilterCategories)
                }
              >
                {isMobile ? (
                  <img width="12.5" src={search} alt="search" />
                ) : (
                  <>
                    {t('SEARCH')}&nbsp;&nbsp;
                    <img width="12.5" src={search} alt="search" />
                  </>
                )}
              </Button>
            </div>
            <div className="dropdown">
              {searchSuggestionsRes?.err === null &&
                searchTerm?.length > 2 &&
                searchSuggestionsRes?.res?.length > 0 &&
                searchSuggestionsRes?.res?.slice(0, 10)?.map(item => (
                  <div
                    onClick={() => onSearchItems(item?.product?.name)}
                    className="dropdown-row"
                    key={item?.product?.name}
                  >
                    {item?.product?.name}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div style={{ textAlign: 'right' }} className="mb-2">
          <RightIconButton
            isDisabled={deleteItems.length < 1}
            onClick={() => {
              if (deleteItems.length === 0) {
                return;
              }
              setIsDeleteModal(true);
            }}
            text={t('DELETE_ITEMS')}
            icon={trash}
          />
        </div>
        {deleteConfirmationPopUp()}
        {currentItems.length > 0 || isLoading ? (
          <>
            {!isMobile && (
              <Container className="p-0">
                <Row>
                  <Col className="p-0">
                    <a
                      className={
                        currentBoundary < pageViewItems
                          ? 'btn-disable d-none'
                          : ''
                      }
                      disabled={currentBoundary < pageViewItems}
                      onClick={() => onScroll(false)}
                    >
                      <span className="left"></span>
                    </a>

                    <a
                      className={
                        currentBoundary + pageViewItems >=
                        inventoriesRes?.res?.length
                          ? 'btn-disable d-none'
                          : ''
                      }
                      disabled={
                        currentBoundary + pageViewItems >=
                        inventoriesRes?.res?.length
                      }
                      onClick={() => onScroll(true)}
                    >
                      <span className="right"></span>
                    </a>
                  </Col>
                </Row>
              </Container>
            )}
            {inventroyUpdateSuccessPopup()}
            <Container>
              <Row>
                {currentItems.map((item, index) => (
                  <>
                    <Col sm={6} lg={4} xl={4} md={6} key={index}>
                      {!isMobile ? (
                        <Card
                          onClick={e => {
                            if (e.target.type === 'checkbox') {
                              return;
                            }
                            setUpdateProductModal(true);
                            setClickedItem(item);
                          }}
                          isAvailable={item?.isAvailable}
                          validTillDate={item?.validTillDate}
                          validFromDate={item?.validFromDate}
                          key={item?.product?.id}
                          id={item?.product?.id}
                          src={item?.product?.imageName}
                          title={item?.product?.name}
                          unit={item?.quantity + ' ' + item?.unitType}
                          price={
                            'LKR ' + item?.unitPrice + '/' + item?.unitType
                          }
                          onHandleCheck={onChecked}
                          isChecked={deleteItems.includes(item?.product?.id)}
                        />
                      ) : (
                        inventoriesRes?.res?.map((item, key) => (
                          <div className="mb-3" key={key}>
                            <Card
                              onClick={e => {
                                if (e.target.type === 'checkbox') {
                                  return;
                                }
                                setUpdateProductModal(true);
                                setClickedItem(item);
                              }}
                              isAvailable={item?.isAvailable}
                              validTillDate={item?.validTillDate}
                              validFromDate={item?.validFromDate}
                              key={item?.product?.id}
                              id={item?.product?.id}
                              src={item?.product?.imageName}
                              title={item?.product?.name}
                              unit={item?.quantity + item?.unitType}
                              price={
                                'LKR ' + item?.unitPrice + '/' + item?.unitType
                              }
                              onHandleCheck={onChecked}
                              isChecked={deleteItems.includes(
                                item?.product?.id,
                              )}
                            />
                          </div>
                        ))
                      )}
                    </Col>
                  </>
                ))}
                <CustomModal
                  isOpen={updateProductModal}
                  toggle={toggleUpdateProduct}
                  isScrollable={true}
                  title={t('UPDATE_PRODUCT')}
                  modalstyleClassName="modal-style"
                  modalContentContainer="modal-container modal-container-intventory-popup "
                  modalHeaderClassName="modal-header"
                  modalheaderTopic="model-inventory-update-header mb-3"
                  modalBodyClassName="small"
                  alignMent="left"
                >
                  <UpdateSupplierProductModal
                    item={clickedItem}
                    currentPrice={
                      clickedItem?.unitPrice + '/' + clickedItem?.unitType
                    }
                    currentInventory={
                      clickedItem?.quantity + ' ' + clickedItem?.unitType
                    }
                    isMobile={isMobile}
                    newPrice={saveInventoryItem?.form?.newPrice?.value}
                    newInventory={saveInventoryItem?.form?.newInventory.value}
                    availability={saveInventoryItem?.form.availability.value}
                    onSaveInventory={onSaveInventory}
                  />
                </CustomModal>
                {errorPopup()}

                <CustomModal
                  isOpen={inventoryUpdateConfirmation}
                  toggle={toggleUpdateConfirmation}
                  isScrollable={true}
                  title={t('NOTE') + '!'}
                  modalstyleClassName="modal-style"
                  modalContentContainer="modal-container"
                  modalHeaderClassName="modal-header"
                  modalheaderTopic="modal-header-topic m-auto"
                  modalFooterClassName="modal-footer"
                  modalBodyClassName="small"
                >
                  <UpdateConfirmationModal
                    close={toggleUpdateConfirmation}
                    onConfirm={onSaveUnAvailableProduct}
                    cancelHandle={() => {
                      setClickedItem(saveInventoryItem.item);
                      setUpdateProductModal(true);
                      setInventoryUpdateConfirmation(false);
                    }}
                    item={saveInventoryItem}
                    itemAvailability={
                      saveInventoryItem?.form?.availability?.value
                    }
                    subTitle={t(
                      'INVENTORY_PRODUCT_UNAVAILABLE_NOT_VISIBLE_TO_BUYERS',
                    )}
                  />
                </CustomModal>
              </Row>
              {isMobile && (
                <span className="dots">
                  <div className="dot active-dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </span>
              )}
            </Container>
          </>
        ) : (
          <div className="py-5 custom-empty-message">
            {selectedFilterCategories?.length > 0 &&
            selectedFilterCategories[0]?.id ? (
              'The searched item is not available in the chosen category.'
            ) : (
              <p>{t('ITEMS_ARE_UNAVAILABLE')}</p>
            )}
          </div>
        )}
      </div>
    </InnerBody>
  );
}

export default InventoryUpdates;
