import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from '../LoadingContext';
import { Connection } from '../../app/config';

const SupplierOrderPaymentHistoryContext = React.createContext();
const SupplierOrderPaymentHistoryProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;

  const [
    supplierOrderHistoryResponse,
    setSupplierOrderHistoryResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    supplierPaymentHistoryResponse,
    setSupplierPaymentHistoryResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const [
    supplierPaymentDetailsInvoiceResponse,
    setSupplierPaymentDetailsInvoiceResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const getSupplierPaymentDetailInvoice = async (invoiceId = '') => {
    setSupplierPaymentDetailsInvoiceResponse({ res: null, err: null });
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const payTypes = await fetch(url + `/supplier-invoices/${invoiceId}`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payTypes.json();
      if (data?.errors || data?.message) {
        setSupplierPaymentDetailsInvoiceResponse({
          res: null,
          err: data?.description,
        });
      } else {
        setSupplierPaymentDetailsInvoiceResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setSupplierPaymentDetailsInvoiceResponse({
        res: null,
        err: err?.description || err,
      });
      setLoading(false);
    }
  };

  const getSupplierPaymentHistory = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const payHis = await fetch(url + `payment-summary`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payHis.json();
      if (data?.errors || data?.message) {
        setSupplierPaymentHistoryResponse({ res: null, err: data?.message });
      } else {
        setSupplierPaymentHistoryResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setSupplierPaymentHistoryResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const getSupplierOrderHistory = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const payHis = await fetch(url + `order-history`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payHis.json();
      if (data?.errors || data?.message) {
        setSupplierOrderHistoryResponse({ res: null, err: data?.message });
      } else {
        setSupplierOrderHistoryResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setSupplierOrderHistoryResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const doSupplierConfirmPayment = async id => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const pay = await fetch(url + `payments/${id}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify([{ op: 'replace', path: 'Status', value: '2' }]),
      });
      const data = await pay.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  return (
    <SupplierOrderPaymentHistoryContext.Provider
      value={{
        state: {
          supplierOrderHistoryResponse: supplierOrderHistoryResponse,
          supplierPaymentHistoryResponse: supplierPaymentHistoryResponse,
          supplierPaymentDetailsInvoiceResponse: supplierPaymentDetailsInvoiceResponse,
        },
        doSupplierConfirmPayment: doSupplierConfirmPayment,
        getSupplierPaymentHistory: getSupplierPaymentHistory,
        getSupplierOrderHistory: getSupplierOrderHistory,
        getSupplierPaymentDetailInvoice: getSupplierPaymentDetailInvoice,
        setSupplierPaymentDetailsInvoiceResponse: setSupplierPaymentDetailsInvoiceResponse,
      }}
    >
      {props.children}
    </SupplierOrderPaymentHistoryContext.Provider>
  );
};
SupplierOrderPaymentHistoryProvider.propTypes = {
  children: PropTypes.any,
};

export {
  SupplierOrderPaymentHistoryProvider,
  SupplierOrderPaymentHistoryContext,
};
