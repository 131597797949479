import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
//import { items } from '../../app/components/Supplier/_mocks/intentoryList';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const SupplierContext = React.createContext();
const SupplierProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [inventoriesRes, setInventoriesRes] = useState({ res: [], err: null });
  const [searchSuggestionsRes, setSearchSuggestionsRes] = useState({
    res: [],
    err: null,
  });
  const [supplierDetailsResponse, setSupplierDetailsResponse] = useState({
    res: null,
    err: null,
  });
  const [filterCategoriesRes, setfilterCategoriesRes] = useState({
    res: [],
    err: null,
  });
  const [updateInventoryRes, setUpdateInventoryRes] = useState({
    res: null,
    err: null,
  });
  const [deleteInventoryRes, setDeleteInventoryRes] = useState({
    res: null,
    err: null,
  });
  const [deliverySlotsResponse, setDeliverySlotsResponse] = useState({
    res: null,
    err: null,
  });
  const [
    orderSortingSummaryResponse,
    setOrderSortingSummaryResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [orderSortingDetailResponse, setOrderSortingDetailResponse] = useState({
    res: null,
    err: null,
  });
  const [
    orderLoadingSummaryResponse,
    setOrderLoadingSummaryResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updatePurchaseOrderStatusResponse,
    setUpdatePurchaseOrderStatusResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    purchaseOrderDispatchResponse,
    setPurchaseOrderDispatchResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updatePackageStatusResponse,
    setUpdatePackageStatusResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updatePurchaseOrderToManualProcumentResponse,
    setUpdatePurchaseOrderToManualProcumentResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const getInventoryList = async query => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const inventories = await fetch(url + `products?${query}`, {
        method: 'GET',
        headers: headers,
      });
      const res = await inventories.json();
      if (res?.description || res?.message) {
        setInventoriesRes({ res: null, err: res?.message });
      } else {
        setInventoriesRes({ res: res, err: null });
      }
      setLoading(false);
    } catch (err) {
      setInventoriesRes({ err: err, res: [] });
      setLoading(false);
    }
  };

  const getSearchSuggestions = async query => {
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const inventories = await fetch(url + `products?${query}`, {
        method: 'GET',
        headers: headers,
      });
      if (inventories.error) {
        setSearchSuggestionsRes({
          res: inventories.body(),
          err: inventories.error,
        });
      } else {
        const data = await inventories.json();
        setSearchSuggestionsRes({ res: data, err: null });
      }
      // setInventoriesRes({ res: items, err: null });
    } catch (err) {
      setInventoriesRes({ err: err });
    }
  };

  const handleSupplierDetails = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const supplierDetails = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const data = await supplierDetails.json();
      if (data.message) {
        setLoading(false);
        setSupplierDetailsResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setSupplierDetailsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setSupplierDetailsResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getFilterCategories = async () => {
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const filters = await fetch(url + `product-categories`, {
        // `product-categories`, {
        method: 'GET',
        headers: headers,
      });
      const data = await filters.json();
      if (data?.description || data?.message) {
        setfilterCategoriesRes({
          err: data?.message || data?.description,
          res: [],
        });
      } else {
        setfilterCategoriesRes({
          res: data,
          err: null,
        });
      }
    } catch (err) {
      setfilterCategoriesRes({ err: err?.message || err, res: [] });
    }
  };

  const deleteItems = async items => {
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      await fetch(url + `products`, {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify(items),
      });
      setDeleteInventoryRes({ res: 'success', err: null });
    } catch (err) {
      setDeleteInventoryRes({ res: null, err: null });
      throw new Error(err);
    }
  };

  const updateInventory = async (inventory, supplierCompId) => {
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const update = await fetch(url + `products/${supplierCompId}/inventory`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(inventory),
      });
      const res = await update.json();
      if (res?.description) {
        setUpdateInventoryRes({ res: null, err: res?.description });
        return;
      }
      setUpdateInventoryRes({ res: res, err: null });
    } catch (err) {
      setUpdateInventoryRes({ res: null, err: err?.message || err });
    }
  };
  const getDeliverySlots = async baseId => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          '/delivery-bases/' +
          baseId +
          '/upcoming-delivery-slots?slotCount=2',
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setDeliverySlotsResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setDeliverySlotsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setDeliverySlotsResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getOrderSortingSummary = async (
    supplierCompanyId,
    deliverSlotId,
    deliveryDate,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          '/supplier-companies/' +
          supplierCompanyId +
          '/order-summary?expectedDeliverySlotId=' +
          deliverSlotId +
          '&expectedDeliveryDate=' +
          deliveryDate,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setOrderSortingSummaryResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setOrderSortingSummaryResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setOrderSortingSummaryResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getOrderSortingDetail = async (
    supplierCompanyId,
    deliverSlotId,
    deliveryDate,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          '/supplier-companies/' +
          supplierCompanyId +
          '/order-products?expectedDeliverySlotId=' +
          deliverSlotId +
          '&expectedDeliveryDate=' +
          deliveryDate,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setOrderSortingDetailResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setOrderSortingDetailResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setOrderSortingDetailResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getOrderLoadingSummary = async (deliverSlotId, deliveryDate) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const deliverySlots = await fetch(
        url +
          'order-details-summary?deliverySlotId=' +
          deliverSlotId +
          '&deliveryDate=' +
          deliveryDate,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setOrderLoadingSummaryResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setOrderLoadingSummaryResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setOrderLoadingSummaryResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const updatePurchaseOrderStatus = async (
    PurchaseOrderId,
    PurchaseProductStatus,
    ProductId,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const deliverySlots = await fetch(url + 'purchase-order-product', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          PurchaseOrderId: PurchaseOrderId,
          PurchaseProductStatus: PurchaseProductStatus,
          ProductId: ProductId,
        }),
      });
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setUpdatePurchaseOrderStatusResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setUpdatePurchaseOrderStatusResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdatePurchaseOrderStatusResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const purchaseOrderDispatch = async (
    PurchaseOrderId,
    ExpectedDeliverySlotId,
    ExpectedDeliveryDate,
    PurchaseOrderProductIds,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('suppliers');
      const deliverySlots = await fetch(url + 'purchase-order', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          PurchaseOrderId: PurchaseOrderId,
          PurchaseOrderProductIds: PurchaseOrderProductIds,
          ExpectedDeliverySlotId: ExpectedDeliverySlotId,
          ExpectedDeliveryDate: ExpectedDeliveryDate,
        }),
      });
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setPurchaseOrderDispatchResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setPurchaseOrderDispatchResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setPurchaseOrderDispatchResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const updatePackageStatus = async (packageIds, packageStatus) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const packageStatusRes = await fetch(url + '/vehicles/package-status', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          packageId: packageIds,
          packageStatus: packageStatus,
        }),
      });
      const data = await packageStatusRes.json();
      if (data.message) {
        setLoading(false);
        setUpdatePackageStatusResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setUpdatePackageStatusResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdatePackageStatusResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const updatePurchaseOrderToManualProcument = async orderIds => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const updateManualProcumente = await fetch(
        url + '/manual-proc-products',
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({
            PurchaseOrderIds: orderIds,
          }),
        },
      );
      const data = await updateManualProcumente.json();
      if (data.message) {
        setLoading(false);
        setUpdatePurchaseOrderToManualProcumentResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setUpdatePurchaseOrderToManualProcumentResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdatePurchaseOrderToManualProcumentResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  return (
    <SupplierContext.Provider
      value={{
        state: {
          inventoriesRes: inventoriesRes,
          supplierDetailsResponse: supplierDetailsResponse,
          filterCategoriesRes: filterCategoriesRes,
          searchSuggestionsRes: searchSuggestionsRes,
          updateInventoryRes: updateInventoryRes,
          deleteInventoryRes: deleteInventoryRes,
          deliverySlotsResponse: deliverySlotsResponse,
          orderSortingDetailResponse: orderSortingDetailResponse,
          orderSortingSummaryResponse: orderSortingSummaryResponse,
          orderLoadingSummaryResponse: orderLoadingSummaryResponse,
          updatePurchaseOrderStatusResponse: updatePurchaseOrderStatusResponse,
          purchaseOrderDispatchResponse: purchaseOrderDispatchResponse,
          updatePackageStatusResponse: updatePackageStatusResponse,
          updatePurchaseOrderToManualProcumentResponse: updatePurchaseOrderToManualProcumentResponse,
        },
        getInventoryList: getInventoryList,
        handleSupplierDetails: handleSupplierDetails,
        getFilterCategories: getFilterCategories,
        getSearchSuggestions: getSearchSuggestions,
        deleteInventoryItems: deleteItems,
        updateInventory: updateInventory,
        getDeliverySlots: getDeliverySlots,
        getOrderSortingDetail: getOrderSortingDetail,
        getOrderSortingSummary: getOrderSortingSummary,
        setSearchSuggestionsRes: setSearchSuggestionsRes,
        getOrderLoadingSummary: getOrderLoadingSummary,
        updatePurchaseOrderStatus: updatePurchaseOrderStatus,
        purchaseOrderDispatch: purchaseOrderDispatch,
        updatePackageStatus: updatePackageStatus,
        updatePurchaseOrderToManualProcument: updatePurchaseOrderToManualProcument,
      }}
    >
      {props.children}
    </SupplierContext.Provider>
  );
};

SupplierProvider.propTypes = {
  children: PropTypes.any,
};

export { SupplierContext, SupplierProvider };
