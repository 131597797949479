import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { CustomModal } from './../../../../shared/components';
import { useTranslation } from 'react-i18next';
import { SupplierManagementContext } from '../../../../apis/admin';

function AlreadyScannedModal({
  qrCode,
  setIsModalOpen,
  setSelectedOrder,
  isCompletedConsolidatedScan,
  isCompletedItemCountScan,
}) {
  const { t } = useTranslation();
  const supplierManagementContext = useContext(SupplierManagementContext);
  const { getDamagedQRCodeValidation } = supplierManagementContext;
  const { qrCodeDetails } = supplierManagementContext.state;

  useEffect(() => {
    getDamagedQRCodeValidation(qrCode);
  }, []);

  const onHandleOk = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div>
      <CustomModal
        isOpen={true}
        showClose={true}
        hideCancel={false}
        title="Already Scanned"
        modalstyleClassName="modal-style already-scanned-modal"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
      >
        <p>{t('THE_PACKAGE_HAS_BEEN_ALREADY_SCANNED')}.</p>
        <p className="order-details">{t('THE_ORDER_DETAILS')}</p>
        <Row className="supplier-code">
          <Col className="supplier-code-lable">{t('SUPPLIER_CODE')}:</Col>
          <Col className="supplier-code-value">
            {qrCodeDetails?.res?.supplierCompany?.supplierCode}
          </Col>
        </Row>
        <Row className="tag-no">
          <Col className="tag-no-lable">{t('TAG_NO')}:</Col>
          <Col className="tag-no-value">
            {qrCodeDetails?.res?.purchaseOrder?.tagNumber}
          </Col>
        </Row>
        {isCompletedItemCountScan && (
          <Row className="no-of-item">
            <Col className="no-of-item-lable">{t('ITEM_NO')} :</Col>
            <Col className="no-of-item-value">{qrCodeDetails?.res?.itemNo}</Col>
          </Row>
        )}
        {isCompletedConsolidatedScan && (
          <>
            <Row className="pack-type">
              <Col className="pack-type-lable">{t('PRIMARY_PACK_TYPE')} :</Col>
              <Col className="pack-type-value">
                {qrCodeDetails?.res?.packageType?.name}
              </Col>
            </Row>
            <Row className="no-of-item">
              <Col className="no-of-item-lable">{t('NO_OF_ITEMS')} :</Col>
              <Col className="no-of-item-value">
                {qrCodeDetails?.res?.numberOfItems}
              </Col>
            </Row>
          </>
        )}
        <Button
          color="primary"
          type="submit"
          className="form-submit mx-auto"
          onClick={() => onHandleOk()}
        >
          {t('OK')}
        </Button>
      </CustomModal>
    </div>
  );
}
AlreadyScannedModal.propTypes = {
  qrCode: PropTypes.string,
  setIsModalOpen: PropTypes.func,
  setSelectedOrder: PropTypes.func,
  isCompletedConsolidatedScan: PropTypes.bool,
  isCompletedItemCountScan: PropTypes.bool,
};
export default AlreadyScannedModal;
