import React, { useState, useEffect, useContext } from 'react';
import InnerBody from '../../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import { CustomModal } from '../../../../../shared/components';
import { Switch } from '../../../../../shared/components';
import { Table, Row } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { BuyerManagementContext } from '../../../../../apis/admin';
import moment from 'moment';
import { CalendarBlue } from '../../../../assets/icons';
import { CustomCalendar, Text } from '../../../../../shared/components';
import { useDeviceDetect } from '../../../../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import CustomDropdown from '../../../../../shared/components/CustomDropdown';
import { LoadingContext } from '../../../../../apis/LoadingContext';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import { UK_DATE_FORMAT } from '../../../../../shared/constants/constants';
function SupplierQualityCheck() {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  const [
    isRequiringVerificationPopup,
    setIsRequiringVerificationPopup,
  ] = useState(false);
  const [openCalendar, setIsOpenCalendar] = useState(false);
  const [deliverySlotOptions, setDeliverySlotOptions] = useState([]);
  const [baseNameOptions, setBaseNameOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [
    requriementVeriAvailability,
    setRequriementVeriAvailability,
  ] = useState(true);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const [isStateAvailable, setIsStateAvailable] = useState(true);
  const buyerManagementContext = useContext(BuyerManagementContext);
  const {
    getDeliverySlots,
    getBaseNameByDeliverySlot,
    getSuppliersByBase,
  } = buyerManagementContext;
  const {
    deliverySlotsResponse,
    baseNamesResponse,
    suppliersByBaseResponse,
  } = buyerManagementContext.state;
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  useEffect(() => {
    if (
      history?.state !== null &&
      history?.state.selectedDate &&
      history?.state.deliverySlot &&
      history?.state.base
    ) {
      setIsStateAvailable(true);
      setSelectedDate(history?.state.selectedDate);
      setSelectedDeliverySlot(JSON.parse(history?.state.deliverySlot));
      setSelectedBaseName(JSON.parse(history?.state.base));
      window.history.replaceState(null, '/admin/supplierqualitycheck/');
    } else {
      setIsStateAvailable(false);
    }
  }, []);

  useEffect(() => {
    if (selectedDate) {
      getDeliverySlots(formatDate(selectedDate, null, null, UK_DATE_FORMAT));
      if (!isStateAvailable) {
        setSelectedDeliverySlot(null);
        setSelectedBaseName(null);
      }
      setPurchaseOrders([]);
      setError('');
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDeliverySlot?.id) {
      getBaseNameByDeliverySlot(selectedDeliverySlot.id);
      if (!isStateAvailable) {
        setSelectedBaseName(null);
      }
      setPurchaseOrders([]);
      setError('');
    }
  }, [selectedDeliverySlot]);

  useEffect(() => {
    if (selectedBaseName?.id) {
      getSuppliersByBase(
        formatDate(selectedDate, null, null, UK_DATE_FORMAT),
        selectedDeliverySlot.id,
        selectedBaseName.id,
      );
      setIsStateAvailable(false);
    }
  }, [selectedBaseName]);

  useEffect(() => {
    if (baseNamesResponse?.res?.length > 0) {
      setBaseNameOptions(baseNamesResponse.res);
      setError('');
    } else {
      if (!isStateAvailable) {
        setBaseNameOptions([]);
        setSelectedBaseName(null);
        if (selectedDeliverySlot) {
          setError('A base name is not available for the selected slot.');
        }
      }
    }
  }, [baseNamesResponse]);

  useEffect(() => {
    if (deliverySlotsResponse?.res?.length > 0) {
      setDeliverySlotOptions(deliverySlotsResponse.res);
      setError('');
    } else {
      if (!isStateAvailable) {
        setDeliverySlotOptions([]);
        setSelectedDeliverySlot(null);
        if (selectedDate) {
          setError('A delivery slot is not available for the selected date.');
        }
      }
    }
  }, [deliverySlotsResponse]);

  useEffect(() => {
    if (suppliersByBaseResponse?.res) {
      setPurchaseOrders(suppliersByBaseResponse?.res);
    }
  }, [suppliersByBaseResponse]);

  const toggleRequiringVerification = () =>
    setIsRequiringVerificationPopup(!isRequiringVerificationPopup);

  const onChangeAvilableToggle = () => {
    setRequriementVeriAvailability(prevProps => !prevProps);
  };
  const requiringVerificationModal = () => {
    return (
      <CustomModal
        isOpen={isRequiringVerificationPopup}
        toggle={toggleRequiringVerification}
        isScrollable={true}
        enableFooter={false}
        title={t('SUPPLIER_DETAILS')}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        <>
          <p>[Supplier ID]-[Supplier Company Name]-[Base Name]</p>
          <Switch
            label={t('AVAILABILITY')}
            labelClassName="form-label"
            id="supplier-requriement-availability"
            field="availability"
            onChange={onChangeAvilableToggle}
            checked={requriementVeriAvailability}
          />
        </>
      </CustomModal>
    );
  };

  return (
    <InnerBody
      topic={t('QUALITY_CONTROL_BASE_SUMMARY')}
      isDisable={true}
      className="supplierQualityCheck"
    >
      <div className="supplier-quality-check-container">
        {requiringVerificationModal()}

        <Row className="px-2" style={!isMobile ? { marginLeft: '-20px' } : {}}>
          <div className="col-lg-4 col-sm-12">
            <Text
              type="text"
              placeholder={t('SELECT_A_DELIVERY_DATE')}
              className="delivery-date  cursor-pointer"
              value={selectedDate && moment(selectedDate).format('DD/MM/YYYY')}
              rightIcon={CalendarBlue}
              rightIconHandle={() => setIsOpenCalendar(prevProps => !prevProps)}
              id="calendar"
              field="calendar"
              onClick={() => setIsOpenCalendar(prevProps => !prevProps)}
              rightIconGroupClassName="position-relative cursor-pointer"
              rightIconClassName="calendar-icon"
              showErrors={false}
              style={{ cursor: 'default', fontSize: '13px' }}
              readOnly
            />
          </div>
          {openCalendar && (
            <CustomCalendar
              onClose={() => setIsOpenCalendar(false)}
              onChange={e => {
                setSelectedDate(e);
                setIsOpenCalendar(false);
              }}
              value={selectedDate && new Date(selectedDate)}
            />
          )}
          <CustomDropdown
            parentClass={'col-lg-4 col-sm-12 mb-3'}
            toggleClass={
              selectedDeliverySlot === null ? 'grey-text' : 'black-text'
            }
            toggleText={
              selectedDeliverySlot === null
                ? t('SELECT_A_DELIVER_SLOT')
                : `${selectedDeliverySlot?.startTime} - ${selectedDeliverySlot?.endTime}`
            }
            parentStyle={selectedDate?.length === 0 ? { opacity: '0.6' } : {}}
            disabled={!selectedDate || deliverySlotOptions?.length == 0}
            dropDownText={date => {
              return `${date.startTime} - ${date.endTime}`;
            }}
            onClickListItem={item => {
              setSelectedDeliverySlot(item);
            }}
            drodDownList={deliverySlotOptions}
          />
          <CustomDropdown
            parentClass={'col-lg-4 col-sm-12 '}
            toggleClass={
              selectedDeliverySlot === null ? 'grey-text' : 'black-text'
            }
            toggleText={
              selectedBaseName === null
                ? t('SELECT_A_BASE_NAME')
                : `${selectedBaseName?.name}`
            }
            parentStyle={
              selectedDeliverySlot?.length === 0 ? { opacity: '0.6' } : {}
            }
            disabled={!selectedDeliverySlot || baseNameOptions?.length == 0}
            dropDownText={data => {
              return `${data.name}`;
            }}
            onClickListItem={item => {
              setSelectedBaseName(item);
            }}
            drodDownList={baseNameOptions}
          />
        </Row>

        {error && <small className="error-message mt-1">{error}</small>}
        {(selectedBaseName?.length === 0 || selectedBaseName === null) && (
          <div className="py-5 custom-empty-message">
            <p>{t('SELECT_A_BASE_TO_VIEW_THE_AVAILABLE_RECORDS')}</p>
          </div>
        )}
        {selectedBaseName?.id && (
          <>
            {purchaseOrders.length > 0 ? (
              <div className="tableFixHead">
                <Table bordered>
                  {!isMobile && (
                    <thead>
                      <tr>
                        <th>{t('SUPPLIER_NAME')}</th>
                        <th>{t('READY')}</th>
                        <th>{t('PREPARING')}</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {purchaseOrders.map(purchaseOrder => {
                      return (
                        <tr
                          key={purchaseOrder.id}
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/admin/supplierqualitycheck/selectedSupplierOrders/${
                                purchaseOrder.supplierCompanyId
                              }/${purchaseOrder.deliverySlotId}/${
                                purchaseOrder.supplierCompanyName
                              }/${encodeURIComponent(
                                JSON.stringify(selectedBaseName),
                              )}/${encodeURIComponent(
                                JSON.stringify(selectedDeliverySlot),
                              )}/${selectedDate}`,
                            )
                          }
                        >
                          {!isMobile ? (
                            <>
                              <td>{purchaseOrder.supplierCompanyName}</td>
                              <td>
                                {purchaseOrder.readyForDispatchOrderCount}
                              </td>
                              <td>{purchaseOrder.processingOrderCount}</td>
                            </>
                          ) : (
                            <td>
                              <div className="mb-1">
                                {purchaseOrder.supplierCompanyName}
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <div className="small">
                                    Ready for dispatch
                                  </div>
                                  {purchaseOrder.readyForDispatchOrderCount}
                                </div>
                                <div>
                                  <div className="small">
                                    Orders in progress
                                  </div>
                                  {purchaseOrder.processingOrderCount}
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              !loading && (
                <div className="py-5 custom-empty-message">
                  <p>{t('NO_ORDERS_AVAILABLE')}</p>
                </div>
              )
            )}
          </>
        )}
      </div>
    </InnerBody>
  );
}

export default SupplierQualityCheck;
