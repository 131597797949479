var buyerRoutes = [
  {
    path: '/makeaneworder',
    name: 'Make a New Order',
    icon: 'makeaNewOrderIcon',
    layout: '/buyer',
    children: [],
  },
  {
    path: '/orderandpaymenthistory',
    name: 'Order And Payment History',
    icon: 'orderAndPaymentHistoryIcon',
    layout: '/buyer',
    children: [],
  },
  {
    path: '/vendor-management',
    name: 'Vendor Management',
    icon: 'vendor-managementIcon',
    layout: '/buyer',
    children: [],
  },
];

export default buyerRoutes;
