import React from 'react';
import { Col } from 'reactstrap';
import InnerBody from '../../../../content/InnerBody';
import { CONTACT_US } from '../../../../shared/constants/constants';

function BuyerContactUs() {
  return (
    <InnerBody topic="Contact Us" isDisable={true}>
      <div className="contact-us">
        <Col sm={12} md={11} className="description">
          Our customer service department is ready to assist you with all of
          your procurement needs.
        </Col>
        <Col sm={12} md={11} className="mt-4 px-0">
          <div className="contact-info-container">
            <Col sm={12} md={5} className="call-container">
              <div className="contact-card m-auto">
                <div className="sub-title-cal"> Call Us: &nbsp;</div>
                <a className="tel" href={`tel:${CONTACT_US.BUYER_TEL}`}>
                  {CONTACT_US.BUYER_TEL}
                </a>
              </div>
            </Col>

            <Col sm={12} md={5} className="email-container">
              <div className="contact-card m-auto">
                <div className="sub-title-email">Email:&nbsp;</div>

                <a href={`mailto:${CONTACT_US.BUYER_EMAIL}`} className="email">
                  {CONTACT_US.BUYER_EMAIL}
                </a>
              </div>
            </Col>
          </div>
        </Col>
      </div>
    </InnerBody>
  );
}
export default BuyerContactUs;
