import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Table, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SupplierContext } from '../../../../apis/supplier';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { S3_BUCKET_URL } from '../../../../environment';
import { warning } from '../../../assets/img';
import { ORDER_SORTING_DETAILS } from './constants/constants';
import moment from 'moment';
import { PURCHASE_ORDER_PRODUCT_STATUS } from '../../../../shared/constants/constants';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function OrderSortingDetail({
  selectedDeliverySlot,
  orderProductDetails,
  setOrderProductDetails,
  currentIndex,
  setCurrentIndex,
  isOrderProductDetailsLoading,
  setIsOrderSortingDetailPage,
}) {
  const { t } = useTranslation();
  const supplierContext = useContext(SupplierContext);
  const { purchaseOrderDispatch } = supplierContext;
  // const [items, setItems] = useState([]);
  // const [selectedPackageIds, setSelectedPackageIds] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    setCurrentIndex(0);
  }, []);
  // const handlegetOrderSortingDetails = () => {
  //   getOrderSortingDetail(1, 1, '27/11/2022').then(res => {
  //     if (res.err) {
  //       console.log(res.err);
  //     } else {
  //       console.log(res.res);
  //       setCurrentIndex(0);
  //       setOrderProductDetails(res.res);
  //       // setItems([
  //       //   {
  //       //     orderId: 192,
  //       //     purchaseOrderId: 205,
  //       //     orderStatus: 'Placed',
  //       //     tagNo: 'TG_192',
  //       //     isAffiliatedSupplier: true,
  //       //     buyerCompany: 'BuyerCompany01',
  //       //     dispatchCutOff: 2,
  //       //     orderItem: [
  //       //       {
  //       //         productId: 6,
  //       //         productName: 'Potato',
  //       //         productImage: 'Carrot.jpg',
  //       //         unitMeasure: 'Kg',
  //       //         note: 'h',
  //       //         productStatus: 5,
  //       //         quantity: 10.0,
  //       //         unitPrice: 10.0,
  //       //       },
  //       //       {
  //       //         productId: 5,
  //       //         productName: 'Onion',
  //       //         productImage: 'Onion.jpg',
  //       //         unitMeasure: 'Kg',
  //       //         note: null,
  //       //         productStatus: 5,
  //       //         quantity: 10.0,
  //       //         unitPrice: 10.0,
  //       //       },
  //       //     ],
  //       //   },
  //       // ]);
  //     }
  //   });
  // };

  const onMove = (_, current) => {
    setCurrentIndex(current);
  };

  const onProductSelect = (orderIndex, productIndex) => {
    setError('');
    let tempItems = [...orderProductDetails];
    let tempStatusId =
      tempItems[orderIndex]?.orderItem[productIndex]?.productStatus;
    if (tempStatusId === PURCHASE_ORDER_PRODUCT_STATUS.SUBMITTED.id) {
      tempItems[orderIndex].orderItem[productIndex].productStatus =
        PURCHASE_ORDER_PRODUCT_STATUS.SUPPLIER_READY_ITEM.id;
    }
    if (tempStatusId === PURCHASE_ORDER_PRODUCT_STATUS.SUPPLIER_READY_ITEM.id) {
      tempItems[orderIndex].orderItem[productIndex].productStatus =
        PURCHASE_ORDER_PRODUCT_STATUS.SUBMITTED.id;
    }
    setOrderProductDetails(tempItems);
    // console.log(selectedPackageIds);
    // let tempselectedPackageIds = [...selectedPackageIds];
    // if (tempselectedPackageIds.includes(packageId)) {
    //   const filteredvalues = tempselectedPackageIds.filter(
    //     id => id !== packageId,
    //   );
    //   setSelectedPackageIds(filteredvalues);
    // } else {
    //   tempselectedPackageIds.push(packageId);
    //   setSelectedPackageIds(tempselectedPackageIds);
    // }
  };

  //   const getOrderStatus = statusId => {
  //     //this should change
  //     switch (statusId) {
  //       case PACKAGING_STATUS.NOT_REQUESTED.id:
  //         return PACKAGING_STATUS.NOT_REQUESTED.value;
  //       case PACKAGING_STATUS.REQUESTED.id:
  //         return PACKAGING_STATUS.REQUESTED.value;
  //       case PACKAGING_STATUS.SENT_BY_SUPPLIER.id:
  //         return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
  //       case PACKAGING_STATUS.RECEIVED.id:
  //         return PACKAGING_STATUS.RECEIVED.value;
  //       case PACKAGING_STATUS.SCANNED.id:
  //         return PACKAGING_STATUS.SCANNED.value;
  //       case PACKAGING_STATUS.LOADED.id:
  //         return PACKAGING_STATUS.LOADED.value;
  //     }
  //   };

  const onHandleSend = () => {
    let selectedPackageIds = [];
    orderProductDetails[currentIndex]?.orderItem?.forEach(product => {
      if (
        product.productStatus ===
        PURCHASE_ORDER_PRODUCT_STATUS.SUPPLIER_READY_ITEM.id
      ) {
        product.productStatus =
          PURCHASE_ORDER_PRODUCT_STATUS.READY_FOR_DISTPATCH.id;
        selectedPackageIds.push(product.productId);
      }
    });
    if (selectedPackageIds.length === 0) {
      setError('Please select the order that needs to be sent');
    } else {
      let purchaseProductIds = [];
      orderProductDetails[currentIndex]?.orderItem?.forEach(order => {
        purchaseProductIds.push(order?.purchaseOrderProductId);
      });

      purchaseOrderDispatch(
        orderProductDetails[currentIndex].purchaseOrderId,
        selectedDeliverySlot?.id,
        moment(selectedDeliverySlot?.date).format('YYYY-MM-DD'),
        purchaseProductIds,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          let tempItems = [...orderProductDetails];
          const filteredItems = tempItems.filter(
            item =>
              item.purchaseOrderId !==
              orderProductDetails[currentIndex].purchaseOrderId,
          );
          setCurrentIndex(
            currentIndex === orderProductDetails.length - 1
              ? currentIndex - 1
              : currentIndex,
          );
          setOrderProductDetails(filteredItems);
          if (filteredItems?.length === 0) setIsOrderSortingDetailPage(false);
        }
      });
    }
  };

  return (
    <>
      {!isOrderProductDetailsLoading && (
        <>
          {orderProductDetails?.length === 0 ? (
            <div className="py-5 custom-empty-message">
              <p>{t('NOT_AVAILABLE_AT_THE_MOMENT._PLEASE_CHECK_LATER')}</p>
            </div>
          ) : (
            <div className="order-sorting-details">
              <Splide
                options={{
                  rewind: true,
                  arrows: orderProductDetails.length !== 1,
                  pagination: false,
                }}
                hasTrack={false}
                onMove={(a, b, c) => onMove(a, b, c)}
              >
                <SplideTrack>
                  {orderProductDetails.map((order, orderIndex) => (
                    <SplideSlide
                      key={order.orderId + '' + order.purchaseOrderId}
                    >
                      <div className="tableFixHead">
                        <Table>
                          <thead>
                            <tr className="p-3">
                              <th colSpan={3} className="main-header">
                                <div className="d-flex tag-number">
                                  <p className="header-label">
                                    {t('TAG_NUMBER')} |
                                  </p>
                                  <p className="header-value">{order.tagNo}</p>
                                </div>
                                <div className="d-flex buyer-name d-sm-none">
                                  <p className="header-label">
                                    {t('BUYER_NAME')} |
                                  </p>
                                  <p className="header-value">
                                    {order.buyerCompany}
                                  </p>
                                </div>
                              </th>
                              <th colSpan={3} className="header-buyer-name">
                                {order.isAffiliatedSupplier && (
                                  <div className="d-flex buyer-name">
                                    <p className="header-label">
                                      {t('BUYER_NAME')} |
                                    </p>
                                    <p className="header-value">
                                      {order.buyerCompany}
                                    </p>
                                  </div>
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="head">
                              <td></td>
                              <td>{t('PRODUCT_NAME')}</td>
                              <td>{t('QUANTITY')}</td>
                              <td>{t('UNIT_PRICE')}</td>
                              <td>{t('NOTE')}</td>
                              <td>{t('STATUS')}</td>
                            </tr>
                            {order.orderItem?.map((product, productindex) => (
                              <tr
                                key={productindex}
                                className={`product-row 
                            ${product.productStatus !==
                              PURCHASE_ORDER_PRODUCT_STATUS.SUBMITTED.id &&
                              'highlight-row'}`}
                                onClick={() => {
                                  // if (
                                  //   e.target.id ===
                                  //   'note' + product.purchaseOrderProductId
                                  // ) {
                                  //   return;
                                  // }
                                  onProductSelect(orderIndex, productindex);
                                }}
                              >
                                <td className="product-image-container">
                                  <div className="product-image-container-div">
                                    <img
                                      loading="lazy"
                                      className="product-image"
                                      src={
                                        S3_BUCKET_URL +
                                        'Products/' +
                                        product?.productImage
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="product-name-value">
                                  {product.productName}{' '}
                                  <span className="d-sm-none quantity-value">
                                    <br />
                                    Qty:{' '}
                                    {product.quantity +
                                      ' ' +
                                      product.unitMeasure}
                                  </span>
                                </td>
                                <td className="td-quantity quantity-value">
                                  {product.quantity + ' ' + product.unitMeasure}
                                </td>
                                <td className="unit-price-value">
                                  <span className="d-sm-none status-value">
                                    {product.productStatus !==
                                    PURCHASE_ORDER_PRODUCT_STATUS.SUBMITTED.id
                                      ? ORDER_SORTING_DETAILS.READY
                                      : ORDER_SORTING_DETAILS.IN_PROGRESS}
                                    <br />
                                  </span>
                                  {commafy(RoundNum(product.unitPrice, 2))}
                                  <span className="d-sm-none">
                                    {' '}
                                    /{product.unitMeasure}
                                  </span>
                                </td>
                                <td className="note-value">
                                  {product.note && (
                                    <Note
                                      note={product.note}
                                      product={product}
                                    />
                                  )}
                                </td>
                                <td className="td-status status-value ">
                                  {product.productStatus !==
                                  PURCHASE_ORDER_PRODUCT_STATUS.SUBMITTED.id
                                    ? ORDER_SORTING_DETAILS.READY
                                    : ORDER_SORTING_DETAILS.IN_PROGRESS}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <Container className="arrows-horizontally-scroller p-0">
                  <div className="splide__arrows">
                    <Row>
                      <Col className="p-0">
                        <a
                          className={`splide__arrow--prev ${currentIndex ===
                            0 && 'btn-disable d-none'}`}
                          disabled={currentIndex == 0}
                        >
                          <span className="left"></span>
                        </a>
                        <a
                          className={`splide__arrow--next ${orderProductDetails?.length -
                            1 ===
                            currentIndex && 'btn-disable d-none'}`}
                          disabled={
                            currentIndex == orderProductDetails?.length - 1
                          }
                        >
                          <span className="right"></span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Splide>
              <Row className="mb-1 ready-for-dispatch">
                {error && <small className="error-message mt-1">{error}</small>}
                <Button
                  color="primary"
                  type="submit"
                  className="form-submit mt-1 ready-for-dispatch-btn"
                  disabled={
                    orderProductDetails[currentIndex]?.orderItem.filter(
                      product =>
                        product.productStatus !==
                        PURCHASE_ORDER_PRODUCT_STATUS.SUPPLIER_READY_ITEM.id,
                    ).length !== 0
                  }
                  onClick={onHandleSend}
                >
                  {t('READY_FOR_DISPATCH')}
                </Button>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
}

OrderSortingDetail.propTypes = {
  selectedDeliverySlot: PropTypes.object,
  orderProductDetails: PropTypes.array,
  setOrderProductDetails: PropTypes.func,
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
  isOrderProductDetailsLoading: PropTypes.bool,
  setIsOrderSortingDetailPage: PropTypes.bool,
};

export default OrderSortingDetail;

function Note({ note, product }) {
  const [isShowNote, setIsShowNote] = useState(false);

  return (
    <Button
      id={'note' + product.purchaseOrderProductId}
      className="unite-note-button"
      onClick={e => {
        e.stopPropagation();
        setIsShowNote(!isShowNote);
      }}
      onBlur={() => setIsShowNote(false)}
    >
      <img width="25" src={warning} alt="notes" />
      <Tooltip
        placement="top"
        isOpen={isShowNote}
        autohide={false}
        target={'note' + product.purchaseOrderProductId}
        toggle={() => setIsShowNote(!isShowNote)}
      >
        {note}
      </Tooltip>
      {/* {!isShowNote ? (
          <img width="20" id="note" src={warning} alt="notes" />
        ) : (
          <p id="note" className="unit-note-value">
            {note}
          </p>
        )} */}
    </Button>
  );
}
Note.propTypes = {
  note: PropTypes.string,
  product: PropTypes.object,
};
