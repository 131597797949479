import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SessionContext } from '../apis/authentication';
import { Container } from 'reactstrap';
import Auth from '../app/components/auth';
import { Interceptor, SillaraRoutes } from '../shared/routing';
import OuterNavContainer from './OuterNavContainer';
import ManageUser from '../app/components/auth/ManageUser';
import { LoadingContext } from '../apis/LoadingContext';
import { routeList as adminRouteList } from '../app/components/Admin';
import { RoutsList as SupplierRoutes } from '../app/components/Supplier';
import { routeList as buyerRoutes } from '../app/components/Buyer';
import { loadingIcon } from '../app/assets/img';
//import NotFound from './NotFound';
import Sidebar from './Sidebar';
import { USER_ROLES } from '../shared/constants/constants';
import Landing from './Landing';
import { Hub } from 'aws-amplify';
import { logger } from '../App';
import { getFirebaseToken } from '../../src/firebase-config';

const Content = () => {
  const sessionContext = useContext(SessionContext);
  const loadingContext = useContext(LoadingContext);
  const pathname = window.location.pathname;
  const { user, errors, isFetching, roleAvailability } = sessionContext.state;
  const { logOut } = sessionContext;
  const { loading } = loadingContext.state;
  const mainPanel = React.useRef();
  const [userRole, setUserRole] = useState();
  const { getauthenticatedUser, setPnTokenUserGroup } = sessionContext;
  const [firebasePnToken, setFirebasePnToken] = useState(null);
  const listener = async data => {
    switch (data.payload.event) {
      case 'signIn':
        logger.info('user signed in');
        pushNotificationTokenGeneration();
        break;
      case 'signUp':
        logger.info('user signed up');
        break;
      case 'signOut':
        logger.info('user signed out');
        break;
      case 'signIn_failure':
        logger.error('user sign in failed');
        break;
      case 'tokenRefresh':
        logger.info('token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        logger.error('token refresh failed');
        logOut();
        break;
      case 'autoSignIn':
        logger.info('Auto Sign In after Sign Up succeeded');
        break;
      case 'autoSignIn_failure':
        logger.error('Auto Sign In after Sign Up failed');
        break;
      case 'configured':
        logger.info('the Auth module is configured');
    }
  };

  useEffect(() => {
    Hub.listen('auth', listener);
  }, []);

  const pushNotificationTokenGeneration = () => {
    getFirebaseToken(setFirebasePnToken);
  };
  useEffect(() => {
    if (firebasePnToken) {
      setPnTokenUserGroup(firebasePnToken).then(() => {
        setFirebasePnToken(null);
      });
    }
  }, [firebasePnToken]);

  const navigationBar = () => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
        return <Sidebar routes={adminRouteList} userRole={userRole} />;
      case USER_ROLES.SUPPLIER:
        return <Sidebar routes={SupplierRoutes} userRole={userRole} />;
      case USER_ROLES.BUYER:
        return <Sidebar routes={buyerRoutes} userRole={userRole} />;
      default:
        return <Sidebar routes={[]} userRole={userRole} />;
    }
  };

  const addToast = (show, isError, message) => {
    const root = document.getElementById('root');
    const el = `<div class="toast fade ${show ? 'show' : ''}" role="alert">
        <div class="${isError ? 'bg-danger' : 'bg-success'} toast-body">
          ${message}
        </div>
      </div>`;
    const divToast = document.getElementById('toast');
    divToast.innerHTML = el;
    if (root) {
      root.appendChild(divToast);
    }
  };

  useEffect(() => {
    window.addEventListener('online', () => {
      addToast(true, false, 'Internet connection established.');
      setTimeout(() => {
        addToast(false, false, '');
      }, 5000);
    });
    window.addEventListener('offline', () => {
      addToast(true, true, 'Please check your internet connection.');
      setTimeout(() => {
        addToast(false, false, '');
      }, 5000);
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(`/meta.json?seed=${Math.random()}`);
        const meta = await res.json();
        if (meta.verified) {
          const { currentVersion } = meta;
          const appVersion = process.env.REACT_APP_VERSION;
          const versionMismatch = currentVersion !== appVersion;
          if (versionMismatch) {
            addToast(
              true,
              true,
              'Application is updated, System will refresh shortly....',
            );
            setTimeout(async () => {
              addToast(false, true, '');
              const names = await caches.keys();
              await Promise.all(names.map(name => caches.delete(name)));
              sessionStorage.clear();
              window.location.reload();
            }, 5000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [pathname]);

  useEffect(() => {
    if (user) {
      setUserRole(
        user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.[0],
      );
    }
  }, [user]);

  useEffect(() => {
    if (!user && !isFetching) {
      getauthenticatedUser();
    }
  }, [user, isFetching, getauthenticatedUser]);
  const setHomePageRedirection = role => {
    if (role === USER_ROLES.ADMIN) {
      return '/admin/sillaramastersheet';
    }
    if (role === USER_ROLES.BUYER) {
      return '/buyer/home';
    }
    if (role === USER_ROLES.SUPPLIER) {
      return '/supplier/home';
    }
    return '';
  };

  const loader = () => {
    return loading ? (
      <div className={`loading-icon`}>
        <img alt="loading-effect" src={loadingIcon} />
      </div>
    ) : (
      ''
    );
  };
  return (
    <Interceptor>
      {/* <Routes>
        <Route
          path="/auth/*"
          element={
            <ManageLanding>
              <Auth />
            </ManageLanding>
          }
        />
      </Routes> */}
      <div id="toast"></div>
      <div id="notification-toast"></div>
      {!errors ? (
        <ManageUser>
          {user &&
          (user.type === 'UNAUTHENTICATED' ||
            roleAvailability === 'unavailable') ? (
            <Routes>
              <Route path="/auth/*" element={<Auth loader={loader()} />} />
              <Route exact path="/" element={<Landing />} />
            </Routes>
          ) : (
            ''
          )}

          {user &&
          user.type !== 'UNAUTHENTICATED' &&
          roleAvailability === 'available' ? (
            <div id="content-container" className="content-container">
              <Container>
                <OuterNavContainer userRole={userRole} />
                <div className="content-body-wrapper shadow">
                  {loader()}
                  {navigationBar()}
                  <div className="main-panel" ref={mainPanel}>
                    <Routes>
                      {/* <Route
                      path="/auth/*"
                      element={
                        // <ManageLanding>
                        <Auth />
                        // </ManageLanding>
                      }
                    /> */}
                      <Route
                        path="/*"
                        key="/home"
                        element={
                          <Navigate to={setHomePageRedirection(userRole)} />
                        }
                      />
                      {SillaraRoutes}
                      {/* <Route path="/*" element={<NotFound />} /> */}
                    </Routes>
                  </div>
                </div>
              </Container>
            </div>
          ) : (
            ''
          )}
        </ManageUser>
      ) : (
        ''
      )}
    </Interceptor>
  );
};
export default Content;
