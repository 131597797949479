import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table, Row } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { BuyerManagementContext } from '../../../../../apis/admin';
import moment from 'moment';
import { CalendarBlue } from '../../../../assets/icons';
import { CustomCalendar, Text } from '../../../../../shared/components';
import { useDeviceDetect } from '../../../../../shared/hooks';
import CustomDropdown from '../../../../../shared/components/CustomDropdown';
import {
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../../shared/constants/constants';
import { formatDate } from '../../../../../shared/utility/dateFormats';
function VehicleList({
  setSelectedVehicle,
  deliverySlotOptions,
  setDeliverySlotOptions,
  baseNameOptions,
  setBaseNameOptions,
  selectedDate,
  setSelectedDate,
  selectedDeliverySlot,
  setSelectedDeliverySlot,
  selectedBaseName,
  setSelectedBaseName,
  vehicleList,
  setVehicleList,
  loading,
  setIsRefresh,
}) {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [openCalendar, setIsOpenCalendar] = useState(false);
  const [error, setError] = useState('');
  const buyerManagementContext = useContext(BuyerManagementContext);
  const {
    getDeliverySlots,
    getBaseNameByDeliverySlot,
    getVehicleList,
  } = buyerManagementContext;

  const onSelectDate = event => {
    setError('');
    setSelectedBaseName(null);
    setSelectedDeliverySlot(null);
    setBaseNameOptions([]);
    setDeliverySlotOptions([]);
    setSelectedDate(event);
    getDeliverySlots(
      formatDate(event, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      false,
    ).then(res => {
      if (res.err) {
        return;
      } else {
        if (res.res.length === 0) {
          setError('A delivery slot is not available for the selected date.');
        }
        setDeliverySlotOptions(res.res);
      }
    });
    setIsOpenCalendar(false);
  };

  const onSelectVehicle = vehicle => {
    setSelectedVehicle(vehicle);
    setIsRefresh(true);
  };

  return (
    <div>
      <div className="loading-container">
        <Row style={!isMobile ? { marginLeft: '-15px' } : {}}>
          <div
            className={
              isMobile ? 'col-lg-4 col-sm-12 px-0' : 'col-lg-4 col-sm-12'
            }
          >
            <Text
              type="text"
              placeholder={t('SELECT_A_DELIVERY_DATE')}
              className="delivery-date cursor-pointer"
              value={selectedDate && moment(selectedDate).format('DD/MM/YYYY')}
              rightIcon={CalendarBlue}
              rightIconHandle={() => setIsOpenCalendar(prevProps => !prevProps)}
              id="calendar"
              field="calendar"
              onClick={() => setIsOpenCalendar(prevProps => !prevProps)}
              rightIconGroupClassName="position-relative cursor-pointer"
              rightIconClassName="calendar-icon"
              showErrors={false}
              readOnly
            />
          </div>
          {openCalendar && (
            <CustomCalendar
              onClose={() => setIsOpenCalendar(false)}
              onChange={e => onSelectDate(e)}
              value={selectedDate && new Date(selectedDate)}
            />
          )}

          <CustomDropdown
            parentClass={
              isMobile
                ? 'col-lg-4 col-sm-12 px-0 mb-3'
                : 'col-lg-4 col-sm-12 mb-3'
            }
            toggleClass={
              selectedDeliverySlot === null ? 'grey-text' : 'black-text'
            }
            toggleText={
              selectedDeliverySlot === null
                ? t('SELECT_A_DELIVER_SLOT')
                : `${selectedDeliverySlot?.startTime} - ${selectedDeliverySlot?.endTime}`
            }
            parentStyle={selectedDate?.length === 0 ? { opacity: '0.6' } : {}}
            disabled={!selectedDate || deliverySlotOptions?.length == 0}
            dropDownText={date => {
              return `${date.startTime} - ${date.endTime}`;
            }}
            onClickListItem={item => {
              setSelectedDeliverySlot(item);
              setSelectedBaseName(null);
              getBaseNameByDeliverySlot(item.id).then(res => {
                if (res.err) {
                  return;
                } else {
                  if (res.res.length === 0) {
                    setError(
                      'A base name is not available for the selected slot.',
                    );
                  }
                  setBaseNameOptions(res.res);
                }
              });
            }}
            drodDownList={deliverySlotOptions}
          />

          <CustomDropdown
            parentClass={
              isMobile ? 'col-lg-4 col-sm-12 px-0' : 'col-lg-4 col-sm-12'
            }
            toggleClass={
              selectedDeliverySlot === null ? 'grey-text' : 'black-text'
            }
            toggleText={
              selectedBaseName === null
                ? t('SELECT_A_BASE_NAME')
                : `${selectedBaseName?.name}`
            }
            parentStyle={
              selectedDeliverySlot?.length === 0 ? { opacity: '0.6' } : {}
            }
            disabled={!selectedDeliverySlot || baseNameOptions?.length == 0}
            dropDownText={data => {
              return `${data.name}`;
            }}
            onClickListItem={item => {
              setSelectedBaseName(item);
              getVehicleList(
                selectedDeliverySlot.id,
                item.id,
                formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
              ).then(res => {
                if (res.err) {
                  return;
                } else {
                  setVehicleList(res.res);
                }
              });
            }}
            drodDownList={baseNameOptions}
          />
        </Row>

        {error && <small className="error-message mt-1">{error}</small>}
        {(selectedBaseName?.length === 0 || selectedBaseName === null) && (
          <div className="py-5 custom-empty-message">
            <p>{t('SELECT_A_BASE_TO_VIEW_THE_AVAILABLE_RECORDS')}</p>
          </div>
        )}
        {selectedBaseName?.id && (
          <>
            {vehicleList.length > 0 ? (
              <div className="tableFixHead">
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="head vehicle-type-head">
                        {t('VEHICLE_TYPE')}
                      </th>
                      <th className="head number-plate-head">
                        {t('NUMBER_PLATE')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleList.map(vehicle => {
                      return (
                        <tr
                          key={vehicle.id}
                          onClick={() => onSelectVehicle(vehicle)}
                          className="cursor-pointer"
                        >
                          <td className="vehicle-type">
                            {vehicle.vehicleType}
                          </td>
                          <td className="number-plate">
                            {vehicle.numberPlate}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              !loading && (
                <div className="py-5 custom-empty-message">
                  <p>{t('NO_VEHICLES_AVAILABLE')}</p>
                </div>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
}

VehicleList.propTypes = {
  setSelectedVehicle: PropTypes.func,
  deliverySlotOptions: PropTypes.array,
  setDeliverySlotOptions: PropTypes.func,
  baseNameOptions: PropTypes.array,
  setBaseNameOptions: PropTypes.func,
  selectedDate: PropTypes.object,
  setSelectedDate: PropTypes.func,
  selectedDeliverySlot: PropTypes.object,
  setSelectedDeliverySlot: PropTypes.func,
  selectedBaseName: PropTypes.object,
  setSelectedBaseName: PropTypes.func,
  vehicleList: PropTypes.array,
  setVehicleList: PropTypes.func,
  loading: PropTypes.bool,
  setIsRefresh: PropTypes.func,
};

export default VehicleList;
