import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Input, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { CheckBox } from '../../../../shared/components';
import { BuyerContext } from '../../../../apis/buyer';
import moment from 'moment';
import { useDeviceDetect } from '../../../../shared/hooks';
import { S3_BUCKET_URL } from '../../../../environment';
import { imageNotFound } from '../../../assets/img/index';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';
function ProductCartCard({
  setCheckedProducts,
  checkedProducts,
  product,
  cartId,
  selectedDate,
  selectedDeliverySlotId,
  setCartProductItems,
}) {
  const buyerContext = useContext(BuyerContext);
  const { updateProductCartItem, getCartProductItems } = buyerContext;
  const [inputValue, setInputValue] = useState(1);
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    setInputValue(product.quantity);
  }, [product]);

  const onChangeCheckBox = e => {
    if (e.target.checked) {
      setCheckedProducts([...checkedProducts, product.productId]);
    } else {
      const tempCheckProducts = [...checkedProducts];
      const filteredCheckProducts = tempCheckProducts.filter(
        ele => ele !== product.productId,
      );
      setCheckedProducts(filteredCheckProducts);
    }
  };

  const handleGettinggetCartProductItems = () => {
    getCartProductItems().then(res => {
      if (res.err) {
        return;
      } else {
        res.res.cartItems?.sort((a, b) =>
          a.productName.localeCompare(b.productName),
        );
        setCartProductItems(res.res);
        getQuantityFromCart(res.res);
      }
    });
  };

  const getQuantityFromCart = items => {
    items.cartItems &&
      items.cartItems.forEach(ele => {
        if (ele.productId === product.productId) {
          setInputValue(ele.quantity);
        }
      });
  };

  const handleValueDecrease = () => {
    if (inputValue >= product.minimumQuantity * 2) {
      const item = {
        CartId: cartId,
        PurchasedQuntity: inputValue - product.minimumQuantity,
        ExpectedDeliverySlotId: selectedDeliverySlotId,
        ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
      };
      handleUpdateProductCartItem(item, inputValue - product.minimumQuantity);
    }
  };

  const handleValueIncrease = () => {
    if (
      !product.maximumQuantity ||
      inputValue <= product.maximumQuantity - product.minimumQuantity
    ) {
      const item = {
        CartId: cartId,
        PurchasedQuntity: inputValue + product.minimumQuantity,
        ExpectedDeliverySlotId: selectedDeliverySlotId,
        ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
      };
      handleUpdateProductCartItem(item, inputValue + product.minimumQuantity);
    }
  };

  //for futher development (editable text)
  // const onInputChange = e => {
  //   const decimalRegex = /^\d+(\.\d)?\d*$/;
  //   if (decimalRegex.test(e.target.value) || e.target.value.slice(-1) === '.') {
  //     setInputValue(e.target.value);
  //     setTimeout(function() {
  //       const regex = /^\d*\.?\d?$/;
  //       if (regex.test(e.target.value)) {
  //         const item = {
  //           CartId: cartId,
  //           PurchasedQuntity: parseFloat(e.target.value),
  //           ExpectedDeliverySlotId: selectedDeliverySlotId,
  //           ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
  //         };
  //         handleUpdateProductCartItem(item, e.target.value);
  //       } else {
  //         handleGettinggetCartProductItems();
  //         setError('Item quantity must be in a single decimal point');
  //         setTimeout(function() {
  //           setError(null);
  //         }, 3000);
  //       }
  //     }, 2000);
  //   }
  // };

  const handleUpdateProductCartItem = (data, number) => {
    updateProductCartItem(product.productId, data).then(res => {
      if (res.err) {
        return;
      } else {
        setInputValue(number);
        handleGettinggetCartProductItems();
      }
    });
  };
  return (
    <Card className="product-cart-card">
      {product && (
        <>
          <div className="d-flex flex-sm-column justify-content-between">
            <div className="d-flex product-details">
              <div className="product-card-img-container">
                <img
                  loading="lazy"
                  className={`${
                    isMobile
                      ? 'product-cart-card-img-mobile'
                      : 'product-cart-card-img'
                  }`}
                  src={
                    product.productImageName
                      ? S3_BUCKET_URL + 'Products/' + product.productImageName
                      : imageNotFound
                  }
                  alt="Image Not Found"
                />
              </div>
              <div className="product-info-container">
                <p
                  className={`${
                    isMobile ? 'product-cart-name-mobile' : 'product-cart-name'
                  }`}
                  title={product.productName}
                >
                  {product.productName}
                </p>
                <div className="price-container">
                  <p className="product-cart-price-label">
                    {isMobile ? 'Avg. P' : 'Avg. Price'}
                  </p>
                  <p className="product-cart-price-value">
                    {product.averagePrice
                      ? 'LKR ' +
                        commafy(RoundNum(Number(product.averagePrice), 2)) +
                        '/Kg'
                      : 'Pending'}
                  </p>
                </div>
              </div>
            </div>
            <div className="align-self-center align-self-sm-start me-4 me-sm-0 mt-0 mt-sm-2">
              <Row className="product-cart-item-action-row">
                <Col
                  className={`product-cart-value-decrease ${
                    inputValue >= product.minimumQuantity * 2
                      ? ''
                      : 'decrease-disabled'
                  }`}
                  onClick={handleValueDecrease}
                >
                  -
                </Col>
                <Col className="product-cart-value-input-container">
                  <Input
                    className="product-cart-value-input"
                    // onChange={e => onInputChange(e)} for futher development
                    value={inputValue}
                    disabled={true}
                  />
                </Col>
                <Col
                  className={`product-cart-value-increase ${
                    !product.maximumQuantity
                      ? ''
                      : inputValue <=
                        product.maximumQuantity - product.minimumQuantity
                      ? ''
                      : 'increase-disabled'
                  }`}
                  onClick={handleValueIncrease}
                >
                  +
                </Col>
              </Row>
            </div>
          </div>
          <div className="checkbox-container">
            <CheckBox
              label=""
              id="cart"
              field="cart"
              showErrors={false}
              checked={checkedProducts.includes(product.productId)}
              onChange={e => onChangeCheckBox(e)}
            />
          </div>
        </>
      )}
    </Card>
  );
}
ProductCartCard.propTypes = {
  setCartProductItems: PropTypes.func,
  setCheckedProducts: PropTypes.func,
  checkedProducts: PropTypes.array,
  product: PropTypes.object,
  cartId: PropTypes.number,
  selectedDate: PropTypes.any,
  selectedDeliverySlotId: PropTypes.any,
};

export default ProductCartCard;
