import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { cognito } from '../../environment';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const AuthContext = React.createContext();
const AuthProvider = props => {
  const [signInResponse, setSignInResponse] = useState({
    res: null,
    err: null,
  });
  const [createPasswordResponse, setCreatePasswordResponse] = useState({
    res: null,
    err: null,
  });
  const [passwordPoliciesResponse, setPasswordPoliciesResponse] = useState({
    res: null,
    err: null,
  });

  const [otpRequestResponse, setOtpRequestResponse] = useState({
    res: null,
    err: null,
  });

  const [forgotPasswordSubmitRes, setForgotPasswordSubmitRes] = useState({
    res: null,
    err: null,
  });
  const [termAndConditionsResponse, setTermAndConditionsResponse] = useState({
    res: null,
    err: null,
  });

  const [permissionsRes, setPermissionsRes] = useState({
    res: null,
    err: null,
  });
  const [username, setUsername] = useState();
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;

  const signIn = async (username, password, rememberMe = false) => {
    setLoading(true);
    try {
      const res = await Auth.signIn(username, password);
      if (rememberMe) {
        await rememberDevice();
      }
      setSignInResponse({ res, err: null });
      setLoading(false);
    } catch (err) {
      setSignInResponse({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const createPassword = async (user, password) => {
    setLoading(true);
    setUsername(username);
    try {
      const res = await Auth.completeNewPassword(user, password);
      setCreatePasswordResponse({ res, err: null });
      setLoading(false);
    } catch (err) {
      setCreatePasswordResponse({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const forgotPassword = async username => {
    setUsername(username);
    try {
      const res = await Auth.forgotPassword(username);
      setOtpRequestResponse({ res, err: null });
    } catch (err) {
      setOtpRequestResponse({ err: err?.message || err, res: null });
    }
  };

  const rememberDevice = async () => {
    await Auth.rememberDevice();
  };

  const getPasswordPolicies = async () => {
    try {
      const res = await fetch(cognito.COGNITO_PASSWORD_POLICIES, {
        method: 'GET',
      });
      setPasswordPoliciesResponse({ res: await res.json(), err: null });
    } catch (err) {
      setPasswordPoliciesResponse({ err: err?.message || err, res: null });
    }
  };

  const logout = async () => {
    await Auth.signOut();
  };

  const reInvite = async username => {
    await Auth.resendSignUp(username);
  };

  const forgotPasswordCode = async (username, code, password) => {
    setLoading(true);
    setUsername(username);
    try {
      const res = await Auth.forgotPasswordSubmit(username, code, password);
      setForgotPasswordSubmitRes({ res, err: null });
      setLoading(false);
    } catch (err) {
      setForgotPasswordSubmitRes({ res: null, err: err?.message || err });
      setLoading(false);
    }
  };

  const getTermsAndConditions = async username => {
    setLoading(true);
    try {
      const url = Connection.baseUrl('users');
      const termsConditions = await fetch(
        url + `${username}/terms-and-conditions`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ isTermsAndConditionAccepted: true }),
        },
      );
      if (termsConditions.status === 200) {
        setTermAndConditionsResponse({ res: 'success', err: null });
      } else {
        const data = await termsConditions.json();
        setTermAndConditionsResponse({ res: null, err: data?.message });
      }
      setLoading(false);
    } catch (err) {
      setTermAndConditionsResponse({ res: null, err: err?.message || err });
      setLoading(false);
    }
  };

  const getPermissions = async user => {
    setLoading(true);
    try {
      const url = Connection.baseUrl('users');
      const permission = await fetch(url + `${user?.sub}/permissions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: user?.token,
        },
      });
      const data = await permission.json();
      if (data.message || data.description || data?.errors) {
        setPermissionsRes({
          res: null,
          err: data?.errors?.[0]?.message || data.description,
        });
      } else {
        setPermissionsRes({ res: data, err: null });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setPermissionsRes({ res: null, err: err?.message || err });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        state: {
          signInResponse: signInResponse,
          passwordPoliciesResponse: passwordPoliciesResponse,
          createPasswordResponse: createPasswordResponse,
          otpRequestResponse: otpRequestResponse,
          forgotPasswordSubmitRes: forgotPasswordSubmitRes,
          username: username,
          termAndConditionsResponse: termAndConditionsResponse,
          permissionsRes: permissionsRes,
        },
        signIn: signIn,
        createPassword: createPassword,
        forgotPassword: forgotPassword,
        fetchPasswordPolicies: getPasswordPolicies,
        logout: logout,
        reInvite: reInvite,
        forgotPasswordCode: forgotPasswordCode,
        getTermsAndConditions: getTermsAndConditions,
        getPermissions: getPermissions,
        setPermissionsRes: setPermissionsRes,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.any,
};

export { AuthProvider, AuthContext };
