import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ProductCard from '../../shared/ProductCard';
import { ManualProcurementContext } from '../../../../../apis/admin';
import ProductCardMobile from '../../shared/ProductCardMobile';
import { formatDate } from '../../../../../shared/utility/dateFormats';

const DESKTOP_VIEW_ITEMS = 6;
// const MOBILE_VIEW_ITEMS = 4;<==
function OrderSorting({
  isMobile,
  onHandleViewOrder,
  deliveryDate,
  deliverySlot,
  baseDeliveryName,
  setQueryParam,
}) {
  const [currentItems, setCurrentItems] = useState([]);

  const { t } = useTranslation();

  const [currentBoundary, setCurrentBoundary] = useState(0);
  const [pageViewItems, setPageViewItems] = useState(DESKTOP_VIEW_ITEMS);
  const manualProcurementContext = useContext(ManualProcurementContext);
  const [isEnableViewOrder, setIsEnableViewOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getSortedSummaryItems, getAdminDetails } = manualProcurementContext;
  const {
    sortingDetailsViewRes,
    sortedSummaryListRes,
    adminDetails,
  } = manualProcurementContext.state;
  const [isBottom, setIsBottom] = useState(false);
  const mobileRef = useRef();
  useEffect(() => {
    getAdminDetails();
    getSortedSummaryItems(
      setQueryParam(deliveryDate, baseDeliveryName, deliverySlot),
    ).finally(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });

    setPageViewItems(DESKTOP_VIEW_ITEMS);
  }, [isMobile, pageViewItems]);

  useEffect(() => {
    setCurrentBoundary(0);
    if (sortedSummaryListRes?.res) {
      setCurrentItems(
        isMobile
          ? sortedSummaryListRes?.res
          : sortedSummaryListRes?.res.slice(0, 0 + pageViewItems),
      );
    }
  }, [sortedSummaryListRes]);

  const onScrollHorizontal = next => {
    if (next) {
      setCurrentItems(
        sortedSummaryListRes?.res.slice(
          currentBoundary + pageViewItems,
          currentBoundary + pageViewItems * 2,
        ),
      );
      setCurrentBoundary(currentBoundary + pageViewItems);
    } else {
      setCurrentItems(
        sortedSummaryListRes?.res.slice(
          currentBoundary - pageViewItems,
          currentBoundary,
        ),
      );
      setCurrentBoundary(currentBoundary - pageViewItems);
    }
  };

  useEffect(() => {
    const el = document.getElementsByClassName('inner-body-container')[0];
    if (el && isMobile) {
      el.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (el) {
        el.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isMobile]);

  function handleScroll() {
    const el = document.getElementsByClassName('inner-body-container')[0];
    if (el) {
      const scrollTop = (el && el.scrollTop) || document.body.scrollTop;
      const scrollHeight =
        (el && el.scrollHeight) || document.body.scrollHeight;
      if (scrollTop + el.clientHeight >= scrollHeight) {
        setIsBottom(true);
      }
    }
  }

  useEffect(() => {
    if (isBottom) {
      setIsBottom(false);
      const list = currentItems;
      let newList = [];
      if (
        currentItems.length + DESKTOP_VIEW_ITEMS <=
        sortedSummaryListRes?.res?.length
      ) {
        newList = sortedSummaryListRes?.res?.slice(
          currentItems.length,
          currentItems.length + DESKTOP_VIEW_ITEMS,
        );
      } else {
        newList = sortedSummaryListRes?.res?.slice(
          currentItems.length,
          sortedSummaryListRes?.res.length,
        );
      }
      const merged = [...list, ...newList];
      setCurrentItems(merged);
    }
  }, [isBottom]);

  useEffect(() => {
    if (baseDeliveryName && adminDetails) {
      if (
        adminDetails?.res?.baseId &&
        baseDeliveryName?.id == adminDetails?.res?.baseId &&
        sortingDetailsViewRes?.res &&
        sortingDetailsViewRes?.res.length > 0
      ) {
        setIsEnableViewOrder(true);
      } else {
        setIsEnableViewOrder(false);
      }
    } else {
      setIsEnableViewOrder(false);
    }
  }, [baseDeliveryName, adminDetails, sortingDetailsViewRes]);

  return (
    <div>
      <div style={!isMobile ? { textAlign: 'right' } : { textAlign: 'center' }}>
        <Button
          disabled={
            !isEnableViewOrder || sortedSummaryListRes?.res.length === 0
          }
          color="primary"
          onClick={() => {
            onHandleViewOrder(
              {
                ExpectedDeliverySlotId: deliverySlot?.id,
                ExpectedDeliveryDate: formatDate(
                  deliveryDate,
                  null,
                  'DD/MM/YYYY',
                  'YYYY-DD-MM',
                ),
              },
              {
                deliveryBaseId: baseDeliveryName?.id,
                deliveryDate: deliveryDate,
                deliverySlot: deliverySlot?.id,
              },
            );
          }}
          style={isMobile ? { width: '100%', marginTop: '25px' } : {}}
        >
          {t('VIEW_ORDERS')}
        </Button>
      </div>

      <div className="sorting-summary my-1">
        <p className="px-3 my-3">{t('SORTING_SUMMARY')}</p>

        {currentItems.length > 0 || isLoading ? (
          <>
            {!isMobile && (
              <Container className="p-0">
                <Row>
                  <Col className="p-0">
                    <a
                      className={
                        currentBoundary < pageViewItems
                          ? 'btn-disable d-none'
                          : ''
                      }
                      disabled={currentBoundary < pageViewItems}
                      onClick={() => onScrollHorizontal(false)}
                    >
                      <span className="left"></span>
                    </a>

                    <a
                      className={
                        currentBoundary + pageViewItems >=
                        sortedSummaryListRes?.res?.length
                          ? 'btn-disable d-none'
                          : ''
                      }
                      disabled={
                        currentBoundary + pageViewItems >=
                        sortedSummaryListRes?.res?.length
                      }
                      onClick={() => onScrollHorizontal(true)}
                    >
                      <span className="right"></span>
                    </a>
                  </Col>
                </Row>
              </Container>
            )}
            <Container
              ref={mobileRef}
              id="container"
              className={!isMobile ? 'mt-3 mb-3' : 'mb-3'}
            >
              <Row style={!isMobile ? { margin: 'auto' } : {}}>
                {currentItems?.map((item, index) =>
                  !isMobile ? (
                    <Col
                      sm={6}
                      lg={4}
                      xl={4}
                      md={6}
                      key={index}
                      className="desktop"
                    >
                      <ProductCard
                        key={item?.productId}
                        id={item?.productId}
                        src={item?.productImage}
                        title={item?.productName}
                        unit={item?.totalQuantity + ' ' + item?.unitMeasure}
                      />
                    </Col>
                  ) : (
                    <Col sm={1} key={index} className="px-0 mobile">
                      <ProductCardMobile
                        key={item?.productId}
                        id={item?.productId}
                        src={item?.productImage}
                        title={item?.productName}
                        unit={item?.totalQuantity + ' ' + item?.unitMeasure}
                      />
                    </Col>
                  ),
                )}
              </Row>
            </Container>
          </>
        ) : (
          <div className="py-5 custom-empty-message">
            <p>Not available at the moment. Please check later.</p>
          </div>
        )}
      </div>
    </div>
  );
}

OrderSorting.propTypes = {
  isMobile: PropTypes.bool,
  onHandleViewOrder: PropTypes.func,
  deliveryDate: PropTypes.any,
  deliverySlot: PropTypes.any,
  baseDeliveryName: PropTypes.any,
  setQueryParam: PropTypes.func,
};

export default OrderSorting;
