import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../../../apis/authentication';
import { COGNITO_POLICY } from '../../../../shared/constants/constants';
import { CreatePassword } from '../share';
import SignUpForm from './SignupForm';

function SignUp() {
  const [stage, setStage] = useState('Step1');
  const [minPassword, setMinPassword] = useState();
  const signUpContext = useContext(AuthContext);
  const { passwordPoliciesResponse } = signUpContext.state;
  const { fetchPasswordPolicies } = signUpContext;

  useEffect(() => {
    const fetchPWpolcies = async () => {
      fetchPasswordPolicies();
    };
    fetchPWpolcies();
  }, []);

  useEffect(() => {
    if (passwordPoliciesResponse?.res) {
      setMinPassword(
        parseInt(passwordPoliciesResponse?.res?.policy?.minimumLength),
      );
    } else {
      setMinPassword(parseInt(COGNITO_POLICY.PASSWORD_DEFAULT_MIN_LENGTH));
    }
  }, [passwordPoliciesResponse]);

  const currentStage = stage => {
    switch (stage) {
      case 'Step1':
        return <SignUpForm setStage={setStage} />;
      case 'Step2':
        return (
          <CreatePassword isForgotPassword={false} minPassword={minPassword} />
        );
    }
  };
  return (
    <div>
      <div>{currentStage(stage)}</div>
    </div>
  );
}
export default SignUp;
