import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'reactstrap';
import {
  COGNITO,
  COGNITO_POLICY,
  LOGIN_ISSUE_CONTACT_NUMBER,
  SRILANKAN_COUNTRY_CODE,
} from '../../../../shared/constants/constants';
import { AuthContext } from '../../../../apis/authentication';
import { Validate } from '../../../config';
import { Text } from '../../../../shared';
import { alterPhoneNumber } from '../../../utilities/utils';
import { useDeviceDetect } from '../../../../shared/hooks';

function MobileVerification(props) {
  const { setStage, onRequestOtp } = props;
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const signInContext = useContext(AuthContext);
  const { otpRequestResponse } = signInContext.state;
  const { isMobile } = useDeviceDetect();
  const [form, setForm] = useState({
    username: {
      value: '',
      title: t('PHONE_NUMBER'),
      rules: {
        title: t('ENTER_YOUR_PHONE_NUMBER'),
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
        range: {
          min: COGNITO_POLICY.PHONE_NUMBER_MIN_LENGTH,
          max: COGNITO_POLICY.PHONE_NUMBER_MAX_LENGTH,
          message: t('PHONE_NUMBER_ENTERED_INVALID_MESSAGE'),
        },
      },
    },
    countryCode: {
      value: SRILANKAN_COUNTRY_CODE,
      title: t('COUNTRY_CODE'),
      rules: {
        stop: true,
        title: t('COUNTRY_CODE'),
      },
    },
  });

  useEffect(() => {
    if (otpRequestResponse?.err != null || otpRequestResponse?.res != null) {
      handleOtpRequestResponse();
    }
  }, [otpRequestResponse]);

  const handleOtpRequestResponse = () => {
    if (otpRequestResponse.err != null) {
      if (
        otpRequestResponse.err?.includes(COGNITO.NUMBER_OF_REQUEST_EXCEEDED)
      ) {
        setError(t('NUMBER_OF_COGNITO_REQUEST_ATTEMPT_EXCEEDED'));
        return;
      }
      setError(otpRequestResponse.err);
      return;
    }
    setStage('Step2');
  };
  const handleRequestOtp = e => {
    setError(null);
    e.preventDefault();
    const formValidation = Validate.form(form);
    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }
    const { username } = form;
    onRequestOtp(alterPhoneNumber(username.value));
  };
  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const onBlur = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  return (
    <div style={{ marginTop: '25px' }}>
      <Form onSubmit={handleRequestOtp}>
        <div className="mobile-center-view" style={{ bottom: '50%' }}>
          <div className="username-container">
            <Text
              label={form.username.title}
              prefix={SRILANKAN_COUNTRY_CODE}
              groupClassName="form-group"
              labelClassName="form-label"
              id="username"
              placeholder={form.username.rules.title}
              field="username"
              onChange={onChange}
              onBlur={onBlur}
              validation={form.username}
              rightIconGroupClassName="username-input-container"
              maxLength={COGNITO_POLICY.PHONE_NUMBER_MAX_LENGTH}
            />
          </div>
          {error !== null && <small className="error-message">{error}</small>}
        </div>
        <div className="form-footer mt-4">
          <Button
            style={isMobile ? { marginTop: '150px' } : {}}
            color="primary"
            type="submit"
            className="form-submit"
          >
            {t('SEND_OTP')}
          </Button>
          <small className="help">
            {t('DO_YOU_HAVE_LOGIN_ISSUES_CALL_US_ON')}{' '}
            <a href={`tel:${LOGIN_ISSUE_CONTACT_NUMBER}`}>
              {' '}
              {LOGIN_ISSUE_CONTACT_NUMBER}
            </a>
          </small>
        </div>
      </Form>
    </div>
  );
}
MobileVerification.propTypes = {
  setStage: PropTypes.func,
  onRequestOtp: PropTypes.func,
};

export default MobileVerification;
