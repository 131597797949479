import React, { useEffect, useState, useContext } from 'react';
import InnerBody from '../../../../content/InnerBody';
import BuyerOrderHistory from './BuyerOrderHistory';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useDeviceDetect } from '../../../../shared/hooks';
import BuyerPaymentHistory from './BuyerPaymentHistory';
import BuyerInvoiceBasedPayment from './BuyerInvoiceBasedPayment';
import BuyerDetailInvoiceView from './BuyerDetailInvoiceView';
import { CustomModal } from '../../../../shared/components';
import { BuyerOrderPaymentHistoryContext } from '../../../../apis/buyer/BuyerOrderPaymentHistory';
const tabs = {
  orderHistory: 'orderHistory',
  orderPayment: 'orderPayment',
};
function BuyerOrderAndPaymentHistory() {
  const [stage, setStage] = useState('Step1');
  const [activeTab, setActiveTab] = useState(tabs.orderHistory);
  const { isMobile } = useDeviceDetect();
  const [selectedOrder, setSelectedOrder] = useState(null);
  useEffect(() => {}, [isMobile]);
  const buyerPayContext = useContext(BuyerOrderPaymentHistoryContext);

  const toggleTabs = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const { buyerOrderHistoryResponse } = buyerPayContext.state;
  const [error, setError] = useState({ title: null, description: null });
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const toggleErrorPopup = () => {
    setError(null);
    setErrorPopupModal(!errorPopupModal);
  };

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={error?.title || ''}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {error?.description}
    </CustomModal>
  );
  return (
    <InnerBody
      topic={
        stage === 'Step2'
          ? 'Select invoices to initiate a payment'
          : 'Order and Payment History'
      }
      isDisable={stage === 'Step1'}
      onHandleBack={() => {
        setStage('Step1');

        if (tabs.orderHistory === activeTab) {
          if (!buyerOrderHistoryResponse?.res?.orders?.length > 0) {
            return;
          }
          setTimeout(() => {
            var rows = document.querySelectorAll('tr');
            const line = buyerOrderHistoryResponse?.res?.orders?.findIndex(
              order => order.purchaseOrderId === selectedOrder?.purchaseOrderId,
            );

            rows[line].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }, 1000);
        }
      }}
    >
      {errorPopup()}
      <div className="buyer-order-payment-history">
        {stage === 'Step1' && (
          <div>
            <Nav tabs className="buyer-order-payment-tab">
              <NavItem
                className={tabs.orderHistory === activeTab ? 'active-tab' : ''}
              >
                <div className="triangle-down"></div>
                <NavLink
                  onClick={() => {
                    toggleTabs(tabs.orderHistory);
                  }}
                >
                  Order History
                </NavLink>
              </NavItem>
              <NavItem
                className={tabs.orderPayment === activeTab ? 'active-tab' : ''}
              >
                <div className="triangle-down"></div>
                <NavLink
                  onClick={() => {
                    toggleTabs(tabs.orderPayment);
                  }}
                >
                  Payment History
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={tabs.orderHistory}>
                <BuyerOrderHistory
                  isMobile={isMobile}
                  setError={setError}
                  setErrorPopupModal={setErrorPopupModal}
                  onClickOrder={e => {
                    setSelectedOrder(e);
                    setStage('Step3');
                  }}
                />
              </TabPane>
              <TabPane tabId={tabs.orderPayment}>
                <BuyerPaymentHistory
                  isMobile={isMobile}
                  initiatePayment={() => setStage('Step2')}
                />
              </TabPane>
            </TabContent>
          </div>
        )}
        {stage === 'Step2' && (
          <BuyerInvoiceBasedPayment
            setError={setError}
            setErrorPopupModal={setErrorPopupModal}
            isMobile={isMobile}
            redirectPayHis={() => {
              setStage('Step1');
              toggleTabs(tabs.orderPayment);
            }}
          />
        )}
        {stage === 'Step3' && (
          <BuyerDetailInvoiceView
            redirectBack={() => setStage('Step1')}
            order={selectedOrder}
            isMobile={isMobile}
          />
        )}
      </div>
    </InnerBody>
  );
}

export default BuyerOrderAndPaymentHistory;
