import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const VendorManagementContext = React.createContext();
const VendorManagementProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [productDetailsResponse, setProductDetailsResponse] = useState({
    res: null,
    err: null,
  });
  const [defaultBaseDetailsResponse, setDefaultBaseDetailsResponse] = useState({
    res: null,
    err: null,
  });

  const getProductDetails = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const productDetails = await fetch(url + `products`, {
        method: 'GET',
        headers: headers,
      });

      if (productDetails?.error || productDetails?.message) {
        setProductDetailsResponse({ res: null, err: productDetails?.message });
      } else {
        setProductDetailsResponse({
          res: await productDetails.json(),
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setProductDetailsResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const getDefaultBaseDetails = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const defaultBaseDetails = await fetch(url + `default-bases`, {
        method: 'GET',
        headers: headers,
      });

      if (defaultBaseDetails?.error || defaultBaseDetails?.message) {
        setDefaultBaseDetailsResponse({
          res: null,
          err: defaultBaseDetails?.message,
        });
      } else {
        setDefaultBaseDetailsResponse({
          res: await defaultBaseDetails.json(),
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setProductDetailsResponse({ res: null, err: err });
      setLoading(false);
    }
  };
  return (
    <VendorManagementContext.Provider
      value={{
        state: {
          productDetailsResponse: productDetailsResponse,
          defaultBaseDetailsResponse: defaultBaseDetailsResponse,
        },
        getProductDetails: getProductDetails,
        getDefaultBaseDetails: getDefaultBaseDetails,
      }}
    >
      {props.children}
    </VendorManagementContext.Provider>
  );
};
VendorManagementProvider.propTypes = {
  children: PropTypes.any,
};

export { VendorManagementProvider, VendorManagementContext };
