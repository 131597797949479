import React, { useState, useContext, useEffect } from 'react';
import { Table, Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { arrowDownGreen, arrowUpGreen } from '../../../assets/icons/';
import moment from 'moment';
import { BuyerOrderPaymentHistoryContext } from '../../../../apis/buyer/BuyerOrderPaymentHistory';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocBuyerDetailInvoice from './PdfDocBuyerDetailInvoice';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function BuyerDetailInvoiceView({ isMobile, order, redirectBack }) {
  const { t } = useTranslation();
  const [isVisibleProdDet, setIsVisibleProdDet] = useState(true);
  const [isVisibleReturnedProdDet, setIsVisibleReturnedProdDet] = useState(
    false,
  );
  const [isVisibleTotal, setIsVisibleTotal] = useState(false);

  const buyerPayContext = useContext(BuyerOrderPaymentHistoryContext);
  const { getBuyerPaymentDetailInvoice } = buyerPayContext;
  const { buyerPaymentDetailsInvoiceResponse } = buyerPayContext.state;
  useEffect(() => {
    const url = new URL(window.location);
    window.history.pushState({}, '', url);

    window.addEventListener('popstate', function() {
      redirectBack();
    });

    getBuyerPaymentDetailInvoice(order?.invoiceId || '');
  }, []);

  return (
    <div
      id="buyer-detail-invoice-view"
      className="pb-3 buyer-detail-invoice-view"
    >
      {buyerPaymentDetailsInvoiceResponse?.res && (
        <PDFDownloadLink
          className="d-none"
          document={
            <PdfDocBuyerDetailInvoice
              invoice={buyerPaymentDetailsInvoiceResponse?.res}
            />
          }
          fileName="movielist.pdf"
          style={{
            textDecoration: 'none',
            padding: '10px',
            color: '#4a4a4a',
            backgroundColor: '#f2f2f2',
            border: '1px solid #4a4a4a',
            width: 'max-content',
          }}
        >
          {({ loading }) => (loading ? 'Loading document...' : 'Download Pdf')}
        </PDFDownloadLink>
      )}

      <div id="pdf-buyer-detail-invoice-view">
        <div className="buyer-detail-row">
          <div className="buyer-detail">
            <b>Invoice Number :</b>
            <small>
              {buyerPaymentDetailsInvoiceResponse?.res?.invoiceNumber}
            </small>
          </div>
          <div className="buyer-detail">
            <b>Delivery Date :</b>
            <small>
              {moment(order?.deliveryDateTime).format('DD-MM-YYYY')}{' '}
            </small>
          </div>
          <div className="buyer-detail">
            <b>Client :</b>
            <small>
              {buyerPaymentDetailsInvoiceResponse?.res?.buyerCompanyName}
            </small>
          </div>
          <div className="buyer-detail">
            <b>Client Address :</b>
            <small>{buyerPaymentDetailsInvoiceResponse?.res?.address}</small>
          </div>
        </div>

        {buyerPaymentDetailsInvoiceResponse?.res && (
          <div className="mt-3">
            <PDFDownloadLink
              document={
                <PdfDocBuyerDetailInvoice
                  invoice={buyerPaymentDetailsInvoiceResponse?.res}
                  deleiveryDate={order?.deliveryDateTime}
                />
              }
              fileName={`${buyerPaymentDetailsInvoiceResponse?.res?.buyerCompanyName}-${buyerPaymentDetailsInvoiceResponse?.res?.invoiceNumber}.pdf`}
              className="text-decoration-none"
            >
              {({ loading, error }) => {
                if (loading) {
                  return (
                    <span className="upload-card-button btn-primary download-pdf">
                      Loading document...
                    </span>
                  );
                }
                if (error) {
                  return (
                    <p className="warning pointer-none">{error?.message}</p>
                  );
                }
                return (
                  <span className="upload-card-button btn-primary download-pdf">
                    Download
                  </span>
                );
              }}
            </PDFDownloadLink>
          </div>
        )}
        <Card className="mt-4">
          <CardHeader onClick={() => setIsVisibleProdDet(!isVisibleProdDet)}>
            <div>Product Details</div>
            <img
              className={isVisibleProdDet ? 'mt-2' : 'm-0'}
              width={'20'}
              height={'20'}
              src={isVisibleProdDet ? arrowUpGreen : arrowDownGreen}
              alt="arrow-up"
            />{' '}
          </CardHeader>
          <CardBody>
            <div className={!isVisibleProdDet ? 'd-none' : 'tableFixHead'}>
              {buyerPaymentDetailsInvoiceResponse?.res?.products?.length ===
                0 && (
                <div
                  style={{ fontSize: '24px' }}
                  className="custom-empty-message"
                >
                  <p className="small-empty-message">
                    {t('ITEMS_ARE_UNAVAILABLE')}
                  </p>
                </div>
              )}
              {buyerPaymentDetailsInvoiceResponse?.res?.products?.length >
                0 && (
                <Table>
                  <thead>
                    {!isMobile && (
                      <tr>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th className="text-right">Unit Price</th>
                        <th className="text-right">Total</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {buyerPaymentDetailsInvoiceResponse?.res?.products?.map(
                      (item, index) => (
                        <>
                          <tr key={index}>
                            {!isMobile ? (
                              <>
                                <td>{item?.productName}</td>
                                <td className="blue-color">
                                  {item?.quantity || 'Pending'}{' '}
                                  {' ' + item?.quantity
                                    ? item?.unitTypeName
                                    : ''}
                                </td>
                                <td className="blue-color amount-due text-right">
                                  {' '}
                                  {item.statusId === 13
                                    ? 'N/A'
                                    : item?.unitPrice
                                    ? commafy(RoundNum(item?.unitPrice, 2)) +
                                      `/${item?.unitTypeName}`
                                    : 'Pending'}
                                </td>
                                <td className="blue-color amount-due text-right">
                                  {item.statusId === 13
                                    ? 'N/A'
                                    : item?.totalPrice
                                    ? commafy(RoundNum(item?.totalPrice, 2))
                                    : 'Pending'}
                                </td>
                              </>
                            ) : (
                              <td>
                                <small className="d-block p-0">
                                  {item?.productName}
                                </small>
                                <div className="pt-2 d-flex flex-row justify-content-between">
                                  <small className="d-block p-0 blue-color col-3">
                                    {item?.quantity || 'Pending'}{' '}
                                    {' ' + item?.unitTypeName}
                                  </small>
                                  <div className="col-3 text-center">
                                    <small className="d-block p-0 blue-color">
                                      {item.statusId === 13
                                        ? 'N/A'
                                        : item?.unitPrice
                                        ? commafy(
                                            RoundNum(item?.unitPrice, 2),
                                          ) + `/${item?.unitTypeName}`
                                        : 'Pending'}
                                    </small>
                                  </div>
                                  <div className="col-3 text-right">
                                    <small className="d-block p-0 blue-color">
                                      {item.statusId === 13
                                        ? 'N/A'
                                        : item?.totalPrice
                                        ? commafy(RoundNum(item?.totalPrice, 2))
                                        : 'Pending'}
                                    </small>
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        </>
                      ),
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </CardBody>
        </Card>
        <Card className="mt-4">
          <CardHeader
            onClick={() =>
              setIsVisibleReturnedProdDet(!isVisibleReturnedProdDet)
            }
          >
            <div>Returns</div>
            <img
              className={isVisibleReturnedProdDet && 'mt-2'}
              width={'20'}
              height={'20'}
              src={isVisibleReturnedProdDet ? arrowUpGreen : arrowDownGreen}
              alt="arrow-up"
            />{' '}
          </CardHeader>
          <CardBody>
            <div
              className={!isVisibleReturnedProdDet ? 'd-none' : 'tableFixHead'}
            >
              {buyerPaymentDetailsInvoiceResponse?.res?.returnedProducts
                ?.length === 0 && (
                <div
                  style={{ fontSize: '24px' }}
                  className="custom-empty-message"
                >
                  <p className="small-empty-message">
                    {t('ITEMS_ARE_UNAVAILABLE')}
                  </p>
                </div>
              )}
              {buyerPaymentDetailsInvoiceResponse?.res?.returnedProducts
                ?.length > 0 && (
                <Table>
                  <thead>
                    {!isMobile && (
                      <tr>
                        <th>Product Name</th>
                        <th>Reason</th>
                        <th className="text-right">Quantity</th>
                        <th className="text-right">Total</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {buyerPaymentDetailsInvoiceResponse?.res?.returnedProducts?.map(
                      (item, index) => (
                        <>
                          <tr key={index}>
                            {!isMobile ? (
                              <>
                                <td>{item?.productName}</td>
                                <td>
                                  {item?.buyerQCRejectedReasons?.length > 0 && (
                                    <>
                                      <strong>Buyer Check: </strong>
                                      {item?.buyerQCRejectedReasons?.map(
                                        (buyerReason, index) => (
                                          <span key={'buyer' + buyerReason}>
                                            {buyerReason}{' '}
                                            {index !==
                                              item?.buyerQCRejectedReasons
                                                .length -
                                                1 && ', '}
                                          </span>
                                        ),
                                      )}
                                      <br />
                                    </>
                                  )}
                                  {item?.supplierQCRejectedReasons?.length >
                                    0 && (
                                    <>
                                      <strong>Supplier Check: </strong>
                                      {item?.supplierQCRejectedReasons?.map(
                                        (supplierReason, index) => (
                                          <span
                                            key={'supplier' + supplierReason}
                                          >
                                            {supplierReason}
                                            {index !==
                                              item?.supplierQCRejectedReasons
                                                .length -
                                                1 && ', '}
                                          </span>
                                        ),
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className="blue-color amount-due text-right">
                                  {' '}
                                  {item?.quantity || 'N/A'}{' '}
                                  {' ' + item?.unitTypeName}
                                </td>
                                <td className="blue-color amount-due text-right">
                                  {item?.totalPrice
                                    ? commafy(RoundNum(item?.totalPrice, 2))
                                    : 'N/A'}
                                </td>
                              </>
                            ) : (
                              <td>
                                <small className="d-block p-0">
                                  {item?.productName}
                                </small>
                                <div className="pt-2 d-flex flex-row justify-content-between">
                                  <small className="d-block p-0 blue-color">
                                    {item?.buyerQCRejectedReasons?.length >
                                      0 && (
                                      <>
                                        <strong>Buyer Check: </strong>
                                        {item?.buyerQCRejectedReasons?.map(
                                          (buyerReason, index) => (
                                            <span key={'buyer' + buyerReason}>
                                              {buyerReason}{' '}
                                              {index !==
                                                item?.buyerQCRejectedReasons
                                                  .length -
                                                  1 && ', '}
                                            </span>
                                          ),
                                        )}
                                        <br />
                                      </>
                                    )}
                                    {item?.supplierQCRejectedReasons?.length >
                                      0 && (
                                      <>
                                        <strong>Supplier Check: </strong>
                                        {item?.supplierQCRejectedReasons?.map(
                                          (supplierReason, index) => (
                                            <span
                                              key={'supplier' + supplierReason}
                                            >
                                              {supplierReason}
                                              {index !==
                                                item?.supplierQCRejectedReasons
                                                  .length -
                                                  1 && ', '}
                                            </span>
                                          ),
                                        )}
                                      </>
                                    )}
                                  </small>
                                  <div>
                                    <small className="d-block p-0">Qty</small>
                                    <small className="d-block p-0 blue-color">
                                      {item?.quantity || 'Pending'}{' '}
                                      {item?.quantity
                                        ? ' ' + item?.unitTypeName
                                        : ''}
                                    </small>
                                  </div>
                                  <div>
                                    <small className="d-block p-0 text-right">
                                      Tot
                                    </small>
                                    <small className="d-block p-0 blue-color text-right">
                                      {item?.totalPrice
                                        ? commafy(RoundNum(item?.totalPrice, 2))
                                        : 'N/A'}
                                    </small>
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        </>
                      ),
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </CardBody>
        </Card>
        <Card className="mt-4 position-relative">
          <CardHeader onClick={() => setIsVisibleTotal(!isVisibleTotal)}>
            <div>Total</div>
            <div>
              <span>
                LKR{' '}
                {commafy(
                  RoundNum(
                    buyerPaymentDetailsInvoiceResponse?.res?.purchaseOrder
                      ?.total,
                    2,
                  ),
                )}
              </span>
              &nbsp;&nbsp;
              <img
                className={isVisibleTotal ? 'mt-2' : 'mb-2'}
                width={'20'}
                height={'20'}
                src={isVisibleTotal ? arrowUpGreen : arrowDownGreen}
                alt="arrow-up"
              />{' '}
            </div>
          </CardHeader>
          <CardBody>
            <div className={!isVisibleTotal ? 'd-none' : 'tableFixHead'}>
              {buyerPaymentDetailsInvoiceResponse?.res?.purchaseOrder &&
                Object.keys(
                  buyerPaymentDetailsInvoiceResponse?.res?.purchaseOrder,
                )?.length === 0 && (
                  <div
                    style={{ fontSize: '24px' }}
                    className="custom-empty-message"
                  >
                    <p className="small-empty-message">
                      {t('ITEMS_ARE_UNAVAILABLE')}
                    </p>
                  </div>
                )}
              {buyerPaymentDetailsInvoiceResponse?.res?.purchaseOrder &&
                Object.keys(
                  buyerPaymentDetailsInvoiceResponse?.res?.purchaseOrder,
                ).length !== 0 && (
                  <Table>
                    <tbody>
                      <tr>
                        <td
                          width="75%"
                          style={{
                            paddingLeft: '10px',
                            marginLeft: '5px',
                            border: 'none',
                          }}
                        >
                          Sub Total
                        </td>{' '}
                        <td className="blue-color text-right">
                          LKR{' '}
                          {commafy(
                            RoundNum(
                              buyerPaymentDetailsInvoiceResponse?.res
                                ?.purchaseOrder?.subTotal,
                              2,
                            ),
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            paddingLeft: '10px',
                            marginLeft: '5px',
                            border: 'none',
                          }}
                          width="75%"
                        >
                          Service Fee
                        </td>{' '}
                        <td className="blue-color text-right">
                          LKR{' '}
                          {commafy(
                            RoundNum(
                              buyerPaymentDetailsInvoiceResponse?.res
                                ?.purchaseOrder?.serviceFee,
                              2,
                            ),
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            paddingLeft: '10px',
                            marginLeft: '5px',
                            border: 'none',
                          }}
                          width="75%"
                        >
                          Loading Fee
                        </td>{' '}
                        <td className="blue-color text-right">
                          LKR{' '}
                          {commafy(
                            RoundNum(
                              buyerPaymentDetailsInvoiceResponse?.res
                                ?.purchaseOrder?.loadingFee,
                              2,
                            ),
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingLeft: '10px', marginLeft: '5px' }}
                          width="75%"
                        >
                          Delivery Fee
                        </td>{' '}
                        <td className="blue-color text-right">
                          LKR{' '}
                          {commafy(
                            RoundNum(
                              buyerPaymentDetailsInvoiceResponse?.res
                                ?.purchaseOrder?.logisticFee,
                              2,
                            ),
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
BuyerDetailInvoiceView.propTypes = {
  isMobile: PropTypes.bool,
  order: PropTypes.any,
  redirectBack: PropTypes.func,
};
export default BuyerDetailInvoiceView;
