import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from '../../../apis/authentication';
import { sillara } from '../../assets/img';
import ForgotPassword from './forgotPassword';
import SignUp from './signup';
import Login from './login';
import PropTypes from 'prop-types';

function Auth({ loader }) {
  return (
    <AuthProvider>
      <div className="auth-container">
        <div className="auth-layout-container">
          <div className="col-lg-8 col-md-9 col-sm-9 auth-layout">
            <div className="auth-image-holder"></div>
            <div className="auth-form-holder">
              {loader}
              <img
                src={sillara}
                className="auth-form-logo mx-auto d-block" //tofill
                alt="sillara logo"
              />
              <Routes>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route path="*" element={<Navigate to="/auth/login" />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </AuthProvider>
  );
}

Auth.propTypes = {
  loader: PropTypes.any,
};
export default Auth;
