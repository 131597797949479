import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'reactstrap';
import { Text } from '../../../../shared/components';
import { Validate } from '../../../config';
import Switch from '../../../../shared/components/Switch';
function UpdateSupplierProductModal({
  currentInventory,
  currentPrice,
  isMobile,
  onSaveInventory,
  item,
  newPrice = '',
  newInventory = '',
}) {
  const { t } = useTranslation();
  const [customError, setCustomError] = useState('');

  const [form, setForm] = useState({
    newInventory: {
      value: newInventory,
      title: t('NEW_INVENTORY'),
      rules: {
        title: t('ENTER_NEW_INVENTORY'),
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
      },
    },
    newPrice: {
      value: newPrice,
      title: t('NEW_PRICE'),
      rules: {
        stop: true,
        title: t('ENTER_PER_UNIT_PRICE'),
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
      },
    },
    availability: {
      value: item?.isAvailable,
      title: t('AVAILABILITY'),
      rules: {
        stop: true,
        title: t('AVAILABILITY'),
      },
    },
  });

  const onChange = e => {
    setCustomError('');
    const { name, value } = e.target;

    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const onChangeToggle = e => {
    const { name } = e.target;
    const updatedForm = Validate.input(
      name,
      !form.availability.value,
      form,
      true,
    );
    updatedForm.newPrice.value = '';
    updatedForm.newInventory.value = '';
    if (form.availability.value) {
      updatedForm.newPrice.value = ' ';
      updatedForm.newInventory.value = ' ';
    }
    setForm(updatedForm);
  };

  const handleSave = e => {
    e.preventDefault();
    if (!form.availability.value) {
      onSaveInventory({ item: item, form: form });
      return;
    }
    const formValidation = Validate.form(form);

    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }

    if (parseFloat(item.quantity) + parseFloat(form.newInventory.value) < 0) {
      setCustomError("Inventory can't go below zero");
      return;
    }

    onSaveInventory({ item: item, form: form });
  };

  return (
    <React.Fragment>
      {customError !== '' && <p className="warning">{customError}</p>}
      <Form onSubmit={handleSave}>
        <Row>
          <p>
            <b className="subtitle">{t('PRODUCT_TITLE')}</b> :{' '}
            <span style={{ fontSize: '0.8rem' }}> {item?.product?.name}</span>
          </p>

          <Col className={!isMobile ? 'px-3' : ''} sm={12} lg={6}>
            <p className="bold-light">{t('CURRENT_INVENTORY')} </p>
            <p className={!isMobile ? 'pt-1' : ''}>{currentInventory}</p>
            <p className={!isMobile ? 'pt-3 bold-light' : 'bold-light'}>
              {t('CURRENT_PRICE')}{' '}
            </p>
            <p className={!isMobile ? 'pt-1' : ''}>LKR {currentPrice}</p>

            <Switch
              groupClassName={isMobile ? '' : 'mt-3'}
              label={form.availability.title}
              labelClassName="form-label"
              placeholder={form.availability.rules.title}
              id="availability"
              field="availability"
              onChange={onChangeToggle}
              checked={form.availability.value}
              validation={form.availability}
            />
          </Col>
          <Col
            style={!isMobile ? {} : { marginTop: '5px' }}
            sm={12}
            lg={6}
            className="text-left"
          >
            <Text
              disabled={!form.availability.value}
              groupClassName="update-form-group"
              labelClassName="form-label"
              label={form.newInventory.title}
              placeholder={form.newInventory.rules.title}
              id="new-inventory"
              autoComplete="off"
              showErrors={false}
              field="newInventory"
              onChange={onChange}
              rightIconGroupClassName="form-container"
              validation={form.newInventory}
            />
            <div
              className={isMobile ? 'mt-2 mb-3' : ''}
              style={!isMobile ? { marginTop: '-8px' } : {}}
            >
              <Text
                autoComplete="off"
                showErrors={false}
                disabled={!form.availability.value}
                groupClassName="update-form-group"
                labelClassName="form-label"
                label={t('NEW_PRICE')}
                placeholder={t('ENTER_PER_UNIT_PRICE')}
                id="new-price"
                field="newPrice"
                onChange={onChange}
                rightIconGroupClassName="form-container"
                validation={form.newPrice}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={
            !isMobile
              ? { float: 'right' }
              : { width: '105%', marginLeft: '-10px' }
          }
          color="primary"
          className="form-submit"
        >
          {t('SAVE')}
        </Button>
      </Form>
    </React.Fragment>
  );
}

UpdateSupplierProductModal.propTypes = {
  item: PropTypes.any,
  currentInventory: PropTypes.any,
  currentPrice: PropTypes.string,
  isMobile: PropTypes.bool,
  onSaveInventory: PropTypes.func,
  newPrice: PropTypes.string,
  newInventory: PropTypes.string,
  availability: PropTypes.bool,
};
export default UpdateSupplierProductModal;
