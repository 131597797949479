import React, { useState, useContext, useEffect } from 'react';
import { Table, Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { arrowDownGreen, arrowUpGreen } from '../../../assets/icons/';
import moment from 'moment';
import { SupplierOrderPaymentHistoryContext } from '../../../../apis/supplier/SupplierOrderPaymentHistory';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocBuyerDetailInvoice from './PdfDocSupplierDetailInvoice';
import { LoadingContext } from '../../../../apis/LoadingContext';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';
function supplierDetailInvoiceView({ isMobile, order, redirectBack }) {
  const { t } = useTranslation();
  const [isVisibleProdDet, setIsVisibleProdDet] = useState(true);
  const [isVisibleReturnedProdDet, setIsVisibleReturnedProdDet] = useState(
    false,
  );
  const [isVisibleTotal, setIsVisibleTotal] = useState(false);

  const supplierPayContext = useContext(SupplierOrderPaymentHistoryContext);
  const { getSupplierPaymentDetailInvoice } = supplierPayContext;
  const { supplierPaymentDetailsInvoiceResponse } = supplierPayContext.state;

  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  useEffect(() => {
    const url = new URL(window.location);
    window.history.pushState({}, '', url);

    window.addEventListener('popstate', function() {
      redirectBack();
    });
    getSupplierPaymentDetailInvoice(order?.supplierInvoiceId || '');
  }, []);
  return (
    <div className="pb-3 supplier-detail-invoice-view">
      {supplierPaymentDetailsInvoiceResponse.err !== null && (
        <p className="warning">{supplierPaymentDetailsInvoiceResponse.err}</p>
      )}
      {!loading && (
        <div className="supplier-detail-row">
          <div className="supplier-detail">
            <b>Invoice Number :&nbsp;</b>
            {supplierPaymentDetailsInvoiceResponse?.res?.invoiceNumber}
          </div>
          <div className="supplier-detail">
            <b>Delivery Date :&nbsp;</b>
            {moment(
              supplierPaymentDetailsInvoiceResponse?.res?.purchaseOrder
                ?.deliveryDate || '',
            ).format('DD-MM-YYYY')}
          </div>
          <div className="supplier-detail">
            <b>Client :&nbsp;</b>
            {supplierPaymentDetailsInvoiceResponse?.res?.buyerCompanyName}
          </div>
        </div>
      )}
      {!loading && supplierPaymentDetailsInvoiceResponse?.res && (
        <div className="mt-3">
          <PDFDownloadLink
            document={
              <PdfDocBuyerDetailInvoice
                invoice={supplierPaymentDetailsInvoiceResponse?.res}
              />
            }
            fileName={`${supplierPaymentDetailsInvoiceResponse?.res?.buyerCompanyName}-${supplierPaymentDetailsInvoiceResponse?.res?.invoiceNumber}.pdf`}
            className="text-decoration-none"
          >
            {({ loading, error }) => {
              if (loading) {
                return (
                  <span className="upload-card-button btn-primary download-pdf">
                    Loading document...
                  </span>
                );
              }
              if (error) {
                return <p className="warning pointer-none">{error?.message}</p>;
              }
              return (
                <span className="upload-card-button btn-primary download-pdf">
                  Download
                </span>
              );
            }}
          </PDFDownloadLink>
        </div>
      )}
      <Card className="mt-4">
        <CardHeader onClick={() => setIsVisibleProdDet(!isVisibleProdDet)}>
          <div>Product Details</div>
          <img
            className={isVisibleProdDet && 'mt-2'}
            width={'20'}
            height={'20'}
            src={isVisibleProdDet ? arrowUpGreen : arrowDownGreen}
            alt="arrow-up"
          />{' '}
        </CardHeader>
        <CardBody>
          <div className={!isVisibleProdDet ? 'd-none' : 'tableFixHead'}>
            {supplierPaymentDetailsInvoiceResponse?.res?.products?.length ===
              0 && (
              <div
                style={{ fontSize: '24px' }}
                className="custom-empty-message"
              >
                <p className="small-empty-message">
                  {t('ITEMS_ARE_UNAVAILABLE')}
                </p>
              </div>
            )}
            {supplierPaymentDetailsInvoiceResponse?.res?.products?.length >
              0 && (
              <Table>
                <thead>
                  {!isMobile && (
                    <tr>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th className="text-right">Unit Price</th>
                      <th className="text-right">Total</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {supplierPaymentDetailsInvoiceResponse?.res?.products?.map(
                    (item, index) => (
                      <>
                        <tr key={index}>
                          {!isMobile ? (
                            <>
                              <td>{item?.productName}</td>
                              <td className="blue-color">
                                {item?.quantity || 'Pending'}
                                {' ' + item?.unitTypeName}
                              </td>
                              <td className="blue-color amount-due text-right">
                                {' '}
                                {item?.unitPrice
                                  ? commafy(RoundNum(item?.unitPrice, 2)) +
                                    '/' +
                                    item?.unitTypeName
                                  : 'Pending'}
                              </td>
                              <td className="blue-color amount-due text-right">
                                {item?.totalPrice
                                  ? commafy(RoundNum(item?.totalPrice, 2))
                                  : 'Pending'}
                              </td>
                            </>
                          ) : (
                            <td>
                              <small className="d-block p-0">
                                {item?.productName}
                              </small>
                              <div className="pt-2 d-flex flex-row justify-content-between ">
                                <small className="d-block p-0 blue-color col-3">
                                  {item?.quantity || 'Pending'}
                                  {item?.quantity
                                    ? ' ' + item?.unitTypeName
                                    : ''}
                                </small>
                                <div className="col-3 text-center">
                                  <small className="d-block p-0 blue-color">
                                    {' '}
                                    {item?.unitPrice
                                      ? commafy(RoundNum(item?.unitPrice, 2)) +
                                        '/' +
                                        item?.unitTypeName
                                      : 'Pending'}
                                  </small>
                                </div>
                                <div className="col-3 text-right">
                                  <small className="d-block p-0 blue-color">
                                    {' '}
                                    {item?.totalPrice
                                      ? commafy(RoundNum(item?.totalPrice, 2))
                                      : 'Pending'}
                                  </small>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    ),
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </CardBody>
      </Card>

      <Card className="mt-4">
        <CardHeader
          onClick={() => setIsVisibleReturnedProdDet(!isVisibleReturnedProdDet)}
        >
          <div>Returns</div>
          <img
            className={isVisibleReturnedProdDet && 'mt-2'}
            width={'20'}
            height={'20'}
            src={isVisibleReturnedProdDet ? arrowUpGreen : arrowDownGreen}
            alt="arrow-up"
          />{' '}
        </CardHeader>
        <CardBody>
          <div
            className={!isVisibleReturnedProdDet ? 'd-none' : 'tableFixHead'}
          >
            {supplierPaymentDetailsInvoiceResponse?.res?.returnedProducts
              ?.length === 0 && (
              <div
                style={{ fontSize: '24px' }}
                className="custom-empty-message"
              >
                <p className="small-empty-message">
                  {t('ITEMS_ARE_UNAVAILABLE')}
                </p>
              </div>
            )}
            {supplierPaymentDetailsInvoiceResponse?.res?.returnedProducts
              ?.length > 0 && (
              <Table>
                <thead>
                  {!isMobile && (
                    <tr>
                      <th>Product Name</th>
                      <th>Reason</th>
                      <th>Quantity</th>
                      <th className=" text-right">Total</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {supplierPaymentDetailsInvoiceResponse?.res?.returnedProducts?.map(
                    (item, index) => (
                      <>
                        <tr key={index}>
                          {!isMobile ? (
                            <>
                              <td>{item?.productName}</td>
                              <td>
                                {item?.buyerQCRejectedReasons?.length > 0 && (
                                  <>
                                    <strong>Buyer Check: </strong>
                                    {item?.buyerQCRejectedReasons?.map(
                                      (buyerReason, index) => (
                                        <span key={'buyer' + buyerReason}>
                                          {buyerReason}{' '}
                                          {index !==
                                            item?.buyerQCRejectedReasons
                                              .length -
                                              1 && ', '}
                                        </span>
                                      ),
                                    )}
                                    <br />
                                  </>
                                )}
                                {item?.supplierQCRejectedReasons?.length >
                                  0 && (
                                  <>
                                    <strong>Supplier Check: </strong>
                                    {item?.supplierQCRejectedReasons?.map(
                                      (supplierReason, index) => (
                                        <span key={'supplier' + supplierReason}>
                                          {supplierReason}
                                          {index !==
                                            item?.supplierQCRejectedReasons
                                              .length -
                                              1 && ', '}
                                        </span>
                                      ),
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="blue-color amount-due ">
                                {item?.quantity || 'N/A'}
                                {item?.quantity ? ' ' + item?.unitTypeName : ''}
                              </td>
                              <td className="blue-color amount-due  text-right">
                                {item?.totalPrice
                                  ? commafy(RoundNum(item?.totalPrice, 2))
                                  : 'N/A'}
                              </td>
                            </>
                          ) : (
                            <td>
                              <small className="d-block p-0">
                                {item?.productName}
                              </small>
                              <div className="pt-2 col-12 d-flex flex-row justify-content-between">
                                <small className="d-block p-0 col-8">
                                  {item?.buyerQCRejectedReasons?.length > 0 && (
                                    <>
                                      <strong>Buyer Check: </strong>
                                      {item?.buyerQCRejectedReasons?.map(
                                        (buyerReason, index) => (
                                          <span key={'buyer' + buyerReason}>
                                            {buyerReason}{' '}
                                            {index !==
                                              item?.buyerQCRejectedReasons
                                                .length -
                                                1 && ', '}
                                          </span>
                                        ),
                                      )}
                                      <br />
                                    </>
                                  )}
                                  {item?.supplierQCRejectedReasons?.length >
                                    0 && (
                                    <>
                                      <strong>Supplier Check: </strong>
                                      {item?.supplierQCRejectedReasons?.map(
                                        (supplierReason, index) => (
                                          <span
                                            key={'supplier' + supplierReason}
                                          >
                                            {supplierReason}
                                            {index !==
                                              item?.supplierQCRejectedReasons
                                                .length -
                                                1 && ', '}
                                          </span>
                                        ),
                                      )}
                                    </>
                                  )}
                                </small>
                                <div
                                  className="col-2"
                                  style={{ paddingLeft: '5px' }}
                                >
                                  <small className="d-block p-0">Qty</small>
                                  <small className="d-block p-0 blue-color">
                                    {item?.quantity || 'N/A'}
                                    {item?.quantity
                                      ? ' ' + item?.unitTypeName
                                      : ''}
                                  </small>
                                </div>
                                <div className="col-2">
                                  <small className="d-block p-0 text-right">
                                    Tot
                                  </small>
                                  <small className="d-block p-0 blue-color text-right">
                                    {item?.totalPrice
                                      ? commafy(RoundNum(item?.totalPrice, 2))
                                      : 'N/A'}
                                  </small>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    ),
                  )}
                </tbody>
              </Table>
            )}
          </div>
        </CardBody>
      </Card>

      <Card className="mt-4">
        <CardHeader onClick={() => setIsVisibleTotal(!isVisibleTotal)}>
          <div> Total </div>
          <div>
            <span>
              &nbsp;&nbsp;LKR{' '}
              {commafy(
                RoundNum(supplierPaymentDetailsInvoiceResponse?.res?.value, 2),
              )}
            </span>
            &nbsp;&nbsp;
            <img
              className={isVisibleTotal ? 'mt-2' : 'mb-2'}
              width={'20'}
              height={'20'}
              src={isVisibleTotal ? arrowUpGreen : arrowDownGreen}
              alt="arrow-up"
            />{' '}
          </div>
        </CardHeader>
        <CardBody>
          <div className={!isVisibleTotal ? 'd-none' : 'tableFixHead'}>
            {supplierPaymentDetailsInvoiceResponse?.res?.purchaseOrder &&
              Object.keys(
                supplierPaymentDetailsInvoiceResponse?.res?.purchaseOrder,
              )?.length === 0 && (
                <div
                  style={{ fontSize: '24px' }}
                  className="custom-empty-message"
                >
                  <p className="small-empty-message">
                    {t('ITEMS_ARE_UNAVAILABLE')}
                  </p>
                </div>
              )}
            {supplierPaymentDetailsInvoiceResponse?.res?.purchaseOrder &&
              Object.keys(
                supplierPaymentDetailsInvoiceResponse?.res?.purchaseOrder,
              ).length !== 0 && (
                <Table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          paddingLeft: '10px',
                          marginLeft: '5px',
                          border: 'none',
                        }}
                        width="75%"
                      >
                        Sub Total
                      </td>{' '}
                      <td
                        style={{
                          paddingLeft: '10px',
                          marginLeft: '5px',
                          border: 'none',
                        }}
                        className="blue-color text-right"
                      >
                        LKR{' '}
                        {commafy(
                          RoundNum(
                            supplierPaymentDetailsInvoiceResponse?.res
                              ?.subTotal,
                            2,
                          ),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingLeft: '10px',
                          marginLeft: '5px',
                          border: 'none',
                        }}
                        width="75%"
                      >
                        Service Fee
                      </td>{' '}
                      <td className="blue-color text-right">
                        LKR{' '}
                        {commafy(
                          RoundNum(
                            supplierPaymentDetailsInvoiceResponse?.res
                              ?.brokerageFee,
                            2,
                          ),
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
supplierDetailInvoiceView.propTypes = {
  isMobile: PropTypes.bool,
  order: PropTypes.any,
  redirectBack: PropTypes.func,
};
export default supplierDetailInvoiceView;
