import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SessionContext } from '../../../apis/authentication';

const ManageUser = props => {
  const sessionContext = useContext(SessionContext);
  const { user, session, permissions, isFetching } = sessionContext.state;
  //const [loading, setLoading] = useState(true);
  const [callPermissions, setCallPermissions] = useState(false);
  const {
    //getauthenticatedUser,
    getPermissions,
    setSessionDetails,
  } = sessionContext;

  //const windowPathName = window.location.pathname;

  // useEffect(() => {
  //   if (!user) {
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1200);
  //   } else {
  //     setLoading(false);
  //   }
  // }, [user]);

  useEffect(() => {
    if (user && !session && user.type !== 'UNAUTHENTICATED') {
      setSessionDetails();
    }
  }, [setSessionDetails, session, user]);

  useEffect(() => {
    if (
      !callPermissions &&
      !isFetching &&
      user &&
      session &&
      !permissions?.res
    ) {
      getPermissions();
      setCallPermissions(true);
    }
  }, [user, session, permissions, getPermissions, isFetching]);

  // useEffect(() => {
  //   if (callPermissions && permissions) {
  //     setCallPermissions(false);
  //   }
  // }, [permissions]);

  // useEffect(() => {
  //   if (!user && !isFetching) {
  //     getauthenticatedUser();
  //   }
  // }, [user, isFetching, getauthenticatedUser]);
  return <React.Fragment>{props.children}</React.Fragment>;
  // return (
  //   <>
  //     {loading && (
  //       <div
  //         className="d-flex align-items-center justify-content-center"
  //         style={{ height: '100vh' }}
  //       >
  //         <Spinner
  //           className="m-auto"
  //           style={{
  //             height: '5rem',
  //             width: '5rem',
  //           }}
  //           color="primary"
  //         >
  //           Loading...
  //         </Spinner>
  //       </div>
  //     )}
  //     {!loading &&
  //     user &&
  //     user.type === 'UNAUTHENTICATED' &&
  //     !windowPathName.startsWith('/auth') ? (
  //       <Navigate to="/auth/login" />
  //     ) : (
  //       ''
  //     )}

  //     {!loading && user && user.type !== 'UNAUTHENTICATED'
  //       ? props.children
  //       : ''}
  //   </>
  // );
};

ManageUser.propTypes = {
  authState: PropTypes.any,
  children: PropTypes.any,
  updateUsername: PropTypes.any,
  onStateChange: PropTypes.any,
};

export default ManageUser;
