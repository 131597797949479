import React, { useState, useContext, useEffect } from 'react';
import { hidePassword, showPassword } from '../../../assets/img';
import { Form, Button } from 'reactstrap';
import { Text } from '../../../../shared';
import { AuthContext } from '../../../../apis/authentication';
import {
  COGNITO,
  COGNITO_POLICY,
  LOGIN_ISSUE_CONTACT_NUMBER,
} from '../../../../shared/constants/constants';
import { useTranslation } from 'react-i18next';
import { Validate } from '../../../config';
import { CheckBox, CustomModal } from '../../../../shared/components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const persistFormValues = (function() {
  var password = '';
  const set = value => {
    password = value;
  };
  const get = () => {
    return password;
  };

  return {
    set: set,
    get: get,
  };
})();
function CreatePassword(props) {
  const { minPassword, isForgotPassword } = props;
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [termCondModal, setTermCondModal] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
  const [error, setError] = useState(null);
  const authContext = useContext(AuthContext);
  const {
    signInResponse,
    createPasswordResponse,
    termAndConditionsResponse,
  } = authContext.state;
  const { createPassword, logout, getTermsAndConditions } = authContext;
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const tempForm = form;
    tempForm.password.rules.range.min = minPassword;
    tempForm.password.rules.range.message = t('MIN_PASSWORD_LENGTH_MESSAGE', {
      LENGTH: minPassword,
    });
    setForm(tempForm);
  }, [minPassword]);

  const [form, setForm] = useState({
    password: {
      value: '',
      title: t('NEW_PASSWORD'),
      rules: {
        title: t('ENTER_YOUR_NEW_PASSWORD'),
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
        range: {
          min: minPassword,
          max: COGNITO_POLICY.PASSWORD_MAX_LENGTH,
          message: t('MIN_PASSWORD_LENGTH_MESSAGE', { LENGTH: minPassword }),
        },
      },
    },
    confirmPassword: {
      value: '',
      title: t('CONFIRM_PASSWORD'),
      rules: {
        stop: true,
        title: t('CONFIRM_YOUR_PASSWORD'),
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
        compare: {
          message: t('PASSWORD_DO_NOT_MATCH'),
          field: 'password',
          comparison: persistFormValues.get,
        },
      },
    },
    acceptConditions: {
      title: t('I_ACCEPT_THE'),
      value: false,
      rules: {
        stop: true,
        required: {
          message: t('ACCEPTANCE_OF_TERMS_AND_CONDITIONS_ID_REQUIRED'),
        },
      },
    },
  });

  useEffect(() => {
    if (
      createPasswordResponse?.err !== null ||
      createPasswordResponse?.res !== null
    ) {
      handlePasswordResponse();
    }
  }, [createPasswordResponse]);

  useEffect(() => {
    if (termAndConditionsResponse?.res && !isForgotPassword) {
      const { password } = form;
      createPassword(signInResponse.res, password.value);
    }
  }, [termAndConditionsResponse]);

  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
    if (name === 'password') {
      persistFormValues.set(value);
    }
  };
  const onBlur = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const onChangeCheckBox = e => {
    const { name } = e.target;
    const updatedForm = Validate.input(
      name,
      !form.acceptConditions.value,
      form,
      false,
    );
    setForm(updatedForm);
  };

  const handlePasswordResponse = () => {
    if (createPasswordResponse?.err) {
      const { err } = createPasswordResponse;
      if (err?.includes(COGNITO.SESSION_EXPIRED)) {
        setError(t('USER_SESSION_WAS_EXPIRED_RESTART_AGAIN'));
        return;
      }
      setError(err);
      return;
    }

    if (createPasswordResponse?.res) {
      logout();
      setTimeout(() => {
        navigate('/auth/login');
      }, 5000);
    }
  };

  const toggle = () => setTermCondModal(!termCondModal);

  const handleSubmitPassword = async e => {
    e.preventDefault();
    const formValidation = Validate.form(form);
    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }
    if (signInResponse?.res !== null) {
      const { password } = form;
      if (!isForgotPassword) {
        getTermsAndConditions(signInResponse?.res?.username);
      }
      if (isForgotPassword) {
        createPassword(signInResponse.res, password.value);
      }
    } else {
      setError(t('USER_SESSION_WAS_EXPIRED_RESTART_AGAIN'));
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmitPassword}>
        <div className="mobile-center-view">
          <Text
            groupClassName="form-group"
            labelClassName="form-label"
            label={form.password.title}
            type={isRevealPwd ? 'text' : 'password'}
            id="password"
            field="password"
            autoComplete="off"
            placeholder={form.password.rules.title}
            onChange={onChange}
            onBlur={onBlur}
            validation={form.password}
            maxLength={COGNITO_POLICY.PASSWORD_MAX_LENGTH}
            rightIconClassName="auth-fa-eye"
            rightIconGroupClassName="auth-text-input-container"
            rightIcon={isRevealPwd ? showPassword : hidePassword}
            rightIconHandle={() => setIsRevealPwd(prevState => !prevState)}
          />

          <Text
            groupClassName="form-group"
            labelClassName="form-label"
            label={form.confirmPassword.title}
            id="confirm-password"
            field="confirmPassword"
            autoComplete="off"
            placeholder={form.confirmPassword.rules.title}
            type={isRevealConfirmPwd ? 'text' : 'password'}
            onChange={onChange}
            onBlur={onBlur}
            validation={form.confirmPassword}
            maxLength={COGNITO_POLICY.PASSWORD_MAX_LENGTH}
            rightIconClassName="auth-fa-eye"
            rightIconGroupClassName="auth-text-input-container"
            rightIcon={isRevealConfirmPwd ? showPassword : hidePassword}
            rightIconHandle={() =>
              setIsRevealConfirmPwd(prevState => !prevState)
            }
          />

          {!isForgotPassword && (
            <div>
              <div className="form-group d-inline-block">
                <CheckBox
                  labelClassName="checkbox-label"
                  label={form.acceptConditions.title}
                  id="accept-conditions"
                  field="acceptConditions"
                  onChange={onChangeCheckBox}
                  checked={form.acceptConditions.value}
                  validation={form.acceptConditions}
                />
              </div>
              <div className="form-group d-inline-block">
                &nbsp;
                <a href="#" onClick={toggle}>
                  {t('TERMS_AND_CONDITIONS')}
                </a>
                <CustomModal
                  isOpen={termCondModal}
                  toggle={toggle}
                  isScrollable={true}
                  title={t('TERMS_AND_CONDITIONS')}
                >
                  <p>{termAndConditionsResponse?.res}</p>
                </CustomModal>
              </div>
            </div>
          )}
          {error !== null && <small className="error-message">{error}</small>}
          {termAndConditionsResponse?.err !== null && (
            <small className="error-message">
              {termAndConditionsResponse?.err?.toString()}
            </small>
          )}
        </div>
        <div className="form-footer">
          <Button color="primary" type="submit" className="form-submit">
            {t('CREATE')}
          </Button>
          {!isForgotPassword && (
            <small className="help">
              {t('DO_YOU_HAVE_LOGIN_ISSUES_CALL_US_ON')}{' '}
              <a href={`tel:${LOGIN_ISSUE_CONTACT_NUMBER}`}>
                {' '}
                {LOGIN_ISSUE_CONTACT_NUMBER}
              </a>
            </small>
          )}
        </div>
      </Form>
    </div>
  );
}

CreatePassword.propTypes = {
  minPassword: PropTypes.number,
  isForgotPassword: PropTypes.bool,
};

export default CreatePassword;
