import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const AdminContext = React.createContext();
const AdminProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [uploadFileResponse, setUploadFileResponse] = useState({
    res: null,
    err: null,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [adminDetailsResponse, setAdminDetailsResponse] = useState({
    res: null,
    err: null,
  });
  const [getAdminDetailsResponse, setgetAdminDetailsResponse] = useState({
    res: null,
    err: null,
  });

  const uploadFile = async file => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const uploadDetails = await fetch(url + `/bulk-product-upload`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(file),
      });
      const data = await uploadDetails.json();

      if (data.error || data?.message) {
        setUploadFileResponse({ err: data?.message, res: null });
      } else {
        setUploadFileResponse({ err: null, res: data });
      }
      setIsFetching(false);
      setLoading(false);
    } catch (err) {
      setIsFetching(false);
      setUploadFileResponse({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const handleAdminDetails = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('admins');
      const supplierDetails = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const data = await supplierDetails.json();
      if (data.message) {
        setLoading(false);
        setAdminDetailsResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setAdminDetailsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setAdminDetailsResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };
  const getAdminDetails = async adminId => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const supplierDetails = await fetch(url, `/${adminId}`, {
        method: 'GET',
        headers: headers,
      });
      const data = await supplierDetails.json();
      if (data.message) {
        setLoading(false);
        setgetAdminDetailsResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setgetAdminDetailsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setgetAdminDetailsResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  return (
    <AdminContext.Provider
      value={{
        state: {
          uploadFileResponse: uploadFileResponse,
          isFetching: isFetching,
          adminDetailsResponse: adminDetailsResponse,
          getAdminDetailsResponse: getAdminDetailsResponse,
        },
        uploadFile: uploadFile,
        handleAdminDetails: handleAdminDetails,
        setUploadFileResponse: setUploadFileResponse,
        getAdminDetails: getAdminDetails,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};
AdminProvider.propTypes = {
  children: PropTypes.any,
};

export { AdminProvider, AdminContext };
