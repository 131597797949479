import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../apis/authentication';
import { PERMISSION_LEVELS } from '../constants/constants';

const PermissionLevels = props => {
  const authContext = useContext(AuthContext);
  const [permitted, setPermitted] = useState(false);
  const { permissions } = authContext.state;
  const { componentName, child, readAction = 'show' } = props;

  useEffect(() => {
    if (permissions && permissions.permission) {
      const keys = Object.keys(permissions.permission);
      if (keys.length > 0 && keys.includes(componentName)) {
        const levelDetails = permissions.permission[componentName];
        const keys = Object.keys(levelDetails);
        if (keys.length > 0 && keys.includes(`${componentName}.${child}`)) {
          const permission =
            levelDetails[`${componentName}.${child}`].permission_level;
          switch (permission) {
            case PERMISSION_LEVELS.FULL_ACCESS:
              setPermitted(true);
              break;
            case PERMISSION_LEVELS.READ_ONLY:
              if (readAction === 'show') {
                setPermitted(true);
              } else {
                setPermitted(false);
              }
              break;
            case PERMISSION_LEVELS.NONE:
              setPermitted(false);
              break;
            default:
          }
        }
      }
    }
  }, [permissions, authContext, componentName]);

  return <>{permitted ? props.children : ''}</>;
};

PermissionLevels.propTypes = {
  children: PropTypes.any,
  componentName: PropTypes.any,
  onStateChange: PropTypes.any,
  subComponent: PropTypes.any,
  level: PropTypes.any,
  child: PropTypes.any,
  readAction: PropTypes.any,
};

export default PermissionLevels;
