import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { BuyerContext } from '../../../../apis/buyer';
import { CustomCalendar } from '../../../../shared/components';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDeviceDetect } from '../../../../shared/hooks';
function DeliveryDate({
  selectedDate,
  setSelectedDate,
  selectedDeliverySlot,
  setSelectedDeliverySlot,
  dateTimeSubmitted,
}) {
  const [deliverySlotOptions, setDeliverySlotOptions] = useState([]);
  const [error, setError] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);
  const buyerContext = useContext(BuyerContext);
  const { t } = useTranslation();
  const { getDeliverySlots, updateCart } = buyerContext;
  const { deliverySlotsResponse } = buyerContext.state;
  const { isMobile } = useDeviceDetect();
  useEffect(() => {
    if (selectedDate) {
      getDeliverySlots(moment(selectedDate).format('DD/MM/YYYY'));
      setError('');
    }
  }, [selectedDate]);

  useEffect(() => {}, [selectedDeliverySlot]);

  useEffect(() => {
    if (deliverySlotsResponse?.res)
      setDeliverySlotOptions(deliverySlotsResponse?.res);
    if (selectedDate && deliverySlotsResponse?.res?.length === 0) {
      setError('A delivery slot is not available for the selected date.');
    }
    if (deliverySlotsResponse?.res?.length > 0) {
      setError('');
    } else if (!isFirstRender) {
      setSelectedDeliverySlot(null);
    } else {
      setIsFirstRender(false);
    }
  }, [deliverySlotsResponse]);

  const onSlotSelect = event => {
    const tempSlot = deliverySlotOptions.filter(
      x => x.id == event.target.value,
    )[0];
    setSelectedDeliverySlot(tempSlot);
  };

  const getNextWeekDate = () => {
    var today = new Date();
    var nextWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7,
    );
    return nextWeek;
  };

  const onNextClick = () => {
    if (!selectedDate) {
      setError('Please select a delivery date to proceed');
    } else if (!selectedDeliverySlot) {
      setError('Please select a delivery slot to proceed');
    } else {
      updateCart(
        moment(selectedDate).format('YYYY-MM-DD'),
        selectedDeliverySlot.id,
      ).then(() => {
        dateTimeSubmitted();
      });
    }
  };

  return (
    <div className="d-md-flex make-a-new-order-delivery-date">
      <div className="me-md-2 mb-4 mb-md-0">
        <div style={isMobile ? { textAlign: 'center' } : {}} className="mb-2">
          {t('DELIVERY_DATE_SELECTION')}
        </div>
        <CustomCalendar
          onChange={setSelectedDate}
          value={new Date(selectedDate)}
          maxDate={getNextWeekDate()}
          minDate={new Date()}
        />
      </div>
      <div className="ms-md-2 w-100 w-md-50">
        <div className="mb-2" style={isMobile ? { textAlign: 'center' } : {}}>
          {t('DELIVERY_SLOT_SELECTION')}
        </div>
        <select
          className={`form-select delivery-slot-select ${
            selectedDeliverySlot?.id ? '' : 'placeholder-color'
          }`}
          disabled={!selectedDate || deliverySlotOptions?.length == 0}
          aria-label="Default select example"
          value={
            deliverySlotOptions.filter(x => x.id == selectedDeliverySlot?.id)
              .length > 0
              ? selectedDeliverySlot?.id
              : ''
          }
          onChange={onSlotSelect}
        >
          <option selected value={''} disabled={deliverySlotOptions.length > 0}>
            {t('SELECT_A_DELIVERY_SLOT')}
          </option>
          {deliverySlotOptions.map(data => {
            return (
              <option key={data.id} value={data.id} className="enabled">
                {data.startTime} - {data.endTime}
              </option>
            );
          })}
        </select>
        {selectedDate &&
          selectedDeliverySlot &&
          deliverySlotOptions.filter(x => x.id == selectedDeliverySlot?.id)
            .length > 0 && (
            <>
              <div className="delivery-date-value-container mt-3">
                <div className="mb-2">Selected Delivery Date:</div>
                <div className="delivery-date-value">
                  {moment(selectedDate).format('dddd')}{' '}
                  {selectedDeliverySlot.startTime} -{' '}
                  {selectedDeliverySlot.endTime} |{' '}
                  {moment(selectedDate).format('DD/MM/YYYY')}
                </div>
              </div>
            </>
          )}
        {error && <small className="error-message mt-1">{error}</small>}
        <div className="mt-3 mb-4" style={{ height: '35px' }}>
          <Button
            color="primary"
            type="submit"
            className="form-submit float-end col col-12 col-md-5"
            disabled={!(selectedDate && selectedDeliverySlot)}
            onClick={onNextClick}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

DeliveryDate.propTypes = {
  selectedDate: PropTypes.any,
  setSelectedDate: PropTypes.func,
  selectedDeliverySlot: PropTypes.any,
  setSelectedDeliverySlot: PropTypes.func,
  dateTimeSubmitted: PropTypes.func,
};

export default DeliveryDate;
