import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { trashWhite, trash } from '../../../assets/icons';
import { useTranslation } from 'react-i18next';
import ProductCartCard from '../shared/ProductCartCard';
import { BuyerContext } from '../../../../apis/buyer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetect } from '../../../../shared/hooks';

function ProductCart() {
  const [checkedProducts, setCheckedProducts] = useState([]);
  const { t } = useTranslation();
  const buyerContext = useContext(BuyerContext);
  const {
    getCartProductItems,
    deleteCartProductItems,
    confirmCart,
    getSillaraCategoriyList,
  } = buyerContext;
  const [cartProductItems, setCartProductItems] = useState(null);
  const [isItemsAvailable, setIsItemsAvailable] = useState(true);
  const [containerHeight, setContainerHeight] = useState(100);
  const { isMobile } = useDeviceDetect();
  const el = document.getElementsByClassName('inner-body-container')[0];
  const [availableGroupedCategories, setAvailableGroupedCategories] = useState(
    [],
  );
  const navigate = useNavigate();

  useEffect(() => {
    handleGettinggetCartProductItems();

    return () => {
      el.classList.remove('overflow-hidden');
    };
  }, []);

  useEffect(() => {
    const pb = document.getElementById('progress-bar-container');
    const ac = document.getElementById('cart-actions-container');
    if (el && pb && ac) {
      setContainerHeight(el.offsetHeight - pb.offsetHeight - ac.offsetHeight);
      el.classList.add('overflow-hidden');
    }

    const el2 = document.getElementsByClassName('inner-body')[0];
    el2.classList.add('mb-0');
  }, [cartProductItems, isMobile, el.offsetHeight]);

  const handleGettinggetCartProductItems = () => {
    getCartProductItems().then(res => {
      if (res.err) {
        return;
      } else {
        res.res.cartItems?.sort((a, b) =>
          a.productName.localeCompare(b.productName),
        );
        setCartProductItems(res.res);
        setIsItemsAvailable(res.res?.cartItems?.length > 0);
        getSillaraCategoriyList().then(response => {
          if (response.err) {
            return;
          } else {
            groupCategoryBySubCategory(response.res, res.res);
          }
        });
      }
    });
  };

  const groupCategoryBySubCategory = (allCategories, cartProducts) => {
    //getting available categories
    const availableCategories = [];
    cartProducts.cartItems.map(product => {
      const productCategory = allCategories.filter(
        x => x.id === product.productCategoryId,
      )[0];
      if (!availableCategories.includes(productCategory)) {
        availableCategories.push(productCategory);
      }
    });

    //getting parent categories
    let parentCategories = allCategories.filter(
      category => category.parentCategoryId == null,
    );

    //group the categories
    let tempGroupedCategories = [];

    parentCategories.map(category => {
      let tempObject = {
        parentCategory: category,
        subCategories: [],
      };

      availableCategories.map(item => {
        if (item.parentCategoryId == category.id || item.id == category.id) {
          tempObject.subCategories.push(item.id);
        }
      });
      tempGroupedCategories.push(tempObject);
    });
    setAvailableGroupedCategories(tempGroupedCategories);
  };

  const handleDeleteProducts = () => {
    const item = {
      CartId: cartProductItems.cartId,
      ProductId: checkedProducts,
      ExpectedDeliverySlotId: cartProductItems.expectedDeliverySlotId,
      ExpectedDeliveryDate: moment(
        cartProductItems.expectedDeliveryDate,
      ).format('YYYY-MM-DD'),
    };
    deleteCartProductItems(item).then(res => {
      if (res.err) {
        handleGettinggetCartProductItems();
      } else {
        handleGettinggetCartProductItems();
        setCheckedProducts([]);
      }
    });
  };

  const handleConfirmCart = () => {
    confirmCart().then(res => {
      if (res.err) {
        return;
      } else {
        navigate('/buyer/makeaneworder/recommendation?orderId=' + res.res);
      }
    });
  };
  return (
    <>
      <div
        className="product-cart-container pb-3 pb-md-5"
        id="cart-actions-container"
      >
        {!isItemsAvailable ? (
          <div className="py-5 custom-empty-message">
            <p>{t('ADD_ITEMS_TO_START_A_CART')}</p>
          </div>
        ) : (
          <>
            <div
              className={`d-flex ${
                isMobile ? 'justify-space-between' : 'justify-content-end'
              }`}
            >
              <Button
                outline
                color="primary"
                type="submit"
                disabled={checkedProducts?.length === 0}
                className={`form-submit d-flex align-items-center me-3 delete-btn-container ${
                  isMobile ? 'mobile-btn-outline-primary' : ''
                }`}
                style={{ float: 'right', width: '150px', whiteSpace: 'nowrap' }}
                onClick={handleDeleteProducts}
              >
                {t('DELETE_ITEMS')}
                {!isMobile && (
                  <img
                    width="15px"
                    height="20px"
                    className="icon ms-2"
                    src={trashWhite}
                    alt="right-icon"
                  />
                )}
                <img
                  width="15px"
                  height="20px"
                  className="icon ms-2 hovered"
                  src={trash}
                  alt="right-icon"
                />
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={cartProductItems?.cartItems?.length == 0}
                className="form-submit"
                style={{ float: 'right', width: '150px' }}
                onClick={handleConfirmCart}
              >
                {t('CONFIRM')}
              </Button>
            </div>
          </>
        )}
      </div>
      {!(!cartProductItems || cartProductItems?.cartItems?.length == 0) && (
        <div
          className="product-cart-container"
          style={{
            height: `calc(${containerHeight}px - ${
              isMobile ? '2rem' : '1rem'
            })`,
            overflowY: 'auto',
            overflowX: 'hidden',
            marginRight: isMobile ? '-20px' : '-30px',
            paddingRight: isMobile ? '20px' : '30px',
          }}
        >
          <Row>
            {availableGroupedCategories?.length > 0 &&
              availableGroupedCategories.map(category => (
                <>
                  {category.subCategories.length > 0 && (
                    <p className="product-cart-category-name">
                      {category.parentCategory.name}
                    </p>
                  )}
                  {category.subCategories.map(subCategory => (
                    <>
                      {cartProductItems.cartItems &&
                        cartProductItems.cartItems.map(product => (
                          <>
                            {product.productCategoryId === subCategory && (
                              <Col
                                xxl={3}
                                xl={4}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                key={product.id}
                              >
                                <ProductCartCard
                                  product={product}
                                  checkedProducts={checkedProducts}
                                  setCheckedProducts={setCheckedProducts}
                                  setCartProductItems={setCartProductItems}
                                  cartId={cartProductItems.cartId}
                                  selectedDate={
                                    cartProductItems.expectedDeliveryDate
                                  }
                                  selectedDeliverySlotId={
                                    cartProductItems.expectedDeliverySlotId
                                  }
                                />
                              </Col>
                            )}
                          </>
                        ))}
                    </>
                  ))}
                </>
              ))}
          </Row>
        </div>
      )}
      {/* <div>
        <Button
          color="primary"
          type="submit"
          disabled={cartProductItems.cartItems.length == 0}
          className="form-submit mt-2"
          style={{ float: 'right', width: '150px' }}
          onClick={handleConfirmCart}
        >
          {t('CONFIRM')}
        </Button>
      </div> */}
    </>
  );
}

export default ProductCart;
