import React, { useContext, useEffect } from 'react';
import { Table, Button, Row, Col } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BuyerOrderPaymentHistoryContext } from '../../../../apis/buyer/BuyerOrderPaymentHistory';
import { BUYER_PAYMENT_STATUS } from '../../../../shared/constants/constants';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function BuyerPaymentHistory({ initiatePayment, isMobile }) {
  const { t } = useTranslation();
  const buyerPayContext = useContext(BuyerOrderPaymentHistoryContext);
  const { getBuyerPaymentHistory } = buyerPayContext;
  const { buyerPaymentHistoryResponse } = buyerPayContext.state;
  useEffect(() => {
    getBuyerPaymentHistory();
  }, []);
  return (
    <div className="buyer-payment-history">
      {buyerPaymentHistoryResponse?.res?.payments?.length > 0 && (
        <h4 className="outstanding-bal-container my-3">
          Outstanding Balance : {isMobile ? <br /> : <></>}LKR{' '}
          {commafy(
            RoundNum(buyerPaymentHistoryResponse?.res?.outstandingBalance, 2),
          )}
        </h4>
      )}
      <div>
        <div className="tableFixHead">
          {buyerPaymentHistoryResponse?.res?.payments?.length > 0 && (
            <Table>
              <thead>
                {!isMobile && (
                  <tr>
                    <th className="text-center">{t('DATE')}</th>
                    <th>{t('PAYMENT_TYPE')}</th>
                    <th>Payment Identifier</th>
                    <th className="text-right">{t('AMOUNT')}</th>
                    <th>{t('STATUS')}</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {buyerPaymentHistoryResponse?.res?.payments?.map(
                  (item, index) =>
                    !isMobile ? (
                      <>
                        <tr key={index}>
                          <td className="text-right">
                            {moment(item?.paymentDate).format('DD-MM-YYYY')}
                          </td>
                          <td>{item?.paymentType}</td>
                          <td>{item?.paymentIdentifier || 'N/A'}</td>
                          <td className="blue-color text-right">
                            {item?.amount
                              ? commafy(RoundNum(item?.amount, 2))
                              : 'Pending'}
                          </td>
                          <td>{BUYER_PAYMENT_STATUS?.[item?.status] || ''}</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr key={index}>
                          <td>
                            <small>
                              <Row className="order-first-row pt-1 pb-1">
                                <Col xs="4" className="col-title">
                                  {moment(item?.paymentDate).format(
                                    'DD-MM-YYYY',
                                  )}
                                </Col>
                                <Col xs="8" className="col-title text-right">
                                  {item?.paymentIdentifier || 'N/A'}
                                </Col>
                              </Row>
                              <Row className="pt-1">
                                <Col xs="4">
                                  {item?.amount
                                    ? commafy(RoundNum(item?.amount, 2))
                                    : 'Pending'}
                                </Col>
                                <Col xs="4" className=" text-center">
                                  {item?.paymentType}
                                </Col>
                                <Col xs="4" className="text-right">
                                  {BUYER_PAYMENT_STATUS?.[item?.status] || ''}
                                </Col>
                              </Row>
                            </small>
                          </td>
                        </tr>
                      </>
                    ),
                )}
              </tbody>
            </Table>
          )}
        </div>
        {(buyerPaymentHistoryResponse?.res?.payments.length === 0 ||
          buyerPaymentHistoryResponse?.err !== null) && (
          <div className="py-5 custom-empty-message">
            <p>No records available</p>
          </div>
        )}
        <div style={!isMobile ? { textAlign: 'right' } : {}}>
          <Button
            disabled={
              buyerPaymentHistoryResponse?.res?.outstandingBalance ==
              (0 || null || '')
            }
            onClick={initiatePayment}
            style={isMobile ? { width: '100%' } : {}}
            color="primary"
          >
            Initialte Payment
          </Button>
        </div>
      </div>
    </div>
  );
}

BuyerPaymentHistory.propTypes = {
  isMobile: PropTypes.bool,
  initiatePayment: PropTypes.func,
};

export default BuyerPaymentHistory;
