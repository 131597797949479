import React, { useState } from 'react';
import { Col, Row, Table, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { CustomModal } from '../shared/components';
import { useTranslation } from 'react-i18next';
// import { warning } from '../app/assets/icons';
import { warning } from '../app/assets/img';
import { SessionContext } from '../apis/authentication';
import { USER_ROLES } from '../shared/constants/constants';
import { useEffect } from 'react';
import { useContext } from 'react';

function UserProfile(props) {
  const {
    topic,
    role,
    brn,
    firstName,
    lastName,
    contactNumber,
    email,
    address,
    base,
  } = props;
  const [termCondModal, setTermCondModal] = useState(false);
  const toggle = () => setTermCondModal(!termCondModal);
  const { t } = useTranslation();
  const sessionContext = useContext(SessionContext);
  const { user } = sessionContext.state;
  const [userRole, setUserRole] = useState();
  const [isShowTermsandCondition, setIsShowTermsandCondition] = useState(false);

  useEffect(() => {
    if (user) {
      setUserRole(
        user?.signInUserSession?.accessToken?.payload?.['cognito:groups']?.[0],
      );
    }
  }, [user]);

  return (
    <div>
      <Table className="table table-bordered user-profile">
        <thead>
          <tr>
            <th colSpan={2} className=" user-profile-head text-center">
              {base ? <div className="user-profile-head-base">{base}</div> : ''}
              {userRole == USER_ROLES.ADMIN ? 'Admin' : topic}
            </th>
          </tr>
        </thead>
        <tbody>
          {userRole == USER_ROLES.ADMIN ? (
            <tr>
              <td className={'user-profile-label'}>
                <Row>
                  <Col md={4} sm={0}></Col>
                  <Col md={8} sm={12}>
                    Role
                  </Col>
                </Row>
              </td>
              <td className={'user-profile-data'}>{role}</td>
            </tr>
          ) : (
            <tr>
              <td className={'user-profile-label'}>
                <Row>
                  <Col md={4} sm={0}></Col>
                  <Col md={8} sm={12}>
                    BRN
                  </Col>
                </Row>
              </td>
              <td className={'user-profile-data'}>{brn}</td>
            </tr>
          )}
          <tr>
            <td className="user-profile-label">
              <Row>
                <Col md={4}></Col>
                <Col md={8}>First Name</Col>
              </Row>
            </td>
            <td className="user-profile-data">{firstName}</td>
          </tr>
          <tr>
            <td className="user-profile-label">
              <Row>
                <Col md={4}></Col>
                <Col md={8}>Last Name</Col>
              </Row>
            </td>
            <td className="user-profile-data">{lastName}</td>
          </tr>
          <tr>
            <td className="user-profile-label">
              <Row>
                <Col md={4}></Col>
                <Col md={8}>Contact Number</Col>
              </Row>
            </td>
            <td className="user-profile-data">{contactNumber}</td>
          </tr>
          <tr>
            <td className="user-profile-label">
              <Row>
                <Col md={4}></Col>
                <Col md={8}>Email</Col>
              </Row>
            </td>
            <td className="user-profile-data">{email}</td>
          </tr>
          {userRole != USER_ROLES.ADMIN && (
            <tr>
              <td className="user-profile-label">
                <Row>
                  <Col md={4}></Col>
                  <Col md={8}>Address</Col>
                </Row>
              </td>
              <td className="user-profile-data">{address}</td>
            </tr>
          )}
        </tbody>
      </Table>
      {userRole != USER_ROLES.ADMIN && (
        <>
          <div className="user-profile-termsandcond">
            <a href="#" onClick={toggle}>
              {t('TERMS_AND_CONDITIONS')}
            </a>
            <CustomModal
              isOpen={termCondModal}
              toggle={toggle}
              isScrollable={true}
              title={t('TERMS_AND_CONDITIONS')}
              modalstyleClassName="modal-style"
              modalContentContainer="modal-container"
              modalHeaderClassName="modal-header"
              modalheaderTopic="modal-header-topic m-auto"
              modalFooterClassName="modal-footer"
              modalBodyClassName="small"
            >
              {t('SIGN_UP_TERMS_AND_CONDITIONS')}
            </CustomModal>
          </div>

          <div
            className="user-profile-img-container"
            onClick={() => setIsShowTermsandCondition(!isShowTermsandCondition)}
            onBlur={() => setIsShowTermsandCondition(false)}
          >
            {/* <Button
              className="user-profile-img-button"
              onClick={() =>
                setIsShowTermsandCondition(!isShowTermsandCondition)
              }
              onBlur={() => setIsShowTermsandCondition(false)}
            >
              <img
                src={warning}
                width="22"
                height="22"
                className="user-profile-img"
              />
            </Button> */}
            <img
              width="22"
              height="22"
              className="user-profile-img"
              src={warning}
              alt="notes"
              id="termsandCondition"
            />
            <Tooltip
              placement="top"
              isOpen={isShowTermsandCondition}
              autohide={false}
              target="termsandCondition"
              toggle={() =>
                setIsShowTermsandCondition(!isShowTermsandCondition)
              }
            >
              Note: Please inform Sillara of any required changes to user
              profile.
            </Tooltip>
          </div>
        </>
      )}
      {/* {isShowTermsandCondition && (
        <Alert
          key="warning"
          variant="danger"
          className="alert-warning user-profile-alert"
        >
          Note: Please inform Sillara of any required changes to user profile.
        </Alert>
      )} */}
    </div>
  );
}

UserProfile.propTypes = {
  topic: PropTypes.string,
  role: PropTypes.string,
  brn: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  contactNumber: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  base: PropTypes.string,
};

export default UserProfile;
