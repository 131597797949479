import React, { useContext, useEffect, useState } from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import { ManualProcurementContext } from '../../../../../apis/admin';
import { useTranslation } from 'react-i18next';
import { CustomModal } from '../../../../../shared/components';
import OrderDetailUpdateModal from './OrderDetailUpdateModal';
import PropTypes from 'prop-types';
import {
  Splide as Splice,
  SplideSlide as SpliceSlide,
  SplideTrack,
} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import OrderDetailViewTable from './OrderDetailViewTable';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import { LoadingContext } from '../../../../../apis/LoadingContext';

function OrderDetailView({
  isMobile,
  readyForDispatchParam,
  viewOrderParam,
  setStage,
}) {
  const manualProcurementContext = useContext(ManualProcurementContext);
  const { sortingDetailsViewRes } = manualProcurementContext.state;
  const {
    getSortingDetailView,
    readyForDispatchOrderDetail,
    setSortingDetailViewRes,
  } = manualProcurementContext;
  const { t } = useTranslation();
  const [updateProductModal, setUpdateProductModal] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  const [isReadyForDispatchEnabled, setIsReadyForDispatchEnabled] = useState(
    false,
  );
  const [readyStateItems, setReadyStateItems] = useState([]);

  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [orderPayableTotalCal, setOrderPayableTotalCal] = useState(0);

  const [items, setItems] = useState(null);

  const toggleErrorPopup = () => {
    setErrorMessage(null);
    setErrorPopupModal(!errorPopupModal);
  };

  const toggleUpdateProduct = () => {
    setClickedItem(null);
    setUpdateProductModal(!updateProductModal);
  };

  useEffect(() => {
    getSortingDetailView(viewOrderParam);
  }, []);

  useEffect(() => {
    if (readyStateItems) {
      isReadyforDispatch();
    }
  }, [readyStateItems]);

  useEffect(() => {
    if (sortingDetailsViewRes?.res) {
      setItems(sortingDetailsViewRes?.res);
      setOrderPayableItem(sortingDetailsViewRes?.res[currentIndex]?.orderItems);
      isReadyforDispatch();
    }
  }, [sortingDetailsViewRes]);

  const setOrderPayableItem = list => {
    let total = 0;
    if (list?.length > 0) {
      list.forEach(order => {
        if (order?.newUpdate) {
          total = total + order?.newUpdate?.UnitPrice * order?.quantity;
        }
      });
    }

    setOrderPayableTotalCal(total);
  };

  const isReadyforDispatch = () => {
    let status = false;
    if (items) {
      let count = 0;
      items[currentIndex]?.orderItems?.forEach(element => {
        if (element?.productStatus === 'Ready') {
          count++;
        }
      });
      if (count === items[currentIndex]?.orderItems?.length) {
        status = true;
      }
    }
    setIsReadyForDispatchEnabled(status);
  };
  const handleConfirmProcurment = update => {
    setUpdateProductModal(false);

    if (clickedItem && clickedItem?.purchaseOrderProductId !== undefined) {
      let tempList = readyStateItems?.filter(
        item =>
          item.purchaseOrderProductId !== clickedItem?.purchaseOrderProductId,
      );
      const sortingRes = items;
      sortingRes[currentIndex].orderItems.forEach((order, index) => {
        if (order.purchaseOrderProductId === update.id) {
          sortingRes[currentIndex].orderItems[index].unitPrice =
            update.UnitPrice;
          sortingRes[currentIndex].orderItems[index].productStatus = 'Ready';
        }
      });

      setItems(sortingRes);

      const updatedItem = clickedItem;
      updatedItem.newUpdate = update;
      tempList.push(updatedItem);
      setReadyStateItems(tempList);

      setOrderPayableItem(sortingRes[currentIndex]?.orderItems);
    }

    setClickedItem(null);
  };
  useEffect(() => {
    let total = 0;
    if (items && items?.length > 0) {
      items[currentIndex]?.orderItems.forEach(order => {
        if (order?.newUpdate) {
          total = total + order?.newUpdate?.UnitPrice * order?.quantity;
        }
      });
    }
    isReadyforDispatch();
    setOrderPayableTotalCal(total);
  }, [items]);
  useEffect(
    () => () => {
      setItems(null);
      setSortingDetailViewRes({ res: [], err: null });
      getSortingDetailView(viewOrderParam);
    },
    [],
  );
  const productUpdatePopUp = () => (
    <CustomModal
      modalstyleClassName="modal-style modal-style-1"
      isOpen={updateProductModal}
      toggle={toggleUpdateProduct}
      isScrollable={true}
      title={t('MANUAL_UPDATE')}
      modalContentContainer="modal-container modal-container-manual-procurement-update "
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
      alignMent="left"
    >
      <OrderDetailUpdateModal
        item={clickedItem}
        onUpdateProcurement={handleConfirmProcurment}
      />
    </CustomModal>
  );

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={errorMessage?.title}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {errorMessage?.des}
    </CustomModal>
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const onMove = (_, current) => {
    setCurrentIndex(current);
  };
  useEffect(() => {
    setItems(sortingDetailsViewRes?.res);
    setOrderPayableItem(sortingDetailsViewRes?.res[currentIndex]?.orderItems);
    isReadyforDispatch();
  }, [currentIndex]);

  return (
    <div className="order-detail-view">
      {productUpdatePopUp()}
      {errorPopup()}
      {!loading && items && items?.length === 0 && (
        <div className="py-5 custom-empty-message">
          <p>Not available at the moment. Please check later.</p>
        </div>
      )}
      {sortingDetailsViewRes?.res?.length > 0 && items && items?.length > 0 && (
        <div className="mp-ad-order-detail-view">
          <Splice
            options={{
              rewind: true,
              arrows: items?.length !== 1,
              pagination: false,
            }}
            hasTrack={false}
            onMove={(a, b, c) => onMove(a, b, c)}
          >
            <SplideTrack>
              {items?.map((item, key) => (
                <SpliceSlide
                  key={key}
                  id={`table-manual-order-detail-${currentIndex}`}
                >
                  <div className="tableFixHead">
                    <OrderDetailViewTable
                      currentIndex={currentIndex}
                      inProgressItems={readyStateItems}
                      onClickRow={product => {
                        setClickedItem(product);
                        setUpdateProductModal(!updateProductModal);
                      }}
                      items={items}
                      isMobile={isMobile}
                      // orderItem={item}
                      orderPayableTotalCal={orderPayableTotalCal}
                      clickedItem={clickedItem}
                    />
                  </div>
                </SpliceSlide>
              ))}
            </SplideTrack>
            <Container className="arrows-horizontally-scroller p-0">
              <div className="splide__arrows">
                <Row>
                  <Col className="p-0">
                    <a
                      className={`splide__arrow--prev ${currentIndex === 0 &&
                        'btn-disable'} ${currentIndex == 0 ? 'd-none' : ''}`}
                    >
                      <span className="left"></span>
                    </a>
                    <a
                      className={`splide__arrow--next ${currentIndex ===
                        items?.length - 1 && 'btn-disable'} ${
                        currentIndex == items?.length - 1 ? 'd-none' : ''
                      }`}
                    >
                      <span className="right"></span>
                    </a>
                  </Col>
                </Row>
              </div>
            </Container>
          </Splice>
        </div>
      )}
      {Object.keys(sortingDetailsViewRes?.res)?.length > 0 &&
        sortingDetailsViewRes?.res?.length > 0 && (
          <div
            style={
              isMobile
                ? { marginTop: '20px', textAlign: 'center' }
                : { textAlign: 'right' }
            }
          >
            <Button
              style={
                !isReadyForDispatchEnabled || loading
                  ? { opacity: '0.6' }
                  : { opacity: '1' }
              }
              disabled={!isReadyForDispatchEnabled || loading}
              color="primary"
              onClick={() => {
                let purchaseProducts = [];

                items[currentIndex]?.orderItems?.forEach(order => {
                  if (order?.newUpdate) {
                    purchaseProducts.push(order?.newUpdate);
                  }
                });
                const dispatch = {
                  ExpectedDeliverySlotId:
                    readyForDispatchParam?.ExpectedDeliverySlotId,
                  ExpectedDeliveryDate: formatDate(
                    readyForDispatchParam?.ExpectedDeliveryDate,
                    null,
                    'YYYY-DD-MM',
                    'YYYY-MM-DD',
                  ),

                  PurchaseOrderProducts: purchaseProducts,
                };

                readyForDispatchOrderDetail(
                  dispatch,
                  sortingDetailsViewRes?.res[currentIndex].purchaseOrderId,
                )
                  .then(result => {
                    if (result.message || result.description) {
                      setErrorMessage({
                        title: t('READY_FOR_DISPATCH'),
                        des: result.description || result.message,
                      });
                      setErrorPopupModal(true);
                      return;
                    }
                    if (sortingDetailsViewRes?.res?.length === 1) {
                      setClickedItem(null);
                      getSortingDetailView(viewOrderParam);
                      setReadyStateItems([]);
                      setStage('Step1');
                      return;
                    }
                    setReadyStateItems([]);
                    setItems([]);
                    getSortingDetailView(viewOrderParam);
                    if (currentIndex !== 0) {
                      setCurrentIndex(currentIndex - 1);
                      return;
                    }
                    setCurrentIndex(0);
                  })
                  .catch(err => {
                    setErrorMessage({
                      title: t('READY_FOR_DISPATCH'),
                      des: err,
                    });
                    setErrorPopupModal(true);
                  });
              }}
            >
              {t('READY_FOR_DISPATCH')}
            </Button>
          </div>
        )}
    </div>
  );
}

OrderDetailView.propTypes = {
  isMobile: PropTypes.bool,
  readyForDispatchParam: PropTypes.any,
  viewOrderParam: PropTypes.any,
  setStage: PropTypes.func,
};

export default OrderDetailView;
