import React from 'react';
import PropTypes from 'prop-types';
import { Session } from './../app/config';
import { Navigate } from 'react-router-dom';

function PrivateRoute() {
  const isAuthorized = Session.isAuthorized();

  return isAuthorized ? (
    <React.Fragment></React.Fragment>
  ) : (
    <Navigate to="/auth/login" />
  );
}

PrivateRoute.propTypes = {
  location: PropTypes.any,
  component: PropTypes.any,
  children: PropTypes.any,
};

export default PrivateRoute;
