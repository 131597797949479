import update from 'immutability-helper';
import approval from 'approvejs';
import './validation-tests';

const Validate = (function() {
  const input = (field, val, form, soil) => {
    if (field.match(/.+?(?=\[)/)) {
      return isArray(field, val, form, soil);
    }
    const name = field;
    const { state } = form[name];
    if (state === undefined || state === null) {
      form = update(form, { [name]: { state: { $set: {} } } });
    }

    form = update(form, { [name]: { value: { $set: val } } });

    if (soil) {
      form = update(form, { [name]: { state: { dirty: { $set: true } } } });
    }

    if (form[name].state.dirty) {
      const inputState = approval.value(val, form[name].rules);
      form = update(form, {
        [name]: { state: { approval: { $set: inputState } } },
      });
    }

    return form;
  };

  const isArray = (field, val, form, soil) => {
    const name = field.match(/.+?(?=\[)/)[0];
    const pos = parseInt(field.match(/\[([^)]+)\]/)[1], 10);
    const { state } = form[name];
    if (state === undefined || state === null) {
      form = update(form, { [name]: { state: { $set: {} } } });
    }
    form = update(form, { [name]: { [pos]: { value: { $set: val } } } });
    if (soil) {
      form = update(form, {
        [name]: { [pos]: { state: { dirty: { $set: true } } } },
      });
    }
    if (form[name][pos].state.dirty) {
      const inputState = approval.value(val, form[name][pos].rules);
      form = update(form, {
        [name]: { [pos]: { state: { approval: { $set: inputState } } } },
      });
    }
    return form;
  };

  const form = _form => {
    delete _form.approved;
    const keys = Object.keys(_form);
    var formApproved = true;
    keys.forEach(name => {
      if (Array.isArray(_form[name])) {
        for (let index = 0; index < _form[name].length; index++) {
          _form = input(
            `${name}[${index}]`,
            _form[name][index].value,
            _form,
            true,
          );
          formApproved =
            formApproved && _form[name][index].state.approval.approved;
        }
      } else {
        _form = input(name, _form[name].value, _form, true);
        formApproved = formApproved && _form[name].state.approval.approved;
      }
    });
    _form = update(_form, { approved: { $set: formApproved } });
    return _form;
  };

  const addTest = (test, name) => {
    approval.addTest(test, name);
  };

  const isValid = state => {
    var { approval = {} } = state;
    if (approval.approved === true) {
      return true;
    }
    return false;
  };

  const isInvalid = state => {
    var { approval = {} } = state;
    if (approval.approved === false) {
      return true;
    }
    return false;
  };

  const errors = field => {
    const { state = {}, rules = {} } = field;
    const { approval = {}, dirty } = state;
    const errors = [];
    if (!approval.failed || !dirty) {
      return errors;
    }
    approval.failed.forEach(failed => {
      if (!approval[failed].approved) {
        approval[failed].errors.forEach(error => {
          if (
            rules &&
            rules[failed].trans &&
            typeof rules[failed].trans === 'function'
          ) {
            errors.push({ rule: failed, message: rules[failed].trans() });
          } else {
            errors.push({ rule: failed, message: error });
          }
        });
      }
    });
    return errors;
  };

  const firstError = field => {
    var errs = this.errors(field);
    if (errs.length === 0) {
      return '';
    }
    return errs[0];
  };

  return {
    isValid: isValid,
    isInvalid: isInvalid,
    input: input,
    form: form,
    errors: errors,
    firstError: firstError,
    addTest: addTest,
  };
})();

export default Validate;
