import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

function BuyerName({ buyerName, index }) {
  const [isShowBuyerName, setIsShowBuyerName] = useState(false);
  return (
    <span
      id={'buyerName' + (index + 1)}
      style={{ paddingRight: '5px' }}
      className="buyer-info-icon"
      onClick={() => setIsShowBuyerName(!isShowBuyerName)}
      onBlur={() => setIsShowBuyerName(false)}
    >
      {buyerName.substring(0, 5) + '...'}
      <Tooltip
        placement="top"
        isOpen={isShowBuyerName}
        autohide={false}
        target={'buyerName' + (index + 1)}
        toggle={() => setIsShowBuyerName(!isShowBuyerName)}
      >
        {buyerName}
      </Tooltip>
    </span>
  );
}
BuyerName.propTypes = {
  buyerName: PropTypes.string,
  index: PropTypes.number,
};

export default BuyerName;
