import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { AdminPaymentContext } from '../../../../apis/admin';
import { useDeviceDetect } from '../../../../shared/hooks';
import { CustomModal } from '../../../../shared/components';
import SupplierPaymentInvoiceRow from './SupplierPaymentInvoiceRow';
import PaymentInvoiceUpdatePopUp from './PaymentInvoiceUpdatePopUp';
import { CheckBox } from '../../../../shared/components';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function AdminSupplierPaymentInvoice() {
  const { t } = useTranslation();
  const adminPaymentContext = useContext(AdminPaymentContext);
  const {
    getSupplierInvoicesBySupplier,
    getSuppliers,
    doSupplierPayment,
    setSupplierPaymentInvoicesResponse,

    getSuppliersPaymentConfirmation,
  } = adminPaymentContext;
  const {
    supplierPaymentInvoicesResponse,
    supplierPaymentConfirmation,
    supplierList,
  } = adminPaymentContext.state;
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useDeviceDetect();
  const [selectedItemsId, setSelectedItemsId] = useState([]);

  const [supplierId, setSupplierId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);
  const [payableAmount, setPayableAmount] = useState('');
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSelectedAll, setIsSelectAll] = useState(false);
  const toggleErrorPopup = () => {
    setErrorMessage(null);
    setErrorPopupModal(!errorPopupModal);
  };
  useEffect(() => {
    setPayableAmount(0);
    getSuppliers();
    resetData();
    setSupplierId(null);
  }, []);

  const resetData = () => {
    setSupplierPaymentInvoicesResponse({ res: [], err: null });
    setPayableAmount(0);
    setSelectedItemsId([]);
    setIsSelectAll(false);
  };
  useEffect(() => {}, [isMobile]);

  const onCheckedRow = row => {
    if (selectedItemsId.includes(row?.invoiceId)) {
      const temp = selectedItemsId.filter(function(invoice) {
        return row?.invoiceId !== invoice;
      });
      setSelectedItemsId(temp);
    } else {
      setSelectedItemsId(prevProps => [...prevProps, row?.invoiceId]);
    }
  };
  useEffect(() => {
    if (selectedItemsId.length > 0) {
      let payAmtTemp = 0;
      supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments?.forEach(
        row => {
          if (selectedItemsId?.includes(row?.invoiceId)) {
            payAmtTemp = payAmtTemp + row?.payableAmount;
          }
        },
      );
      setPayableAmount(payAmtTemp);
    } else {
      setPayableAmount(0);
    }

    if (
      supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments?.length ==
      selectedItemsId.length
    ) {
      setIsSelectAll(true);
    } else if (
      supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments?.length >
      selectedItemsId.length
    ) {
      setIsSelectAll(false);
    }
  }, [selectedItemsId]);

  const [isUpdatePaymentModal, setIsUpdatePaymentModal] = useState(false);
  const toggleUpdatePaymentModal = () => {
    setIsUpdatePaymentModal(!isUpdatePaymentModal);
  };
  const supplierCompIdentifiier = sup => {
    setSupplierId(sup);

    getSuppliersPaymentConfirmation(sup?.supplierId);
  };

  const supplierPaymentPopup = () => (
    <CustomModal
      isOpen={isUpdatePaymentModal}
      toggle={toggleUpdatePaymentModal}
      isScrollable={true}
      title={'Payment Confirmation'}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container supplier-invoice-update-popup "
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic mt-0 mb-3"
      modalFooterClassName="modal-footer"
      modalBodyClassName="small"
    >
      <PaymentInvoiceUpdatePopUp
        paymentConfirmation={supplierPaymentConfirmation?.res}
        isMobile={isMobile}
        supplier={supplierId}
        onPay={onPay}
        payableAmount={payableAmount}
      />
    </CustomModal>
  );
  const onPay = (supp, payType) => {
    const py = {
      SupplierIdentifier:
        supplierPaymentConfirmation?.res?.supplierCompanyIdentifier,
      PaymentAmount: payableAmount,
      PaymentType: payType?.id,
      Invoices: selectedItemsId,
      supplierCompanyId: supp?.supplierCompanyId,
    };
    setIsUpdatePaymentModal(false);
    doSupplierPayment(py)
      .then(res => {
        if (res?.description || res.message) {
          setErrorMessage(res.message);
          setErrorPopupModal(true);
          return;
        }
        resetData();
        getSupplierInvoicesBySupplier(supplierId?.supplierCompanyId);
      })
      .catch(err => {
        setErrorMessage(err);
        setErrorPopupModal(true);
      });
  };
  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={'Payment failure'}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {errorMessage}
    </CustomModal>
  );

  return (
    <div className="admin-supplier-payment-invoice">
      {supplierPaymentPopup()}
      {errorPopup()}
      {supplierPaymentInvoicesResponse?.err !== null && (
        <p className="warning">{supplierPaymentInvoicesResponse?.err}</p>
      )}
      {supplierPaymentConfirmation?.err !== null && (
        <p className="warning">{supplierPaymentConfirmation?.err}</p>
      )}
      <div className="text-center">
        <Dropdown
          isOpen={isDropdownOpen}
          toggle={dropdownToggle}
          direction="down"
          className="my-3"
        >
          <DropdownToggle
            caret
            className={supplierId === null ? 'grey-text' : 'black-text'}
          >
            {supplierId === null
              ? t('SELECT_A_SUPPLIER_ID')
              : supplierId?.supplierCode}
          </DropdownToggle>
          <DropdownMenu>
            {supplierList?.res?.map((supplier, index) => (
              <DropdownItem
                onClick={() => {
                  resetData();
                  supplierCompIdentifiier(supplier);

                  getSupplierInvoicesBySupplier(supplier?.supplierCompanyId)
                    .then(() => {
                      setIsLoading(false);
                    })
                    .catch(() => {
                      setIsLoading(false);
                    });
                }}
                key={index}
              >
                {supplier?.supplierCode}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {!isLoading &&
          supplierPaymentInvoicesResponse?.res &&
          supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments
            ?.length > 0 && (
            <h4 className="payable-container mb-3">
              Payable to Supplier: LKR{' '}
              {commafy(
                RoundNum(supplierPaymentInvoicesResponse?.res?.totalPayable, 2),
              )}
            </h4>
          )}
        {(supplierId !== null &&
          supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments
            ?.length) > 0 &&
          isMobile && (
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                paddingRight: '10px',
              }}
            >
              <CheckBox
                label={''}
                groupClassName="check-box"
                id="select-unselect-All"
                field="selectUnselectAll"
                checked={isSelectedAll}
                onClick={() => {
                  if (!isSelectedAll) {
                    supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments?.forEach(
                      row => {
                        if (!selectedItemsId?.includes(row?.invoiceId)) {
                          setSelectedItemsId(prevProps => [
                            ...prevProps,
                            row?.invoiceId,
                          ]);
                        }
                      },
                    );
                    setIsSelectAll(!isSelectedAll);
                  } else {
                    setSelectedItemsId([]);
                  }
                }}
              />
            </div>
          )}
      </div>

      <div>
        <div className="tableFixHead">
          {supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments
            ?.length > 0 && (
            <Table>
              <thead>
                {!isMobile && (
                  <tr>
                    <th>Buyer Name</th>
                    <th className="text-right">Payable</th>
                    <th className="text-right">Total</th>
                    <th className="text-center" style={{ width: '100px' }}>
                      <CheckBox
                        style={{ height: '16px', width: '16px' }}
                        label={''}
                        groupClassName="check-box"
                        id="select-unselect-All"
                        field="selectUnselectAll"
                        checked={isSelectedAll}
                        onClick={() => {
                          if (!isSelectedAll) {
                            supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments?.forEach(
                              row => {
                                if (
                                  !selectedItemsId?.includes(row?.invoiceId)
                                ) {
                                  setSelectedItemsId(prevProps => [
                                    ...prevProps,
                                    row?.invoiceId,
                                  ]);
                                }
                              },
                            );
                            setIsSelectAll(!isSelectedAll);
                          } else {
                            setSelectedItemsId([]);
                          }
                        }}
                      />
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments?.map(
                  (item, index) => (
                    <>
                      {item.totalAmount !== 0 && (
                        <SupplierPaymentInvoiceRow
                          index={index}
                          isMobile={isMobile}
                          item={item}
                          onCheckedRow={onCheckedRow}
                          selectedInvoices={selectedItemsId}
                        />
                      )}
                    </>
                  ),
                )}
              </tbody>
            </Table>
          )}

          {supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments
            ?.length === 0 &&
            !isLoading &&
            supplierPaymentInvoicesResponse?.err === null && (
              <div className="py-5 custom-empty-message">
                <p>{t('ITEMS_ARE_UNAVAILABLE')}</p>
              </div>
            )}
        </div>
        {supplierPaymentInvoicesResponse?.res?.supplierInitiatePayments
          ?.length > 0 && (
          <div
            style={
              isMobile
                ? { marginTop: '20px', textAlign: 'center' }
                : { textAlign: 'right' }
            }
          >
            <Button
              onClick={() => {
                setIsUpdatePaymentModal(!isUpdatePaymentModal);
              }}
              style={isMobile ? { width: '100%' } : { width: '100px' }}
              color="primary"
              disabled={
                selectedItemsId?.length === 0 ||
                supplierPaymentConfirmation?.err !== null
              }
            >
              Pay
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default AdminSupplierPaymentInvoice;
