import { BASE_URL } from './environment';

import { Connection, Session, keyLock } from '../src/app/config';
// set global local to current browser language

let sillara = BASE_URL;

Connection.config(
  {
    users: `${sillara}/users/`,
    admins: `${sillara}/admins/`,
    suppliers: `${sillara}/suppliers/`,
    buyers: `${sillara}/buyers/`,
    bulkUpload: `${sillara}/bulk-upload/`,
    purchaseOrders: `${sillara}/purchase-orders/`,
    base: sillara,
  },
  {
    'Content-Type': 'application/json',
    authorization: () => {
      return Session.ticket().ticket;
    },
  },
);

const Config = async function() {
  Session.config(
    function(ticket) {
      if (!ticket) {
        window.sessionStorage.removeItem('ticket');
      } else {
        window.sessionStorage.setItem(
          'ticket',
          keyLock.hexEncode(JSON.stringify({ ticket })),
        );
      }
    },
    function() {
      const ticket = window.sessionStorage.getItem('ticket');
      if (ticket) {
        return JSON.parse(keyLock.hexDecode(ticket));
      }
      return null;
    },
    function(permissions) {
      if (!permissions) {
        window.sessionStorage.removeItem('permissions');
      } else {
        window.sessionStorage.setItem(
          'permissions',
          keyLock.hexEncode(JSON.stringify({ permissions })),
        );
      }
    },
    function() {
      const permissions = window.sessionStorage.getItem('permissions');
      if (permissions) {
        return JSON.parse(keyLock.hexDecode(permissions)).permissions;
      }
      return null;
    },
    function(user) {
      if (!user) {
        window.sessionStorage.removeItem('user');
      } else {
        window.sessionStorage.setItem(
          'user',
          keyLock.hexEncode(JSON.stringify({ user })),
        );
      }
    },
    function() {
      const user = window.sessionStorage.getItem('user');
      if (user) {
        return JSON.parse(keyLock.hexDecode(user)).user;
      }
      return null;
    },
  );
};

export default Config;
