import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../apis/authentication';
import { COGNITO_POLICY } from '../../../../shared/constants/constants';
import MobileVerification from './MobileVerification';
import OtpInput from './OtpInput';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
  const [stage, setStage] = useState('Step1');
  const [minPassword, setMinPassword] = useState();
  const forgotPasswordContext = useContext(AuthContext);
  const { passwordPoliciesResponse } = forgotPasswordContext.state;
  const { fetchPasswordPolicies, forgotPassword } = forgotPasswordContext;
  const { t } = useTranslation();
  const onRequestOtp = user => {
    forgotPassword(user);
  };

  useEffect(() => {
    const fetchPWpolcies = async () => {
      fetchPasswordPolicies();
    };
    fetchPWpolcies();
  }, []);

  useEffect(() => {
    if (passwordPoliciesResponse?.res) {
      setMinPassword(
        parseInt(passwordPoliciesResponse?.res?.policy?.minimumLength),
      );
    } else {
      setMinPassword(COGNITO_POLICY.PASSWORD_DEFAULT_MIN_LENGTH);
    }
  }, [passwordPoliciesResponse]);

  const currentStage = stage => {
    switch (stage) {
      case 'Step1':
        return (
          <MobileVerification setStage={setStage} onRequestOtp={onRequestOtp} />
        );
      case 'Step2':
        return (
          <OtpInput
            setStage={setStage}
            length={minPassword}
            onResend={onRequestOtp}
          />
        );
    }
  };
  return (
    <div>
      <h5 className="auth-form-topic">{t('FORGOT_PASSWORD_TEXT')}</h5>
      <div>{currentStage(stage)}</div>
    </div>
  );
}

export default ForgotPassword;
