import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import PropTypes from 'prop-types';
function CustomCalendar({ onClose = null, ...props }) {
  useEffect(() => {
    const root = document.querySelector('#content-container');
    if (onClose) {
      root.addEventListener('click', event => {
        handleClickOutside(event);
      });
    }
    function handleClickOutside(event) {
      const node = event.target;
      if (
        node.name === 'calendar' ||
        event.target?.className?.toString()?.includes('calendar') ||
        node?.parentElement?.className.toString()?.includes('calendar')
      ) {
        return;
      }

      onClose();
    }
    return () => {
      if (onClose) {
        root.removeEventListener('click', handleClickOutside);
      }
    };
  }, []);
  return (
    <>
      <Calendar {...props} />
    </>
  );
}

CustomCalendar.propTypes = {
  onClose: PropTypes.func,
};

export default CustomCalendar;
