import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import InnerBody from '../../../../content/InnerBody';
import {
  Countdown,
  CustomModal,
  ProgressBar,
} from '../../../../shared/components';
import DeliveryDateSelection from './DeliveryDateSelection';
import OrderSortingSummary from './OrderSortingSummary';
import { SupplierContext } from '../../../../apis/supplier';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import OrderLoadingSummary from './OrderLoadingSummary';
import OrderSortingDetail from './OrderSortingDetail';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

function OrderFulfilmentManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const supplierContext = useContext(SupplierContext);
  const {
    handleSupplierDetails,
    getDeliverySlots,
    getOrderSortingSummary,
    getOrderSortingDetail,
    getOrderLoadingSummary,
  } = supplierContext;
  const [deliverySlotOptions, setDeliverySlotOptions] = useState([]);
  const [error, setError] = useState('');
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [supplierCompanyId, setSupplierCompanyId] = useState(1);
  const [dispatchCutOff, setDispatchCutOff] = useState(0);
  const [isDisplayedPopup, setIsDisplayedPopup] = useState(false);
  const [orderProducts, setOrderProducts] = useState([]);
  const [isOrderProductsLoading, setIsOrderProductsLoading] = useState(true);
  const [
    isOrderProductDetailsLoading,
    setIsOrderProductDetailsLoading,
  ] = useState(true);
  const [orderProductDetails, setOrderProductDetails] = useState([]);
  const [isOrderSortingDetailPage, setIsOrderSortingDetailPage] = useState(
    false,
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    if (!selectedDeliverySlot) {
      setCurrentStep(0);
    }
    handleSupplierDetails().then(res => {
      if (res.err) {
        return;
      } else {
        setSupplierCompanyId(res.res.supplierCompanyId);
        getDeliverySlots(res.res.supplierCompany.baseId).then(res => {
          if (res.err) {
            return;
          } else {
            if (res.res.length === 0) {
              setError(t('NOT_AVAILABLE_AT_THE_MOMENT._PLEASE_CHECK_LATER'));
            }
            setDeliverySlotOptions(res.res);
          }
        });
      }
    });
  }, []);

  const onDateTimeSubmit = () => {
    onHandleOrderSortingLanding();
  };

  const onHandleOrderSortingLanding = () => {
    setIsOrderProductsLoading(true);
    setIsOrderSortingDetailPage(false);
    getOrderSortingDetail(
      supplierCompanyId,
      selectedDeliverySlot?.id,
      moment(selectedDeliverySlot?.date).format('DD/MM/YYYY'),
    ).then(res => {
      if (res.err) {
        return;
      } else {
        setOrderProductDetails(res.res);
      }
    });
    getOrderSortingSummary(
      supplierCompanyId,
      selectedDeliverySlot?.id,
      moment(selectedDeliverySlot?.date).format('DD/MM/YYYY'),
    ).then(res => {
      if (res.err) {
        return;
      } else {
        var a = moment(); //now
        var b = moment(res.res.dispatchCutOffTime);
        if (res.res?.orderProducts?.length === 0) {
          setIsDisplayedPopup(true);
        } else {
          setIsDisplayedPopup(false);
        }
        setCurrentStep(1);
        setDispatchCutOff(moment().add(b.diff(a, 'seconds'), 'second'));

        setOrderProducts(res.res.orderProducts);
        setIsTimeOut(false);
      }
      setIsOrderProductsLoading(false);
    });
  };

  const onViewOrder = () => {
    setIsOrderProductDetailsLoading(true);
    getOrderSortingDetail(
      supplierCompanyId,
      selectedDeliverySlot?.id,
      moment(selectedDeliverySlot?.date).format('DD/MM/YYYY'),
    ).then(res => {
      if (res.err) {
        return;
      } else {
        setOrderProductDetails(res.res);
        setIsOrderSortingDetailPage(true);
        setOrderProducts([]);
        setIsTimeOut(false);
      }
      setIsOrderProductDetailsLoading(false);
    });
  };
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <DeliveryDateSelection
            selectedDeliverySlot={selectedDeliverySlot}
            setSelectedDeliverySlot={setSelectedDeliverySlot}
            dateTimeSubmitted={onDateTimeSubmit}
            deliverySlotOptions={deliverySlotOptions}
            error={error}
            setError={setError}
          />
        );
      case 1:
        return (
          <>
            {!isOrderSortingDetailPage ? (
              <OrderSortingSummary
                onViewOrder={onViewOrder}
                date={moment(selectedDeliverySlot?.date).format('DD/MM/YYYY')}
                selectedDeliverySlotId={selectedDeliverySlot?.id}
                currentStep={currentStep}
                isTimeOut={isTimeOut}
                orderProducts={orderProducts}
                isOrderProductsLoading={isOrderProductsLoading}
                setIsOrderSortingDetailPage={setIsOrderSortingDetailPage}
                onHandleOrderSortingLanding={onHandleOrderSortingLanding}
              />
            ) : (
              <OrderSortingDetail
                orderProductDetails={orderProductDetails}
                setOrderProductDetails={setOrderProductDetails}
                selectedDeliverySlot={selectedDeliverySlot}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                setIsOrderSortingDetailPage={setIsOrderSortingDetailPage}
                isOrderProductDetailsLoading={isOrderProductDetailsLoading}
              />
            )}
          </>
        );
      case 2:
        return (
          <OrderLoadingSummary
            date={moment(selectedDeliverySlot?.date).format('DD/MM/YYYY')}
            selectedDeliverySlotId={selectedDeliverySlot?.id}
            currentStep={currentStep}
            setIsOrderSortingDetailPage={setIsOrderSortingDetailPage}
            setIsRefresh={setIsRefresh}
            isRefresh={isRefresh}
          />
        );
    }
  };

  const orderTimeOutPopupToggle = () => {
    if (!isDisplayedPopup) {
      setIsTimeOut(true);
    }
    setIsDisplayedPopup(true);
  };

  const handleUpdatePurchaseOrderToManualProcument = () => {
    const orderIds = [];
    orderProductDetails.forEach(order => {
      orderIds.push(order.purchaseOrderId);
    });
    getOrderLoadingSummary(
      selectedDeliverySlot?.id,
      moment(selectedDeliverySlot?.date).format('DD/MM/YYYY'),
    ).then(response => {
      if (response.err) {
        return;
      } else {
        if (response.res.length === 0) {
          navigate('/supplier/home');
        } else {
          setCurrentStep(2);
        }
      }
    });
  };

  const onHandleBack = () => {
    setIsOrderSortingDetailPage(false);
  };

  return (
    <InnerBody
      topic={
        currentStep === 0
          ? t('DELIVERY_SLOT_SELECTION')
          : currentStep === 1
          ? t('ORDER_SORTING')
          : t('ORDER_LOADING')
      }
      onRefresh={() => setIsRefresh(true)}
      rightSideElement={
        currentStep === 1 && (
          <Countdown
            startTime={dispatchCutOff}
            config={{ hours: { show: true, hideSpan: true } }}
            onComplete={orderTimeOutPopupToggle}
            isShowDays={false}
          />
        )
      }
      isDisable={
        currentStep !== 1 ? true : isOrderSortingDetailPage ? false : true
      }
      isRefreshDisable={currentStep !== 2}
      onHandleBack={onHandleBack}
    >
      <ProgressBar
        currentStepIndex={currentStep}
        steps={['Delivery', 'Sorting', 'Loading']}
        barStyle={false}
        setCurrentStep={setCurrentStep}
        changeStepOnClick={currentStep != 0}
      />
      {renderStep()}
      <CustomModal
        isOpen={isTimeOut}
        isConfirmation={true}
        hideCancel={true}
        isScrollable={true}
        enableFooter={false}
        negativeBtnTitle="no"
        title="Time Out!"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="d-flex justify-content-around"
        modalBodyClassName="small"
      >
        <div className="my-3">
          {t(
            'YOU_HAVE_RUN_OUT_OF_TIME_TO_SORT_ORDERS._ANY_REMAINING_ORDERS_WILL_BE_TRANSFERRED',
          )}
        </div>
        <Button
          className="form-submit w-40"
          type="submit"
          color="primary"
          onClick={() => {
            setIsTimeOut(false);
            handleUpdatePurchaseOrderToManualProcument();
          }}
        >
          {' '}
          {t('OK')}
        </Button>
      </CustomModal>
    </InnerBody>
  );
}

export default OrderFulfilmentManagement;
