import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';

function ArrowsHorizontallyScroller({
  itemsPerPage,
  currentBoundary,
  items,
  setCurrentBoundary,
  setCurrentItems,
}) {
  const onScroll = next => {
    if (next) {
      setCurrentItems(
        items?.slice(
          currentBoundary + itemsPerPage,
          currentBoundary + itemsPerPage * 2,
        ),
      );
      setCurrentBoundary(currentBoundary + itemsPerPage);
    } else {
      setCurrentItems(
        items?.slice(currentBoundary - itemsPerPage, currentBoundary),
      );
      setCurrentBoundary(currentBoundary - itemsPerPage);
    }
  };

  return (
    <Container className="arrows-horizontally-scroller p-0">
      <Row>
        <Col className="p-0">
          <a
            className={currentBoundary < itemsPerPage ? 'btn-disable' : ''}
            disabled={currentBoundary < itemsPerPage}
            onClick={() => onScroll(false)}
          >
            <span className="left"></span>
          </a>

          <a
            className={
              currentBoundary + itemsPerPage >= items?.length
                ? 'btn-disable'
                : ''
            }
            disabled={currentBoundary + itemsPerPage >= items?.length}
            onClick={() => onScroll(true)}
          >
            <span className="right"></span>
          </a>
        </Col>
      </Row>
    </Container>
  );
}
ArrowsHorizontallyScroller.propTypes = {
  itemsPerPage: PropTypes.number,
  currentBoundary: PropTypes.number,
  items: PropTypes.array,
  setCurrentBoundary: PropTypes.func,
  setCurrentItems: PropTypes.func,
};

export default ArrowsHorizontallyScroller;
