import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

function CustomDropdown({
  disable,
  parentClass,
  parentStyle,
  direction = 'down',
  toggleClass,
  toggleText,
  drodDownList,
  onClickListItem,
  dropDownText,
  ...rest
}) {
  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const dropDownToggle = () => setDropDownOpen(!isDropDownOpen);

  return (
    <>
      <Dropdown
        disabled={disable}
        isOpen={isDropDownOpen}
        toggle={dropDownToggle}
        direction={direction}
        className={parentClass}
        style={parentStyle}
        {...rest}
      >
        <DropdownToggle caret className={toggleClass}>
          {toggleText}
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: '30vh', overflowY: 'auto' }}>
          {drodDownList.map((item, index) => (
            <DropdownItem
              key={index}
              onClick={() => {
                onClickListItem(item);
              }}
            >
              {dropDownText(item)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
CustomDropdown.propTypes = {
  disable: PropTypes.bool,
  parentClass: PropTypes.string,
  parentStyle: PropTypes.any,
  direction: PropTypes.string,
  toggleClass: PropTypes.string,
  toggleText: PropTypes.string,
  drodDownList: PropTypes.any,
  onClickListItem: PropTypes.any,
  dropDownText: PropTypes.func,
};
export default CustomDropdown;
