import { firebase } from './environment';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
const firebaseConfig = {
  apiKey: firebase.API_KEY,
  authDomain: firebase.AUTH_DOMAIN,
  projectId: firebase.PROJECT_ID,
  storageBucket: firebase.STORAGE_BUCKET,
  messagingSenderId: firebase.MESSAGING_SENDER_ID,
  appId: firebase.APP_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = setFirebaseToken => {
  Notification.requestPermission(function(result) {
    if (result === 'granted') {
      return getToken(messaging, {
        vapidKey: firebase.VAPID_KEY,
      })
        .then(currentToken => {
          if (currentToken) {
            setFirebaseToken(currentToken);
          } else {
            console.log(
              'No registration token available. Request permission to generate one.',
            );
            setFirebaseToken(null);
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          setFirebaseToken(null);
          // catch error while creating client token
        });
    }
  });
};
