import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'reactstrap';
import ProductCard from './ProductCard';
import { useState } from 'react';
import { useDeviceDetect } from '../../../../shared/hooks';
import { useEffect } from 'react';
import ArrowsHorizontallyScroller from '../../../../shared/components/ArrowsHorizontallyScroller';
import { useTranslation } from 'react-i18next';
function OrderSortingSummary({
  onViewOrder,
  isTimeOut,
  orderProducts,
  isOrderProductsLoading,
  currentStep,
  onHandleOrderSortingLanding,
}) {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [currentBoundary, setCurrentBoundary] = useState(0);
  const { isMobile } = useDeviceDetect();
  const itemsPerPage = 12;

  useEffect(() => {
    setCurrentBoundary(0);
    if (!isMobile) {
      if (orderProducts?.length > 0) {
        setCurrentItems(
          orderProducts?.slice(currentBoundary, currentBoundary + itemsPerPage),
        );
      }
    } else {
      if (orderProducts?.length > 0) {
        setCurrentItems(orderProducts);
      }
    }
  }, [isMobile, isTimeOut, orderProducts]);

  useEffect(() => {
    onHandleOrderSortingLanding();
  }, [currentStep]);

  return (
    <>
      {!isOrderProductsLoading && (
        <div className="order-sorting-summary">
          {orderProducts.length === 0 ? (
            <div className="py-5 custom-empty-message">
              <p>{t('NOT_AVAILABLE_AT_THE_MOMENT._PLEASE_CHECK_LATER')}</p>
            </div>
          ) : (
            <>
              <Row className="justify-content-end mb-1 order-sorting-btn-row">
                <Button
                  color="primary"
                  type="submit"
                  className="form-submit mt-3 ml-1 col col-12 col-md-2 p-1 mb-3"
                  onClick={onViewOrder}
                  disabled={orderProducts.length === 0}
                >
                  {t('VIEW_ORDERS')}
                </Button>
              </Row>
              <div>
                {currentItems.length > 0 && (
                  <>
                    {!isMobile && orderProducts > itemsPerPage && (
                      <ArrowsHorizontallyScroller
                        itemsPerPage={itemsPerPage}
                        currentBoundary={currentBoundary}
                        items={orderProducts}
                        setCurrentBoundary={setCurrentBoundary}
                        setCurrentItems={setCurrentItems}
                      />
                    )}
                    <div className="sorting-products-container">
                      <Row>
                        {currentItems?.map((item, index) => (
                          <ProductCard
                            index={index}
                            product={item}
                            key={item.purchaseOrderProductId}
                          />
                        ))}
                      </Row>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
OrderSortingSummary.propTypes = {
  onViewOrder: PropTypes.func,
  isTimeOut: PropTypes.bool,
  orderProducts: PropTypes.array,
  currentStep: PropTypes.number,
  onHandleOrderSortingLanding: PropTypes.func,
  isOrderProductsLoading: PropTypes.bool,
};

export default OrderSortingSummary;
