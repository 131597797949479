import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDeviceDetect } from '../../../../shared/hooks';
import PropTypes from 'prop-types';
import SearchBar from '../../../../shared/components/searchBar/SearchBar';
import ProductCard from './ProductCard';
import ProductCategoryCard from './ProductCategoryCard';
import { useTranslation } from 'react-i18next';
import { BuyerContext } from '../../../../apis/buyer';

function BrowsePageTemplate({
  filterCategories,
  items,
  dropDownPlaceholder,
  dropdownDisplayValue,
  setSelectedCategory,
  getSubCategories,
  isProductList,
  selectedDate,
  selectedDeliverySlot,
  currentStep,
  isItemsAvailable,
  onSelectFilter,
  selectedValues,
  onSearchProduct,
  searchTerm,
  onChangeSearch,
  buyerSearchSuggestionResponse,
  handleBrowseProductList,
  isSubCategory,
  isSearchingOrFiltering,
  selectedCategory,
  selectedSubCategory,
}) {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [currentBoundary, setCurrentBoundary] = useState(0);
  const { isMobile } = useDeviceDetect();
  const buyerContext = useContext(BuyerContext);
  const { getCartProductItems } = buyerContext;
  const { getCartProductItemsResponse } = buyerContext.state;
  const [cartProductItems, setCartProductItems] = useState(false);
  let itemsPerPage = isProductList ? 6 : 6;

  useEffect(() => {
    setCurrentBoundary(0);
    setCurrentItems([]);
    if (!isMobile) {
      if (items?.length > 0) {
        setCurrentItems(
          items?.slice(currentBoundary, currentBoundary + itemsPerPage),
        );
      }
    } else {
      if (items?.length > 0) {
        setCurrentItems(items);
      }
    }
  }, [
    items,
    window.innerWidth,
    isMobile,
    currentStep,
    isProductList,
    isSearchingOrFiltering,
  ]);

  useEffect(() => {
    getCartProductItems().then(res => {
      if (res.err) {
        return;
      } else {
        setCartProductItems(res.res);
      }
    });
  }, [getCartProductItemsResponse?.res?.length]);

  const onScroll = next => {
    if (next) {
      setCurrentItems(
        items?.slice(
          currentBoundary + itemsPerPage,
          currentBoundary + itemsPerPage * 2,
        ),
      );
      setCurrentBoundary(currentBoundary + itemsPerPage);
    } else {
      setCurrentItems(
        items?.slice(currentBoundary - itemsPerPage, currentBoundary),
      );
      setCurrentBoundary(currentBoundary - itemsPerPage);
    }
  };

  const setFilterCategories = () => {
    let tempArray = [];
    let parentCategories = [];
    filterCategories?.forEach(element => {
      if (element?.parentCategoryId === null) {
        parentCategories.push(element);
      }
    });

    filterCategories?.forEach(element => {
      if (element?.parentCategoryId) {
        let temp = element;
        temp['parentCategoryName'] = filterCategories.filter(
          item => item?.id === element?.parentCategoryId,
        )[0]?.name;
        tempArray.push(element);
      }
    });
    parentCategories?.forEach(element => {
      const newArray = [
        {
          id: element?.id,
          name: 'All ' + element?.name,
          parentCategoryId: element?.id,
          parentCategoryName: element?.name,
        },
      ].concat(tempArray);
      tempArray = newArray;
    });
    const newArray = [
      {
        id: '',
        name: 'All Categories',
        parentCategoryId: '',
        parentCategoryName: 'All Categories',
      },
    ].concat(tempArray);
    tempArray = newArray;
    return tempArray;
  };
  const setParentCategoryText = text =>
    text === 'All Categories' ? 'Categories' : text;

  const setSubCategoryText = text =>
    text === 'Select a Category' ? '' : ' > ' + text;
  return (
    <>
      <div className="browse-page-template">
        <div className="d-flex justify-content-between align-items-center">
          <p className="browse-page-topic">
            {isSearchingOrFiltering
              ? setParentCategoryText(selectedValues.parentCategory)
              : selectedCategory
              ? selectedCategory.name
              : t('CATEGORIES')}
            <span className="">
              {!isSearchingOrFiltering
                ? selectedSubCategory
                  ? setSubCategoryText(selectedSubCategory.name)
                  : ''
                : setSubCategoryText(selectedValues?.name)}
            </span>
          </p>
        </div>
        <SearchBar
          isMobile={isMobile}
          onSelectFilter={onSelectFilter}
          filterCategories={setFilterCategories()}
          dropDownPlaceholder={dropDownPlaceholder}
          dropdownDisplayValue={dropdownDisplayValue}
          onSearchProduct={onSearchProduct}
          onChangeSearch={onChangeSearch}
          searchTerm={searchTerm}
          selectedValues={selectedValues}
          buyerSearchSuggestionResponse={buyerSearchSuggestionResponse}
        />

        {!isItemsAvailable ? (
          <div className="py-5 custom-empty-message">
            {selectedValues?.parentCategory &&
            selectedValues?.parentCategory?.length > 0 ? (
              'The searched item is not available in the chosen category.'
            ) : (
              <p>{t('ITEMS_ARE_UNAVAILABLE')}</p>
            )}
          </div>
        ) : (
          <>
            {currentItems?.length > 0 && (
              <>
                {!isMobile && items?.length > itemsPerPage && (
                  <Container className="p-0">
                    <Row>
                      <Col className="p-0">
                        <a
                          className={
                            currentBoundary < itemsPerPage ? 'btn-disable' : ''
                          }
                          disabled={currentBoundary < itemsPerPage}
                          onClick={() => onScroll(false)}
                        >
                          <span className="left"></span>
                        </a>

                        <a
                          className={
                            currentBoundary + itemsPerPage >= items?.length
                              ? 'btn-disable'
                              : ''
                          }
                          disabled={
                            currentBoundary + itemsPerPage >= items?.length
                          }
                          onClick={() => onScroll(true)}
                        >
                          <span className="right"></span>
                        </a>
                      </Col>
                    </Row>
                  </Container>
                )}
                <div
                  className={`${
                    !isProductList ? 'products-container' : 'd-none'
                  }`}
                >
                  {!isProductList &&
                    currentItems?.map(item => (
                      <ProductCategoryCard
                        category={item}
                        setSelectedCategory={setSelectedCategory}
                        getSubCategories={getSubCategories}
                        key={'category' + item.id}
                        handleBrowseProductList={handleBrowseProductList}
                        isSubCategory={isSubCategory}
                      />
                    ))}
                </div>
                <div className="products-container-product-list">
                  {isProductList &&
                    currentItems?.map(product => (
                      <Col
                        xl={4}
                        lg={4}
                        md={6}
                        sm={6}
                        key={'product' + product.id}
                        className="product-card-container"
                      >
                        <ProductCard
                          product={product}
                          selectedDeliverySlot={selectedDeliverySlot}
                          selectedDate={selectedDate}
                          cartProductItems={cartProductItems}
                          setCartProductItems={setCartProductItems}
                        />
                      </Col>
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

BrowsePageTemplate.propTypes = {
  filterCategories: PropTypes.array,
  items: PropTypes.array,
  searchSuggestions: PropTypes.array,
  title: PropTypes.string,
  dropDownPlaceholder: PropTypes.string,
  dropdownDisplayValue: PropTypes.string,
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  getSubCategories: PropTypes.func,
  isProductList: PropTypes.bool,
  selectedDate: PropTypes.any,
  selectedDeliverySlot: PropTypes.any,
  currentStep: PropTypes.number,
  isItemsAvailable: PropTypes.bool,
  onSelectFilter: PropTypes.func,
  selectedValues: PropTypes.object,
  onSearchProduct: PropTypes.func,
  searchTerm: PropTypes.string,
  onChangeSearch: PropTypes.func,
  buyerSearchSuggestionResponse: PropTypes.any,
  handleBrowseProductList: PropTypes.func,
  isSubCategory: PropTypes.bool,
  isSearchingOrFiltering: PropTypes.bool,
  selectedSubCategory: PropTypes.object,
};

export default BrowsePageTemplate;
