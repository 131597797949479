const keyLock = (function() {
  const hexEncode = str => {
    var hex, i;

    var result = '';
    for (i = 0; i < str.length; i++) {
      hex = str.charCodeAt(i).toString(16);
      result += ('000' + hex).slice(-4);
    }

    return result;
  };

  const hexDecode = str => {
    var j;
    var hexes = str.match(/.{1,4}/g) || [];
    var back = '';
    for (j = 0; j < hexes.length; j++) {
      back += String.fromCharCode(parseInt(hexes[j], 16));
    }

    return back;
  };

  return {
    hexEncode: hexEncode,
    hexDecode: hexDecode,
  };
})();

export default keyLock;
