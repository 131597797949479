import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
  Card,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarBlue } from '../../../assets/icons';
import {
  CustomCalendar,
  Text,
  CustomModal,
} from '../../../../shared/components';
import { useDeviceDetect } from '../../../../shared/hooks';
import InnerBody from '../../../../content/InnerBody';
import { BuyerManagementContext } from '../../../../apis/admin';
import { excelIcon } from '../../../assets/img/';
import closeIcon from '../../../assets/icons/Close.svg';

import { SessionContext } from '../../../../apis/authentication';
import { formatDate } from '../../../../shared/utility/dateFormats';
import {
  DATE_FORMAT_API,
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../shared/constants/constants';
import moment from 'moment';

function Logistics() {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [openCalendar, setIsOpenCalendar] = useState(false);
  const [isDeliverySlot, setIsDeliverySlot] = useState(false);
  const [isBaseNameDropdown, setIsBaseNameDropdown] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState();
  const sessionContext = useContext(SessionContext);
  const { user } = sessionContext.state;
  const [vehicleFileResponse, setVehicleFileResponse] = useState(null);

  const [file, setFile] = useState(null);

  const fileRef = useRef();

  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const buyerManagementContext = useContext(BuyerManagementContext);
  const [fileUploadResPopupModal, setFileUploadResPopupModal] = useState(false);
  const {
    getDeliverySlots,
    getBaseNameByDeliverySlot,
    uploadVehicleFile,
  } = buyerManagementContext;
  const {
    deliverySlotsResponse,
    baseNamesResponse,
  } = buyerManagementContext.state;

  const deliverySlotToggle = () => setIsDeliverySlot(!isDeliverySlot);
  const baseNameToggle = () => setIsBaseNameDropdown(!isBaseNameDropdown);
  const fileUploadResToggle = () => {
    setVehicleFileResponse(null);
    setFileUploadResPopupModal(!fileUploadResPopupModal);
  };

  useEffect(() => {}, [isMobile]);

  useEffect(() => {
    if (selectedDate) {
      getDeliverySlots(
        formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      );
      setSelectedDeliverySlot(null);
      setSelectedBaseName(null);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDeliverySlot?.id) {
      getBaseNameByDeliverySlot(selectedDeliverySlot.id);
      setSelectedBaseName(null);
    }
  }, [selectedDeliverySlot]);

  useEffect(() => {
    setError('');
    if (
      deliverySlotsResponse?.res &&
      selectedDate &&
      deliverySlotsResponse?.res?.length === 0
    ) {
      setError('A delivery slot is not available for the selected date.');
    }
  }, [deliverySlotsResponse]);
  useEffect(() => {
    setError('');
    if (
      baseNamesResponse?.res &&
      selectedDeliverySlot &&
      baseNamesResponse?.res?.length === 0
    ) {
      setError('A base name is not available for the selected slot.');
    }
  }, [baseNamesResponse]);

  const handleChangeFile = e => {
    setFile(e?.target?.files[0]);
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const newBaseString = reader.result.split('base64,');
      upload(btoa(newBaseString[1]));
      removeFile();
    };
    reader.onerror = function() {
      //return null;
    };
  }

  const handleClick = () => {
    fileRef.current.click();
  };

  const removeFile = () => {
    setFile(null);
    fileRef.current.value = null;
  };

  const handleUploadFile = () => {
    setError(null);

    getBase64(file);
  };

  const upload = encodedFile => {
    if (encodedFile) {
      uploadVehicleFile({
        fileName: file?.name,
        UserId: user?.username,
        ExcelBase64: encodedFile,
        DeliveryDate: formatDate(selectedDate, null, null, DATE_FORMAT_API),
        DeliverySlotId: selectedDeliverySlot?.id,
        DeliveryBaseId: selectedBaseName?.id,
      })
        .then(res => {
          if (res?.message) {
            setFileUploadResPopupModal(true);
            setVehicleFileResponse({ err: res?.message, isError: true });
            return;
          }
          setFileUploadResPopupModal(true);
          setVehicleFileResponse({ res, isError: false });
          setSelectedDate(null);
          setSelectedBaseName(null);
          setSelectedDeliverySlot(null);
        })
        .catch(err => {
          setFileUploadResPopupModal(true);
          setVehicleFileResponse({ err, isError: true });
        });

      fileRef.current.value = null;
      removeFile();
    }
  };

  const fileUploadResponsePopup = () => (
    <CustomModal
      isOpen={fileUploadResPopupModal}
      toggle={fileUploadResToggle}
      isScrollable={true}
      title={
        !vehicleFileResponse?.isError ? 'Upload Confirmation' : 'Upload failure'
      }
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {!vehicleFileResponse?.isError ? (
        <>
          The vehicle allocation list is being uploaded. After completing the
          uploading process, you will receive an SMS to{' '}
          {user?.attributes?.phone_number}.
        </>
      ) : (
        vehicleFileResponse?.err
      )}
    </CustomModal>
  );

  return (
    <InnerBody
      topic={t('VEHICLE_ALLOCATION_DETAILS')}
      isDisable={true}
      className="logistics"
    >
      <div className="logistics-container">
        <Row>
          <div className="col-lg-4 col-sm-12">
            <Text
              type="text"
              placeholder={t('SELECT_A_DELIVERY_DATE')}
              className="delivery-date"
              value={selectedDate && moment(selectedDate).format('DD/MM/YYYY')}
              rightIcon={CalendarBlue}
              rightIconHandle={() => setIsOpenCalendar(prevProps => !prevProps)}
              id="calendar"
              field="calendar"
              onClick={() => setIsOpenCalendar(prevProps => !prevProps)}
              rightIconGroupClassName="position-relative cursor-pointer"
              rightIconClassName="calendar-icon"
              showErrors={false}
              style={{ cursor: 'pointer' }}
              readOnly
            />
            {error?.isSelectedDateError && (
              <small
                style={{ marginTop: '-15px' }}
                className="error-message mb-3"
              >
                {t('SELECT_A_DELIVERY_DATE')}
              </small>
            )}
          </div>
          {openCalendar && (
            <CustomCalendar
              onClose={() => setIsOpenCalendar(false)}
              onChange={e => {
                setSelectedDate(e);
                setIsOpenCalendar(false);
              }}
              value={selectedDate && new Date(selectedDate)}
            />
          )}

          <Dropdown
            disabled={
              selectedDate === null || deliverySlotsResponse?.res?.length === 0
            }
            isOpen={isDeliverySlot}
            toggle={deliverySlotToggle}
            direction="down"
            className="col-lg-4 col-sm-12 mb-3"
          >
            <DropdownToggle
              style={
                selectedDate === null ||
                deliverySlotsResponse?.res?.length === 0
                  ? { opacity: '0.6' }
                  : {}
              }
              caret
              className={
                selectedDeliverySlot === null ? 'grey-text' : 'black-text'
              }
            >
              {selectedDeliverySlot === null
                ? t('SELECT_A_DELIVER_SLOT')
                : `${selectedDeliverySlot?.startTime} - ${selectedDeliverySlot?.endTime}`}
            </DropdownToggle>
            <DropdownMenu>
              {deliverySlotsResponse?.res?.map(item => (
                <DropdownItem
                  key={item?.id}
                  onClick={() => {
                    setSelectedDeliverySlot(item);
                    getBaseNameByDeliverySlot, item?.id;
                  }}
                >
                  {`${item?.startTime} - ${item?.endTime}`}
                </DropdownItem>
              ))}
            </DropdownMenu>
            {error?.isDeliverySlotError && (
              <small className="error-message  mb-3 ">
                {t('SELECT_A_DELIVERY_SLOT')}
              </small>
            )}
          </Dropdown>

          <Dropdown
            disabled={
              selectedDeliverySlot === null ||
              baseNamesResponse?.res?.length === 0
            }
            isOpen={isBaseNameDropdown}
            toggle={baseNameToggle}
            direction="down"
            className="col-lg-4 col-sm-12 mb-3"
          >
            <DropdownToggle
              style={
                selectedDeliverySlot === null ||
                baseNamesResponse?.res?.length === 0
                  ? { opacity: '0.6' }
                  : {}
              }
              caret
              className={selectedBaseName === null ? 'grey-text' : 'black-text'}
            >
              {selectedBaseName === null
                ? t('SELECT_A_BASE_NAME')
                : selectedBaseName?.name}
            </DropdownToggle>
            <DropdownMenu>
              {baseNamesResponse?.res?.map(item => (
                <DropdownItem
                  key={item?.id}
                  onClick={() => {
                    setSelectedBaseName(item);
                  }}
                >
                  {item?.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
            {error?.isBaseNameError && (
              <small className="error-message mb-3">
                {t('SELECT_A_BASE_NAME')}
              </small>
            )}
          </Dropdown>
        </Row>
        {error && <small className="error-message mt-1">{error}</small>}

        <Row className="mt-3">
          <Col md={6} xs={12} style={{ minHeight: '250px' }}>
            <Card className="upload-card text-center shadow">
              <div className="rounded upload-card-body">
                {file ? (
                  <>
                    <div className="upload-card-icon-container">
                      <img
                        src={excelIcon}
                        alt="Excel Icon"
                        className="m-auto upload-card-icon"
                      />
                      <img
                        src={closeIcon}
                        alt="home icon"
                        className="upload-card-close-icon"
                        onClick={removeFile}
                      />
                    </div>
                    <p className="upload-card-file-name">{file.name}</p>
                  </>
                ) : (
                  <div className="upload-card-icon-container">
                    <img
                      src={excelIcon}
                      alt="Excel Icon"
                      className="m-auto upload-card-icon"
                    />
                  </div>
                )}
                {!file && (
                  <p className="upload-card-text">
                    {t('UPLOAD_VEHICLE_ALLOCATION_LIST_HERE')}
                  </p>
                )}
                <div className="d-flex flex-column">
                  <Button
                    disabled={
                      selectedDate === null ||
                      selectedDeliverySlot === null ||
                      selectedBaseName === null
                    }
                    className="browse-card-button"
                    onClick={handleClick}
                  >
                    {t('BROWSE')}
                  </Button>
                  <input
                    ref={fileRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".xlsx"
                    onChange={handleChangeFile}
                  />

                  <Button
                    disabled={
                      selectedDate === null ||
                      selectedDeliverySlot === null ||
                      selectedBaseName === null ||
                      !file
                    }
                    className="mt-2 upload-card-button btn-primary"
                    onClick={handleUploadFile}
                  >
                    {t('UPLOAD')}
                  </Button>
                </div>
                {fileUploadResponsePopup()}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </InnerBody>
  );
}

export default Logistics;
