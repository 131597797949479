/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { useDeviceDetect } from '../hooks';
import { warning } from '../../../src/app/assets/img';
const CustomTooltipContainer = props => {
  const {
    targetId,
    displayMessage,
    placement = 'bottom',
    width = '22',
    height,
    imgClass = '',
    imgSrc = warning,
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { isDesktop } = useDeviceDetect();
  useEffect(() => {}, [isDesktop]);

  return (
    <div
      onClick={() => setTooltipOpen(!tooltipOpen)}
      onBlur={() => setTooltipOpen(false)}
    >
      <img
        width={width}
        height={height}
        className={imgClass}
        src={imgSrc}
        alt="notes"
        id={targetId}
      />
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={targetId}
        toggle={toggle}
      >
        {displayMessage}
      </Tooltip>
    </div>
  );
};

CustomTooltipContainer.propTypes = {
  targetId: PropTypes.any,
  displayMessage: PropTypes.string,
  placement: PropTypes.string,
  width: PropTypes.string,
  imgClass: PropTypes.string,
  imgSrc: PropTypes.string,
  height: PropTypes.string,
};

export default CustomTooltipContainer;
