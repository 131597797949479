import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const ValidateQrCodeContext = React.createContext();
const ValidateQrCodeProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;

  const validateQrCodePackages = async qrCode => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const qrCodePack = await fetch(
        url + `/primary-packages?qrcode=${qrCode.toString()}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await qrCodePack.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const transferQrCodePackages = async (req, qrCode) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const qrCodePack = await fetch(url + `/primary-packages/${qrCode}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(req),
      });
      const data = await qrCodePack.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  return (
    <ValidateQrCodeContext.Provider
      value={{
        state: {},
        validateQrCodePackages: validateQrCodePackages,
        transferQrCodePackages: transferQrCodePackages,
      }}
    >
      {props.children}
    </ValidateQrCodeContext.Provider>
  );
};
ValidateQrCodeProvider.propTypes = {
  children: PropTypes.any,
};

export { ValidateQrCodeProvider, ValidateQrCodeContext };
