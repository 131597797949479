export const REVIEW_VALUES = {
  QUALITY: { id: 1, value: 'Issue due to quality' },
  QUANTITY: { id: 2, value: 'Issue due to quantity' },
  TAGGING: { id: 3, value: 'Issue due to tagging' },
};

export const PURCHASE_ORDER_STATUS = {
  LOADED: { id: 6, value: 'On the way' },
  UNLOADED: { id: 7, value: 'Goods received' },
  BUYERCONFIRMED: { id: 8, value: 'Goods received' },
  COMPLETE: { id: 9, value: 'Complete' },
};
