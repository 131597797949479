import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Col, Row, Table, Tooltip } from 'reactstrap';
import { BuyerManagementContext } from '../../../../../apis/admin';
import { warning } from '../../../../assets/img';
import CustomDropdown from '../../../../../shared/components/CustomDropdown';
import {
  PACKAGING_STATUS,
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
  //PURCHASE_ORDER_PRODUCT_STATUS,
} from '../../../../../shared/constants/constants';
import Scanner from '../Scanner';
import { CustomModal } from '../../../../../shared/components';
import StatusContainer from './StatusContainer';
import { useDeviceDetect } from '../../../../../shared/hooks';
import AlreadyScannedModal from '../AlreadyScannedModal';
import { LoadingContext } from '../../../../../apis/LoadingContext';
import BuyerName from '../BuyerName';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import { successMp3, successOgg, successWav } from '../../../../assets/audio';
function Orders({
  selectedVehicle,
  setSelectedVehicle,
  selectedBaseName,
  selectedDeliverySlot,
  deliveryDate,
}) {
  var audioElement = document.getElementById('qrScannerAudioControl');
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  const buyerManagementContext = useContext(BuyerManagementContext);
  const {
    updateStatusOfaPackage,
    getOrderBuyers,
    getUnloadPackages,
    updatePackageStatus,
  } = buyerManagementContext;
  const [orderList, setOrderList] = useState(null);
  const [isShowStatusInfo, setIsShowStatusInfo] = useState(false);
  const [isStartScan, setIsStartScan] = useState(false);
  const [error, setError] = useState('');
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [
    isPendingManualProcumentItems,
    setIsPendingManualProcumentItems,
  ] = useState(false);
  const [buyerOptions, setBuyerOptions] = useState([]);
  const [selectedBuyer, setSelectedBayer] = useState(null);
  const [isNormalUnloading, setIsNormalUnloading] = useState(true);
  const [isShowBuyerName, setIsShowBuyerName] = useState(false);
  const [disableCompleteBtn, setDisableCompleteBtn] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getOrderBuyers(
      selectedVehicle?.id,
      selectedBaseName?.id,
      selectedDeliverySlot?.id,
      formatDate(deliveryDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      !isNormalUnloading,
    ).then(res => {
      if (res.err) {
        return;
      } else {
        setBuyerOptions(res.res);
      }
    });
    setIsDataAvailable(true);
  }, [isNormalUnloading]);

  const modalToggle = () => {
    setIsStartScan(false);
  };

  const onHandleComplete = () => {
    if (isNormalUnloading) {
      if (
        orderList.filter(
          item =>
            item.manualProcCount &&
            !(
              item.packageStatus === PACKAGING_STATUS.UNLOAD_SCANNED.id ||
              item.packageStatus === PACKAGING_STATUS.UNLOADED.id
            ),
        ).length > 0
      ) {
        setIsPendingManualProcumentItems(true);
      } else {
        onHandleCompleteStatusUpdate();
      }
    } else {
      onHandleCompleteStatusUpdate();
    }
  };

  const onHandleCompleteStatusUpdate = () => {
    let packIds = [];
    orderList?.forEach(item => {
      if (item?.packageStatus === PACKAGING_STATUS.UNLOAD_SCANNED.id) {
        packIds.push(item?.packageId);
      }
    });
    updatePackageStatus(packIds, PACKAGING_STATUS.UNLOADED.id)
      .then(res => {
        if (res?.err) {
          setError(res?.err);
          setTimeout(function() {
            setError('');
          }, 5000);
          return;
        }
        setSelectedVehicle(null);
      })
      .catch(err => {
        if (err) {
          setError(err);
          setTimeout(function() {
            setError('');
          }, 5000);
          return;
        }
      });
  };

  const onHandleScan = qrValue => {
    const qrId = qrValue;

    const tempResult = orderList?.filter(item => item.packageQRCode === qrId);
    if (tempResult.length === 0 || !qrId) {
      setError('The QR code is invalid.');
      setTimeout(function() {
        setError('');
      }, 5000);
    } else {
      orderList?.forEach(item => {
        if (item.packageQRCode === qrId) {
          if (
            item.packageStatus === PACKAGING_STATUS.UNLOAD_SCANNED.id ||
            item.packageStatus === PACKAGING_STATUS.UNLOADED.id
          ) {
            setError('The primary package has been already scanned.');
            setTimeout(function() {
              setError('');
            }, 5000);
            setIsModalOpen(true);
            setSelectedOrder(item);
          } else {
            // let tempOrderList = [...orderList];
            // tempOrderList.filter(
            //   pack => pack.packageId === item.packageId,
            // )[0].packageStatus = PACKAGING_STATUS.UNLOAD_SCANNED.id;
            // setOrderList(tempOrderList);
            audioElement.play();
            onHandleUpdateStatusOfaPackage(
              item.packageId,
              PACKAGING_STATUS.UNLOAD_SCANNED.id,
            );
          }
        }
      });
    }
  };

  const onHandleUpdateStatusOfaPackage = (packageId, statusId) => {
    updateStatusOfaPackage(packageId, statusId).then(res => {
      if (res.err) {
        return;
      } else {
        onHandleGetPackages(selectedBuyer.buyerCompanyId);
      }
    });
  };

  const onPendingManualProcumentItemsModalClose = () => {
    setIsPendingManualProcumentItems(false);
  };

  const onHandleGetPackages = buyerCompanyId => {
    if (isNormalUnloading) {
      getUnloadPackages(
        selectedVehicle?.id,
        buyerCompanyId,
        false,
        selectedDeliverySlot?.id,
        formatDate(deliveryDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      )
        .then(res => {
          if (res.message) {
            setIsDataAvailable(false);
          } else {
            setOrderList(res?.res);
            setIsDataAvailable(res?.res.length > 0);
          }
        })
        .catch(() => {
          setIsDataAvailable(false);
        });
    } else {
      getUnloadPackages(
        selectedVehicle?.id,
        buyerCompanyId,
        true,
        selectedDeliverySlot?.id,
        formatDate(deliveryDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      )
        .then(res => {
          if (res.message) {
            setIsDataAvailable(res?.res.length > 0);
          } else {
            setOrderList(res?.res);
            setIsDataAvailable(res?.res.length > 0);
          }
        })
        .catch(() => {
          setIsDataAvailable(false);
        });
    }
  };

  const getOrderStatus = statusId => {
    switch (statusId) {
      case PACKAGING_STATUS.LOADED.id:
        return PACKAGING_STATUS.LOADED.value;
      case PACKAGING_STATUS.UNLOADED.id:
        return PACKAGING_STATUS.UNLOAD_SCANNED.value; //according to the requirement unloaded scanned and unloaded is showing in same color
      case PACKAGING_STATUS.UNLOAD_SCANNED.id:
        return PACKAGING_STATUS.UNLOAD_SCANNED.value;
    }
  };
  useEffect(() => {
    if (orderList?.length > 0) {
      let nonManualItems = [];
      orderList.forEach(order => {
        if (!order.manualPackage) {
          nonManualItems.push(order);
        }
      });
      let enableComplete = true;
      for (let order of nonManualItems) {
        if (order.packageStatus !== PACKAGING_STATUS.UNLOAD_SCANNED.id) {
          enableComplete = false;
          break;
        }
      }

      if (
        (nonManualItems?.length === 0 ||
          nonManualItems?.filter(
            item => item.packageStatus === PACKAGING_STATUS.UNLOADED.id,
          ).length === nonManualItems?.length) &&
        orderList?.length > 0
      ) {
        if (
          orderList?.filter(
            item => item.packageStatus === PACKAGING_STATUS.UNLOAD_SCANNED.id,
          ).length > 0
        ) {
          enableComplete = true;
        } else {
          enableComplete = false;
        }
      }
      setDisableCompleteBtn(!enableComplete);
    }
  }, [orderList]);

  return (
    <div className="unloading-container">
      {error && <small className="error-message mt-1">{error}</small>}
      <div className="orders">
        <ul className="nav nav-pills justify-content-center tab">
          <li
            className={`nav-item normal-unloading-tab ${
              isNormalUnloading ? ' active-tab' : 'non-active-tab'
            }`}
            onClick={() => {
              setSelectedBayer(null);
              setIsNormalUnloading(true);
              setOrderList(null);
              setIsDataAvailable(true);
            }}
          >
            <a className="nav-link" aria-current="page" href="#">
              {t('NORMAL_UNLOADING')}
            </a>
            {isNormalUnloading && <div className="active-traingle"></div>}
          </li>
          <li
            className={`nav-item manual-procument-tab ${
              !isNormalUnloading ? ' active-tab' : 'non-active-tab'
            }`}
            onClick={() => {
              setSelectedBayer(null);
              setIsNormalUnloading(false);
              setOrderList(null);
              setIsDataAvailable(true);
            }}
          >
            <a className="nav-link" href="#">
              {t('MP_UNLOADING')}
            </a>
            {!isNormalUnloading && <div className="active-traingle"></div>}
          </li>
        </ul>

        <CustomDropdown
          parentClass={
            isMobile
              ? 'col-lg-6 col-sm-12 px-0 mb-3'
              : 'col-lg-6 col-sm-12 mb-3'
          }
          toggleClass={selectedBuyer === null ? 'grey-text' : 'black-text'}
          toggleText={
            selectedBuyer === null
              ? t('BUYER_NAME')
              : `${selectedBuyer.buyerCompanyName}`
          }
          dropDownText={data => {
            return `${data.buyerCompanyName}`;
          }}
          onClickListItem={item => {
            setError('');
            setSelectedBayer(item);
            onHandleGetPackages(item.buyerCompanyId);
          }}
          drodDownList={buyerOptions}
        />

        {selectedBuyer && !isDataAvailable && !loading && (
          <div className="py-5 custom-empty-message">
            <p>{t('NO_ORDERS_AVAILABLE')}</p>
          </div>
        )}
        {selectedBuyer && orderList?.length > 0 && (
          <div className="tableFixHead">
            <Table bordered>
              <thead>
                <tr>
                  <th className="head th-rank">{t('RANK')}</th>
                  <th className="head th-buyer-name">{t('BUYER_NAME')}</th>
                  <th className="head th-tag-number">{t('TAG_NUMBER')}</th>
                  <th
                    className={`head th-primary-packages  ${isNormalUnloading &&
                      'border-right'}`}
                  >
                    {t('PRIMARY_PACKAGES')}
                  </th>
                  {isNormalUnloading && (
                    <th className="head th-manual-proc"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {orderList?.map((item, index) => (
                  <tr
                    className={`${getOrderStatus(
                      item.packageStatus,
                    )} ${item.manualPackage && 'manualProcument'}`}
                    key={index}
                  >
                    <td style={{ cursor: 'default' }} className="td-rank">
                      {item.rank}
                    </td>
                    <td
                      style={{ cursor: 'default' }}
                      className="td-buyer-name"
                      onClick={() => setIsShowBuyerName(!isShowBuyerName)}
                      onBlur={() => setIsShowBuyerName(false)}
                    >
                      <span className="buyerName" id="buyerName">
                        {/* {item.buyerName.length > 12 && isMobile
                          ? item.buyerName.substring(0, 12) + '...'
                          : item.buyerName} */}
                        {isMobile ? (
                          item.buyerName.length > 6 ? (
                            <BuyerName
                              buyerName={item.buyerName}
                              index={index}
                            />
                          ) : (
                            item.buyerName
                          )
                        ) : (
                          item.buyerName
                        )}{' '}
                      </span>
                      {/*
                      <Tooltip
                        style={{ width: '40px' }}
                        placement="top"
                        isOpen={isShowBuyerName}
                        autohide={false}
                        target="buyerName"
                        toggle={() => setIsShowBuyerName(!isShowBuyerName)}
                      >
                        {item.buyerName}
                      </Tooltip>
                        */}
                      <span className="d-sm-none td-rank-color">
                        <br />
                        Rank {item.rank}
                      </span>
                    </td>
                    <td style={{ cursor: 'default' }} className="td-tag-number">
                      <span className="d-sm-none" style={{ color: 'black' }}>
                        Tag No.{' '}
                      </span>
                      {item.tagNumber}
                    </td>
                    <td
                      style={{ cursor: 'default' }}
                      className={`td-primary-packages ${isNormalUnloading &&
                        'border-right'}`}
                    >
                      {item.pimaryPackage}
                    </td>
                    {isNormalUnloading && (
                      <td className="td-manual-proc text-center">
                        {item.manualPackage && <>&#9733;</>}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {/* {isShowStatusInfo && <StatusContainer />} */}
        {isModalOpen && (
          <AlreadyScannedModal
            qrCode={selectedOrder?.packageQRCode}
            setSelectedOrder={setSelectedOrder}
            setIsModalOpen={setIsModalOpen}
            isCompletedConsolidatedScan={true}
            isCompletedItemCountScan={false}
          />
        )}
        <CustomModal
          isOpen={isStartScan}
          toggle={modalToggle}
          isScrollable={true}
          title="QR Scanner"
          modalstyleClassName="modal-style"
          modalContentContainer="modal-container"
          modalHeaderClassName="modal-header"
          modalheaderTopic="modal-header-topic"
          modalBodyClassName="small"
        >
          <Scanner setStartScan={setIsStartScan} onHandleScan={onHandleScan} />
        </CustomModal>
        {orderList?.length > 0 && (
          <div className="button-container">
            <Row className="d-flex justify-content-center">
              <Button
                style={{ width: '40px' }}
                className="warning-icon-button"
                onClick={() => setIsShowStatusInfo(!isShowStatusInfo)}
                onBlur={() => setIsShowStatusInfo(false)}
              >
                <img
                  src={warning}
                  width="28"
                  height="28"
                  className="warning-icon"
                  id="statusContainer"
                />
                <Tooltip
                  placement="top"
                  isOpen={isShowStatusInfo}
                  autohide={false}
                  target="statusContainer"
                  toggle={() => setIsShowStatusInfo(!isShowStatusInfo)}
                >
                  <StatusContainer />
                </Tooltip>
              </Button>
            </Row>
            <Row>
              <Col md={8} sm={0}></Col>
              <Col md={2} sm={12}>
                <Button
                  className="btn-outline-primary"
                  onClick={() => setIsStartScan(!isStartScan)}
                >
                  {t('SCAN')}
                </Button>
              </Col>
              <Col md={2} sm={12}>
                <Button
                  className="btn-primary"
                  disabled={disableCompleteBtn}
                  onClick={() => onHandleComplete()}
                >
                  {t('COMPLETE')}
                </Button>
              </Col>
            </Row>
          </div>
        )}

        <CustomModal
          isOpen={isPendingManualProcumentItems}
          toggle={onPendingManualProcumentItemsModalClose}
          isScrollable={true}
          enableFooter={false}
          modalstyleClassName="modal-style"
          modalContentContainer="modal-container"
          modalHeaderClassName="modal-header"
          modalheaderTopic="modal-header-topic m-auto"
          modalFooterClassName="modal-footer"
          modalBodyClassName="small"
        >
          <>
            <p className="dispatch-modal my-4">
              {t(
                'ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITHOUT_THE_MANUAL_PROCUMENT_ITEMS',
              )}
            </p>
            <div className="d-flex justify-content-around mx-5">
              <Button
                outline
                color="primary"
                type="submit"
                className="form-submit  w-40"
                onClick={() => {
                  onPendingManualProcumentItemsModalClose();
                  onHandleCompleteStatusUpdate();
                }}
              >
                {t('YES')}
              </Button>
              <Button
                color="primary"
                type="submit"
                className="form-submit w-40"
                onClick={() => onPendingManualProcumentItemsModalClose()}
              >
                {t('NO')}
              </Button>
            </div>
          </>
        </CustomModal>
      </div>
      <audio className="d-none" id="qrScannerAudioControl" controls>
        <source src={successMp3} type="audio/mpeg" />
        <source src={successOgg} type="audio/ogg" />
        <source src={successWav} type="audio/wav" />
      </audio>
    </div>
  );
}

Orders.propTypes = {
  selectedVehicle: PropTypes.object,
  setSelectedVehicle: PropTypes.func,
  selectedBaseName: PropTypes.object,
  selectedDeliverySlot: PropTypes.object,
  deliveryDate: PropTypes.object,
};

export default Orders;
