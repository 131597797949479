import React, { useEffect, useState, useContext } from 'react';
import InnerBody from '../../../../content/InnerBody';
import SupplierOrderHistory from './SupplierOrderHistory';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useDeviceDetect } from '../../../../shared/hooks';
import SupplierPaymentHistory from './SupplierPaymentHistory';
import SupplierDetailInvoiceView from './SupplierDetailInvoiceView';
import { CustomModal } from '../../../../shared/components';
import { SupplierOrderPaymentHistoryContext } from '../../../../apis/supplier/SupplierOrderPaymentHistory';

const tabs = {
  orderHistory: 'orderHistory',
  orderPayment: 'orderPayment',
};
function SupplierOrderAndPaymentHistory() {
  const [stage, setStage] = useState('Step1');
  const [activeTab, setActiveTab] = useState(tabs.orderHistory);
  const { isMobile } = useDeviceDetect();
  const [selectedOrder, setSelectedOrder] = useState(null);
  useEffect(() => {}, [isMobile]);
  const supplierOrderContext = useContext(SupplierOrderPaymentHistoryContext);
  const {
    getSupplierOrderHistory,
    getSupplierPaymentHistory,
  } = supplierOrderContext;
  const { supplierOrderHistoryResponse } = supplierOrderContext.state;
  const toggleTabs = tab => {
    if (tab === tabs.orderHistory) {
      getSupplierOrderHistory();
    } else {
      getSupplierPaymentHistory();
    }
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [error, setError] = useState({ title: null, description: null });
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const toggleErrorPopup = () => {
    setError(null);
    setErrorPopupModal(!errorPopupModal);
  };

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={error?.title || ''}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {error?.description}
    </CustomModal>
  );
  return (
    <InnerBody
      topic={
        stage === 'Step2'
          ? 'Select invoices to initiate a payment'
          : stage === 'Step3'
          ? 'Invoice'
          : 'Order And Payment History'
      }
      isDisable={stage === 'Step1'}
      onHandleBack={() => {
        setStage('Step1');
        if (tabs.orderHistory === activeTab) {
          if (!supplierOrderHistoryResponse?.res?.purchaseOrders?.length > 0) {
            return;
          }
          setTimeout(() => {
            var rows = document.querySelectorAll('tr');
            const line = supplierOrderHistoryResponse?.res?.purchaseOrders?.findIndex(
              order =>
                order.supplierInvoiceId === selectedOrder?.supplierInvoiceId,
            );

            rows[line].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }, 1000);
        }
      }}
    >
      {errorPopup()}
      <div className="supplier-order-payment-history">
        {stage === 'Step1' && (
          <div>
            <Nav tabs className="supplier-order-payment-tab">
              <NavItem
                className={tabs.orderHistory === activeTab ? 'active-tab' : ''}
              >
                <div className="triangle-down"></div>
                <NavLink
                  onClick={() => {
                    toggleTabs(tabs.orderHistory);
                  }}
                >
                  Order History
                </NavLink>
              </NavItem>
              <NavItem
                className={tabs.orderPayment === activeTab ? 'active-tab' : ''}
              >
                <div className="triangle-down"></div>
                <NavLink
                  onClick={() => {
                    toggleTabs(tabs.orderPayment);
                  }}
                >
                  Payment History
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={tabs.orderHistory}>
                <SupplierOrderHistory
                  isMobile={isMobile}
                  onClickOrder={e => {
                    setSelectedOrder(e);
                    setStage('Step3');
                  }}
                />
              </TabPane>
              <TabPane tabId={tabs.orderPayment}>
                {tabs.orderPayment == activeTab && (
                  <SupplierPaymentHistory
                    setError={setError}
                    setErrorPopupModal={setErrorPopupModal}
                    isMobile={isMobile}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>
        )}
        {stage === 'Step3' && (
          <SupplierDetailInvoiceView
            order={selectedOrder}
            isMobile={isMobile}
            redirectBack={() => setStage('Step1')}
          />
        )}
      </div>
    </InnerBody>
  );
}

export default SupplierOrderAndPaymentHistory;
