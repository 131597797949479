import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Col, Row } from 'reactstrap';

function StatusContainer() {
  const { t } = useTranslation();

  const statusArray = [
    {
      name: 'Loaded',
      text: t('ON_THE_WAY'),
    },
    {
      name: 'UnloadScanned',
      text: t('GOODS_DROPPED'),
    },
    {
      name: 'manualProcument',
      text: t('CONFIRMATION_PENDING'),
    },
  ];

  return (
    <div className="unloading-container">
      {/* <Row> */}
      {statusArray.map(status => (
        <p className="d-flex flex-row align-items-center" key={status.name}>
          {status.text} &nbsp;&nbsp;&nbsp;&nbsp;:
          <div
            style={{ width: '10px', height: '10px' }}
            className={`mx-1 ${status.name}`}
          ></div>
        </p>
      ))}
      {/* </Row> */}
    </div>
  );
}

export default StatusContainer;
