import React, { useState, useContext } from 'react';
import InnerBody from '../../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import VehicleList from './VehicleList';
import Orders from './Orders';
import { LoadingContext } from '../../../../../apis/LoadingContext';
function UnLoading() {
  const { t } = useTranslation();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [deliverySlotOptions, setDeliverySlotOptions] = useState([]);
  const [baseNameOptions, setBaseNameOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);

  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  return (
    <InnerBody
      topic={t('UNLOADING')}
      isDisable={selectedVehicle === null}
      onHandleBack={() => setSelectedVehicle(null)}
    >
      {!selectedVehicle ? (
        <VehicleList
          loading={loading}
          setSelectedVehicle={setSelectedVehicle}
          deliverySlotOptions={deliverySlotOptions}
          setDeliverySlotOptions={setDeliverySlotOptions}
          baseNameOptions={baseNameOptions}
          setBaseNameOptions={setBaseNameOptions}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedDeliverySlot={selectedDeliverySlot}
          setSelectedDeliverySlot={setSelectedDeliverySlot}
          selectedBaseName={selectedBaseName}
          setSelectedBaseName={setSelectedBaseName}
          vehicleList={vehicleList}
          setVehicleList={setVehicleList}
        />
      ) : (
        <Orders
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
          selectedDeliverySlot={selectedDeliverySlot}
          selectedBaseName={selectedBaseName}
          deliveryDate={selectedDate}
        />
      )}
    </InnerBody>
  );
}

export default UnLoading;
