import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function SupplierHome() {
  const navigate = useNavigate();
  return (
    <div className="home-container supplier-home-container">
      <div className="home-topic">
        <p className="home-main-topic">Distribution Redefined</p>
        <p className="home-sub-topic">Efficient | Reliable | Sustainable</p>
      </div>
      <Button
        className="home-btn"
        onClick={() => navigate('/supplier/order-fulfillment')}
      >
        START
      </Button>
    </div>
  );
}

export default SupplierHome;
