import session from './session';
const Connection = (function() {
  const errorMessage =
    'Connection config not set, please call config(services, headers)';

  const config = (services, headers) => {
    ServiceHeaders = headers;
    Services = services;
  };

  var ServiceHeaders;
  var Services;

  const content = response => {
    if (response === 204) {
      return {};
    }
    return response.json();
  };

  const errored = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  const headers = () => {
    if (!ServiceHeaders) {
      throw errorMessage;
    }
    // Allow the use of a function to derive headers (must be a Headers object)
    if (typeof ServiceHeaders === 'function') {
      return ServiceHeaders();
    }
    const h = {};
    var keys = Object.keys(ServiceHeaders);

    keys.forEach(key => {
      var val = ServiceHeaders[key];
      // Allow the use of a function to derive a header (must return string)
      if (typeof val === 'function') {
        val = ServiceHeaders[key]();
      }
      if (val !== null) {
        h[key] = val;
      }
    });
    return h;
  };

  const baseUrl = service => {
    if (!Services) {
      throw new Error(errorMessage);
    }
    if (!service) {
      throw new Error('parameter is null');
    }
    var BaseUrl = Services[service];
    if (!BaseUrl) {
      throw new Error(`'${service}' not found in service list`);
    }
    // Allow the use of a function to derive base URL (must return string)
    if (typeof BaseUrl === 'function') {
      return BaseUrl(service);
    }
    return BaseUrl;
  };

  const getTokenBaseUrl = service => {
    const user = session.getUser();
    if (!Services) {
      throw new Error(errorMessage);
    }
    if (!service) {
      throw new Error('parameter is null');
    }
    if (!user) {
      throw new Error('user is null');
    }
    var BaseUrl = `${Services[service]}${user.username}/`;
    if (!BaseUrl) {
      throw new Error(`'${service}' not found in service list`);
    }
    // Allow the use of a function to derive base URL (must return string)
    if (typeof BaseUrl === 'function') {
      return BaseUrl(service);
    }
    return BaseUrl;
  };
  return {
    content: content,
    config: config,
    baseUrl: baseUrl,
    headers: headers,
    getTokenBaseUrl: getTokenBaseUrl,
    errored: errored,
  };
})();

export default Connection;
