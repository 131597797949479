import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
//import { Validate } from '@myie/interact';
//import { ContentType } from '../HO_components/contentManaged';

/**
 * Errors component
 * will display error message
 */
const LocalPagination = props => {
  const _renderPaginationBody = (pagesCount, currentPage, handlePageClick) => {
    if (pagesCount <= 5) {
      return [...Array(pagesCount)].map((page, i) => (
        <PaginationItem active={i === currentPage} key={i}>
          <PaginationLink
            className={`Square ${
              i === currentPage
                ? 'primary-background-color'
                : 'primary-text-color'
            }`}
            onClick={e => handlePageClick(e, i)}
          >
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      ));
    }
    return _renderLargePaginationBody(currentPage, handlePageClick);
  };

  const _renderLargePaginationBody = (currentPage, handlePageClick) => {
    const output = [];
    const offset = currentPage >= 5 ? currentPage - 5 + 1 : 0;
    for (let i = 0; i < 5; i++) {
      output.push(
        <PaginationItem active={i + offset === currentPage} key={i + offset}>
          <PaginationLink
            className={`Square ${
              i + offset === currentPage
                ? 'primary-background-color'
                : 'primary-text-color'
            }`}
            onClick={e => handlePageClick(e, i + offset)}
          >
            {i + offset + 1}
          </PaginationLink>
        </PaginationItem>,
      );
    }
    return output;
  };
  return (
    <Pagination
      className="local-pagination"
      aria-label="Page navigation example"
    >
      <PaginationItem disabled={props.currentPage <= 0}>
        <PaginationLink
          onClick={e => props.handlePageClick(e, props.currentPage - 1)}
          previous={true}
          className="primary-text-color "
          id="pagination-backward"
        />
      </PaginationItem>
      {_renderPaginationBody(
        props.pagesCount,
        props.currentPage,
        props.handlePageClick,
      )}
      <PaginationItem disabled={props.currentPage >= props.pagesCount - 1}>
        <PaginationLink
          onClick={e => props.handlePageClick(e, props.currentPage + 1)}
          next={true}
          className="primary-text-color"
          id="pagination-forward"
        />
      </PaginationItem>
    </Pagination>
  );
};

LocalPagination.propTypes = {
  /**
   * Input validation
   */
  validation: PropTypes.object,
  contentValue: PropTypes.any,
  handlePageClick: PropTypes.any,
  currentPage: PropTypes.any,
  pagesCount: PropTypes.any,
  messages: PropTypes.any,
};

export default LocalPagination;
//export { HasErrors };
