const SupplierRoutes = [
  {
    path: '/order-fulfillment',
    name: 'Order FulFillment',
    icon: 'orderfulfillmentIcon',
    layout: '/supplier',
    children: [],
  },
  {
    path: '/inventory-updates',
    name: 'Inventory Management',
    icon: 'inventoryupdatesIcon',
    layout: '/supplier',
    children: [],
  },
  {
    path: '/order-payment-history',
    name: 'Order And Payment History',
    icon: 'orderpaymenthistoryIcon',
    layout: '/supplier',
    children: [],
  },
];
export default SupplierRoutes;
