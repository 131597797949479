import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '../../../../shared/components';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';
function SupplierPaymentInvoiceRow({
  index,
  isMobile,
  item,
  onCheckedRow,
  selectedInvoices,
}) {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (selectedInvoices?.length > 0) {
      setIsChecked(selectedInvoices?.includes(item?.invoiceId));
    } else {
      setIsChecked(false);
    }
  }, [selectedInvoices]);

  return (
    <>
      {!isMobile ? (
        <>
          <tr key={index}>
            <td>{item?.buyerName}</td>
            <td className="blue-color text-right">
              {commafy(RoundNum(item?.payableAmount, 2))}
            </td>
            <td className="blue-color text-right">
              {commafy(RoundNum(item?.totalAmount, 2))}
            </td>
            <td className="text-center">
              <CheckBox
                groupClassName="check-box"
                id={`add-payable-${index}`}
                field={`addPayable${index}`}
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                  onCheckedRow(item);
                }}
              />
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr key={index}>
            <td className="d-flex">
              <div style={{ width: '50%' }}>
                <small className="d-block p-0  pb-1">Buyer Name</small>
                <small className="d-block p-0">{item?.buyerName}</small>
              </div>
              <div style={{ width: '30%' }}>
                <small className="d-block p-0 pb-1">Pay. t Sup</small>
                <small className="d-block  p-0 blue-color">
                  {commafy(RoundNum(item?.payableAmount, 2))}
                </small>
              </div>
              <div style={{ width: '20%' }}>
                <small className="d-block p-0  pb-1">Tot.</small>
                <small className="d-block  p-0 blue-color">
                  {item?.totalAmount}
                </small>
                <small className="d-block" style={{ textAlign: 'right' }}>
                  <CheckBox
                    groupClassName="check-box"
                    id={`add-payable-${index}`}
                    field={`addPayable${index}`}
                    checked={isChecked}
                    onChange={() => {
                      setIsChecked(!isChecked);
                      onCheckedRow(item);
                    }}
                  />
                </small>
              </div>
            </td>
          </tr>
        </>
      )}
    </>
  );
}
SupplierPaymentInvoiceRow.propTypes = {
  isMobile: PropTypes.bool,
  item: PropTypes.any,
  index: PropTypes.any,
  onCheckedRow: PropTypes.func,
  selectedInvoices: PropTypes.any,
};
export default SupplierPaymentInvoiceRow;
