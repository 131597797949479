import React, { useState } from 'react';
import InnerBody from '../../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import ClientDetailsOfVehicle from './ClientDetailsOfVehicle';
import QualityCheckDueBuyers from './QualityCheckDueBuyers';
import DetailViewOfOrder from './DetailViewOfOrder';

function BuyerQualityCheckManagement() {
  const { t } = useTranslation();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [deliverySlotOptions, setDeliverySlotOptions] = useState([]);
  const [baseNameOptions, setBaseNameOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);

  const onHandleBack = () => {
    if (selectedPurchaseOrder) {
      setSelectedPurchaseOrder(null);
    } else {
      setSelectedVehicle(null);
    }
  };

  return (
    <InnerBody
      topic={
        !selectedVehicle
          ? t('BUYER_QUALITY_CHECK_OVERVIEW')
          : !selectedPurchaseOrder
          ? t('BUYER_QUALITY_CHECK_VEHICLE')
          : t('BUYER_QUALITY_CHECK')
      }
      isDisable={selectedVehicle === null}
      onHandleBack={() => onHandleBack()}
    >
      <div className="buyer-quality-check-management-container">
        {!selectedVehicle ? (
          <ClientDetailsOfVehicle
            setSelectedVehicle={setSelectedVehicle}
            deliverySlotOptions={deliverySlotOptions}
            setDeliverySlotOptions={setDeliverySlotOptions}
            baseNameOptions={baseNameOptions}
            setBaseNameOptions={setBaseNameOptions}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedDeliverySlot={selectedDeliverySlot}
            setSelectedDeliverySlot={setSelectedDeliverySlot}
            selectedBaseName={selectedBaseName}
            setSelectedBaseName={setSelectedBaseName}
            vehicleList={vehicleList}
            setVehicleList={setVehicleList}
          />
        ) : !selectedPurchaseOrder ? (
          <QualityCheckDueBuyers
            selectedVehicle={selectedVehicle}
            setSelectedPurchaseOrder={setSelectedPurchaseOrder}
            selectedDate={selectedDate}
            selectedDeliverySlot={selectedDeliverySlot}
            selectedBaseName={selectedBaseName}
            setSelectedVehicle={setSelectedVehicle}
          />
        ) : (
          <DetailViewOfOrder
            selectedPurchaseOrder={selectedPurchaseOrder}
            setSelectedPurchaseOrder={setSelectedPurchaseOrder}
          />
        )}
      </div>
    </InnerBody>
  );
}

export default BuyerQualityCheckManagement;
