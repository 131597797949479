import React, { useContext, useEffect } from 'react';
import BrowsePageTemplate from '../shared/BrowsePageTemplate';
import PropTypes from 'prop-types';
import { BuyerContext } from '../../../../apis/buyer';
import { BROWSE_PAGE_RADIO_BUTTON_VALUE } from './constants/constants';
import moment from 'moment';
import { useDeviceDetect } from '../../../../shared/hooks';
function BrowseProduct(props) {
  const {
    checkedValue,
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    selectedDate,
    selectedDeliverySlot,
    currentStep,
    allCategories,
    setAllCategories,
    parentCategories,
    setParentCategories,
    subCategories,
    setSubCategories,
    productList,
    setProductList,
    setCurrentStep,
    isItemsAvailable,
    setIsItemsAvailable,
    setCurrentStepInBrowseProduct,
    currentStepInBrowseProduct,
    isSearchingOrFiltering,
    setIsSearchingOrFiltering,
    searchedProductList,
    setSearchedProductList,
    selectedFilterCategories,
    setSelectedFilterCategories,
    searchTerm,
    setSearchTerm,
  } = props;
  const buyerContext = useContext(BuyerContext);
  const {
    getSillaraCategoriyList,
    getBuyerProductCategoryList,
    getSillaraProductList,
    getBuyerProductList,
    getSillaraProductListSearchSuggestion,
    getBuyerProductSearchSuggestion,
    setSearchSuggestionResponse,
  } = buyerContext;
  const { buyerSearchSuggestionResponse } = buyerContext.state;
  const { isMobile } = useDeviceDetect();
  useEffect(() => {
    if (isMobile) {
      const innerBodyContainer = document.getElementsByClassName(
        'inner-body-container',
      )[0];
      innerBodyContainer.style.overflowY = 'hidden';

      return () => {
        innerBodyContainer.style.overflowY = 'auto';
      };
    }
  }, [isMobile]);

  useEffect(() => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setProductList([]);
    setIsItemsAvailable(true);
    setCurrentStepInBrowseProduct(1);
    setIsSearchingOrFiltering(false);
    setSearchedProductList([]);
    setSearchTerm('');
    if (!selectedDate || !selectedDeliverySlot) {
      setCurrentStep(0);
    }
    if (currentStepInBrowseProduct == 1) {
      if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.ALL_ITEMS) {
        getSillaraCategoriyList().then(res => {
          if (res.err) {
            return;
          } else {
            setAllCategories(res.res);
            getCategories(res.res);
          }
        });
      } else if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.YOUR_ITEMS) {
        getBuyerProductCategoryList().then(res => {
          if (res.err) {
            return;
          } else {
            setAllCategories(res.res);
            getCategories(res.res);
          }
        });
      }
    }
  }, [checkedValue]);

  const getCategories = categories => {
    setIsSearchingOrFiltering(false);
    setCurrentStepInBrowseProduct(1);
    const tempAllCategories = [...categories].filter(
      category => category.parentCategoryId == null,
    );
    setIsItemsAvailable(tempAllCategories.length > 0);
    setParentCategories(tempAllCategories);
  };

  const getSubCategories = parentCategory => {
    setIsSearchingOrFiltering(false);
    setCurrentStepInBrowseProduct(2);
    let tempAllCategories = [...allCategories];
    const tempSubCategories = tempAllCategories.filter(
      category => category.parentCategoryId == parentCategory.id,
    );
    setSubCategories(tempSubCategories);
    if (tempSubCategories.length == 0)
      handleBrowseProductList(parentCategory, '');
  };

  const handleBrowseProductList = (productCatagory, SearchString) => {
    setSelectedFilterCategories([productCatagory]);
    setCurrentStepInBrowseProduct(3);
    setIsSearchingOrFiltering(false);
    if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.ALL_ITEMS) {
      getSillaraProductList(
        productCatagory.id,
        SearchString,
        moment(selectedDate).format('DD/MM/YYYY'),
        selectedDeliverySlot?.endTime,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          setIsItemsAvailable(res.res.length > 0);
          setProductList(res.res);
        }
      });
    } else if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.YOUR_ITEMS) {
      getBuyerProductList(
        productCatagory.id,
        SearchString,
        moment(selectedDate).format('DD/MM/YYYY'),
        selectedDeliverySlot?.endTime,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          setIsItemsAvailable(res.res.length > 0);
          setProductList(res.res);
        }
      });
    }
  };

  const handleSearchedBrowseProductList = (ProductCatagoryId, SearchString) => {
    setCurrentStepInBrowseProduct(4);
    setIsSearchingOrFiltering(true);
    if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.ALL_ITEMS) {
      getSillaraProductList(
        ProductCatagoryId,
        SearchString,
        moment(selectedDate).format('DD/MM/YYYY'),
        selectedDeliverySlot?.endTime,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          setIsItemsAvailable(res.res.length > 0);
          setSearchedProductList(res.res);
        }
      });
    } else if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.YOUR_ITEMS) {
      getBuyerProductList(
        ProductCatagoryId,
        SearchString,
        moment(selectedDate).format('DD/MM/YYYY'),
        selectedDeliverySlot?.endTime,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          setIsItemsAvailable(res.res.length > 0);
          setSearchedProductList(res.res);
        }
      });
    }
  };

  const onSearchProductSuggestions = searchStr => {
    if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.ALL_ITEMS) {
      getSillaraProductListSearchSuggestion(
        selectedFilterCategories?.[0]?.id || '',
        searchStr,
        moment(selectedDate).format('DD/MM/YYYY'),
        selectedDeliverySlot?.endTime,
      );
    } else if (checkedValue === BROWSE_PAGE_RADIO_BUTTON_VALUE.YOUR_ITEMS) {
      getBuyerProductSearchSuggestion(
        selectedFilterCategories?.[0]?.id || '',
        searchStr,
        moment(selectedDate).format('DD/MM/YYYY'),
        selectedDeliverySlot?.endTime,
      );
    }
  };

  const onInputSearch = event => {
    setSearchTerm(event.target.value);
    if (event?.target?.value?.length < 3) {
      return;
    }
    onSearchProductSuggestions(event.target.value);
  };

  const removeSearchSuggestion = () => {
    setSearchSuggestionResponse({ res: null, err: null });
  };

  const handleOnSelect = selectedItem => {
    setSearchTerm('');
    setSelectedFilterCategories(selectedItem);
    setIsSearchingOrFiltering(true);
    handleSearchedBrowseProductList(selectedItem?.[0]?.id || '', '');
  };

  const onSearchProducts = e => {
    if (
      selectedFilterCategories?.[0]?.id !== '' &&
      !selectedFilterCategories?.[0]?.id &&
      (e === '' || e === null)
    ) {
      return;
    }
    setSearchTerm(e);
    handleSearchedBrowseProductList(selectedFilterCategories?.[0]?.id || '', e);
    removeSearchSuggestion();
  };

  return (
    <div className="browse-product" onClick={() => removeSearchSuggestion()}>
      {!isSearchingOrFiltering &&
      !selectedCategory &&
      productList.length === 0 &&
      searchedProductList.length == 0 ? (
        <BrowsePageTemplate
          items={parentCategories}
          setSelectedCategory={setSelectedCategory}
          isProductList={false}
          getSubCategories={getSubCategories}
          selectedDeliverySlot={selectedDeliverySlot}
          selectedDate={selectedDate}
          currentStep={currentStep}
          selectedValues={null}
          onSelectFilter={handleOnSelect}
          filterCategories={allCategories}
          dropDownPlaceholder={'Select a Category'}
          dropdownDisplayValue="name"
          onSearchProduct={onSearchProducts}
          onChangeSearch={onInputSearch}
          searchTerm={searchTerm}
          buyerSearchSuggestionResponse={buyerSearchSuggestionResponse}
          isItemsAvailable={isItemsAvailable}
          isSearchingOrFiltering={isSearchingOrFiltering}
        />
      ) : !isSearchingOrFiltering &&
        !selectedSubCategory &&
        subCategories.length > 0 &&
        productList.length === 0 &&
        searchedProductList.length == 0 ? (
        <BrowsePageTemplate
          items={subCategories}
          onSelectFilter={handleOnSelect}
          filterCategories={allCategories}
          onSearchProduct={onSearchProducts}
          onChangeSearch={onInputSearch}
          searchTerm={searchTerm}
          buyerSearchSuggestionResponse={buyerSearchSuggestionResponse}
          dropDownPlaceholder={'Select a Category'}
          dropdownDisplayValue="name"
          selectedValues={{ name: selectedCategory?.name }}
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedCategory={setSelectedSubCategory}
          isProductList={false}
          selectedDeliverySlot={selectedDeliverySlot}
          selectedDate={selectedDate}
          currentStep={currentStep}
          isItemsAvailable={isItemsAvailable}
          handleBrowseProductList={handleBrowseProductList}
          isSubCategory={true}
          isSearchingOrFiltering={isSearchingOrFiltering}
        />
      ) : !isSearchingOrFiltering ? (
        <BrowsePageTemplate
          items={productList}
          isProductList={true}
          selectedValues={{
            name: selectedSubCategory
              ? selectedSubCategory?.name
              : selectedCategory?.name,
          }}
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          selectedDeliverySlot={selectedDeliverySlot}
          selectedDate={selectedDate}
          currentStep={currentStep}
          onSelectFilter={handleOnSelect}
          filterCategories={allCategories}
          dropDownPlaceholder={'Select a Category'}
          dropdownDisplayValue="name"
          onSearchProduct={onSearchProducts}
          onChangeSearch={onInputSearch}
          searchTerm={searchTerm}
          buyerSearchSuggestionResponse={buyerSearchSuggestionResponse}
          isItemsAvailable={isItemsAvailable}
          isSearchingOrFiltering={isSearchingOrFiltering}
        />
      ) : (
        <BrowsePageTemplate
          items={searchedProductList}
          isProductList={true}
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          selectedDeliverySlot={selectedDeliverySlot}
          selectedDate={selectedDate}
          currentStep={currentStep}
          selectedValues={{
            parentCategory: selectedFilterCategories[0]?.parentCategoryName,
            name: selectedFilterCategories[0]
              ? selectedFilterCategories[0].name
              : 'Select a Category',
          }}
          onSelectFilter={handleOnSelect}
          filterCategories={allCategories}
          dropDownPlaceholder={'Select a Category'}
          dropdownDisplayValue="name"
          onSearchProduct={onSearchProducts}
          onChangeSearch={onInputSearch}
          searchTerm={searchTerm}
          buyerSearchSuggestionResponse={buyerSearchSuggestionResponse}
          isItemsAvailable={isItemsAvailable}
          isSearchingOrFiltering={true}
        />
      )}
    </div>
  );
}
BrowseProduct.propTypes = {
  checkedValue: PropTypes.string,
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  selectedSubCategory: PropTypes.object,
  setSelectedSubCategory: PropTypes.func,
  selectedDate: PropTypes.any,
  selectedDeliverySlot: PropTypes.any,
  currentStep: PropTypes.number,
  allCategories: PropTypes.array,
  setAllCategories: PropTypes.func,
  parentCategories: PropTypes.array,
  setParentCategories: PropTypes.func,
  subCategories: PropTypes.array,
  setSubCategories: PropTypes.func,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  setCurrentStep: PropTypes.func,
  buyerSearchSuggestionResponse: PropTypes.any,
  isItemsAvailable: PropTypes.bool,
  setIsItemsAvailable: PropTypes.func,
  setCurrentStepInBrowseProduct: PropTypes.func,
  currentStepInBrowseProduct: PropTypes.number,
  setIsSearchingOrFiltering: PropTypes.func,
  isSearchingOrFiltering: PropTypes.bool,
  setSearchedProductList: PropTypes.func,
  searchedProductList: PropTypes.array,
  setSelectedFilterCategories: PropTypes.func,
  selectedFilterCategories: PropTypes.array,
  setSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
};

export default BrowseProduct;
