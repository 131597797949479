import React, { useEffect } from 'react';
import { useState } from 'react';
import { CheckBox } from '../../../../shared/components';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import { S3_BUCKET_URL } from '../../../../environment';
import { pending } from '../../../assets/img';
import moment, { now } from 'moment';
function ProductCard({
  isAvailable,
  validTillDate,
  validFromDate,
  id,
  src,
  title,
  unit,
  price,
  onHandleCheck,
  isChecked = false,
  onClick,
}) {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [id, isChecked]);

  useEffect(() => {
    document
      .getElementById('delete')
      .addEventListener('touchstart', function(e) {
        e.stopPropagation();
      });
    document.getElementById('delete').addEventListener('touchend', function(e) {
      e.stopPropagation();
    });
    document
      .getElementById('delete')
      .addEventListener('mouseleave', function(e) {
        e.stopPropagation();
      });
    document
      .getElementById('delete')
      .addEventListener('mousemove', function(e) {
        e.stopPropagation();
      });
    document.getElementById('delete').addEventListener('mouseup', function(e) {
      e.stopPropagation();
    });
  }, []);

  const isPendingItem = () => {
    if (moment().isBefore(validFromDate)) return true;

    return false;
  };

  const validationCheck = () => {
    let style = {};
    isAvailable &&
    (moment().isBefore(validTillDate) ||
      moment(now()).format('YYYY-MM-DD') ==
        moment(validTillDate).format('YYYY-MM-DD')) &&
    (validFromDate
      ? moment().isAfter(validFromDate) ||
        moment(now()).format('YYYY-MM-DD') ==
          moment(validFromDate).format('YYYY-MM-DD')
      : true)
      ? (style = {})
      : isAvailable
      ? (style = { color: '#C0C0C0' })
      : (style = { color: '#C0C0C0', textDecoration: 'line-through' });

    return style;
  };

  return (
    <Card key={id}>
      <div onClick={onClick}>
        <CheckBox
          groupClassName="check-box"
          id="delete"
          field="delete"
          onChange={() => {
            setChecked(!checked);
            onHandleCheck(id);
          }}
          checked={checked}
        />
        <div className="item-img-container">
          <img
            className="item-img"
            alt="Image Not Found"
            src={S3_BUCKET_URL + 'Products/' + src}
          />
        </div>

        <CardBody>
          <CardTitle
            className="mb-3"
            tag="h5"
            title={title}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {isPendingItem() && <img width="20" src={pending} alt="Pending" />}
            {title}
          </CardTitle>
          <CardSubtitle className="my-2" tag="h6" style={validationCheck()}>
            {unit}
          </CardSubtitle>
          <CardText style={validationCheck()}>{price}</CardText>
        </CardBody>
      </div>
    </Card>
  );
}

ProductCard.propTypes = {
  id: PropTypes.any,
  validTillDate: PropTypes.any,
  validFromDate: PropTypes.any,
  isAvailable: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  price: PropTypes.string,
  onHandleCheck: PropTypes.func,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
};
export default ProductCard;
