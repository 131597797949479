import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Label, Button, Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../apis/authentication';
import { Text } from '../../../../shared';
import { Validate } from '../../../config';
import { hidePassword, showPassword } from '../../../assets/img';
import {
  COGNITO,
  COGNITO_POLICY,
} from '../../../../shared/constants/constants';
import { useNavigate } from 'react-router-dom';

const persistFormValues = (function() {
  var password = '';
  const set = value => {
    password = value;
  };
  const get = () => {
    return password;
  };

  return {
    set: set,
    get: get,
  };
})();

function OtpInput(props) {
  const { length, onResend } = props;
  const [error, setError] = useState();
  const [res, setRes] = useState();
  const [otpRequired, setOtpRequired] = useState(false);
  const { t } = useTranslation();
  const otpCodeContext = useContext(AuthContext);
  const {
    forgotPasswordSubmitRes,
    username,
    otpRequestResponse,
  } = otpCodeContext.state;
  const { forgotPasswordCode } = otpCodeContext;
  const [code, setCode] = useState(new Array(length).fill(''));
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setIsRevealConfirmPwd] = useState(false);
  const [resendClicks, setResendClicks] = useState(0);
  let navigate = useNavigate();

  const [form, setForm] = useState({
    newPassword: {
      value: '',
      title: t('NEW_PASSWORD'),
      rules: {
        title: t('ENTER_YOUR_NEW_PASSWORD'),
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
        range: {
          min: length,
          max: COGNITO_POLICY.PASSWORD_MAX_LENGTH,
          message: t('MIN_PASSWORD_LENGTH_MESSAGE', { LENGTH: length }),
        },
      },
    },
    confirmPassword: {
      value: '',
      title: t('CONFIRM_PASSWORD'),
      rules: {
        stop: true,
        title: t('CONFIRM_YOUR_PASSWORD'),
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
        compare: {
          message: t('PASSWORD_DO_NOT_MATCH'),
          field: 'password',
          comparison: persistFormValues.get,
        },
      },
    },
  });

  useEffect(() => {
    setCode(new Array(length).fill(''));
    const tempForm = form;
    tempForm.newPassword.rules.range.min = length;
    tempForm.newPassword.rules.range.message = t(
      'MIN_PASSWORD_LENGTH_MESSAGE',
      {
        LENGTH: length,
      },
    );
    setCode(new Array(length).fill(''));
    for (var i = 0; i < length; i++) {
      const tempObj = {
        value: '',
        title: '',
        rules: {
          title: '',
          stop: true,
          required: {
            message: t('THIS_IS_A_REQUIRED_FIELD'),
          },
        },
      };
      tempForm['otp' + i.toString()] = tempObj;
    }
    setForm(tempForm);
    setCode(new Array(length).fill(''));
  }, [length]);

  const handleChange = (e, key) => {
    setOtpRequired(false);
    let otpArray = [];
    code.forEach((otp, index) => {
      if (index != key) {
        otpArray.push(otp);
        return;
      }
      otpArray.push(e.target.value);
    });

    setCode(otpArray);
    const currentIndex = e.target.id[3];
    if (parseInt(currentIndex) < 6) {
      const nextElementSibling = document.getElementById(
        'otp' + (parseInt(currentIndex) + 1).toString(),
      );
      if (e?.nativeEvent?.inputType === 'insertText') {
        nextElementSibling?.focus();
      }
    }
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  useEffect(() => {
    if (
      forgotPasswordSubmitRes.err != null ||
      forgotPasswordSubmitRes.res != null
    ) {
      handleResponse();
    }
  }, [forgotPasswordSubmitRes]);

  const handleResponse = () => {
    if (forgotPasswordSubmitRes?.err) {
      const { err } = forgotPasswordSubmitRes;
      if (
        err?.includes(COGNITO.INVALID_OTP_CODE) ||
        err?.includes(COGNITO.INVALID_VERIFICATION_CODE)
      ) {
        setError(t('ENTERED_OTP_IS_INVALID'));
        return;
      }
      setError(err);
      return;
    }

    if (forgotPasswordSubmitRes.res != null) {
      navigate('/auth/login', { replace: true });
    }
  };

  useEffect(() => {
    if (otpRequestResponse.err != null) {
      if (
        otpRequestResponse.err?.includes(COGNITO.NUMBER_OF_REQUEST_EXCEEDED)
      ) {
        setError(t('NUMBER_OF_COGNITO_REQUEST_ATTEMPT_EXCEEDED'));
        return;
      }
      setError(otpRequestResponse.err);
      return;
    }
    if (otpRequestResponse.res !== null) {
      setResendClicks(prevState => prevState + 1);
      setRes('Resented Successfully');
      setTimeout(() => {
        setRes(null);
      }, 3000);
    }
  }, [otpRequestResponse]);

  const handleSubmit = e => {
    setError(null);
    e.preventDefault();
    const formValidation = Validate.form(form);
    setForm(formValidation);
    let isError = false;
    code.forEach(otp => {
      if (otp === '') {
        setOtpRequired(true);
        isError = true;
      }
    });
    if (!formValidation.approved) {
      isError = true;
    }
    if (isError) {
      return;
    }

    if (!username) {
      setError(t('USER_SESSION_WAS_EXPIRED_RESTART_AGAIN'));
      return;
    }
    forgotPasswordCode(username, code.join(''), form.newPassword.value);
  };

  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
    if (name === 'newPassword') {
      persistFormValues.set(value);
    }
  };
  const onBlur = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const handleResend = () => {
    if (!username) {
      setError(t('USER_SESSION_WAS_EXPIRED_RESTART_AGAIN'));
      return;
    }
    onResend(username);
    setCode(new Array(length).fill(''));
  };

  const otpInputItems = code.map((data, index) => (
    <Text
      className="text-center form-control rounded otp-input"
      key={index}
      field={'otp' + index.toString()}
      name={'otp' + index.toString()}
      id={'otp' + index.toString()}
      validation={form['otp' + index.toString()]}
      type="text"
      autoComplete="off"
      value={data}
      onChange={e => handleChange(e, index)}
      // tabIndex="1"
      maxLength="1"
      showErrors={false}
    />
  ));

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {res && resendClicks > 1 && (
          <small className="res-message">{res}</small>
        )}
        <Label>{t('ENTER_OTP_NUMBER_HERE')}</Label>
        <div
          id="otp-container"
          style={{ paddingLeft: '10px' }}
          className="inputs d-flex flex-row justify-content-center mt-1"
        >
          {otpInputItems}
        </div>
        {otpRequired && (
          <small className="error-message">Otp is required</small>
        )}

        <Text
          groupClassName="form-group"
          labelClassName="form-label"
          type={isRevealPwd ? 'text' : 'password'}
          label={form.newPassword.title}
          placeholder={form.newPassword.rules.title}
          autoComplete="off"
          id="new-forgot-password"
          field="newPassword"
          onChange={onChange}
          onBlur={onBlur}
          validation={form.newPassword}
          maxLength={COGNITO_POLICY.PASSWORD_MAX_LENGTH}
          rightIconClassName="auth-fa-eye"
          rightIconGroupClassName="auth-text-input-container"
          rightIcon={isRevealPwd ? showPassword : hidePassword}
          rightIconHandle={() => setIsRevealPwd(prevState => !prevState)}
        />

        <Text
          groupClassName="form-group"
          labelClassName="form-label"
          autoComplete="off"
          type={isRevealConfirmPwd ? 'text' : 'password'}
          label={form.confirmPassword.title}
          id="confirm-forgot-password"
          field="confirmPassword"
          placeholder={form.confirmPassword.rules.title}
          onChange={onChange}
          onBlur={onBlur}
          validation={form.confirmPassword}
          maxLength={COGNITO_POLICY.PASSWORD_MAX_LENGTH}
          rightIconClassName="auth-fa-eye"
          rightIconGroupClassName="auth-text-input-container"
          rightIcon={isRevealConfirmPwd ? showPassword : hidePassword}
          rightIconHandle={() => setIsRevealConfirmPwd(prevState => !prevState)}
        />

        {error !== null && <small className="error-message">{error}</small>}
        <div className="form-footer" style={{ bottom: '0' }}>
          <Button color="primary" type="submit" className="form-submit">
            {t('CONFIRM_TEXT')}
          </Button>
        </div>
      </Form>
      <small className="help">
        <Button size="sm" color="link" onClick={handleResend}>
          {' '}
          {t('RESEND_OTP')}{' '}
        </Button>
      </small>
    </div>
  );
}

OtpInput.propTypes = {
  length: PropTypes.number,
  error: PropTypes.string,
  onResend: PropTypes.func,
  username: PropTypes.string,
  setStage: PropTypes.func,
};

export default OtpInput;
