import React, { useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import PropTypes from 'prop-types';
import { dropdownCaret } from '../../../app/assets/icons';
function DropdownSelector({
  placeholder,
  filterCategories,
  onSelect,
  isMobile,
  displayValue,
  groupBy = 'parentCategoryName',
  isLargeDesktop,
  selectedValues,
  isTablet,
}) {
  useEffect(() => {
    document.getElementById('search_input').setAttribute('readonly', true);
    document
      .getElementsByClassName('icon_down_dir')?.[0]
      .classList?.add('hide-before');
    if (isMobile) {
      document.getElementsByClassName('icon_down_dir')[0].style.display =
        'none';
    }
  }, [isMobile, isLargeDesktop]);

  useEffect(() => {
    if (
      document.getElementsByClassName('groupHeading')?.[0]?.innerHTML ===
      'All Categories'
    ) {
      document.getElementsByClassName('groupHeading')[0].style.display = 'none';
      if (
        document.getElementsByClassName('groupChildEle option')?.[0]
          ?.innerHTML === 'All Categories'
      ) {
        document.getElementsByClassName('groupChildEle option')[0].style.color =
          '#000000';
        document.getElementsByClassName(
          'groupChildEle option',
        )[0].style.paddingLeft = '15px';
      }
    }
  }, [filterCategories]);
  const dropDownText = text => {
    if (isMobile) {
      return '☰';
    }
    if (isLargeDesktop) {
      if (text?.length > 20) {
        return text.substring(0, 16) + '...';
      }
      return text;
    }
    if (isTablet) {
      if (text?.length > 12) {
        return text.substring(0, 10) + '...';
      }
    }
    if (text?.length > 15) {
      return text.substring(0, 12) + '...';
    }
    return text;
  };
  return (
    <Multiselect
      displayValue={displayValue}
      groupBy={groupBy}
      selectedValueDecorator={dropDownText}
      onSelect={e => {
        onSelect(e);
      }}
      isSearchable={false}
      showArrow={false}
      customArrow={!isMobile ? <img width="10" src={dropdownCaret} /> : null}
      options={filterCategories}
      placeholder={dropDownText(placeholder)}
      hideSelectedList={false}
      style={{
        searchBox: {
          borderRight: 'none',
        },
      }}
      singleSelect={true}
      selectedValues={selectedValues ? selectedValues : []}
    />
  );
}

DropdownSelector.propTypes = {
  isMobile: PropTypes.bool,
  placeholder: PropTypes.string,
  filterCategories: PropTypes.array,
  onSelect: PropTypes.func,
  displayValue: PropTypes.string,
  groupBy: PropTypes.string,
  isLargeDesktop: PropTypes.bool,
  isTablet: PropTypes.bool,
  selectedValues: PropTypes.array,
};

export default DropdownSelector;
