import { useEffect, useState } from 'react';
import { VIEW_PORT_BREAK } from '../constants/constants';

function useDeviceDetect() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]); // Empty array ensures that effect is only run on mount
  return {
    windowSize,
    isMobile: windowSize.width <= VIEW_PORT_BREAK.MOBILE,
    isTablet:
      windowSize.width <= VIEW_PORT_BREAK.TABLET &&
      VIEW_PORT_BREAK.MOBILE < windowSize.width,
    isDesktop:
      windowSize.width <= VIEW_PORT_BREAK.DESKTOP &&
      VIEW_PORT_BREAK.TABLET < windowSize.width,
    isLargeDesktop: VIEW_PORT_BREAK.LARGE_DESKTOP <= windowSize.width,
  };
}
export default useDeviceDetect;
