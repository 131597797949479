import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
//import { ManualProcurementContext } from '../../../../../apis/admin';
import InnerBody from '../../../../../content/InnerBody';
import moment from 'moment';
import { CalendarBlue } from '../../../../assets/icons';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import OrderSorting from './OrderSorting';
import OrderLoading from './OrderLoading';
import { useDeviceDetect } from '../../../../../shared/hooks';
import OrderDetailView from './OrderDetailView';
import { ManualProcurementContext } from '../../../../../apis/admin';
import { CustomCalendar, Text } from '../../../../../shared/components';
const tabs = {
  orderSorting: 'orderSorting',
  orderLoading: 'orderLoading',
};
function ManualProcurement() {
  const [stage, setStage] = useState('Step1');
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(tabs.orderSorting);
  const { isMobile } = useDeviceDetect();
  const manualProcurementContext = useContext(ManualProcurementContext);
  const { getOrderLoadingList } = manualProcurementContext;
  const [readyForDispatchParam, setReadyForDispatchParam] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  useEffect(() => {}, [activeTab, isMobile]);

  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliverySlot, setDeliverySlot] = useState(null);
  const [baseDeliveryName, setBaseDeliveryName] = useState(null);
  const [openCalendar, setIsOpenCalendar] = useState(false);

  const [isDeliverySlot, setIsDeliverySlot] = useState(false);
  const [isBaseNameDrop, setIsBaseNameDrop] = useState(false);

  const {
    deliverySlotListRes,
    deliveryBasesListRes,
  } = manualProcurementContext.state;

  const {
    getDeliverySlotList,
    getDeliveryBasesList,
    getSortedSummaryItems,
    getSortingDetailView,
  } = manualProcurementContext;
  const [error, setError] = useState('');
  const toggleTabs = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const deliverySlotToggle = () => setIsDeliverySlot(!isDeliverySlot);
  const baseNameToggle = () => setIsBaseNameDrop(!isBaseNameDrop);

  const currentStage = stage => {
    switch (stage) {
      case 'Step1':
        return (
          <>
            <Nav tabs className="procurement-tab">
              <NavItem
                className={tabs.orderSorting === activeTab ? 'active-tab' : ''}
              >
                <div className="triangle-down"></div>
                <NavLink
                  onClick={() => {
                    toggleTabs(tabs.orderSorting);
                  }}
                >
                  {t('ORDER_SORTING')}
                </NavLink>
              </NavItem>
              <NavItem
                className={tabs.orderLoading === activeTab ? 'active-tab' : ''}
              >
                <div className="triangle-down"></div>
                <NavLink
                  onClick={() => {
                    onRefreshOrderLoading();
                    toggleTabs(tabs.orderLoading);
                  }}
                >
                  {t('ORDER_LOADING')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={tabs.orderSorting}>
                <OrderSorting
                  setQueryParam={setQueryParam}
                  isMobile={isMobile}
                  onHandleViewOrder={(e, param) => viewOrders(e, param)}
                  deliveryDate={moment(deliveryDate).format('DD/MM/YYYY')}
                  deliverySlot={deliverySlot}
                  baseDeliveryName={baseDeliveryName}
                />
              </TabPane>
              <TabPane tabId={tabs.orderLoading}>
                <OrderLoading
                  setQueryParam={setQueryParam}
                  deliveryDate={moment(deliveryDate).format('DD/MM/YYYY')}
                  deliverySlot={deliverySlot}
                  baseDeliveryName={baseDeliveryName}
                  isRefresh={isRefresh}
                  setIsRefresh={setIsRefresh}
                />
              </TabPane>
            </TabContent>
          </>
        );
      case 'Step2':
        return (
          <OrderDetailView
            readyForDispatchParam={readyForDispatchParam}
            isMobile={isMobile}
            viewOrderParam={viewOrderParam}
            setStage={setStage}
          />
        );
    }
  };
  const [viewOrderParam, setViewOrderParam] = useState(null);
  const viewOrders = (e, queryParamViewOrder) => {
    setViewOrderParam(queryParamViewOrder);
    setReadyForDispatchParam(e);
  };
  useEffect(() => {
    if (viewOrderParam) {
      setStage('Step2');
    }
  }, [viewOrderParam]);

  useEffect(() => {
    setError('');
    if (deliverySlotListRes?.res && deliveryDate) {
      if (deliverySlotListRes?.res.length === 0) {
        setError('A delivery slot is not available for the selected date.');
      }
    }
  }, [deliverySlotListRes]);
  useEffect(() => {
    setError('');
    if (deliveryBasesListRes?.res && deliverySlot) {
      if (deliveryBasesListRes?.res.length === 0) {
        setError('A base name is not available for the selected slot.');
      }
    }
  }, [deliveryBasesListRes]);

  const onRefreshOrderLoading = () => {
    getOrderLoadingList(
      setQueryParam(
        moment(deliveryDate).format('DD/MM/YYYY'),
        baseDeliveryName,
        deliverySlot,
      ),
    );
  };

  const setQueryParam = (deliDate, baseDeliName, deliSlot) => {
    let queryString = '';

    if (deliDate && moment(deliDate, 'DD/MM/YYYY').isValid()) {
      queryString = queryString + `deliveryDate=`;
      if (deliDate.length > 4) {
        queryString = queryString + `${deliDate}`;
      } else {
        queryString = queryString + deliDate;
      }
    }

    if (baseDeliName) {
      queryString = queryString + `&deliveryBaseId=`;
      queryString = queryString + `${baseDeliName?.id}`;
    }

    if (deliSlot) {
      queryString = queryString + `&deliverySlotId=`;
      queryString = queryString + `${deliSlot?.id}`;
    }

    return queryString;
  };

  return (
    <InnerBody
      topic={t('MANUAL_PROCUREMENT')}
      isDisable={stage === 'Step1'}
      isRefreshDisable={stage === 'Step1' && activeTab != tabs.orderLoading}
      onHandleBack={() => {
        setReadyForDispatchParam(null);
        setStage('Step1');
        setViewOrderParam(null);
      }}
      onRefresh={activeTab == tabs.orderLoading ? onRefreshOrderLoading : null}
    >
      {stage === 'Step1' && (
        <div className="sorting-summary my-3" style={{ minHeight: '25px' }}>
          <Row>
            <div
              style={isMobile ? { padding: '0' } : {}}
              className="col-lg-4 col-sm-12"
            >
              <Text
                type="text"
                groupClassName="delivery-date-form"
                placeholder={t('SELECT_A_DELIVERY_DATE')}
                className="delivery-date"
                value={
                  deliveryDate && moment(deliveryDate).format('DD/MM/YYYY')
                }
                rightIcon={CalendarBlue}
                id="calendar"
                field="calendar"
                rightIconHandle={() =>
                  setIsOpenCalendar(prevProps => !prevProps)
                }
                onClick={() => setIsOpenCalendar(prevProps => !prevProps)}
                rightIconGroupClassName="position-relative cursor-pointer"
                rightIconClassName="calendar-icon"
                showErrors={false}
                readOnly
              />
            </div>
            {openCalendar && (
              <CustomCalendar
                onClose={() => setIsOpenCalendar(false)}
                onChange={e => {
                  setDeliveryDate(e);
                  setDeliverySlot(null);
                  setBaseDeliveryName(null);
                  setIsOpenCalendar(false);
                  getDeliverySlotList(moment(e).format('DD/MM/YYYY'), false);
                  getSortedSummaryItems(
                    setQueryParam(moment(e).format('DD/MM/YYYY'), null, null),
                  );
                  getOrderLoadingList(
                    setQueryParam(moment(e).format('DD/MM/YYYY'), null, null),
                  );
                }}
                value={deliveryDate && new Date(deliveryDate)}
              />
            )}
            <Dropdown
              disabled={
                deliveryDate.length === 0 ||
                deliverySlotListRes?.res.length == 0
              }
              isOpen={isDeliverySlot}
              toggle={deliverySlotToggle}
              direction="down"
              className="col-lg-4 col-sm-12 mb-3"
              style={
                deliveryDate.length === 0 ||
                deliverySlotListRes?.res.length == 0
                  ? { opacity: '0.6' }
                  : {}
              }
            >
              <DropdownToggle
                caret
                className={deliverySlot === null ? 'grey-text' : 'black-text'}
              >
                {deliverySlot === null
                  ? t('SELECT_A_DELIVER_SLOT')
                  : `${deliverySlot?.startTime} - ${deliverySlot?.endTime}`}
              </DropdownToggle>
              <DropdownMenu>
                {deliverySlotListRes?.res?.map(item => (
                  <DropdownItem
                    key={item?.id}
                    onClick={() => {
                      setDeliverySlot(item);
                      setBaseDeliveryName(null);
                      getDeliveryBasesList(item?.id);
                      getSortedSummaryItems(
                        setQueryParam(
                          moment(deliveryDate).format('DD/MM/YYYY'),
                          null,
                          item,
                        ),
                      );
                      getOrderLoadingList(
                        setQueryParam(
                          moment(deliveryDate).format('DD/MM/YYYY'),
                          null,
                          item,
                        ),
                      );
                    }}
                  >
                    {`${item?.startTime} - ${item?.endTime}`}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={isBaseNameDrop}
              toggle={baseNameToggle}
              disabled={
                deliverySlot === null || deliveryBasesListRes?.res?.length === 0
              }
              direction="down"
              style={
                deliverySlot === null || deliveryBasesListRes?.res?.length === 0
                  ? { opacity: '0.6' }
                  : {}
              }
              className={
                isMobile ? 'col-lg-4 col-sm-12' : ' col-lg-4 col-sm-12'
              }
            >
              <DropdownToggle
                caret
                className={
                  baseDeliveryName === null ? 'grey-text' : 'black-text'
                }
              >
                {baseDeliveryName === null
                  ? t('SELECT_A_BASE_NAME')
                  : baseDeliveryName.name}
              </DropdownToggle>
              <DropdownMenu>
                {deliveryBasesListRes?.res?.map(item => (
                  <DropdownItem
                    key={item?.id}
                    onClick={() => {
                      setBaseDeliveryName(item);
                      getSortedSummaryItems(
                        setQueryParam(
                          moment(deliveryDate).format('DD/MM/YYYY'),
                          item,
                          deliverySlot,
                        ),
                      );
                      getOrderLoadingList(
                        setQueryParam(
                          moment(deliveryDate).format('DD/MM/YYYY'),
                          item,
                          deliverySlot,
                        ),
                      );
                      getSortingDetailView({
                        deliveryBaseId: item?.id,
                        deliveryDate: moment(deliveryDate).format('DD/MM/YYYY'),
                        deliverySlot: deliverySlot?.id,
                      });
                    }}
                  >
                    {item?.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Row>
          {error && <small className="error-message mt-1">{error}</small>}
        </div>
      )}

      <div className="manual-procurement">{currentStage(stage)}</div>
    </InnerBody>
  );
}

export default ManualProcurement;
