import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SillaraMasterSheet from './SillaraMasterSheet';
import AdminProfile from './adminProfile/AdminProfile';
import {
  AdminProvider,
  BuyerManagementProvider,
  SupplierManagementProvider,
  ManualProcurementProvider,
  AdminPaymentProvider,
  ReportsProvider,
  ValidateQrCodeProvider,
} from '../../../apis/admin';
import SupplierAccountManagement from './supplierAccountManagement/SupplierAccountManagement';
import BuyerAccountManagement from './buyerAccountManagment/BuyerAccountManagement';
import RequiringVerification from './ProcessManagement/RequiringVerification/RequiringVerification';
import Logistics from './Logistics/Logistics';
//import SupplierQualityCheck from './qualityCheck/SupplierQualityCheck/SupplierQualityCheck';
import AdminBuyerPaymentManagement from './PaymentManagement/AdminBuyerPaymentManagement';
import Loading from './Loading-Unloading/Loading/Loading';
import UnLoading from './Loading-Unloading/Unloading/UnloadingManagement';
import ManualProcurementQualityCheck from './qualityCheck/ManualProcurement/ManualProcurementQualityCheck';
import SelectedSupplierOrders from './qualityCheck/SupplierQualityCheck/SelectedSupplierOrders';
import SupplierQualityCheck from './qualityCheck/SupplierQualityCheck/SupplierQualityCheck';
import SelectedOrderOfSupplier from './qualityCheck/SupplierQualityCheck/SelectedOrderOfSupplier';
import SupplierQualityCheckAdminConsolidateScan from './qualityCheck/SupplierQualityCheck/SupplierQualityCheckAdminConsolidateScan';
import AdminSupplierPaymentManagement from './PaymentManagement/AdminSupplierPaymentManagement';
import ReportAccounting from './Report/ReportAccounting';
import ManualProcurement from './ProcessManagement/ManualProcurement/ManualProcurement';
import BuyerQualityCheckManagement from './qualityCheck/BuyerQualityCheck/BuyerQualityCheckManagement';
import ItemCount from './qualityCheck/SupplierQualityCheck/ItemCount';
import AdminValidateQr from './ValidateQr/AdminValidateQr';
import PrivateRoute from '../../../shared/routing/PrivateRoute';
import { USER_ROLES } from '../../../shared/constants/constants';
function AdminIndexRoutes() {
  return (
    <AdminProvider>
      <SupplierManagementProvider>
        <Routes>
          <Route
            path="/profile"
            key="/profile"
            element={
              <PrivateRoute userRole={USER_ROLES.ADMIN}>
                <AdminProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/sillaramastersheet"
            key="/sillaramastersheet"
            element={
              <PrivateRoute userRole={USER_ROLES.ADMIN}>
                <SillaraMasterSheet />
              </PrivateRoute>
            }
          />
          <Route
            path="/buyeraccoutmanagement"
            key="/buyeraccoutmanagement"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <BuyerAccountManagement />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />
          <Route
            path="/supplieraccountmanagement"
            key="/supplieraccountmanagement"
            element={
              <SupplierManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <SupplierAccountManagement />
                </PrivateRoute>
              </SupplierManagementProvider>
            }
          />
          <Route
            key="/logistics"
            path="/logistics"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <Logistics />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />
          <Route
            key="/processmanagement"
            path="/processmanagement"
            element={<h3>Process management</h3>}
          />
          <Route
            key="/requiringverification"
            path="/requiringverification"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <RequiringVerification />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/manualprocurement"
            path="/manualprocurement"
            element={
              <ManualProcurementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <ManualProcurement />
                </PrivateRoute>
              </ManualProcurementProvider>
            }
          />
          <Route
            key="/buyerpayment"
            path="/buyerpayment"
            element={
              <AdminPaymentProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <AdminBuyerPaymentManagement />
                </PrivateRoute>
              </AdminPaymentProvider>
            }
          />
          <Route
            key="/supplierpayment"
            path="/supplierpayment"
            element={
              <AdminPaymentProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <AdminSupplierPaymentManagement />
                </PrivateRoute>
              </AdminPaymentProvider>
            }
          />

          <Route
            key="/qualitycheck"
            path="/qualitycheck"
            element={<h3>Buyer Account Management</h3>}
          />
          <Route
            key="/manualprocurementqualitycheck"
            path="/manualprocurementqualitycheck"
            element={
              <ManualProcurementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <ManualProcurementQualityCheck />
                </PrivateRoute>
              </ManualProcurementProvider>
            }
          />
          <Route
            key="/supplierqualitycheck"
            path="/supplierqualitycheck"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <SupplierQualityCheck />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/supplierqualitycheck/selectedSupplierOrders/:supplierCompanyId/:deliverySlotId/:supplierCompanyName/:selectedBase/:deliverySlot/:selectedDate"
            path="/supplierqualitycheck/selectedSupplierOrders/:supplierCompanyId/:deliverySlotId/:supplierCompanyName/:selectedBase/:deliverySlot/:selectedDate"
            element={
              <BuyerManagementProvider>
                <AdminProvider>
                  <PrivateRoute userRole={USER_ROLES.ADMIN}>
                    <SelectedSupplierOrders />
                  </PrivateRoute>
                </AdminProvider>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/supplierqualitycheck/selectedorderOfsupplier/:supplierCompanyId/:purchaseOrderId/:deliverySlotId"
            path="/:qualitycheck/selectedorderOfsupplier/:supplierCompanyId/:purchaseOrderId/:deliverySlotId"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <SelectedOrderOfSupplier />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/supplierqualitycheck/itemCount/:supplierCompanyId/:purchaseOrderId/:deliverySlotId"
            path="/:qualitycheck/itemCount/:supplierCompanyId/:purchaseOrderId/:deliverySlotId"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <ItemCount />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/supplierqualitycheck/consolidateScan/:supplierCompanyId/:purchaseOrderId/:deliverySlotId"
            path="/:qualitycheck/consolidateScan/:supplierCompanyId/:purchaseOrderId/:deliverySlotId"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <SupplierQualityCheckAdminConsolidateScan />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/buyerqualitycheck"
            path="/buyerqualitycheck"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <BuyerQualityCheckManagement />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/loadinguploading"
            path="/loadinguploading"
            element={<h3>Loading/ Uploading</h3>}
          />

          <Route
            key="/loading"
            path="/loading"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <Loading />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/unloading"
            path="/unloading"
            element={
              <BuyerManagementProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <UnLoading />
                </PrivateRoute>
              </BuyerManagementProvider>
            }
          />

          <Route
            key="/validate-qr"
            path="/validate-qr"
            element={
              <ValidateQrCodeProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <AdminValidateQr />
                </PrivateRoute>
              </ValidateQrCodeProvider>
            }
          />

          <Route
            key="/reports"
            path="/reports"
            element={
              <ReportsProvider>
                <PrivateRoute userRole={USER_ROLES.ADMIN}>
                  <ReportAccounting />
                </PrivateRoute>
              </ReportsProvider>
            }
          />

          <Route
            key="/"
            path="/"
            element={<Navigate to="/admin/sillaramastersheet" />}
          />
        </Routes>
      </SupplierManagementProvider>
    </AdminProvider>
  );
}

export default AdminIndexRoutes;
