import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'reactstrap';
import { AdminPaymentContext } from '../../../../apis/admin';
import InnerBody from '../../../../content/InnerBody';
import { useDeviceDetect } from '../../../../shared/hooks';
import moment from 'moment';
import { CustomModal } from '../../../../shared/components';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function AdminBuyerPaymentManagement() {
  const { t } = useTranslation();
  const adminPaymentContext = useContext(AdminPaymentContext);
  const { getBuyerPayments, buyerPaymentToBeConfirm } = adminPaymentContext;
  const { buyerPaymentsResponse } = adminPaymentContext.state;
  const { isMobile } = useDeviceDetect();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getBuyerPayments()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {}, [isMobile]);
  const [error, setError] = useState(null);
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const toggleErrorPopup = () => {
    setError(null);
    setErrorPopupModal(!errorPopupModal);
  };

  const setConfirmationCol = (confirmation, id) => {
    //if status = 1  its' to be confirm, if status =2 it's confirmed
    if (confirmation === 1) {
      return (
        <Button
          style={{ fontSize: '0.8rem', textAlign: 'left' }}
          className="p-0 btn-link btn-color "
          onClick={() => {
            setError(null);
            buyerPaymentToBeConfirm(id)
              .then(res => {
                if (res?.message || res?.description) {
                  setError({
                    title: 'Confirmation Failed',
                    desc: res?.description || res?.message,
                  });
                  setErrorPopupModal(true);
                  return;
                }
                getBuyerPayments();
              })
              .catch(err => {
                setError({
                  title: 'Confirmation Failed',
                  desc: err?.message || err,
                });
                setErrorPopupModal(true);
              });
          }}
        >
          To be confirmed
        </Button>
      );
    }
    if (confirmation === 2) {
      return <>Confirmed</>;
    }
  };

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={error?.title || ''}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {error?.desc}
    </CustomModal>
  );

  return (
    <InnerBody
      topic={t('BUYER_PAYMENTS')}
      isDisable={true}
      className="adminBuyerPayments"
    >
      {buyerPaymentsResponse?.err !== null && (
        <p className="warning">{buyerPaymentsResponse?.err}</p>
      )}

      {errorPopup()}
      <div className="admin-payment-management-container ">
        <div className="tableFixHead admin-buyer-pay-mangement">
          {buyerPaymentsResponse?.res?.length > 0 && (
            <Table>
              <thead>
                {!isMobile && (
                  <tr>
                    <th className="text-center">{t('DATE')}</th>
                    <th>{t('PAYMENT_TYPE')}</th>
                    <th>Client Reference No</th>
                    <th className="text-right">{t('AMOUNT')}</th>
                    <th>Reference No</th>
                    <th>{t('CLIENT_ID')}</th>
                    <th>Status</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {buyerPaymentsResponse?.res?.map(item => {
                  return !isMobile ? (
                    <>
                      <tr>
                        <td className="text-right">
                          {moment(item?.createdDateTime).format('DD-MM-YYYY')}
                        </td>
                        <td>{item?.paymentType}</td>
                        <td>
                          {item?.buyerPaymentIdentifier !== null &&
                          item?.buyerPaymentIdentifier !== ''
                            ? item?.buyerPaymentIdentifier
                            : 'N/A'}
                        </td>
                        <td className="blue-color text-right">
                          {commafy(RoundNum(item?.amount, 2))}
                        </td>
                        <td>{item?.companyIdentifier}</td>
                        <td>{item?.buyerCode}</td>
                        <td style={{ fontSize: '0.8rem' }}>
                          {setConfirmationCol(item?.status, item?.id)}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>
                          <div className="pt-2 d-flex flex-row justify-content-between">
                            <small className="d-block col-w-3">
                              {moment(item?.createdDateTime).format(
                                'DD/MM/YYYY',
                              )}
                            </small>
                            <small className="d-block col-w-3">
                              {item?.paymentType}
                            </small>
                            <div className="col-w-3">
                              <small className="d-block ">Client Ref No</small>
                              <small className="d-block ">
                                {item?.buyerPaymentIdentifier !== null &&
                                item?.buyerPaymentIdentifier !== ''
                                  ? item?.buyerPaymentIdentifier
                                  : 'N/A'}
                              </small>
                            </div>
                          </div>
                          <div className="pt-2 d-flex flex-row justify-content-between">
                            <small className="d-block col-w-3">
                              {item?.buyerCode}
                            </small>
                            <div className="col-w-3">
                              <small className="d-block ">Ref No</small>
                              <small className="d-block ">
                                {item?.companyIdentifier}
                              </small>
                            </div>
                            <div className="col-w-3">
                              <small className="d-block">Amt</small>
                              <small className="d-block blue-color ">
                                {commafy(RoundNum(item?.amount, 2))}
                              </small>
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-end">
                            <small className="d-block col-w-3 pt-2">
                              {setConfirmationCol(item?.status, item?.id)}
                            </small>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          )}
          {buyerPaymentsResponse?.res?.length === 0 &&
            buyerPaymentsResponse?.err === null &&
            !isLoading && (
              <div className="py-5 custom-empty-message">
                <p>No records available</p>
              </div>
            )}
        </div>
      </div>
    </InnerBody>
  );
}
export default AdminBuyerPaymentManagement;
