import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function BuyerHome() {
  const navigate = useNavigate();
  return (
    <div className="home-container buyer-home-container">
      <div className="home-topic">
        <p className="home-main-topic">
          Procurement Redefined <br />
        </p>
        <p className="home-sub-topic">Efficient | Reliable | Sustainable</p>
      </div>
      <Button
        className="btn-primary home-btn"
        onClick={() => navigate('/buyer/makeaneworder/')}
      >
        ORDER NOW
      </Button>
    </div>
  );
}

export default BuyerHome;
