import fetchIntercept from 'fetch-intercept';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { HTTP_CODES } from '../constants/constants';
import Unauthenticated from './Unauthenticated';
import { Outlet } from 'react-router-dom';

const Interceptor = props => {
  const [statusError, setStatusError] = useState('');
  const [code, setCode] = useState('');
  fetchIntercept.register({
    request: function(url, config) {
      // Modify the url or config here

      return [url, config];
    },
    requestError: function(error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function(response) {
      const { status } = response;
      setCode(status);

      // all non error
      if (status < 400) {
        setStatusError('');
        return response;
      } else {
        switch (status) {
          case HTTP_CODES.UNAUTHENTICATED:
            setStatusError(response);
            break;
        }
      }

      return response;
    },

    responseError: function(error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });

  const handleErrors = code => {
    switch (code) {
      case HTTP_CODES.UNAUTHENTICATED:
        return <Unauthenticated statusError={statusError} />;
      default:
        return <Outlet />; //props.children;
    }
  };

  return (
    <React.Fragment>
      {statusError ? handleErrors(code) : <Outlet />}
      {props.children}
    </React.Fragment>
  );
};

Interceptor.propTypes = {
  TwoFactorWrapper: PropTypes.any,
  children: PropTypes.any,
  continueTwoFactor: PropTypes.any,
  finishTwoFactor: PropTypes.any,
  inProgress: PropTypes.any,
  setChallenge: PropTypes.any,
  setValue: PropTypes.any,
  startTwoFactor: PropTypes.any,
  url: PropTypes.any,
};

export default Interceptor;
