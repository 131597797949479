import React from 'react';
import PropTypes from 'prop-types';
import { QrReader } from 'react-qr-reader';

const Scanner = ({ setStartScan, onHandleScan }) => {
  const onHandleResult = result => {
    if (result) {
      onHandleScan(result?.text);
      setStartScan(false);
    }
  };

  return (
    <QrReader
      onResult={result => onHandleResult(result)}
      style={{ width: '100%' }}
      constraints={{
        facingMode: 'environment',
      }}
    />
  );
};

Scanner.propTypes = {
  setStartScan: PropTypes.object,
  onHandleScan: PropTypes.func,
};

export default Scanner;
