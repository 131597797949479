var adminRoutes = [
  {
    path: '/sillaramastersheet',
    name: 'Sillara Master Sheet',
    icon: 'sillaraMasterSheetIcon',
    layout: '/admin',
    children: [],
  },
  {
    path: '/buyeraccoutmanagement',
    name: 'Buyer Account Management',
    icon: 'buyerAccoutManagementIcon',
    layout: '/admin',
    children: [],
  },
  {
    path: '/supplieraccountmanagement',
    name: 'Supplier Account Management',
    icon: 'supplierAccoutManagementIcon',
    layout: '/admin',
    children: [],
  },
  {
    path: '/processmanagement',
    name: 'Process Management',
    icon: 'processManagementIcon',
    layout: '/admin',
    children: [
      {
        path: '/requiringverification/',
        name: 'Requiring Verification',
        layout: '/admin',
      },
      {
        path: '/manualprocurement/',
        name: 'Manual Procurement',
        layout: '/admin',
        children: [
          {
            path: '/orderdetail/',
          },
        ],
      },
    ],
  },
  {
    path: '/paymentmanagement',
    name: 'Payment Management',
    icon: 'paymentManagementIcon',
    layout: '/admin',
    children: [
      {
        path: '/buyerpayment/',
        name: 'Buyer Payment',
        layout: '/admin',
      },
      {
        path: '/supplierpayment/',
        name: 'Supplier Payment',
        layout: '/admin',
      },
    ],
  },
  {
    path: '/qualitycheck',
    name: 'Quality check',
    icon: 'qualityCheckIcon',
    layout: '/admin',
    children: [
      {
        path: '/manualprocurementqualitycheck/',
        name: 'Manual Procurement Quality Check',
        layout: '/admin',
      },
      {
        path: '/supplierqualitycheck/',
        name: 'Supplier Quality Check',
        layout: '/admin',
      },
      {
        path: '/buyerqualitycheck/',
        name: 'Buyer Quality Check',
        layout: '/admin',
      },
    ],
  },
  {
    path: '/loadingunloading',
    name: 'Loading/Unloading',
    icon: 'loadingUnloadingIcon',
    layout: '/admin',
    children: [
      {
        path: '/loading/',
        name: 'Loading',
        layout: '/admin',
      },
      {
        path: '/unloading',
        name: 'Unloading',
        layout: '/admin',
      },
    ],
  },
  {
    path: '/logistics',
    name: 'Logistics',
    icon: 'logisticsIcon',
    layout: '/admin',
    children: [],
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: 'reportsIcon',
    layout: '/admin',
    children: [],
  },
  {
    path: '/validate-qr',
    name: 'Validate Qr',
    icon: 'validateQrIcon',
    layout: '/admin',
    children: [],
  },
];
export default adminRoutes;
