import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { VendorManagementProvider } from '../../../apis/buyer';
import { BuyerOrderPaymentHistoryProvider } from '../../../apis/buyer/BuyerOrderPaymentHistory';
import { USER_ROLES } from '../../../shared/constants/constants';
import { PrivateRoute } from '../../../shared/routing';
import BuyerHome from './buyerHome/BuyerHome';
import BuyerProfile from './buyerProfile/BuyerProfile';
import BuyerContactUs from './ContactUs/BuyerContactUs';
import MakeaNewOrder from './makeNewOrder/MakeaNewOrder';
import BuyerOrderAndPaymentHistory from './OrderAndPaymentHistory/BuyerOrderAndPaymentHistory';
import Recommendation from './recommendation/Recommendation';
import VendorManagement from './vendorManagement/VendorManagement';

function BuyerIndexRoutes() {
  return (
    <Routes>
      <Route
        path="/home"
        key="/home"
        element={
          <PrivateRoute userRole={USER_ROLES.BUYER}>
            <BuyerHome />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        key="/profile"
        element={
          <PrivateRoute userRole={USER_ROLES.BUYER}>
            <BuyerProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/contact-us"
        key="/contact-us"
        element={
          <PrivateRoute userRole={USER_ROLES.BUYER}>
            <BuyerContactUs />
          </PrivateRoute>
        }
      />
      <Route
        path="/makeaneworder/*"
        key="/makeaneworder"
        element={
          <PrivateRoute userRole={USER_ROLES.BUYER}>
            <MakeaNewOrder />
          </PrivateRoute>
        }
      />
      <Route
        path="/makeaneworder/recommendation"
        key="/makeaneworder/recommendation"
        element={
          <PrivateRoute userRole={USER_ROLES.BUYER}>
            <Recommendation />
          </PrivateRoute>
        }
      />
      <Route
        path="/orderandpaymenthistory"
        key="/orderandpaymenthistory"
        element={
          <BuyerOrderPaymentHistoryProvider>
            <PrivateRoute userRole={USER_ROLES.BUYER}>
              <BuyerOrderAndPaymentHistory />
            </PrivateRoute>
          </BuyerOrderPaymentHistoryProvider>
        }
      />
      <Route
        path="/vendor-management"
        key="/vendor-management"
        element={
          <VendorManagementProvider>
            <PrivateRoute userRole={USER_ROLES.BUYER}>
              <VendorManagement />
            </PrivateRoute>
          </VendorManagementProvider>
        }
      />
      <Route key="/" path="/*" element={<Navigate to="/buyer/home" />} />,
    </Routes>
  );
}

export default BuyerIndexRoutes;
