import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

function DeliveryDateSelection({
  selectedDeliverySlot,
  setSelectedDeliverySlot,
  dateTimeSubmitted,
  deliverySlotOptions,
  setError,
  error,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    selectedDeliverySlot?.length === 0
      ? setError('A delivery slot is not available')
      : setError('');
  }, [selectedDeliverySlot]);

  const onSlotSelect = event => {
    setError('');
    const tempSlot = deliverySlotOptions.filter(
      x => x.id == event.target.value,
    )[0];
    setSelectedDeliverySlot(tempSlot);
  };

  const onNextClick = () => {
    if (!selectedDeliverySlot) {
      setError(t('PLEASE_SELECT_A_DELIVERY_SLOT_TO_PROCEED'));
    } else {
      dateTimeSubmitted();
    }
  };
  return (
    <div className="col-12 col-md-6 mx-auto supplier-delivery-date-selection">
      <div className="ms-md-2 w-100 w-md-50">
        <select
          className={`form-select delivery-slot-select ${
            selectedDeliverySlot?.id ? '' : 'placeholder-color'
          }`}
          disabled={deliverySlotOptions?.length == 0}
          aria-label="Default select example"
          value={selectedDeliverySlot?.id}
          onChange={onSlotSelect}
        >
          <option selected value={0} disabled hidden>
            {t('SELECT_A_DELIVERY_SLOT')}
          </option>
          {deliverySlotOptions.map(data => {
            return (
              <option key={data.id} value={data.id} className="enabled">
                {moment(data.date).format('DD/MM/YYYY')} | {data.startTime} -
                {data.endTime}
              </option>
            );
          })}
        </select>
        {error && <small className="error-message mt-1">{error}</small>}
        <Button
          color="primary"
          type="submit"
          className="form-submit mt-3 float-end col col-12 col-md-2 py-1 px-2"
          onClick={onNextClick}
        >
          {t('NEXT')}
        </Button>
      </div>
    </div>
  );
}
DeliveryDateSelection.propTypes = {
  selectedDeliverySlot: PropTypes.any,
  setSelectedDeliverySlot: PropTypes.func,
  dateTimeSubmitted: PropTypes.func,
  deliverySlotOptions: PropTypes.array,
  error: PropTypes.string,
  setError: PropTypes.func,
};

export default DeliveryDateSelection;
