import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { like, dislike } from '../../../assets/img';
import { useDeviceDetect } from '../../../../shared/hooks';
import PropTypes from 'prop-types';
import { BuyerOrderPaymentHistoryContext } from '../../../../apis/buyer/BuyerOrderPaymentHistory';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function BuyerOrderHistory({ onClickOrder, setError, setErrorPopupModal }) {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const buyerPayContext = useContext(BuyerOrderPaymentHistoryContext);
  const {
    getBuyerOrderHistory,
    likeAndDislikeBuyerPaymentHistory,
  } = buyerPayContext;
  const { buyerOrderHistoryResponse } = buyerPayContext.state;
  const [isShowActionCol, setIsShowActionCol] = useState(false);
  useEffect(() => {
    getBuyerOrderHistory();
  }, []);
  useEffect(() => {
    if (buyerOrderHistoryResponse?.res?.orders?.length > 0) {
      buyerOrderHistoryResponse?.res?.orders?.forEach(element => {
        if (
          element.purchaseOrderStatusId === 7 ||
          element.purchaseOrderStatusId === 8
        ) {
          setIsShowActionCol(true);
          return;
        }
      });
    }
  }, [buyerOrderHistoryResponse]);
  const onClickOrderStatus = (item, req) => {
    //order, purchase id
    likeAndDislikeBuyerPaymentHistory(item?.orderId, item?.purchaseOrderId, req)
      .then(res => {
        if (res?.message || res?.description) {
          setError({
            title: 'Order Failure',
            description: res?.message || res?.description,
          });
          setErrorPopupModal(prevProp => !prevProp);
          return;
        }
        getBuyerOrderHistory();
      })
      .catch(err => {
        setError({ title: 'Order Failure', description: err?.description });
        setErrorPopupModal(prevProp => !prevProp);
      });
  };

  const onClickConfirm = item => {
    const req = [
      {
        op: 'replace',
        path: 'StatusId',
        value: '8',
      },
    ];
    onClickOrderStatus(item, req);
  };
  const onClickLike = item => {
    const req = [
      {
        op: 'replace',
        path: 'BuyerLiked',
        value: 'true',
      },
      {
        op: 'replace',
        path: 'StatusId',
        value: '9',
      },
    ];
    onClickOrderStatus(item, req);
  };
  const onClickDisLike = item => {
    const req = [
      {
        op: 'replace',
        path: 'BuyerLiked',
        value: 'false',
      },
    ];
    onClickOrderStatus(item, req);
  };

  return (
    <div className="buyer-order-history ">
      {buyerOrderHistoryResponse?.res?.orders?.length > 0 && (
        <h4 className="outstanding-bal-container my-3">
          Outstanding Balance : {isMobile ? <br /> : <></>}LKR{' '}
          {commafy(
            RoundNum(buyerOrderHistoryResponse?.res?.outstandingBalance, 2),
          )}
        </h4>
      )}
      <div>
        <div className="tableFixHead">
          {buyerOrderHistoryResponse?.res?.orders?.length > 0 && (
            <Table>
              {!isMobile && (
                <thead>
                  <tr>
                    <th className="text-center">{t('DATE')}</th>
                    <th>Invoice Number</th>
                    <th>Order Status</th>
                    <th className="text-right">Invoice Value (LKR)</th>
                    <th className="text-right">Amount Due (LKR)</th>
                    {isShowActionCol && <th></th>}
                  </tr>
                </thead>
              )}
              <tbody>
                {buyerOrderHistoryResponse?.res?.orders?.map((item, index) => (
                  <tr key={index}>
                    {!isMobile ? (
                      <>
                        <td
                          className="text-right cursor-pointer"
                          onClick={() => onClickOrder(item)}
                        >
                          {moment(item?.deliveryDateTime).format('DD-MM-YYYY')}
                        </td>
                        <td
                          className="cursor-pointer"
                          onClick={() => onClickOrder(item)}
                        >
                          {item?.invoiceNumber}
                        </td>
                        <td
                          className="cursor-pointer"
                          onClick={() => onClickOrder(item)}
                        >
                          {item?.purchaseOrderStatusText}
                        </td>
                        <td
                          onClick={() => onClickOrder(item)}
                          className="blue-color text-right cursor-pointer"
                        >
                          {item?.purchaseOrderStatusId === 13
                            ? 'N/A'
                            : item?.invoiceValue
                            ? commafy(RoundNum(item?.invoiceValue, 2))
                            : 'Pending'}
                        </td>
                        <td
                          onClick={() => onClickOrder(item)}
                          className="blue-color text-right cursor-pointer"
                        >
                          {item?.purchaseOrderStatusId === 13
                            ? 'N/A'
                            : commafy(RoundNum(item?.amountDue, 2))}
                        </td>
                        {isShowActionCol && (
                          <td className="text-center ">
                            {item?.purchaseOrderStatusId == 7 &&
                              item?.pendingConfirm && (
                                <Button
                                  className=" delete-items btn-color cursor-pointer"
                                  color="link"
                                  onClick={() => {
                                    onClickConfirm(item);
                                  }}
                                >
                                  Confirm
                                </Button>
                              )}
                            {item?.purchaseOrderStatusId === 8 && (
                              <div className="d-flex flex-row justify-content-evenly">
                                <Button
                                  className="p-0 btn-link cursor-pointer"
                                  onClick={() => {
                                    onClickLike(item);
                                  }}
                                >
                                  <img width="35" src={like} alt="like" />
                                </Button>
                                <Button
                                  className="p-0 btn-link cursor-pointer"
                                  style={
                                    !item.buyerLiked && item.buyerLiked !== null
                                      ? {
                                          pointerEvents: 'none',
                                          background: '#87d5f4',
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    onClickDisLike(item);
                                  }}
                                  color="btn-link"
                                >
                                  <img width="35" src={dislike} alt="dislike" />
                                </Button>
                              </div>
                            )}
                          </td>
                        )}
                      </>
                    ) : (
                      <>
                        <td
                          className="d-flex flex-row justify-content-between cursor-pointer"
                          style={{ borderWidth: '0' }}
                        >
                          <div
                            onClick={() => onClickOrder(item)}
                            style={{ width: '50%' }}
                          >
                            <small className="order-first-row d-block p-1">
                              <span className="col-title">
                                {' '}
                                {moment(item?.deliveryDateTime).format(
                                  'DD-MM-YYYY',
                                )}
                              </span>{' '}
                            </small>
                            <small className="d-block p-1">
                              {item?.invoiceNumber}
                            </small>
                            <small className="d-block p-1 btn-color">
                              {item?.purchaseOrderStatusText}
                            </small>
                          </div>
                          <div style={{ width: '50%', textAlign: 'right' }}>
                            <small
                              onClick={() => onClickOrder(item)}
                              className="order-first-row  d-block p-1"
                            >
                              <b className="col-title">Amt. Due</b>{' '}
                              <span className="blue-color">
                                {item?.purchaseOrderStatusId === 13
                                  ? 'N/A'
                                  : commafy(RoundNum(item?.amountDue, 2))}
                              </span>
                            </small>
                            <small
                              onClick={() => onClickOrder(item)}
                              className="d-block p-1"
                            >
                              <b className="col-title ">Inv. Value</b>{' '}
                              <span className="blue-color">
                                {item?.purchaseOrderStatusId === 13
                                  ? 'N/A'
                                  : commafy(RoundNum(item?.invoiceValue, 2))}
                              </span>
                            </small>
                            <small className="d-block text-right ">
                              {item?.purchaseOrderStatusId == 7 &&
                                item?.pendingConfirm && (
                                  <Button
                                    style={{
                                      padding: '4px',
                                      fontSize: '12px',
                                    }}
                                    className="delete-items btn-color"
                                    color="link"
                                    onClick={() => onClickConfirm(item)}
                                  >
                                    Confirm
                                  </Button>
                                )}
                              {item?.purchaseOrderStatusId == 8 && (
                                <>
                                  <Button
                                    onClick={() => {
                                      onClickLike(item);
                                    }}
                                    style={{ marginLeft: '10px' }}
                                    className="p-0 ml-2 btn-link"
                                  >
                                    <img width="25" src={like} alt="like" />
                                  </Button>
                                  <Button
                                    style={
                                      !item.buyerLiked &&
                                      item.buyerLiked !== null
                                        ? {
                                            pointerEvents: 'none',
                                            background: '#87d5f4',
                                          }
                                        : {}
                                    }
                                    onClick={() => {
                                      onClickDisLike(item);
                                    }}
                                    color="p-0 btn-link"
                                  >
                                    <img
                                      width="25"
                                      src={dislike}
                                      alt="dislike"
                                    />
                                  </Button>
                                </>
                              )}
                            </small>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <div>
        {(buyerOrderHistoryResponse?.res?.orders?.length === 0 ||
          buyerOrderHistoryResponse?.err !== null) && (
          <div className="py-5 custom-empty-message">
            <p>No records available</p>
          </div>
        )}
      </div>
    </div>
  );
}

BuyerOrderHistory.propTypes = {
  onClickOrder: PropTypes.func,
  setError: PropTypes.func,
  setErrorPopupModal: PropTypes.func,
};

export default BuyerOrderHistory;
