import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from '../LoadingContext';
import { Connection } from '../../app/config';

const BuyerOrderPaymentHistoryContext = React.createContext();
const BuyerOrderPaymentHistoryProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;

  const [buyerOrderHistoryResponse, setbuyerOrderHistoryResponse] = useState({
    res: null,
    err: null,
  });
  const [
    buyerPaymentHistoryResponse,
    setBuyerPaymentHistoryResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const [
    buyerInvoiceBasedPayResponse,
    setBuyerInvoiceBasedPayResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const [paymentTypesResponse, setPaymentTypesResponse] = useState({
    res: null,
    err: null,
  });

  const [
    buyerPaymentDetailsInvoiceResponse,
    setBuyerPaymentDetailsInvoiceResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const getBuyerPaymentDetailInvoice = async (invoiceId = '') => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const payTypes = await fetch(url + `/buyer-invoices/${invoiceId}`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payTypes.json();
      if (data?.errors || data?.message) {
        setBuyerPaymentDetailsInvoiceResponse({
          res: null,
          err: data?.message,
        });
      } else {
        setBuyerPaymentDetailsInvoiceResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      buyerPaymentDetailsInvoiceResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const doPaymentToInvoices = async req => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const pay = await fetch(url + `payments`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(req),
      });
      const data = await pay.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const getPaymentTypes = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const payTypes = await fetch(url + `/payment-types`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payTypes.json();
      if (data?.errors || data?.message) {
        setPaymentTypesResponse({ res: null, err: data?.message });
      } else {
        setPaymentTypesResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setPaymentTypesResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const getBuyerInvoiceBasedPayment = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const payHis = await fetch(url + `invoices`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payHis.json();
      if (data?.errors || data?.message) {
        setBuyerInvoiceBasedPayResponse({ res: null, err: data?.message });
      } else {
        setBuyerInvoiceBasedPayResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setBuyerPaymentHistoryResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const getBuyerPaymentHistory = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const payHis = await fetch(url + `payment-summary`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payHis.json();
      if (data?.errors || data?.message) {
        setBuyerPaymentHistoryResponse({ res: null, err: data?.message });
      } else {
        setBuyerPaymentHistoryResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setBuyerPaymentHistoryResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const getBuyerOrderHistory = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const payHis = await fetch(url + `order-history-summary`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payHis.json();
      if (data?.errors || data?.message) {
        setbuyerOrderHistoryResponse({ res: null, err: data?.message });
      } else {
        setbuyerOrderHistoryResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setbuyerOrderHistoryResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const likeAndDislikeBuyerPaymentHistory = async (
    orderId,
    purchaseId,
    req,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const payHis = await fetch(
        url + `/orders/${orderId}/purchase-orders/${purchaseId}`,
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify(req),
        },
      );
      const data = await payHis.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  return (
    <BuyerOrderPaymentHistoryContext.Provider
      value={{
        state: {
          buyerOrderHistoryResponse: buyerOrderHistoryResponse,
          buyerPaymentHistoryResponse: buyerPaymentHistoryResponse,
          buyerInvoiceBasedPayResponse: buyerInvoiceBasedPayResponse,
          paymentTypesResponse: paymentTypesResponse,
          buyerPaymentDetailsInvoiceResponse: buyerPaymentDetailsInvoiceResponse,
        },
        getBuyerPaymentHistory: getBuyerPaymentHistory,
        getBuyerOrderHistory: getBuyerOrderHistory,
        likeAndDislikeBuyerPaymentHistory: likeAndDislikeBuyerPaymentHistory,
        getBuyerInvoiceBasedPayment: getBuyerInvoiceBasedPayment,
        getPaymentTypes: getPaymentTypes,
        doPaymentToInvoices: doPaymentToInvoices,
        getBuyerPaymentDetailInvoice: getBuyerPaymentDetailInvoice,
      }}
    >
      {props.children}
    </BuyerOrderPaymentHistoryContext.Provider>
  );
};
BuyerOrderPaymentHistoryProvider.propTypes = {
  children: PropTypes.any,
};

export { BuyerOrderPaymentHistoryProvider, BuyerOrderPaymentHistoryContext };
