const Session = (function() {
  const errorMessage =
    'Session config not set, please call config(ticketFunc, authenticatedFunc)';

  const config = (
    setTicketFunc,
    getTicketFunc,
    setPermissionsFunc,
    getPermissionsFunc,
    setUserFunc,
    getUserFunc,
  ) => {
    _setTicketFunc = setTicketFunc;
    _getTicketFunc = getTicketFunc;
    _setPermissionsFunc = setPermissionsFunc;
    _getPermissionsFunc = getPermissionsFunc;
    _setUserFunc = setUserFunc;
    _getUserFunc = getUserFunc;
  };

  var _setTicketFunc;
  var _getTicketFunc;
  var _setPermissionsFunc;
  var _getPermissionsFunc;
  var _setUserFunc;
  var _getUserFunc;
  var active = false;

  const setUser = user => {
    if (!_setUserFunc) {
      throw errorMessage;
    }
    _setUserFunc(user);
  };

  const getUser = function() {
    if (!_getUserFunc) {
      throw errorMessage;
    }

    return _getUserFunc();
  };

  const setIdleStatus = function(status) {
    active = status;
  };

  const getIdleStatus = function() {
    return active;
  };

  const set = ticket => {
    if (!_setTicketFunc) {
      throw errorMessage;
    }
    _setTicketFunc(ticket);
  };

  const setPermission = permissions => {
    if (!_setPermissionsFunc) {
      throw errorMessage;
    }
    _setPermissionsFunc(permissions);
  };

  const getPermission = function() {
    if (!_getPermissionsFunc) {
      throw errorMessage;
    }

    return _getPermissionsFunc();
  };

  const abandon = () => {
    if (!_setTicketFunc) {
      throw errorMessage;
    }
    _setTicketFunc(null);
  };

  const isAuthorized = function() {
    if (!_getTicketFunc) {
      throw errorMessage;
    }

    return _getTicketFunc() != null;
  };

  const ticket = function() {
    if (!_getTicketFunc) {
      throw errorMessage;
    }
    return _getTicketFunc();
  };

  let token;

  const setToken = function(session) {
    token = session;
  };

  const getToken = function() {
    return token;
  };

  return {
    config: config,
    isAuthorized: isAuthorized,
    ticket: ticket,
    set: set,
    getPermission: getPermission,
    setPermission: setPermission,
    setUser: setUser,
    getUser: getUser,
    setToken: setToken,
    getToken: getToken,
    abandon: abandon,
    setIdleStatus: setIdleStatus,
    getIdleStatus: getIdleStatus,
  };
})();

export default Session;
