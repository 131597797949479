import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import InnerNavContainer from './InnerNavContainer';
function InnerBody({
  topic,
  children,
  isDisable,
  isRefreshDisable = true,
  rightSideElement,
  onHandleBack,
  onRefresh = null,
  footer,
}) {
  const innerBodyEl = document.getElementsByClassName('inner-body')[0];
  const el = document.getElementsByClassName('inner-body-container')[0];
  useEffect(() => {
    setTimeout(() => {
      const footerEl = document.getElementById('inner-body-footer');
      if (footerEl) {
        if (el && innerBodyEl) {
          innerBodyEl.style.maxHeight =
            el.offsetHeight - footerEl.offsetHeight + 'px';
        }
      }
    }, 100);
  }, [footer, innerBodyEl?.offsetHeight]);

  return (
    <>
      <InnerNavContainer
        isDisable={isDisable}
        isRefreshDisable={isRefreshDisable}
        rightSideElement={rightSideElement}
        onHandleBack={onHandleBack}
        onRefresh={onRefresh}
      />
      <Container
        className={`inner-body-container ${footer ? 'position-relative' : ''}`}
      >
        <div className={`inner-body ${footer ? 'responsive-inner-body' : ''}`}>
          <p className="inner-body-topic p-0">{topic}</p>
          {children}
        </div>
        {footer}
      </Container>
    </>
  );
}

InnerBody.propTypes = {
  topic: PropTypes.string,
  children: PropTypes.any,
  isDisable: PropTypes.bool,
  isRefreshDisable: PropTypes.bool,
  rightSideElement: PropTypes.any,
  onHandleBack: PropTypes.func,
  onRefresh: PropTypes.func,
  footer: PropTypes.any,
};

export default InnerBody;
