import React from 'react';
import UserProfile from '../../../../content/UserProfile';
import InnerBody from '../../../../content/InnerBody';
import { useEffect } from 'react';
import { useContext } from 'react';
import { SupplierContext } from '../../../../apis/supplier';
import { useState } from 'react';

function SupplierProfile() {
  const supplierContext = useContext(SupplierContext);
  const { handleSupplierDetails } = supplierContext;
  const [supplierData, setSupplierData] = useState(null);

  useEffect(() => {
    handleSupplierDetails().then(res => {
      if (res.err) {
        return;
      } else {
        setSupplierData(res.res);
      }
    });
  }, []);

  return (
    <InnerBody topic="User Profile" isDisable={true}>
      <UserProfile
        base={supplierData && supplierData.supplierCompany.baseMarketName}
        topic={supplierData && supplierData.supplierCompany.name}
        brn={supplierData && supplierData.supplierCompany.brn}
        firstName={supplierData && supplierData.firstName}
        lastName={supplierData && supplierData.lastName}
        contactNumber={supplierData && supplierData.contactNumber}
        email={supplierData && supplierData.email}
        address={supplierData && supplierData.supplierCompany.address}
      />
    </InnerBody>
  );
}

export default SupplierProfile;
