import React from 'react';
import { Route } from 'react-router-dom';
import SupplierIndexRoutes from './SupplierIndex';

const Routes = [
  <Route
    key="supplier/*"
    path="supplier/*"
    element={<SupplierIndexRoutes />}
  />,
];

export default Routes;
