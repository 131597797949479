import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { S3_BUCKET_URL } from '../../../../environment';
import { imageNotFound } from '../../../assets/img/index';
import { useDeviceDetect } from '../../../../shared/hooks';

function ProductCategoryCard({
  category,
  setSelectedCategory,
  getSubCategories,
  isSubCategory,
  handleBrowseProductList,
}) {
  const { isMobile } = useDeviceDetect();
  const handleOnClick = () => {
    setSelectedCategory(category);
    if (isSubCategory) {
      handleBrowseProductList(category, '');
    } else {
      getSubCategories(category);
    }
  };

  return (
    <div className="product-category-card-container " onClick={handleOnClick}>
      <Card className="product-category-card">
        {isMobile ? (
          <Row>
            <Col>
              <img
                loading="lazy"
                className="product-category-card-image"
                alt="Image Not Found"
                src={
                  category.categoryImageName
                    ? S3_BUCKET_URL + 'Catagories/' + category.categoryImageName
                    : imageNotFound
                }
              />
            </Col>
            <Col className="product-category-title-container">
              <p className="product-category-title">{category.name}</p>
            </Col>
          </Row>
        ) : (
          <img
            loading="lazy"
            className="product-category-card-image"
            alt="Image Not Found"
            src={
              category.categoryImageName
                ? S3_BUCKET_URL + 'Catagories/' + category.categoryImageName
                : imageNotFound
            }
          />
        )}
      </Card>
      {!isMobile && <p className="product-category-title">{category.name}</p>}
    </div>
  );
}
ProductCategoryCard.propTypes = {
  category: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  getSubCategories: PropTypes.func,
  isSubCategory: PropTypes.bool,
  handleBrowseProductList: PropTypes.func,
};
export default ProductCategoryCard;
