import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
//import { PrivateRoute } from '../../../shared/routing';
import { InventoryUpdates } from './inventoryUpdates';
import { SupplierProvider } from '../../../apis/supplier';
import SupplierHome from './supplierHome/SupplierHome';
import SupplierProfile from './supplierProfile/SupplierProfile';
import { SupplierOrderPaymentHistoryProvider } from '../../../apis/supplier/SupplierOrderPaymentHistory';
import SupplierOrderAndPaymentHistory from './OrderAndPaymentHistory/SupplierOrderAndPaymentHistory';
import OrderFulfilmentManagement from './orderFulfilment/OrderFulfilmentManagement';
import SupplierContactUs from './ContactUs/SupplierContactUs';
import PrivateRoute from '../../../shared/routing/PrivateRoute';
import { USER_ROLES } from '../../../shared/constants/constants';
function SupplierIndexRoutes() {
  return (
    <SupplierProvider>
      <Routes>
        <Route
          path="/home"
          key="/home"
          element={
            <PrivateRoute userRole={USER_ROLES.SUPPLIER}>
              <SupplierHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/contact-us"
          key="/contact-us"
          element={
            <PrivateRoute userRole={USER_ROLES.SUPPLIER}>
              <SupplierContactUs />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          key="/profile"
          element={
            <PrivateRoute userRole={USER_ROLES.SUPPLIER}>
              <SupplierProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-fulfillment"
          key="/order-fulfillment"
          element={
            <PrivateRoute userRole={USER_ROLES.SUPPLIER}>
              <OrderFulfilmentManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/inventory-updates"
          key="/inventory-updates"
          element={
            <PrivateRoute userRole={USER_ROLES.SUPPLIER}>
              <InventoryUpdates />
            </PrivateRoute>
          }
        />
        <Route
          path="/order-payment-history"
          key="/order-payment-history"
          element={
            <SupplierOrderPaymentHistoryProvider>
              <PrivateRoute userRole={USER_ROLES.SUPPLIER}>
                <SupplierOrderAndPaymentHistory />
              </PrivateRoute>
            </SupplierOrderPaymentHistoryProvider>
          }
        />

        <Route
          path="/*"
          key="/supplier/home"
          element={<Navigate to="/supplier/home" />}
        />
      </Routes>
    </SupplierProvider>
  );
}

export default SupplierIndexRoutes;
