import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import InnerBody from '../../../../content/InnerBody';
import { ProgressBar, RadioGroup } from '../../../../shared/components';
import { CustomModal } from '../../../../shared/components';
import { SessionContext } from '../../../../apis/authentication';
import ProductCart from './ProductCart';
import BrowseProduct from './BrowseProduct';
import DeliverDate from './DeliveryDate';
import { BROWSE_PAGE_RADIO_BUTTON_VALUE } from './constants/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
function MakeaNewOrder() {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedDateTemp, setSelectedDateTemp] = useState(null);
  const [selectedDeliverySlotTemp, setSelectedDeliverySlotTemp] = useState(
    null,
  );
  const [selectedDateChangeTemp, setSelectedDateChangeTemp] = useState(null);
  const [
    selectedDeliverySlotChangeTemp,
    setSelectedDeliverySlotChangeTemp,
  ] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [dateTimeSubmitted, setDateTimeSubmitted] = useState(false);
  const [sessionDataReceived, setSessionDataReceived] = useState(false);
  const [changeDateTimeModalIsOpen, setChangeDateTimeModalIsOpen] = useState(
    false,
  );
  const [checkedValue, setcheckedValue] = useState(
    BROWSE_PAGE_RADIO_BUTTON_VALUE.ALL_ITEMS,
  );
  const [allCategories, setAllCategories] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const [searchedProductList, setSearchedProductList] = useState([]);
  const [isItemsAvailable, setIsItemsAvailable] = useState(true);
  const [currentStepInBrowseProduct, setCurrentStepInBrowseProduct] = useState(
    1,
  );

  const [selectedFilterCategories, setSelectedFilterCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchingOrFiltering, setIsSearchingOrFiltering] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const sessionContext = useContext(SessionContext);
  const search = useLocation().search;
  const step = new URLSearchParams(search).get('step');

  let navigate = useNavigate();

  useEffect(() => {
    if (sessionContext) {
      const sessionData = sessionContext.getDeliveryDateAndSlot();
      if (
        sessionData &&
        sessionData.selectedDate &&
        sessionData.selectedDeliverySlot
      ) {
        setSelectedDate(sessionData.selectedDate);
        setSelectedDeliverySlot(sessionData.selectedDeliverySlot);
        setTimeout(() => {
          setDateTimeSubmitted(true);
          setSessionDataReceived(false);
        }, 100);
      } else {
        setSelectedDate(new Date());
      }
    }
  }, []);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let value = params.step;
    const sessionData = sessionContext.getDeliveryDateAndSlot();
    if (value != null && parseInt(value) > 0) {
      if (!(value == 3 && currentStep == 0)) {
        if (
          value == 2 &&
          (!selectedDate || !selectedDeliverySlot) &&
          !(
            sessionData &&
            sessionData.selectedDate &&
            sessionData.selectedDeliverySlot
          )
        ) {
          navigate('/buyer/makeaneworder?step=1');
        } else {
          setCurrentStep(parseInt(value) - 1);
        }
      } else if (
        value == 3 &&
        sessionData &&
        sessionData.selectedDate &&
        sessionData.selectedDeliverySlot
      ) {
        setCurrentStep(parseInt(value) - 1);
      }
    }
  }, [step]);

  useEffect(() => {
    if (!isFirstRender) {
      navigate(`/buyer/makeaneworder?step=${currentStep + 1}`);
    } else {
      setIsFirstRender(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (dateTimeSubmitted && !sessionDataReceived) {
      setChangeDateTimeModalIsOpen(true);
      setSelectedDateChangeTemp(selectedDate);
    } else {
      setSelectedDateTemp(selectedDate);
      setSelectedDeliverySlotTemp(selectedDeliverySlot);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (dateTimeSubmitted && !sessionDataReceived) {
      setChangeDateTimeModalIsOpen(true);
      setSelectedDateChangeTemp(selectedDate);
      setSelectedDeliverySlotChangeTemp(selectedDeliverySlot);
    } else {
      setSelectedDateTemp(selectedDate);
      setSelectedDeliverySlotTemp(selectedDeliverySlot);
    }
  }, [selectedDeliverySlot]);

  const onDateTimeSubmit = () => {
    setCurrentStep(1);
    setDateTimeSubmitted(true);
    sessionContext.setDeliveryDateAndSlot(selectedDate, selectedDeliverySlot);
    setSessionDataReceived(false);
  };

  const onChangeConfirm = () => {
    setDateTimeSubmitted(false);

    if (selectedDateChangeTemp) {
      setSelectedDate(selectedDateChangeTemp);
      setTimeout(() => {
        setSelectedDateChangeTemp(null);
      }, 50);
    } else {
      setSelectedDate(null);
    }

    if (selectedDeliverySlotChangeTemp) {
      setSelectedDeliverySlot(selectedDeliverySlotChangeTemp);
      setTimeout(() => {
        setSelectedDeliverySlotChangeTemp(null);
      }, 50);
    } else {
      setSelectedDeliverySlot(null);
    }
    setChangeDateTimeModalIsOpen(false);
  };

  const onChangeDecline = () => {
    setSelectedDate(new Date(selectedDateTemp));
    setSelectedDeliverySlot(selectedDeliverySlotTemp);
    setChangeDateTimeModalIsOpen(false);
    setDateTimeSubmitted(false);
    setTimeout(() => {
      setDateTimeSubmitted(true);
    }, 100);
  };

  const handleCheckboxOnChange = e => {
    if (e.target.value !== checkedValue) {
      setcheckedValue(e.target.value);
      setSelectedCategory(null);
      setSelectedSubCategory(null);
      setProductList([]);
      setAllCategories([]);
      setSubCategories([]);
      setParentCategories([]);
      setSearchedProductList([]);
      setIsSearchingOrFiltering(false);
      setCurrentStepInBrowseProduct(1);
    }
  };

  const handleBackButtonToCategory = () => {
    setIsSearchingOrFiltering(false);
    setSelectedFilterCategories([{ name: 'Select a Category' }]);
    setCurrentStepInBrowseProduct(1);
    setIsItemsAvailable(parentCategories.length > 0);
    setSubCategories([]);
    setSelectedCategory(null);
  };

  const handleBackButtonFromSearchResult = () => {
    setCurrentStepInBrowseProduct(3);
    setIsSearchingOrFiltering(false);
    setSearchTerm('');

    setSearchedProductList([]);
    if (selectedSubCategory) {
      setSelectedFilterCategories([selectedSubCategory]);
      setIsItemsAvailable(productList.length > 0);
    } else if (selectedCategory) {
      setSelectedFilterCategories([selectedCategory]);
      setIsItemsAvailable(productList.length > 0 || subCategories.length > 0);
    } else {
      handleBackButtonToSubCategory();
      setIsItemsAvailable(true);
    }
  };

  const handleBackButtonToSubCategory = () => {
    setCurrentStepInBrowseProduct(2);
    setIsItemsAvailable(subCategories.length > 0);

    if (selectedSubCategory) {
      setSelectedSubCategory(null);
      setSelectedFilterCategories([selectedCategory]);
    } else {
      handleBackButtonToCategory();
    }
    setProductList([]);
    setIsSearchingOrFiltering(false);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <DeliverDate
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedDeliverySlot={selectedDeliverySlot}
            setSelectedDeliverySlot={setSelectedDeliverySlot}
            dateTimeSubmitted={onDateTimeSubmit}
          />
        );
      case 1:
        return (
          <BrowseProduct
            checkedValue={checkedValue}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            selectedDate={selectedDate}
            selectedDeliverySlot={selectedDeliverySlot}
            currentStep={currentStep}
            allCategories={allCategories}
            setAllCategories={setAllCategories}
            parentCategories={parentCategories}
            setParentCategories={setParentCategories}
            subCategories={subCategories}
            setSubCategories={setSubCategories}
            productList={productList}
            setProductList={setProductList}
            setCurrentStep={setCurrentStep}
            setIsItemsAvailable={setIsItemsAvailable}
            isItemsAvailable={isItemsAvailable}
            setCurrentStepInBrowseProduct={setCurrentStepInBrowseProduct}
            currentStepInBrowseProduct={currentStepInBrowseProduct}
            setIsSearchingOrFiltering={setIsSearchingOrFiltering}
            isSearchingOrFiltering={isSearchingOrFiltering}
            setSearchedProductList={setSearchedProductList}
            searchedProductList={searchedProductList}
            setSelectedFilterCategories={setSelectedFilterCategories}
            selectedFilterCategories={selectedFilterCategories}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
        );
      case 2:
        return <ProductCart />;
    }
  };

  return (
    <InnerBody
      topic=""
      isDisable={currentStepInBrowseProduct == 1 || currentStep != 1}
      onHandleBack={
        currentStepInBrowseProduct == 4
          ? handleBackButtonFromSearchResult
          : currentStepInBrowseProduct == 3
          ? handleBackButtonToSubCategory
          : currentStepInBrowseProduct == 2
          ? handleBackButtonToCategory
          : null
      }
      rightSideElement={
        currentStep != 0 && selectedDate && selectedDeliverySlot ? (
          <div className="me-1 me-md-3 header-right-date">
            {moment(selectedDate).format('DD-MM-YYYY | dddd')}{' '}
            {selectedDeliverySlot.startTime.slice(0, -3)} -{' '}
            {selectedDeliverySlot.endTime.slice(0, -3)}
          </div>
        ) : (
          <></>
        )
      }
    >
      <Row id="progress-bar-container">
        <Col className="py-0" style={{ marginTop: '-15px' }}>
          <ProgressBar
            currentStepIndex={currentStep}
            steps={[t('DELIVERY'), t('BROWSE'), t('CART')]}
            changeStepOnClick={currentStep != 0}
            setCurrentStep={setCurrentStep}
          />
        </Col>
      </Row>
      <Row>
        {currentStep === 1 && (
          <Col
            className="browse-radio-container"
            xl={4}
            lg={12}
            md={12}
            sm={12}
          >
            <RadioGroup
              name="Browse"
              options={[
                {
                  value: BROWSE_PAGE_RADIO_BUTTON_VALUE.ALL_ITEMS,
                  displayValue: t('ALL_ITEMS'),
                },
                {
                  value: BROWSE_PAGE_RADIO_BUTTON_VALUE.YOUR_ITEMS,
                  displayValue: t('YOUR_ITEMS'),
                },
              ]}
              radioButtonInputClassName="brows-radio-button "
              radioButtonContainerClassName="browse-radio-button-group d-flex"
              selectedOption={checkedValue}
              onClick={handleCheckboxOnChange}
              isDisable={
                selectedCategory ||
                selectedSubCategory ||
                isSearchingOrFiltering
              }
            />
          </Col>
        )}
      </Row>

      {renderStep()}
      <CustomModal
        isOpen={changeDateTimeModalIsOpen}
        toggle={onChangeDecline}
        showClose={false}
        isScrollable={true}
        enableFooter={true}
        isConfirmation={true}
        positiveBtnTitle={'Yes'}
        negativeBtnTitle={'No'}
        action={onChangeConfirm}
        title="Delivery slot changes!"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="d-flex justify-content-around"
        modalBodyClassName="small"
        positiveBtnClass={'without-w-change'}
        negativeBtnClass={'without-w-change'}
      >
        <div className="my-3">
          Are you sure you want to change the delivery date or the slot?
        </div>
      </CustomModal>
    </InnerBody>
  );
}
export default MakeaNewOrder;
