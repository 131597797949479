import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const AdminPaymentContext = React.createContext();
const AdminPaymentProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [buyerPaymentsResponse, setBuyerPaymentsResponse] = useState({
    res: null,
    err: null,
  });
  const [supplierPaymentsResponse, setSupplierPaymentsResponse] = useState({
    res: null,
    err: null,
  });
  const [supplierList, setSupplierList] = useState({
    res: [],
    err: null,
  });
  const [
    supplierPaymentInvoicesResponse,
    setSupplierPaymentInvoicesResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const [paymentTypesResponse, setPaymentTypesResponse] = useState({
    res: null,
    err: null,
  });
  const [
    supplierPaymentConfirmation,
    setSupplierPaymentConfirmation,
  ] = useState({
    res: null,
    err: null,
  });
  const getPaymentTypes = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const payTypes = await fetch(url + `/payment-types`, {
        method: 'GET',
        headers: headers,
      });
      const data = await payTypes.json();
      if (data?.errors || data?.message) {
        setPaymentTypesResponse({ res: null, err: data?.message });
      } else {
        setPaymentTypesResponse({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setPaymentTypesResponse({ res: null, err: err });
      setLoading(false);
    }
  };

  const getBuyerPayments = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const res = await fetch(url + `/buyer-payments`, {
        method: 'GET',
        headers: headers,
      });
      const data = await res.json();

      if (data.error || data?.message) {
        setBuyerPaymentsResponse({ err: data?.description, res: null });
      } else {
        setBuyerPaymentsResponse({ err: null, res: data });
      }
      setLoading(false);
    } catch (err) {
      setBuyerPaymentsResponse({ err: err?.description || err, res: null });
      setLoading(false);
    }
  };

  const getSupplierPayments = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const res = await fetch(
        url + `/supplier-companies/supplier-payment-details`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await res.json();

      if (data.error || data?.message) {
        setSupplierPaymentsResponse({ err: data?.message, res: null });
      } else {
        setSupplierPaymentsResponse({ err: null, res: data });
      }
      setLoading(false);
    } catch (err) {
      setSupplierPaymentsResponse({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getSupplierInvoicesBySupplier = async id => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const res = await fetch(
        url + `/supplier-companies/${id}/payment-summary`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await res.json();

      if (data.description || data?.message) {
        setSupplierPaymentInvoicesResponse({ err: data?.message, res: null });
      } else {
        setSupplierPaymentInvoicesResponse({ err: null, res: data });
      }
      setLoading(false);
    } catch (err) {
      setSupplierPaymentInvoicesResponse({
        err: err?.message || err,
        res: null,
      });
      setLoading(false);
    }
  };

  const buyerPaymentToBeConfirm = async paymentId => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const res = await fetch(url + `/buyer-payments/${paymentId}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify([
          {
            op: 'replace',
            path: 'Status',
            value: 2,
          },
        ]),
      });
      const data = await res.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const getSuppliers = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('suppliers');
      const suppliers = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const data = await suppliers.json();
      if (data.description || data?.message) {
        setSupplierList({
          res: data,
          err: data?.message || data.description,
        });
      } else {
        setSupplierList({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSupplierList({ err: err?.message || err });
    }
  };

  const doSupplierPayment = async payment => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const supplier = await fetch(url + `/supplier-payments`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payment),
      });
      const data = await supplier.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const getSuppliersPaymentConfirmation = async supplierId => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const suppliers = await fetch(
        url + `/suppliers/payment-confirmations?supplierId=${supplierId}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await suppliers.json();
      if (data.description || data?.message) {
        setSupplierPaymentConfirmation({
          res: null,
          err: data?.message || data.description,
        });
      } else {
        setSupplierPaymentConfirmation({
          res: data,
          err: null,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSupplierPaymentConfirmation({ err: err?.message || err, res: null });
    }
  };

  return (
    <AdminPaymentContext.Provider
      value={{
        state: {
          buyerPaymentsResponse: buyerPaymentsResponse,
          supplierPaymentsResponse: supplierPaymentsResponse,
          supplierList: supplierList,
          supplierPaymentInvoicesResponse: supplierPaymentInvoicesResponse,
          paymentTypesResponse: paymentTypesResponse,
          supplierPaymentConfirmation: supplierPaymentConfirmation,
        },
        getSuppliersPaymentConfirmation: getSuppliersPaymentConfirmation,
        getPaymentTypes: getPaymentTypes,
        getBuyerPayments: getBuyerPayments,
        buyerPaymentToBeConfirm: buyerPaymentToBeConfirm,
        getSupplierPayments: getSupplierPayments,
        getSuppliers: getSuppliers,
        getSupplierInvoicesBySupplier: getSupplierInvoicesBySupplier,
        doSupplierPayment: doSupplierPayment,
        setSupplierPaymentInvoicesResponse: setSupplierPaymentInvoicesResponse,
      }}
    >
      {props.children}
    </AdminPaymentContext.Provider>
  );
};
AdminPaymentProvider.propTypes = {
  children: PropTypes.any,
};

export { AdminPaymentProvider, AdminPaymentContext };
