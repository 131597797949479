/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '../../../src/app/config';

function Text(props) {
  const {
    children,
    validation = {},
    disabled,
    label,
    subLabel,
    description,
    suffix,
    placeholder,
    required,
    labelClassName = '',
    groupClassName = '',
    showErrors = true,
    id,
    field,
    rightIcon = '',
    rightIconHandle,
    rightIconClassName = '',
    rightIconGroupClassName = '',
    prefix,
    ...rest
  } = props;

  const { value = '', state = {} } = validation;
  var fieldName =
    typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
  const isRequired =
    required || (validation && validation.rules && validation.rules.required);
  let dynamicProps = {};
  if (placeholder) {
    dynamicProps.placeholder = placeholder;
  }
  if (suffix) {
    dynamicProps['aria-labelledby'] = `${id}-desc`;
  }

  return (
    <FormGroup
      className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
        isRequired ? 'required-input' : ''
      }`.trim()}
    >
      {label || subLabel || description ? (
        <Label className={labelClassName} id={`${id}-label`} htmlFor={id}>
          {label}
          {isRequired ? (
            <React.Fragment>
              <span aria-hidden="true"></span>
            </React.Fragment>
          ) : (
            ''
          )}{' '}
          {subLabel && subLabel.length > 0 ? <small>{subLabel}</small> : ''}
          {description && description.length > 0 ? (
            <span className="description">{description}</span>
          ) : (
            ''
          )}
        </Label>
      ) : (
        ''
      )}
      <div className={`${rightIconGroupClassName}`} >
      {prefix && (
        <p className="prefix" id={`${id}-desc`}>
          {prefix}
        </p>
      )}
        <Input
          key="input"
          aria-describedby={
            HasErrors(validation)
            ? null
            : `error-${fieldName.replace('[', '-').replace(']', '')}-validation`
          }
          invalid={Validate.isInvalid(state)}
          valid={Validate.isValid(state)}
          value={value}
          disabled={disabled}
          required={required}
          id={id}
          name={fieldName}
          maxLength={props.maxLength}
          {...dynamicProps}
          {...rest}
        />
        {rightIcon && (
          <img
            width="20px"
            className={`${rightIconClassName}`}
            src={rightIcon}
            onClick={rightIconHandle}
          />
        )}
         {showErrors ? <Errors validation={validation} field={field} showErrors={showErrors} /> : ''}
      </div>
      {suffix && (
        <p className="suffix" id={`${id}-desc`}>
          {suffix}
        </p>
      )}
      {children}
    </FormGroup>
  );
}

Text.propTypes = {
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Text placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Text description text
   */
  description: PropTypes.string,
  /**
   * Text descriptionExample text
   */
  descriptionExample: PropTypes.string,
  /**
   * Text input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Date ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Style class name for input group
   */
  inputGroupClassName: PropTypes.string,
  /**
   * A prefix for the input
   */
  inputPrefix: PropTypes.string,
  /**
   * A suffix for the input
   */
  inputSuffix: PropTypes.string,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * Maximum character limit
   */
  maxLength: PropTypes.any,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Input validation
   */
  validation: PropTypes.object,
  /**
   * Check box
   */
  check: PropTypes.bool,
  /**
   * Right Icon
   */
  rightIcon: PropTypes.string,
  /**
   * Right Icon Class
   */
  rightIconClassName: PropTypes.string,
  /**
   * Right Icon Container Class
   */
  rightIconGroupClassName: PropTypes.string,
  /**
   * Right Icon Click
   */
  rightIconHandle: PropTypes.func,
  /**
   * Input validation
   */
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Text;
