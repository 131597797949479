import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { deleteBtnSign } from '../../../../assets/img';
import { Text } from '../../../../../shared/components';

function AdminConsolidateScanTableRow({
  order,
  primPackageTypes,
  onChangePackage,
  onRemoveRow,
  length,
  radioOnChange,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <>
      <td className="p-0">
        <Dropdown
          isOpen={isDropdownOpen}
          toggle={dropdownToggle}
          direction="down"
          className=""
        >
          <DropdownToggle caret className="black-text">
            {order?.primaryPack}
          </DropdownToggle>
          <DropdownMenu>
            {primPackageTypes?.map((packageType, index) => (
              <DropdownItem
                onClick={() => {
                  onChangePackage(packageType.name, order.id, 'primaryPack');
                  onChangePackage(packageType.id, order.id, 'primaryPackId');
                }}
                key={index}
              >
                {packageType?.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </td>
      <td style={{ padding: '0' }}>
        <Text
          groupClassName="no-of-item-form-group"
          labelClassName="form-label"
          id="manual-procurement-price"
          validation={{ value: order?.noOfItems }}
          showErrors={false}
          autoComplete="off"
          field="noOfItems"
          onChange={e => {
            onChangePackage(e.target.value, order.id, 'noOfItems');
          }}
          rightIconGroupClassName="form-container"
        />
      </td>
      <td className="text-center p-0">
        <Button
          className="btn-link p-0"
          style={
            length == 1 || order.scanned
              ? { opacity: '0.5', pointerEvents: 'none' }
              : {}
          }
          onClick={() => {
            onRemoveRow(order?.id);
          }}
        >
          <img alt="remove" width="35" src={deleteBtnSign} />
        </Button>
      </td>
      <td>
        <input
          type="radio"
          name="selectedPack"
          value={order.id}
          disabled={order.scanned}
          onChange={radioOnChange}
        ></input>
      </td>
    </>
  );
}

AdminConsolidateScanTableRow.propTypes = {
  order: PropTypes.any,
  primPackageTypes: PropTypes.any,
  onChangePackage: PropTypes.func,
  onChangeNoOfItem: PropTypes.func,
  onRemoveRow: PropTypes.func,
  isMobile: PropTypes.bool,
  length: PropTypes.number,
  radioOnChange: PropTypes.func,
};
export default AdminConsolidateScanTableRow;
