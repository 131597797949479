/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { useDeviceDetect } from '../hooks';

const CustomTooltip = forwardRef((props, ref) => {
  const { targetId, displayMessage, placement = 'bottom' } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { isDesktop } = useDeviceDetect();
  useEffect(() => {}, [isDesktop]);
  useImperativeHandle(ref, () => {
    return {
      toggle,
    };
  });

  return (
    <Tooltip
      placement={placement}
      isOpen={tooltipOpen}
      target={targetId}
      toggle={toggle}
    >
      {displayMessage}
    </Tooltip>
  );
});

CustomTooltip.propTypes = {
  targetId: PropTypes.any,
  displayMessage: PropTypes.string,
  placement: PropTypes.string,
};

export default CustomTooltip;
