import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'reactstrap';
import { S3_BUCKET_URL } from '../../../../environment';
import { useDeviceDetect } from '../../../../shared/hooks';
// import { SupplierContext } from '../../../../apis/supplier';
function ProductCard({ product, index }) {
  //   const supplierContext = useContext(SupplierContext);
  //   const {} = supplierContext;
  //   const {} = supplierContext.state;
  //   const { t } = useTranslation();
  const [isShowBuyerName, setIsShowBuyerName] = useState(false);
  const { isMobile } = useDeviceDetect();

  return (
    <div className="supplier-product-card-container">
      <Card className="supplier-product-card shadow-lg">
        <div className="d-flex flex-sm-column justify-content-between">
          <div className="d-flex">
            <div className="supplier-product-card-image-container">
              <img
                loading="lazy"
                className="supplier-product-card-image"
                src={S3_BUCKET_URL + 'Products/' + product?.productImage}
              />
            </div>
            {/* <div className="align-self-center ml-1">
              <p className="product-title">Cabbage</p>
            </div> */}
          </div>
          <div className="align-self-center me-2 me-sm-0">
            <p className="supplier-product-title">
              {product.productName.length > 8 && !isMobile ? (
                <span
                  id={'buyerName' + (index + 1)}
                  style={{ paddingRight: '5px' }}
                  className="buyer-info-icon"
                  onClick={() => setIsShowBuyerName(!isShowBuyerName)}
                  onBlur={() => setIsShowBuyerName(false)}
                >
                  {product.productName.substring(0, 7) + '...'}
                  <Tooltip
                    placement="top"
                    isOpen={isShowBuyerName}
                    autohide={false}
                    target={'buyerName' + (index + 1)}
                    toggle={() => setIsShowBuyerName(!isShowBuyerName)}
                  >
                    {product.productName}
                  </Tooltip>
                </span>
              ) : isMobile ? (
                <div style={{ maxWidth: '45vw' }}>{product.productName}</div>
              ) : (
                product.productName
              )}
            </p>
          </div>
          <div className="align-self-center ">
            <p className="supplier-product-quantity">
              {product.totalQuantity + product.unitMeasure}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}
ProductCard.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
};

export default ProductCard;
