import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import { SupplierManagementContext } from '../../../../apis/admin';
import { excelIcon } from '../../../assets/img/';
import closeIcon from '../../../assets/icons/Close.svg';
// import qrIcon from '../../../assets/icons/QR.svg';
import { CustomModal } from '../../../../shared/components';
import InnerBody from '../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import { Session } from '../../../../app/config';
// import QrCodePopup from './QrCodePopup';
import html2pdf from 'html2pdf.js';
import { SessionContext } from '../../../../apis/authentication';

function SupplierAccountManagement() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const ref = useRef();
  const supplierMgmContext = useContext(SupplierManagementContext);
  const {
    uploadFile,
    getSuppliers,
    getPreviousQRCodeCount,
    // getQRCodes,
  } = supplierMgmContext;
  const {
    supplierList,
    uploadFileResponse,
    // existingQrCodeResponse,
    qrCodes,
  } = supplierMgmContext.state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  // const [qrCodeModel, setQrCodeModel] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const sessionContext = useContext(SessionContext);
  const { user } = sessionContext.state;
  const handleClick = () => {
    ref.current.click();
  };
  // const toggleQrcode = () => setQrCodeModel(!qrCodeModel);

  const upload = encodedFile => {
    const user = Session.getUser();
    let supplier;
    supplierList?.res.forEach(sup => {
      if (sup?.supplierCode == supplierId) {
        supplier = sup;
      }
    });
    if (encodedFile) {
      uploadFile({
        fileName: file?.name,
        masterFileType: 'SupplierMaster',
        userId: user.username,
        supplierCompanyId: supplier?.supplierCompanyId,
        excelBase64: encodedFile,
      });
    }
    setFile(null);
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  const toggleErrorPopup = () => {
    setErrorPopupModal(!errorPopupModal);
  };

  useEffect(() => {
    if (uploadFileResponse?.res) {
      setIsModalOpen(true);
    } else if (uploadFileResponse?.err) {
      setErrorPopupModal(prevProps => !prevProps);
    }
    ref.current.value = null;
    setSupplierId(null);
  }, [uploadFileResponse]);

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={'Upload failure'}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {uploadFileResponse?.err}
    </CustomModal>
  );

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const newBaseString = reader.result.split('base64,');
      upload(btoa(newBaseString[1]));
      removeFile();
    };
    reader.onerror = function() {
      //return null;
    };
  }
  const handleChangeFile = e => {
    setFile(e.target.files[0]);
  };

  const removeFile = () => {
    setFile(null);
    ref.current.value = null;
  };

  const modalToggle = () => setIsModalOpen(!isModalOpen);
  const dropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);

  const handleUploadFile = () => {
    if (supplierId === null) {
      setError(t('PLEASE_SELECT_SUPPLIER_ID'));
      return;
    }
    setError(null);
    getBase64(file);
  };

  // const generateQRcodePopup = () => {
  //   if (supplierId === null) {
  //     setError(t('PLEASE_SELECT_SUPPLIER_ID'));
  //     return;
  //   }
  //   let supplier;
  //   supplierList?.res.forEach(sup => {
  //     if (sup?.supplierCode == supplierId) {
  //       supplier = sup;
  //     }
  //   });
  //   getPreviousQRCodeCount(supplier?.supplierCompanyId);
  //   setQrCodeModel(!qrCodeModel);
  // };
  // const handleDownload = count => {
  //   const reqBody = {
  //     QRCodeCount: parseInt(count),
  //   };
  //   let supplier;
  //   supplierList?.res.forEach(sup => {
  //     if (sup?.supplierCode == supplierId) {
  //       supplier = sup;
  //     }
  //   });
  //   getQRCodes(supplier?.supplierCompanyId, reqBody);
  //   setQrCodeModel(!qrCodeModel);
  // };
  useEffect(() => {
    if (qrCodes?.res) {
      const QrHtml = atob(qrCodes?.res);
      if (QrHtml) {
        var opt = {
          filename: 'sillara-supplier-qr-code.pdf',
          jsPDF: {
            unit: 'px',
            format: [1200, 1750],
            orientation: 'portrait',
          },
        };
        html2pdf()
          .from(QrHtml)
          .set(opt)
          .save();
      }
    }
  }, [qrCodes]);

  return (
    <div className="supplier-account-management">
      <InnerBody topic={t('SUPPLIER_ACCOUNT_MANAGEMENT')} isDisable={true}>
        <Col sm={12} md={6} className="mb-4">
          <small style={{ fontSize: '14px' }}>Supplier ID</small>
          <Dropdown
            isOpen={isDropdownOpen}
            toggle={dropdownToggle}
            direction="down"
            className="mt-2"
          >
            <DropdownToggle
              caret
              className={supplierId === null ? 'grey-text' : 'black-text'}
            >
              {supplierId === null ? t('SELECT_A_SUPPLIER_ID') : supplierId}
            </DropdownToggle>
            <DropdownMenu>
              {supplierList?.res?.map((supplier, index) => (
                <DropdownItem
                  onClick={e => {
                    setSupplierId(e.currentTarget.textContent);
                    let supplier;
                    supplierList?.res.forEach(sup => {
                      if (sup?.supplierCode == e.currentTarget.textContent) {
                        supplier = sup;
                      }
                    });
                    getPreviousQRCodeCount(supplier?.supplierCompanyId);
                  }}
                  key={index}
                >
                  {supplier?.supplierCode}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {!supplierId && error && (
            <small className="error-message dropdown-text">{error}</small>
          )}
        </Col>
        <Row>
          <Col md={6} xs={12}>
            <Card className="upload-card text-center shadow">
              <div className="rounded upload-card-body">
                {file ? (
                  <>
                    <div className="upload-card-icon-container">
                      <img
                        src={excelIcon}
                        alt="Excel Icon"
                        className="m-auto upload-card-icon"
                      />
                      <img
                        src={closeIcon}
                        alt="home icon"
                        className="upload-card-close-icon"
                        onClick={removeFile}
                      />
                    </div>
                    <p className="upload-card-text">{file.name}</p>
                  </>
                ) : (
                  <div className="upload-card-icon-container">
                    <img
                      src={excelIcon}
                      alt="Excel Icon"
                      className="m-auto upload-card-icon"
                    />
                  </div>
                )}
                {!file && (
                  <p className="upload-card-text">
                    Upload Supplier Sillara Product List Here
                  </p>
                )}
                <div className="d-flex flex-column">
                  <Button className="browse-card-button" onClick={handleClick}>
                    {t('BROWSE')}
                  </Button>
                  <input
                    ref={ref}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".xlsx"
                    onChange={handleChangeFile}
                  />

                  <Button
                    disabled={!file}
                    className="mt-2 upload-card-button btn-primary"
                    onClick={handleUploadFile}
                  >
                    {t('UPLOAD_PRODUCT_LIST')}
                  </Button>
                </div>
                {errorPopup()}
                <CustomModal
                  isOpen={isModalOpen}
                  toggle={modalToggle}
                  isScrollable={true}
                  enableFooter={false}
                  title="Upload Confirmation"
                  modalstyleClassName="modal-style"
                  modalContentContainer="modal-container"
                  modalHeaderClassName="modal-header"
                  modalheaderTopic="modal-header-topic mt-0 mb-3"
                  modalFooterClassName="modal-footer"
                  modalBodyClassName="small"
                >
                  {t(
                    'PRODUCT_LIST_UPLOADED_AFTER_COMPLETED_YOU_WILL_RECEIVE_SMS',
                    {
                      PHONE_NUMBER: user?.attributes?.phone_number,
                    },
                  )}
                  <p>
                    <Button
                      className="my-3 btn-primary"
                      id="modal-footer-btn-save"
                      onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                      {t('OK')}
                    </Button>
                  </p>
                </CustomModal>
              </div>
            </Card>
          </Col>
          {/* <Col md={6} xs={12}>
            <Card className="upload-card text-center shadow">
              <div className="rounded upload-card-body">
                <div className="upload-card-icon-container">
                  <img
                    src={qrIcon}
                    alt="qr icon"
                    style={{ height: '55px', width: '55px' }}
                    className="m-auto upload-card-icon"
                    onClick={removeFile}
                  />
                </div>
                <p className="upload-card-text">Generate Your QR Codes Here</p>
                <Button
                  className="upload-card-button btn-primary"
                  onClick={generateQRcodePopup}
                >
                  Generate QR Codes
                </Button>

                <CustomModal
                  isOpen={qrCodeModel}
                  toggle={toggleQrcode}
                  isScrollable={true}
                  enableFooter={false}
                  title={t('GENERATE_QR_CODES')}
                  modalstyleClassName="modal-style"
                  modalContentContainer="modal-container"
                  modalHeaderClassName="modal-header"
                  modalheaderTopic="modal-header-topic m-auto"
                  modalFooterClassName="modal-footer"
                >
                  <QrCodePopup
                    supplierId={supplierId}
                    error={qrCodes?.err}
                    existingQrCodeResponse={existingQrCodeResponse}
                    handleDownload={item => handleDownload(item)}
                  />
                </CustomModal>
              </div>
            </Card>
          </Col> */}
        </Row>
      </InnerBody>
    </div>
  );
}

export default SupplierAccountManagement;
