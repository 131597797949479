import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row, Table, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ManualProcurementContext } from '../../../../../apis/admin';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { PACKAGING_STATUS } from '../../../../../shared/constants/constants';
import { warning } from '../../../../assets/img';
import StatusContainer from './StatusContainer';
import { useDeviceDetect } from '../../../../../shared/hooks';

function OrderLoading({
  deliveryDate,
  deliverySlot,
  baseDeliveryName,
  isRefresh,
  setIsRefresh,
  setQueryParam,
}) {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const manualProcurementContext = useContext(ManualProcurementContext);
  const { getOrderLoadingList, sendForOrderLoading } = manualProcurementContext;
  const { orderLoadingListRes } = manualProcurementContext.state;
  const [items, setItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedPackageIds, setSelectedPackageIds] = useState([]);
  const [isAvalaible, setIsAvalaible] = useState(true);
  const [error, setError] = useState('');
  const [isShowStatusInfo, setIsShowStatusInfo] = useState(false);

  useEffect(() => {
    setCurrentIndex(0);
    handlegetOrderLoadingSummary();
    setError('');
  }, []);

  useEffect(() => {
    if (isRefresh) {
      handlegetOrderLoadingSummary();
      setError('');
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    if (orderLoadingListRes?.res?.length === 0) {
      setIsAvalaible(false);
    } else {
      setIsAvalaible(true);
    }
    setItems(orderLoadingListRes.res);
  }, [orderLoadingListRes?.res]);

  const handlegetOrderLoadingSummary = () => {
    getOrderLoadingList(
      setQueryParam(deliveryDate, baseDeliveryName, deliverySlot),
    );
  };

  const onMove = (_, current) => {
    setCurrentIndex(current);
    setError('');
    setSelectedPackageIds([]);
  };

  const onPackageSelect = packageId => {
    setError('');
    let tempselectedPackageIds = [...selectedPackageIds];
    if (tempselectedPackageIds.includes(packageId)) {
      const filteredvalues = tempselectedPackageIds.filter(
        id => id !== packageId,
      );
      setSelectedPackageIds(filteredvalues);
    } else {
      tempselectedPackageIds.push(packageId);
      setSelectedPackageIds(tempselectedPackageIds);
    }
  };

  const getOrderStatus = statusValue => {
    //this should change
    switch (statusValue) {
      case PACKAGING_STATUS.NOT_REQUESTED.value:
        return PACKAGING_STATUS.NOT_REQUESTED.value;
      case PACKAGING_STATUS.REQUESTED.value:
        return PACKAGING_STATUS.REQUESTED.value;
      case PACKAGING_STATUS.SENT_BY_SUPPLIER.value:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.RECEIVED.value:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.SCANNED.value:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.LOADED.value:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.UNLOAD_SCANNED.value:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
      case PACKAGING_STATUS.UNLOADED.value:
        return PACKAGING_STATUS.SENT_BY_SUPPLIER.value;
    }
  };

  const onHandleSend = () => {
    if (selectedPackageIds.length === 0) {
      setError('Please select the order that needs to be sent');
    } else {
      sendForOrderLoading(
        selectedPackageIds,
        PACKAGING_STATUS.SENT_BY_SUPPLIER.id,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          setSelectedPackageIds([]);
          handlegetOrderLoadingSummary();
        }
      });
    }
  };
  return (
    <>
      {!isAvalaible ? (
        <div className="py-5 custom-empty-message">
          <p>{t('NOT_AVAILABLE_AT_THE_MOMENT._PLEASE_CHECK_LATER')}</p>
        </div>
      ) : (
        <>
          {items?.length !== 0 && (
            <div className="manual-procuremnet-order-loading-summary">
              <Splide
                options={{
                  rewind: true,
                  arrows: items?.length !== 1,
                  pagination: false,
                }}
                hasTrack={false}
                onMove={(a, b, c) => onMove(a, b, c)}
              >
                <SplideTrack>
                  {items?.map(vehicle => (
                    <SplideSlide key={vehicle.vehicleId}>
                      <div className="table-fixed">
                        <Table>
                          <thead>
                            <tr className="table-header">
                              <th colSpan={3}>
                                <p>
                                  {vehicle.vehicleType} /{' '}
                                  {vehicle.vehicleNumber} / {vehicle.location}
                                </p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="head">
                              <td className="th-tag-no">
                                {isMobile ? t('TAG_NO') : t('TAG_NUMBER')}
                              </td>
                              <td className="th-no-of-items">
                                {t('NO_OF_ITEMS')}
                              </td>
                              <td className="th-packaging-type">
                                {isMobile
                                  ? t('PRIM_PACK')
                                  : t('PRIMARY_PACKAGING')}
                              </td>
                            </tr>
                            {vehicle.purchaseOrders?.map(
                              (order, orderIndex) => (
                                <tr
                                  key={orderIndex}
                                  className={`body ${getOrderStatus(
                                    order.loadStatus,
                                  )}  ${order.loadStatus !==
                                    PACKAGING_STATUS.REQUESTED.value &&
                                    'pe-none'}
                          ${selectedPackageIds.includes(order.packageId) &&
                            'selected-row'}`}
                                  onClick={() => {
                                    if (
                                      order.loadStatus ===
                                      PACKAGING_STATUS.REQUESTED.value
                                    ) {
                                      onPackageSelect(order.packageId);
                                    }
                                  }}
                                >
                                  <td className="td-tag-no">
                                    {isMobile ? (
                                      <>
                                        <small className="d-block">
                                          Tag No.
                                        </small>
                                        <small className="d-block table-data-1">
                                          {order?.tagNo}
                                        </small>
                                      </>
                                    ) : (
                                      order.tagNo
                                    )}
                                  </td>
                                  <td className="td-no-of-items">
                                    {isMobile ? (
                                      <>
                                        <small className="d-block">
                                          No of Item
                                        </small>
                                        <small className="d-block table-data-2">
                                          {order?.noOfItems}
                                        </small>
                                      </>
                                    ) : (
                                      order.noOfItems
                                    )}
                                  </td>
                                  <td className="td-packaging-type">
                                    {isMobile ? (
                                      <>
                                        <small className="d-block">
                                          Prim Pack
                                        </small>
                                        <small className="d-block table-data-3">
                                          {order?.packagingType}
                                        </small>
                                      </>
                                    ) : (
                                      order.packagingType
                                    )}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </SplideSlide>
                  ))}
                </SplideTrack>
                <Container className="arrows-horizontally-scroller p-0">
                  <div className="splide__arrows">
                    <Row>
                      <Col className="p-0">
                        <a
                          className={`splide__arrow--prev ${currentIndex ===
                            0 && 'btn-disable d-none'}`}
                          disabled={currentIndex == 0}
                        >
                          <span className="left"></span>
                        </a>
                        <a
                          className={`splide__arrow--next ${currentIndex ===
                            items?.length - 1 && 'btn-disable d-none'}`}
                          disabled={currentIndex == items?.length - 1}
                        >
                          <span className="right"></span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Splide>

              <div className="button-container">
                {/* {isShowStatusInfo && <StatusContainer />} */}
                <Row>
                  <Button
                    className="warning-icon-button"
                    onClick={() => setIsShowStatusInfo(!isShowStatusInfo)}
                    onBlur={() => setIsShowStatusInfo(false)}
                  >
                    <img
                      src={warning}
                      width="28"
                      height="28"
                      className="warning-icon"
                      id="statusContainer"
                    />
                    <Tooltip
                      placement="top"
                      isOpen={isShowStatusInfo}
                      autohide={false}
                      target="statusContainer"
                      toggle={() => setIsShowStatusInfo(!isShowStatusInfo)}
                    >
                      <StatusContainer />
                    </Tooltip>
                  </Button>
                </Row>
                <div className="buttons">
                  <Row className="mb-1">
                    {error && (
                      <small className="error-message mt-1">{error}</small>
                    )}
                    <Col md={9} sm={0}></Col>
                    <Col md={3} sm={12}>
                      <Button
                        color="primary"
                        type="submit"
                        className="btn-primary send-btn"
                        disabled={
                          items[currentIndex]?.purchaseOrders?.filter(
                            pack =>
                              pack.loadStatus ===
                              PACKAGING_STATUS.REQUESTED.value,
                          ).length === 0
                        }
                        onClick={onHandleSend}
                      >
                        {t('SEND')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

OrderLoading.propTypes = {
  isMobile: PropTypes.bool,
  deliveryDate: PropTypes.any,
  deliverySlot: PropTypes.any,
  baseDeliveryName: PropTypes.any,
  setQueryParam: PropTypes.func,
  isRefresh: PropTypes.bool,
  setIsRefresh: PropTypes.func,
};

export default OrderLoading;
