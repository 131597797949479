import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { BuyerManagementContext } from '../../../../../apis/admin';
import { AdminContext } from '../../../../../apis/admin';
import ProgressAdminDetailModal from './ProgressAdminDetailModal';
import { PURCHASE_ORDER_STATUS } from './constant';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import {
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../../shared/constants/constants';
import moment from 'moment';

function QualityCheckDueBuyers({
  selectedBaseName,
  selectedVehicle,
  setSelectedPurchaseOrder,
  selectedDate,
  selectedDeliverySlot,
  setSelectedVehicle,
}) {
  const { t } = useTranslation();
  const buyerManagementContext = useContext(BuyerManagementContext);
  const {
    getVehiclesQualityCheckSummary,
    patchPurchaseOrder,
  } = buyerManagementContext;
  const adminContext = useContext(AdminContext);
  const { handleAdminDetails } = adminContext;
  const [adminData, setAdminData] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSelectedOrder(null);
    handleAdminDetails().then(res => {
      if (res.err) {
        return;
      } else {
        setAdminData(res.res);
      }
    });
  }, []);

  useEffect(() => {
    getVehiclesQualityCheckSummary(
      selectedBaseName.id,
      selectedDeliverySlot.id,
      formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
    ).then(res => {
      if (res.err) {
        return;
      } else {
        let tempPurchaseOrder = res.res?.filter(
          vehicle => vehicle.vehicleId === selectedVehicle.vehicleId,
        )[0];
        setSelectedVehicle(tempPurchaseOrder);
      }
    });
  }, []);

  const onSelectOrder = purchaseOrder => {
    getVehiclesQualityCheckSummary(
      selectedBaseName.id,
      selectedDeliverySlot.id,
      formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
    ).then(res => {
      if (res.err) {
        return;
      } else {
        const tempBuyerQualityCheckAdmin = res.res
          ?.filter(
            vehicle => vehicle.vehicleId === selectedVehicle.vehicleId,
          )[0]
          .purchaseOrders.filter(
            order => order.purchaseOrderId === purchaseOrder.purchaseOrderId,
          )[0].buyerQualityCheckAdmin;

        if (
          !tempBuyerQualityCheckAdmin ||
          tempBuyerQualityCheckAdmin?.id === adminData.id
        ) {
          handleUpdatePurchaseOrder(purchaseOrder);
        } else {
          setSelectedOrder(purchaseOrder);
          setIsModalOpen(true);
        }
      }
    });
  };

  const handleUpdatePurchaseOrder = purchaseOrder => {
    patchPurchaseOrder(
      purchaseOrder?.orderId,
      purchaseOrder?.purchaseOrderId,
      adminData.id,
    ).then(res => {
      if (res.err) {
        return;
      } else {
        setSelectedPurchaseOrder(purchaseOrder);
      }
    });
  };

  const getOrderStatus = statusId => {
    switch (statusId) {
      case PURCHASE_ORDER_STATUS.LOADED.id:
        return PURCHASE_ORDER_STATUS.LOADED.value;
      case PURCHASE_ORDER_STATUS.UNLOADED.id:
        return PURCHASE_ORDER_STATUS.UNLOADED.value;
      case PURCHASE_ORDER_STATUS.BUYERCONFIRMED.id:
        return PURCHASE_ORDER_STATUS.BUYERCONFIRMED.value;
      case PURCHASE_ORDER_STATUS.COMPLETE.id:
        return PURCHASE_ORDER_STATUS.COMPLETE.value;
    }
  };
  const isRowStatusQualityDue = order => {
    if (
      !order.buyerLiked &&
      order.buyerLiked !== null &&
      order.statusId !== PURCHASE_ORDER_STATUS.COMPLETE.id
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div className="quality-check-due-buyers-container">
        {selectedVehicle && (
          <>
            <p>
              {t('DELIVERY_SLOT')}:{' '}
              {moment(selectedDate).format('DD/MM/YYYY') +
                ' - (' +
                selectedDeliverySlot.startTime +
                '-' +
                selectedDeliverySlot.endTime +
                ')'}
            </p>
            {selectedVehicle.purchaseOrders?.length > 0 ? (
              <div className="tableFixHead">
                <Table bordered>
                  <thead>
                    <tr className="main-header">
                      <th
                        colSpan={3}
                        className=" user-profile-head text-center"
                      >
                        {selectedVehicle.numberPlate}
                      </th>
                    </tr>
                    {/* </thead>
                  <thead> */}
                    <tr className="detail-header">
                      <th className="head rank-head">{t('RANK')}</th>
                      <th className="head buyer-head">{t('BUYER')}</th>
                      <th className="head status-head">{t('STATUS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedVehicle.purchaseOrders?.map(order => {
                      return (
                        <tr
                          key={order.id}
                          className={
                            isRowStatusQualityDue(order)
                              ? 'highlight-row cursor-pointer'
                              : 'disable-row'
                          }
                          onClick={() => {
                            if (isRowStatusQualityDue(order))
                              onSelectOrder(order);
                          }}
                        >
                          <td
                            className={`rank ${
                              isRowStatusQualityDue(order)
                                ? 'highlight-row'
                                : 'disable-row'
                            }
                          }`}
                          >
                            {order.rank}
                            <span className="d-sm-none buyer">
                              <br />
                              {order.buyerCompany}
                            </span>
                          </td>
                          <td
                            className={`buyer buyer-hide ${
                              isRowStatusQualityDue(order)
                                ? 'highlight-row'
                                : 'disable-row'
                            }
                          }`}
                          >
                            {order.buyerCompany}
                          </td>
                          <td
                            className={`status ${
                              isRowStatusQualityDue(order)
                                ? 'highlight-row'
                                : 'disable-row'
                            }
                          }`}
                          >
                            {isRowStatusQualityDue(order)
                              ? t('QUALITY_CHECK_DUE')
                              : getOrderStatus(order.statusId)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="py-5 custom-empty-message">
                <p>{t('NO_ORDERS_AVAILABLE')}</p>
              </div>
            )}
          </>
        )}
      </div>
      {isModalOpen && (
        <ProgressAdminDetailModal
          order={selectedOrder}
          setIsModalOpen={setIsModalOpen}
          setSelectedOrder={setSelectedOrder}
        />
      )}
    </div>
  );
}

QualityCheckDueBuyers.propTypes = {
  selectedVehicle: PropTypes.object,
  setSelectedPurchaseOrder: PropTypes.func,
  selectedDate: PropTypes.object,
  selectedDeliverySlot: PropTypes.object,
  selectedBaseName: PropTypes.object,
  setSelectedVehicle: PropTypes.func,
};

export default QualityCheckDueBuyers;
