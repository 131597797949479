import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Table, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from '../../../../../shared/hooks';
import InnerBody from '../../../../../content/InnerBody';
import { addPlusSign } from '../../../../assets/img';
import AdminConsolidateScanTableRow from './AdminConsolidateScanTableRow';
import {
  BuyerManagementContext,
  AdminContext,
} from '../../../../../apis/admin';
import { CustomModal, Scanner } from '../../../../../shared/components';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { LoadingContext } from '../../../../../apis/LoadingContext';
import { Text } from '../../../../../shared/components';
import { successMp3, successWav, successOgg } from '../../../../assets/audio';

function SupplierQualityCheckAdminConsolidateScan() {
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  const { isMobile } = useDeviceDetect();
  const [detailedOrders, setDetailedOrders] = useState([]);
  const [error, setError] = useState(null);
  const buyerManagementContext = useContext(BuyerManagementContext);
  const {
    getPackageTypes,
    getSelectedOrderOfySupplier,
    getProductCount,
    assignQrCode,
    validateQrCodePackages,
    transferQrCodePackages,
    updatePurchaseOrderAdmin,
  } = buyerManagementContext;
  const [primPackageTypes, setPrimPackageTypes] = useState([]);
  const { supplierCompanyId, purchaseOrderId } = useParams();
  const [isStartScan, setIsStartScan] = useState(false);
  const [order, setOrder] = useState(null);
  const [productsCount, setProductsCount] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [tempHref, setTempHref] = useState('');
  const navigate = useNavigate();
  const pathQuery = new URLSearchParams(useLocation().search);
  const [currentQrCode, setCurrentQrCode] = useState(null);
  const [enableScanBtn, setEnableScanBtn] = useState(false);
  useBeforeunload(event => {
    event.preventDefault();
  });
  const [popUpResponse, setPopUpResponse] = useState(null);
  const adminContext = useContext(AdminContext);
  const { handleAdminDetails } = adminContext;
  var audioElement = document.getElementById('qrScannerAudioControl');

  const getManualProcuremntId = () => {
    if (pathQuery.get('manualProcurement') === 'true') {
      return supplierCompanyId;
    }
    return '';
  };
  const getSupplierId = () => {
    if (pathQuery.get('manualProcurement') === 'true') {
      return '';
    }
    return supplierCompanyId;
  };
  const isManualProcurement = () => {
    return pathQuery.get('manualProcurement') === 'true';
  };

  useEffect(() => {
    if (!isManualProcurement()) {
      handleAdminDetails().then(res => {
        updatePurchaseOrderAdmin(
          res.res.id,
          supplierCompanyId,
          purchaseOrderId,
        );
      });
    }

    setDetailedOrders([
      {
        id: 1,
        primaryPack: '',
        noOfItems: '',
      },
    ]);
    getSelectedOrderOfySupplier(
      getSupplierId(),
      purchaseOrderId,
      isManualProcurement(),
    ).then(res => {
      setOrder(res);
      getProductCount(
        purchaseOrderId,
        getSupplierId(),
        getManualProcuremntId(),
        false,
      ).then(res => {
        setProductsCount(res?.res?.productsCount);
        getPackageTypes().then(res => {
          setPrimPackageTypes(res);
        });
      });
    });
  }, []);

  useEffect(() => {
    const tempOrder = detailedOrders?.filter(ord => ord?.scanned);
    if (tempOrder.length == detailedOrders.length) {
      let total = 0;

      detailedOrders.forEach(element => {
        if (element.primaryPack === '') {
          return;
        }
        total = total + parseInt(element.noOfItems);
      });
      if (productsCount && total == productsCount) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    } else {
      setCompleted(false);
    }
    changeScanButtonEnable();
  }, [detailedOrders]);

  const addQrCodeToList = qrValue => {
    const tempOrders = [...detailedOrders];

    const tempOrder = tempOrders?.filter(ord => ord?.id == selectedRow)[0];
    const index = tempOrders.indexOf(tempOrder);
    tempOrders[index].scanned = true;
    tempOrders[index].SupplierQRCode = qrValue;
    setDetailedOrders(tempOrders);
    setIsStartScan(false);
    var ele = document.getElementsByName('selectedPack');
    for (var i = 0; i < ele.length; i++) ele[i].checked = false;

    setSelectedRow(null);
  };
  const changeScanButtonEnable = () => {
    let total = 0;
    for (let element in detailedOrders) {
      if (detailedOrders[element].primaryPack === '') {
        return;
      }

      total = total + parseInt(detailedOrders[element].noOfItems);
    }

    if (productsCount && total == productsCount) {
      setEnableScanBtn(true);
    } else {
      setEnableScanBtn(false);
    }
  };
  const validateQrCode = qrcode => {
    validateQrCodePackages(qrcode)
      .then(data => {
        if (data?.message || data?.error) {
          openResponsePopup('Unable to Validate the Qr', data?.message);
          return true;
        }
        if (data === null) {
          audioElement.play();
          addQrCodeToList(qrcode);
          return false;
        }
        setCurrentQrCode(data);
        setSelectedRow(null);
        setTransformFunctionPopUp(true);
        return true;
      })
      .catch(err => {
        openResponsePopup('Unable to Validate the Qr', err);
        return true;
      });
  };

  const onHandleScan = qrValue => {
    let isFountDuplicate = false;
    for (const pack of detailedOrders) {
      if (pack.SupplierQRCode === qrValue) {
        setCurrentQrCode({
          id: pack.id,
          isPrimaryPackage: true,
          qrCodeId: qrValue,
          numberOfItems: pack.noOfItems,
          packageType: {
            id: '',
            name: pack.primaryPack === '' ? 'N/A' : pack.primaryPack,
          },
        });
        setIsDeleteFunctionPopUp(true);
        isFountDuplicate = true;
        break;
      }
    }

    if (isFountDuplicate) {
      return;
    }
    if (selectedRow === null) {
      setError('Select a row');
      setTimeout(() => {
        setError(null);
      }, 10000);
      return;
    }
    validateQrCode(qrValue);
  };

  const onRemoveRow = id => {
    let newOrders = [];
    let x = 1;
    for (let ord of detailedOrders) {
      if (ord?.id === id) {
        continue;
      }
      ord.id = x;
      ++x;
      newOrders.push(ord);
    }
    setDetailedOrders(newOrders);
    var ele = document.getElementsByName('selectedPack');
    for (var i = 0; i < ele.length; i++) ele[i].checked = false;
    setSelectedRow(null);
  };

  const onDeleteScannedQrCode = id => {
    let newOrders = [];
    for (let ord of detailedOrders) {
      if (ord?.id === id) {
        ord.scanned = false;
        ord.SupplierQRCode = null;
      }
      newOrders.push(ord);
    }
    setDetailedOrders(newOrders);
    var ele = document.getElementsByName('selectedPack');
    for (var i = 0; i < ele.length; i++) ele[i].checked = false;
    setSelectedRow(null);
  };

  const addNewRow = () => {
    let temp = [...detailedOrders];

    temp.push({
      id: detailedOrders.length + 1,
      primaryPack: '',
      noOfItems: '',
    });
    setDetailedOrders(temp);

    var ele = document.getElementsByName('selectedPack');
    for (var i = 0; i < ele.length; i++) ele[i].checked = false;

    setSelectedRow(null);
  };
  const onChangePackage = (value, id, property) => {
    let tempaArray = [];
    detailedOrders?.forEach(ord => {
      if (ord?.id === id) {
        ord[property] = value;
        tempaArray.push(ord);
      } else {
        tempaArray.push(ord);
      }
    });

    setDetailedOrders(tempaArray);
  };
  /*
  useEffect(() => {
    history.pushState(null, null, location.href);
    setTimeout(() => {
      window.onpopstate = function() {
        const el = document.getElementById('back-confirm-modal');
        const el2 = el?.closest('.modal');
        if (!el || !el2?.classList?.contains('show')) {
          history.go(1);
          setExitModalOpen(true);
        }
        // }
      };
    }, 100);
    var elements = document.getElementsByTagName(`a`);
    for (var i = 0, len = elements.length; i < len; i++) {
      if (!elements[i]?.classList?.contains('active')) {
        elements[i].onclick = function(e) {
          if (
            location.pathname ==
            `/admin/supplierqualitycheck/consolidateScan/${supplierCompanyId}/${purchaseOrderId}/${deliverySlotId}`
          ) {
            e.preventDefault();
            const e2 = e?.target?.closest(`a`);
            console.log(e2.href, location);
            setTempHref(e2.href.split('3000')[1]);
            setExitModalOpen(true);
          }
        };
      }
    }
    return () => {
      window.onpopstate = () => {};
    };
  }, []);
*/
  const radioOnChange = e => {
    setSelectedRow(e.target.value);
  };

  const onComplete = () => {
    const payload = detailedOrders.map(x => ({
      PackageTypeId: x.primaryPackId,
      IsPrimaryPackage: true,
      NumberOfItems: parseInt(x.noOfItems),
      QRCodeId: x.SupplierQRCode,
      SupplierCompanyId: isManualProcurement()
        ? null
        : parseInt(supplierCompanyId),
      ItemNo: x.id,
      IsManualProcurement: isManualProcurement(),
    }));
    assignQrCode(purchaseOrderId, payload)
      .then(res => {
        if (res?.err) {
          openResponsePopup('Failed', res.err);
          return;
        }
        if (isManualProcurement()) {
          navigate(`/admin/manualprocurementqualitycheck/`);
        } else {
          navigate(`/admin/supplierqualitycheck/`);
        }
      })
      .catch(err => {
        openResponsePopup('Failed', err.err);
      });
  };

  const [isDeleteFunctionPopUp, setIsDeleteFunctionPopUp] = useState(false);
  const deleteFunctionPopUp = () => {
    return (
      <CustomModal
        isOpen={isDeleteFunctionPopUp}
        isScrollable={true}
        enableFooter={false}
        title={'Already Scanned'}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        <>
          <p>The package has been already scanned. </p>
          <p style={{ textAlign: 'left' }}>The order details</p>
          <ul style={{ textAlign: 'left' }}>
            {!isManualProcurement() && (
              <li>
                Supplier Code :{' '}
                {order?.supplierName.substring(0, 3) + '-' + order?.supplierId}
              </li>
            )}
            <li>Tag No : {order?.tagNumber}</li>
            {currentQrCode?.isPrimaryPackage ? (
              <>
                <li>Primary pack type : {currentQrCode?.packageType?.name}</li>
                <li>No of items : {currentQrCode?.numberOfItems}</li>
              </>
            ) : (
              <li>Item No : {currentQrCode?.itemNo}</li>
            )}
          </ul>
          <Col
            className="d-flex justify-content-evenly"
            style={{ width: '100%' }}
          >
            <Button
              className="btn-outline-primary"
              onClick={() => {
                onDeleteScannedQrCode(currentQrCode.id);
                setIsDeleteFunctionPopUp(false);
                openResponsePopup(
                  'Successful',
                  'You have successfully deleted the QR code details',
                );
                setCurrentQrCode(null);

                return;
              }}
            >
              Delete
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                setCurrentQrCode(null);
                setIsDeleteFunctionPopUp(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </>
      </CustomModal>
    );
  };

  const transferQrCode = newCode => {
    const req = [
      {
        op: 'replace',
        path: 'QRCodeId',
        value: newCode,
      },
    ];
    transferQrCodePackages(req, currentQrCode?.packageId)
      .then(res => {
        setTransformNewQrCode('');
        setCurrentQrCode(null);
        if (res?.err) {
          openResponsePopup('Transfer Failure', res?.err);
          return;
        }
        setTransformNewQrCode('');
        openResponsePopup(
          'Successful',
          'You have successfully transferred the QR code details',
        );
      })
      .catch(err => {
        setTransformNewQrCode('');
        setCurrentQrCode(null);
        openResponsePopup('Transfer Failure', err.err);
      });
  };
  const [isTransformFunctionPopUp, setTransformFunctionPopUp] = useState(false);
  const [transformNewQrCode, setTransformNewQrCode] = useState('');
  const qrTransformFunctionPopUp = () => {
    return (
      <CustomModal
        isOpen={isTransformFunctionPopUp}
        isScrollable={true}
        enableFooter={false}
        title={'Already Scanned'}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        <>
          <p>The package has been already scanned.</p>
          <p style={{ textAlign: 'left' }}>The order details</p>
          <ul style={{ textAlign: 'left' }}>
            {!isManualProcurement() && (
              <li>
                Supplier Code : {currentQrCode?.supplierCompany?.supplierCode}
              </li>
            )}
            <li>Tag No : {currentQrCode?.purchaseOrder?.tagNumber}</li>
            {currentQrCode?.isPrimaryPackage ? (
              <>
                <li>Primary pack type : {currentQrCode?.packageType?.name}</li>
                <li>No of items : {currentQrCode?.numberOfItems}</li>
              </>
            ) : (
              <li>Item No : {currentQrCode?.itemNo}</li>
            )}
          </ul>
          <b>Transfer the details</b>
          <Text
            label={'From'}
            groupClassName="form-group"
            rightIconGroupClassName="input-group-qr-val"
            labelClassName="form-label"
            value={currentQrCode?.qrCodeId}
            showErrors={false}
            id="fromQrCodeNo"
            name="fromQrCodeNo"
            field="fromQrCodeNo"
            disabled={true}
          />
          <Text
            label={'To'}
            groupClassName="form-group"
            rightIconGroupClassName="input-group-qr-val"
            labelClassName="form-label"
            validation={{ value: transformNewQrCode }}
            onChange={e => {
              setTransformNewQrCode(e.target.value);
            }}
            placeholder={'New QR code number'}
            showErrors={false}
            id="newQrCodeNo"
            name="newQrCodeNo"
            field="newQrCodeNo"
          />
          <Col
            className="d-flex justify-content-evenly"
            style={{ width: '100%' }}
          >
            <Button
              className="btn-outline-primary"
              onClick={() => {
                transferQrCode(transformNewQrCode);
                setTransformFunctionPopUp(false);
                setCurrentQrCode(null);
                return;
              }}
            >
              Transfer
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                setCurrentQrCode(null);
                setTransformNewQrCode('');
                setTransformFunctionPopUp(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </>
      </CustomModal>
    );
  };
  const openResponsePopup = (title, des) => {
    setPopUpResponse({
      title: title,
      description: des,
    });
    setResponsePopupModal(true);
  };
  const [responePopupModal, setResponsePopupModal] = useState(false);
  const errorPopup = () => (
    <CustomModal
      isOpen={responePopupModal}
      isScrollable={true}
      title={popUpResponse?.title || ''}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {popUpResponse?.description}
      <div>
        <Button
          color="primary mt-2"
          type="submit"
          className="form-submit"
          onClick={() => {
            setPopUpResponse(null);
            setResponsePopupModal(false);
          }}
        >
          {'OK'}
        </Button>
      </div>
    </CustomModal>
  );

  const backEventFired = () => {
    setExitModalOpen(true);
  };

  useEffect(
    () => () => {
      if (!isManualProcurement()) {
        updatePurchaseOrderAdmin(0, supplierCompanyId, purchaseOrderId)
          .then(() => {})
          .catch(() => {});
      }
    },
    [],
  );

  const handleUpdatePurchaseOrderAdmin = () => {
    setExitModalOpen(false);
    if (!isManualProcurement()) {
      updatePurchaseOrderAdmin(0, supplierCompanyId, purchaseOrderId)
        .then(() => {
          if (tempHref) {
            // history.pushState(null, null, tempHref);
            navigate(tempHref);
          } else {
            history.go(-1);
          }
        })
        .catch(() => {});
    } else {
      if (tempHref) {
        // history.pushState(null, null, tempHref);
        navigate(tempHref);
      } else {
        history.go(-1);
      }
    }
  };

  return (
    <InnerBody
      onHandleBack={backEventFired}
      topic={t('SUPPLIER_QUALITY_CHECK')}
    >
      {deleteFunctionPopUp()}
      {qrTransformFunctionPopUp()}
      {errorPopup()}
      <div
        className="supplier-quality-check-admin-consolidate-scan"
        id="supplier-quality-check-admin-consolidate-scan"
      >
        <div className="sub-title">
          <div className="blue-color d-md-flex">
            <div className="me-3">
              Supplier <span className="text-green">{order?.supplierName}</span>
            </div>
            <div className="me-3">
              Tag Number <span className="text-green">{order?.tagNumber}</span>
            </div>
            <div className="me-3">
              Buyer <span className="text-green">{order?.buyerName}</span>
            </div>
          </div>
        </div>

        {!loading && (
          <>
            {error && <p className="warning">{error}</p>}
            <div className="d-md-flex">
              <Card className="mt-3 mb-0 flex-grow-1">
                <CardHeader>Total Number of Items: {productsCount}</CardHeader>
                <CardBody>
                  <div className="tableFixHead">
                    <Table bordered>
                      <thead className="table-header">
                        <tr>
                          <th width="40%">Primary Packaging</th>
                          <th width="35%">No of Items</th>
                          <th width="12.5%"></th>
                          <th width="1%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailedOrders?.length > 0 &&
                          detailedOrders.map((order, index) => (
                            <tr
                              key={index}
                              style={
                                order.scanned
                                  ? { backgroundColor: '#F2FBF8' }
                                  : {}
                              }
                            >
                              <AdminConsolidateScanTableRow
                                order={order}
                                isMobile={isMobile}
                                primPackageTypes={primPackageTypes}
                                onChangePackage={onChangePackage}
                                onRemoveRow={onRemoveRow}
                                length={detailedOrders?.length}
                                radioOnChange={radioOnChange}
                              />
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <div className="plus-btn-container align-self-end mt-3 mx-2">
                <img
                  className="plus-btn p-0"
                  alt="remove"
                  width="25"
                  src={addPlusSign}
                  onClick={addNewRow}
                />
              </div>
            </div>

            <div className="mt-3 action-button">
              <Button
                className="btn-outline-primary "
                disabled={
                  (!selectedRow &&
                    detailedOrders?.filter(ord => ord?.scanned).length === 0) ||
                  (detailedOrders?.filter(ord => ord?.scanned).length === 0 &&
                    !enableScanBtn) ||
                  productsCount < 0
                }
                onClick={() => {
                  setIsStartScan(true);
                }}
              >
                Scan
              </Button>
              {!isMobile ? <>&nbsp;&nbsp;</> : ''}
              <Button
                className="btn-primary"
                disabled={!completed}
                onClick={onComplete}
                id="complete-button"
              >
                Complete
              </Button>
            </div>
          </>
        )}
      </div>
      <CustomModal
        isOpen={isStartScan}
        toggle={() => setIsStartScan(false)}
        isScrollable={true}
        title="QR Scanner"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
      >
        <Scanner
          setStartScan={setIsStartScan}
          onHandleScan={onHandleScan}
          isSuccessSound={false}
        />
      </CustomModal>
      <CustomModal
        isOpen={exitModalOpen}
        toggle={() => {
          setExitModalOpen(false);
          setTempHref('');
        }}
        showClose={false}
        isScrollable={true}
        title="Leave Page"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
        id="back-confirm-modal"
      >
        <p>
          Are you sure you want to leave the page without completing the quality
          check?
        </p>
        <div className="d-flex justify-content-around mx-5">
          <Button
            outline
            color="primary"
            type="submit"
            className="form-submit"
            style={{ width: '75px' }}
            onClick={handleUpdatePurchaseOrderAdmin}
          >
            {t('YES')}
          </Button>
          <Button
            color="primary"
            type="submit"
            className="form-submit"
            style={{ width: '75px' }}
            onClick={() => {
              setExitModalOpen(false);
              setTempHref('');
            }}
          >
            {t('NO')}
          </Button>
        </div>
      </CustomModal>
      <audio className="d-none" id="qrScannerAudioControl" controls>
        <source src={successMp3} type="audio/mpeg" />
        <source src={successOgg} type="audio/ogg" />
        <source src={successWav} type="audio/wav" />
      </audio>
    </InnerBody>
  );
}
export default SupplierQualityCheckAdminConsolidateScan;
