import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { USER_IDLE_LOGOUT_TIME } from '../../shared/constants/constants';
import { Connection, Session, keyLock } from '../../app/config';

const SessionContext = React.createContext();
const SessionProvider = props => {
  const [user, setUser] = useState();
  const [permissions, setPermissions] = useState({
    res: null,
    err: null,
  });
  const navigate = useNavigate();
  const [session, setSession] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [logIn, setLogin] = useState(false);
  const [errors, setErrors] = useState(null);
  const [roleAvailability, setRoleAvailability] = useState('');

  const updateToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          if (session) {
            const { idToken } = session;
            setSession(idToken.jwtToken);
            Session.set(idToken.jwtToken);
            Session.setUser(cognitoUser);
            Session.setIdleStatus(true);
          }
        },
      );
    } catch (e) {
      if (e?.message === 'Refresh Token has expired') {
        logOut();
        navigate('/auth/login', { replace: true });
      }
      throw new Error('Unable to refresh Token', e);
    }
  };

  useEffect(() => {
    setInterval(function() {
      updateBasedOnActiveMode();
    }, 60000 * 5);
  }, []);

  const updateBasedOnActiveMode = () => {
    if (Session.getIdleStatus()) {
      updateToken();
    }
  };

  const getPermissions = async () => {
    setIsFetching(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('users');
      const permission = await fetch(url + `permissions`, {
        method: 'GET',
        headers: headers,
      });
      const data = await permission.json();
      Session.setPermission(keyLock.hexEncode(data));

      if (data.error) {
        setErrors({ error: 'Network Error' });
      } else {
        setPermissions(data);
      }
      setIsFetching(false);
    } catch (err) {
      setPermissions(null);
      setIsFetching(false);
    }
  };

  const getauthenticatedUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (err) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      setUser({ type: 'UNAUTHENTICATED', error: err });
    }
  };

  const setSessionDetails = async (setRoleNavigations = null) => {
    setIsFetching(true);
    try {
      const session = await Auth.currentSession();
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setSession(session.idToken.jwtToken);
      Session.set(session.idToken.jwtToken);
      Session.setUser(cognitoUser);
      setUser(cognitoUser);
      Session.setIdleStatus(true);
      setIsFetching(false);
      setLogin(true);
      const role =
        cognitoUser?.signInUserSession?.accessToken?.payload?.[
          'cognito:groups'
        ]?.[0];
      if (setRoleNavigations) {
        if (role) {
          setRoleNavigations(role);
        }
      }
      if (role) {
        setRoleAvailability('available');
      } else {
        setRoleAvailability('unavailable');
      }
    } catch (err) {
      Session.set(err);
      setIsFetching(false);
    }
  };

  const logOut = async () => {
    try {
      setLogin(false);
      await Auth.signOut();
      Session.set(null);
      try {
        await Auth.currentAuthenticatedUser();
        //  setUser(user);
      } catch (err) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        setUser({ type: 'UNAUTHENTICATED', error: err });
        navigate('/auth/login');
      }

      //setUser(null);
      //window.location.reload();
    } catch (err) {
      setLogin({ error: err });
    }
  };

  const handleIdleLogout = () => {
    setTimeout(function() {
      if (!Session.getIdleStatus() && !Connection.getSessionTimeOut()) {
        logOut();
      }
    }, USER_IDLE_LOGOUT_TIME);
  };

  const handleOnIdle = () => {
    handleIdleLogout();
    Session.setIdleStatus(false);
  };

  const handleOnActive = () => {
    updateToken();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  const setDeliveryDateAndSlot = (selectedDate, selectedDeliverySlot) => {
    if (window) {
      window.sessionStorage.setItem(
        'deliveryDateAndSlot',
        JSON.stringify({
          selectedDate,
          selectedDeliverySlot,
        }),
      );
    }
  };

  const clearDeliveryDateAndSlot = () => {
    if (window) {
      window.sessionStorage.removeItem('deliveryDateAndSlot');
    }
  };

  const getDeliveryDateAndSlot = () => {
    var data = null;
    if (window) {
      const temp = window.sessionStorage.getItem('deliveryDateAndSlot');
      data = temp ? JSON.parse(temp) : null;
    }
    return data;
  };

  const setPnTokenUserGroup = async (token = '') => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const url = Connection.baseUrl('base');
      const res = await fetch(url + `/push-notifications/device-group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: user?.signInUserSession?.idToken?.jwtToken || '',
        },
        body: JSON.stringify({
          UserIdentity: user?.username,
          RegistrationToken: token,
        }),
      });
      return await res.json();
    } catch (err) {
      return err;
    }
  };

  return (
    <SessionContext.Provider
      value={{
        state: {
          user: user,
          logIn: logIn,
          permissions: permissions,
          session: session,
          roleAvailability: roleAvailability,
          isFetching: isFetching,
          errors: errors,
        },
        getauthenticatedUser: getauthenticatedUser,
        setSessionDetails: setSessionDetails,
        getPermissions: getPermissions,
        logOut: logOut,
        setDeliveryDateAndSlot: setDeliveryDateAndSlot,
        getDeliveryDateAndSlot: getDeliveryDateAndSlot,
        clearDeliveryDateAndSlot: clearDeliveryDateAndSlot,
        setPnTokenUserGroup: setPnTokenUserGroup,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
SessionProvider.propTypes = {
  children: PropTypes.any,
};

export { SessionProvider, SessionContext };
