import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'reactstrap';
import { Validate } from '../../../../config';
import { Switch, Text } from '../../../../../shared/components';
import { useDeviceDetect } from '../../../../../shared/hooks';
import { commafy } from '../../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../../shared/utility/roundingNumber';
function OrderDetailUpdateModal({ onUpdateProcurement, item }) {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [form, setForm] = useState({
    manualProcurementPrice: {
      value: '',
      title: t('MANUAL_PROCUREMENT_PRICE_LKR'),
      rules: {
        title: t('MANUAL_PROCUREMENT_PRICE'),
        stop: true,
        required: {
          message: 'This is a required field',
        },
      },
    },
    supplierName: {
      value: '',
      title: t('SUPPLIER_NAME'),
      rules: {
        stop: true,
        title: t('ENTER_A_SUPPLIER_NAME'),
        required: {
          message: 'This is a required field',
        },
      },
    },
    availability: {
      value:
        item?.newUpdate?.ManualProcSupplierName === null &&
        item?.newUpdate?.UnitPrice === null
          ? false
          : true,
      title: t('AVAILABILITY'),
      rules: {
        stop: true,
        title: t('AVAILABILITY'),
      },
    },
  });

  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const onChangeToggle = e => {
    const { name } = e.target;
    const updatedForm = Validate.input(
      name,
      !form.availability.value,
      form,
      true,
    );
    if (form.availability.value) {
      updatedForm.supplierName.value = ' ';
      updatedForm.manualProcurementPrice.value = ' ';
    }
    setForm(updatedForm);
  };

  const handleConfirm = e => {
    e.preventDefault();
    const formValidation = Validate.form(form);
    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }
    onUpdateProcurement({
      UnitPrice: form.availability.value
        ? parseFloat(form.manualProcurementPrice.value)
        : null,
      ManualProcSupplierName: form.availability.value
        ? form.supplierName.value
        : null,
      id: item?.purchaseOrderProductId,
    });
  };

  return (
    <React.Fragment>
      <p style={isMobile ? { marginLeft: '0' } : {}}>
        <b>
          Expected Price:{' '}
          {item?.expectedUnitPrice !== null
            ? 'LKR ' + commafy(RoundNum(item?.expectedUnitPrice, 2))
            : 'N/A'}
        </b>
      </p>

      <Form onSubmit={handleConfirm}>
        <Row style={!isMobile ? { height: '65px' } : {}}>
          <Col sm={6} lg={6}>
            <Text
              disabled={!form.availability.value}
              groupClassName="update-form-group"
              labelClassName="form-label"
              label={form.manualProcurementPrice.title}
              placeholder={form.manualProcurementPrice.rules.title}
              id="manual-procurement-price"
              autoComplete="off"
              field="manualProcurementPrice"
              onChange={onChange}
              rightIconGroupClassName="form-container"
              validation={form.manualProcurementPrice}
            />
          </Col>
          <Col sm={6} lg={6}>
            <Text
              disabled={!form.availability.value}
              autoComplete="off"
              groupClassName="update-form-group "
              labelClassName="form-label"
              label={form.supplierName.title}
              placeholder={form.supplierName.rules.title}
              id="supplier-name"
              field="supplierName"
              onChange={onChange}
              rightIconGroupClassName="form-container"
              validation={form.supplierName}
            />
          </Col>
        </Row>
        <Col>
          <Switch
            groupClassName="mt-3"
            label={form.availability.title}
            labelClassName="form-label"
            placeholder={form.availability.rules.title}
            id="availability"
            field="availability"
            onChange={onChangeToggle}
            checked={form.availability.value}
            validation={form.availability}
          />
        </Col>

        <Button
          style={!isMobile ? { float: 'right' } : { width: '100%' }}
          color="primary"
          className="form-submit"
        >
          {t('CONFIRM')}
        </Button>
      </Form>
    </React.Fragment>
  );
}

OrderDetailUpdateModal.propTypes = {
  item: PropTypes.any,
  onUpdateProcurement: PropTypes.func,
};
export default OrderDetailUpdateModal;
