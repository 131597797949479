import React, { useState, useEffect, useContext } from 'react';
import InnerBody from '../../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table, Button, Tooltip, Row, Col } from 'reactstrap';
import { BuyerManagementContext } from '../../../../../apis/admin';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { sad, smile, warning } from '../../../../assets/img';
import CustomTooltip from '../../../../../shared/components/CustomTooltip';
import {
  CustomModal,
  Scanner,
  RadioGroup,
  CheckBox,
} from '../../../../../shared/components';
import { PURCHASE_ORDER_PRODUCT_STATUS } from '../../../../../shared/constants/constants';
import { useBeforeunload } from 'react-beforeunload';
import { useDeviceDetect } from '../../../../../shared/hooks';

function SelectedOrderOfSupplier() {
  const { t } = useTranslation();

  const [orderProducts, setOrderProducts] = useState([]);
  const [order, setOrder] = useState(null);
  const { supplierCompanyId, purchaseOrderId, deliverySlotId } = useParams();
  const pathQuery = new URLSearchParams(useLocation().search);
  const buyerManagementContext = useContext(BuyerManagementContext);

  const navigate = useNavigate();
  const {
    getSelectedOrderOfySupplier,
    updatePurchaseOrderProduct,
    updateAllProductOfOrder,
    addComplainReason,
    updatePurchaseOrderAdmin,
  } = buyerManagementContext;

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [
    issueTypeSelectionModalOpen,
    setIssueTypeSelectionModalOpen,
  ] = useState(false);
  const [scanMethodModalOpen, setScanMethodModalOpen] = useState(false);
  const [sadOrderStatusChecked, setSadOrderStatusChecked] = useState(2);
  const [issueTypeChecked, setIssueTypeChecked] = useState([]);
  const [scanMethodChecked, setScanMethodChecked] = useState(1);
  const [isStartScan, setIsStartScan] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [tempHref, setTempHref] = useState('');
  const { isMobile } = useDeviceDetect();

  useBeforeunload(event => {
    event.preventDefault();
  });

  useEffect(() => {
    if ((supplierCompanyId, purchaseOrderId)) {
      onHandleGetSelectedOrderOfySupplier();
    }
  }, [supplierCompanyId, purchaseOrderId]);

  const isManualProcurement = () => {
    return pathQuery.get('manualProcurement') === 'true';
  };

  const onHandleGetSelectedOrderOfySupplier = (isRedirectionNeeded = false) => {
    getSelectedOrderOfySupplier(
      supplierCompanyId == isManualProcurement() ? '' : supplierCompanyId,
      purchaseOrderId,
      isManualProcurement(),
      true,
    ).then(res => {
      setOrderProducts(res.orderProducts);
      if (isRedirectionNeeded) {
        if (res?.orderProducts?.length === 0) {
          navigate(-1);
        }
      }
      setOrder(res);
    });
  };

  // const toggleBuyerInfoOpen = index => {
  //   const temp = [...buyerInfoOpen];
  //   temp[index] = !temp[index];
  //   setBuyerInfoOpen(temp);
  // };

  useEffect(
    () => () => {
      if (!isManualProcurement()) {
        updatePurchaseOrderAdmin(0, supplierCompanyId, purchaseOrderId)
          .then(() => {})
          .catch(() => {});
      }
    },
    [],
  );

  const handleUpdatePurchaseOrderAdmin = () => {
    setExitModalOpen(false);
    if (!isManualProcurement()) {
      updatePurchaseOrderAdmin(0, supplierCompanyId, purchaseOrderId)
        .then(() => {
          if (tempHref) {
            // history.pushState(null, null, tempHref);
            navigate(tempHref);
          } else {
            navigate(-1);
          }
        })
        .catch(() => {});
    } else {
      if (tempHref) {
        // history.pushState(null, null, tempHref);
        navigate(tempHref);
      } else {
        navigate(-1);
      }
    }
  };

  const onHandleScan = qrValue => {
    console.log(qrValue, onClickOnSad);
  };

  const onClickOnSmile = selectedOrder => {
    updatePurchaseOrderProduct(
      selectedOrder.purchaseOrderProductId,
      PURCHASE_ORDER_PRODUCT_STATUS.SUPPLIER_QUALITY_CHECK_PASSED.id,
    ).then(res => {
      if (res.err) {
        return;
      } else {
        onHandleGetSelectedOrderOfySupplier();
      }
    });
  };

  const onClickOnSad = selectedOrder => {
    updatePurchaseOrderProduct(
      selectedOrder.purchaseOrderProductId,
      PURCHASE_ORDER_PRODUCT_STATUS.SUPPLIER_QUALITY_CHECK_FAILED.id,
    ).then(res => {
      if (res.err) {
        return;
      } else {
        onHandleGetSelectedOrderOfySupplier(true);
      }
    });
  };

  const onHandleAcceptAll = () => {
    updateAllProductOfOrder(
      order.purchaseOrderId,
      isManualProcurement() ? null : parseInt(supplierCompanyId),
    ).then(res => {
      if (res.err) {
        return;
      } else {
        onHandleGetSelectedOrderOfySupplier();
      }
    });
  };

  const onIssuesConfirm = () => {
    setIssueTypeSelectionModalOpen(false);
    addComplainReason(
      sadOrderStatusChecked,
      issueTypeChecked,
      selectedOrder.purchaseOrderProductId,
    ).then(() => {
      updatePurchaseOrderProduct(
        selectedOrder.purchaseOrderProductId,
        PURCHASE_ORDER_PRODUCT_STATUS.SUPPLIER_QUALITY_CHECK_FAILED.id,
      ).then(res => {
        if (res.err) {
          return;
        } else {
          onHandleGetSelectedOrderOfySupplier(true);
          setIssueTypeChecked([]);
        }
      });
    });
  };
  /*browser back button clicks event prevent and popup showing for yes/no confirmation
  useEffect(() => {
  if (order?.orderProducts.length > 0) {
      history.pushState(null, null, location.href);
    }
    if (order?.orderProducts.length > 0) {
      setTimeout(() => {
        window.onpopstate = function() {
          const el = document.getElementById('back-confirm-modal');
          const el2 = el?.closest('.modal');
          if (!el || !el2?.classList?.contains('show')) {
            history.go(1);
            setExitModalOpen(true);
          }
          // }
        };
      }, 100);
    }

    if (order?.orderProducts.length > 0) {
      var elements = document.getElementsByTagName(`a`);
      for (var i = 0, len = elements.length; i < len; i++) {
        if (!elements[i]?.classList?.contains('active')) {
          elements[i].onclick = function(e) {
            if (
              order?.orderProducts.length > 0 &&
              location.pathname ==
                `/admin/supplierqualitycheck/selectedorderOfsupplier/${supplierCompanyId}/${purchaseOrderId}/${deliverySlotId}`
            ) {
              e.preventDefault();
              const e2 = e?.target?.closest(`a`);
              setTempHref(e2.href.split('3000')[1]);
              setExitModalOpen(true);
            }
          };
        }
      }
    }
  
    return () => {
      if (order?.orderProducts.length > 0) {
        window.onpopstate = () => {};
      }
    };
  }, [order]);
  */
  const backEventFired = () => {
    if (order?.orderProducts.length > 0) {
      setExitModalOpen(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <InnerBody
      onHandleBack={backEventFired}
      topic={t('QUALITY_CONTROL')}
      className="supplierQualityCheck"
    >
      {order && (
        <div className="selected-order-of-supplier-container">
          <div className="d-flex mb-4">
            {order?.supplierName && <div>Supplier - {order?.supplierName}</div>}
            {order?.buyerName && (
              <div className="ms-4">Buyer - {order?.buyerName}</div>
            )}
          </div>
          {orderProducts?.length > 0 ? (
            <div className="info-container">
              <div className="tag-number">Tag Number {order?.tagNumber}</div>
              {orderProducts && (
                <>
                  {orderProducts.length > 0 && (
                    <>
                      <div className="tableFixHead">
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>
                                {/* <div style={{ marginLeft: '40px' }}>Product name</div> */}
                                Product Name
                              </th>
                              <th>Quantity</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderProducts.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={
                                    item.productStatus == 5
                                      ? { backgroundColor: '#E6F4FF' }
                                      : item.productStatus == 8
                                      ? { backgroundColor: '#88ebc9' }
                                      : item.productStatus == 7
                                      ? { backgroundColor: '#EAC7C7' }
                                      : {}
                                  }
                                >
                                  <>
                                    <td>
                                      {isMobile ? (
                                        <div className="w-100 text-left">
                                          {item.buyerNote ? (
                                            <Note item={item} />
                                          ) : !orderProducts.every(function(v) {
                                              return v.buyerNote === null;
                                            }) ? (
                                            <div
                                              style={{ width: '33px' }}
                                            ></div>
                                          ) : (
                                            ''
                                          )}
                                          {item.productName}
                                        </div>
                                      ) : (
                                        <div className="d-flex align-items-center">
                                          {item.buyerNote ? (
                                            <Note item={item} />
                                          ) : !orderProducts.every(function(v) {
                                              return v.buyerNote === null;
                                            }) ? (
                                            <div
                                              style={{ width: '33px' }}
                                            ></div>
                                          ) : (
                                            ''
                                          )}
                                          {item.productName}
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      {item.quantity + ' ' + item.unitMeasure}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-around',
                                        }}
                                      >
                                        <img
                                          width="20"
                                          className="me-2 ms-2 cursor-pointer"
                                          src={smile}
                                          alt="notes"
                                          onClick={() => onClickOnSmile(item)}
                                        />
                                        <img
                                          width="20"
                                          src={sad}
                                          className="me-2 ms-2 cursor-pointer"
                                          alt="notes cursor-pointer"
                                          onClick={() => {
                                            setIssueTypeSelectionModalOpen(
                                              true,
                                            );
                                            setSelectedOrder(item);
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="mt-2  d-flex  justify-content-center">
                        <img
                          width="22"
                          height="22"
                          className="user-profile-img"
                          src={warning}
                          alt="notes"
                          id={`order-note`}
                        />
                        <CustomTooltip
                          placement="bottom"
                          targetId={`order-note`}
                          displayMessage={
                            <>
                              <p className="d-flex flex-row align-items-center">
                                {t('APPROVAL_PENDING')}:
                                <div
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: '#E6F4FF',
                                  }}
                                  className="mx-1 "
                                ></div>
                              </p>
                              <p className="d-flex flex-row align-items-center">
                                {t('APPROVED_ITEMS')}:
                                <div
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: '#88ebc9',
                                  }}
                                  className="mx-1 "
                                ></div>
                              </p>
                              <p className="d-flex flex-row align-items-center">
                                {t('RED_FLAGGED_ITEMS')}:
                                <div
                                  style={{
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: '#EAC7C7',
                                  }}
                                  className="mx-1 "
                                ></div>
                              </p>
                            </>
                          }
                        />
                      </div>
                      <div className="d-md-flex justify-content-end align-items-center">
                        <div className="me-0 me-md-2 mt-2 col-12 col-md-2">
                          <Button
                            className="btn-outline-primary w-100"
                            onClick={() => setScanMethodModalOpen(true)}
                            disabled={
                              orderProducts.filter(
                                item =>
                                  item.productStatus !==
                                  PURCHASE_ORDER_PRODUCT_STATUS
                                    .SUPPLIER_QUALITY_CHECK_PASSED.id,
                              ).length !== 0
                            }
                          >
                            Scan
                          </Button>
                        </div>
                        <div className="mt-2 col-12 col-md-2">
                          <Button
                            className="btn-success w-100"
                            onClick={() => onHandleAcceptAll()}
                            disabled={
                              orderProducts.filter(
                                item =>
                                  item.productStatus !==
                                  PURCHASE_ORDER_PRODUCT_STATUS
                                    .SUPPLIER_QUALITY_CHECK_PASSED.id,
                              ).length === 0
                            }
                          >
                            Accept All
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="py-5 custom-empty-message">
              <p>{t('NO_ORDERS_AVAILABLE')}</p>
            </div>
          )}
          <CustomModal
            isOpen={isStartScan}
            toggle={() => setIsStartScan(false)}
            isScrollable={true}
            title="QR Scanner"
            modalstyleClassName="modal-style"
            modalContentContainer="modal-container"
            modalHeaderClassName="modal-header"
            modalheaderTopic="modal-header-topic"
            modalBodyClassName="small"
          >
            <Scanner
              isSuccessSound={false}
              setStartScan={setIsStartScan}
              onHandleScan={onHandleScan}
            />
          </CustomModal>
          <CustomModal
            isOpen={issueTypeSelectionModalOpen}
            toggle={() => {
              setIssueTypeSelectionModalOpen(false);
              setIssueTypeChecked([]);
              setSadOrderStatusChecked(2);
            }}
            isScrollable={true}
            enableFooter={false}
            title={'Quality Control Issues'}
            modalstyleClassName="modal-style"
            modalContentContainer="modal-container"
            modalHeaderClassName="modal-header"
            modalheaderTopic="modal-header-topic m-auto"
            modalFooterClassName="modal-footer"
            modalBodyClassName="small"
          >
            <>
              <div className="browse-radio-container">
                <RadioGroup
                  rootDivClassName="d-flex"
                  name="sadOrderStatus"
                  options={[
                    {
                      value: 1,
                      displayValue: 'Reject',
                    },
                    {
                      value: 2,
                      displayValue: 'Red flag',
                    },
                  ]}
                  radioButtonInputClassName="brows-radio-button "
                  radioButtonContainerClassName="brows-radio-button-group d-flex"
                  selectedOption={sadOrderStatusChecked}
                  onClick={e => {
                    setSadOrderStatusChecked(e.target.value);
                  }}
                />
              </div>
              <div className="mt-3" style={{ textAlign: 'left' }}>
                Issue Types
              </div>
              <div
                style={{ textAlign: 'left' }}
                className="browse-radio-container mb-3"
              >
                <Row>
                  <Col xs="4">
                    <CheckBox
                      label={t('QUALITY')}
                      groupClassName="check-box"
                      id="Issue-due-to-quality"
                      field="IssueDueToQuality"
                      value={1}
                      inputClassName="supplier-checkbox-input-className"
                      //checked={}
                      onClick={e => {
                        if (
                          issueTypeChecked.includes(parseInt(e.target.value))
                        ) {
                          const temp = issueTypeChecked.filter(function(
                            typeId,
                          ) {
                            return parseInt(e.target.value) !== typeId;
                          });
                          setIssueTypeChecked(temp);
                        } else {
                          setIssueTypeChecked(prevProps => [
                            ...prevProps,
                            parseInt(e.target.value),
                          ]);
                        }
                      }}
                    />
                  </Col>
                  <Col xs="4">
                    <CheckBox
                      label={t('QUANTITY')}
                      groupClassName="check-box"
                      id="Issue-due-to-quantity"
                      field="IssueDueToQuantity"
                      inputClassName="supplier-checkbox-input-className"
                      value={2}
                      //checked={}
                      onClick={e => {
                        if (
                          issueTypeChecked.includes(parseInt(e.target.value))
                        ) {
                          const temp = issueTypeChecked.filter(function(
                            typeId,
                          ) {
                            return parseInt(e.target.value) !== typeId;
                          });
                          setIssueTypeChecked(temp);
                        } else {
                          setIssueTypeChecked(prevProps => [
                            ...prevProps,
                            parseInt(e.target.value),
                          ]);
                        }
                      }}
                    />
                  </Col>
                  <Col xs="4">
                    <CheckBox
                      label={t('TAGGING')}
                      groupClassName="check-box"
                      id="Issue-due-to-tagging"
                      field="IssueDueToTagging"
                      inputClassName="supplier-checkbox-input-className"
                      value={3}
                      //checked={}
                      onClick={e => {
                        if (
                          issueTypeChecked.includes(parseInt(e.target.value))
                        ) {
                          const temp = issueTypeChecked.filter(function(
                            typeId,
                          ) {
                            return parseInt(e.target.value) !== typeId;
                          });
                          setIssueTypeChecked(temp);
                        } else {
                          setIssueTypeChecked(prevProps => [
                            ...prevProps,
                            parseInt(e.target.value),
                          ]);
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Button
                  className="form-submit w-40"
                  type="submit"
                  color="primary"
                  disabled={
                    !issueTypeChecked ||
                    !sadOrderStatusChecked ||
                    issueTypeChecked?.length === 0
                  }
                  onClick={() => onIssuesConfirm()}
                >
                  {t('CONFIRM')}
                </Button>
              </div>
            </>
          </CustomModal>
          <CustomModal
            isOpen={scanMethodModalOpen}
            toggle={() => setScanMethodModalOpen(false)}
            hideCancel={false}
            isScrollable={true}
            enableFooter={false}
            title={'Scan Method'}
            modalstyleClassName="modal-style"
            modalContentContainer="modal-container"
            modalHeaderClassName="modal-header"
            modalheaderTopic="modal-header-topic m-auto"
            modalFooterClassName="modal-footer"
            modalBodyClassName="small"
          >
            <>
              <div className="browse-radio-container mb-3">
                <RadioGroup
                  name="scanMethod"
                  options={[
                    {
                      value: 1,
                      displayValue: 'Item Count',
                    },
                    {
                      value: 2,
                      displayValue: 'Consolidate',
                    },
                  ]}
                  radioButtonInputClassName="brows-radio-button "
                  radioButtonContainerClassName="brows-radio-button-group d-flex"
                  selectedOption={scanMethodChecked}
                  onClick={e => setScanMethodChecked(e.target.value)}
                />
              </div>
              <div>
                <Button
                  className="form-submit w-40"
                  type="submit"
                  color="primary"
                  disabled={!scanMethodChecked}
                  onClick={() => {
                    navigate(
                      scanMethodChecked == 2
                        ? `/admin/${
                            isManualProcurement()
                              ? 'manualprocurementqualitycheck'
                              : 'supplierqualitycheck'
                          }/consolidateScan/${supplierCompanyId}/${purchaseOrderId}/${deliverySlotId}?manualProcurement=${isManualProcurement()}`
                        : `/admin/${
                            isManualProcurement()
                              ? 'manualprocurementqualitycheck'
                              : 'supplierqualitycheck'
                          }/itemCount/${supplierCompanyId}/${purchaseOrderId}/${deliverySlotId}?manualProcurement=${isManualProcurement()}`,
                    );
                    setScanMethodModalOpen(false);
                  }}
                >
                  {t('CONFIRM')}
                </Button>
              </div>
            </>
          </CustomModal>
          {/* <p className="shop-name p-0">{supplierCompanyName}</p> */}
        </div>
      )}
      <CustomModal
        isOpen={exitModalOpen}
        showClose={false}
        toggle={() => {
          setExitModalOpen(false);
          setTempHref('');
        }}
        isScrollable={true}
        title="Leave Page"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
        id="back-confirm-modal"
      >
        <p>
          {t('QUALITY_CONTROL_IS_INCOMPLETE_ARE_YOU_SURE_YOU_WANT_TO_PROCEES')}
        </p>
        <div className="d-flex justify-content-around mx-5">
          <Button
            outline
            color="primary"
            type="submit"
            className="form-submit"
            style={{ width: '75px' }}
            onClick={handleUpdatePurchaseOrderAdmin}
          >
            {t('YES')}
          </Button>
          <Button
            color="primary"
            type="submit"
            className="form-submit"
            style={{ width: '75px' }}
            onClick={() => {
              setExitModalOpen(false);
              setTempHref('');
            }}
          >
            {t('NO')}
          </Button>
        </div>
      </CustomModal>
    </InnerBody>
  );
}

export default SelectedOrderOfSupplier;

function Note({ item }) {
  const [isShowNote, setIsShowNote] = useState(false);

  return (
    <Button
      id={'note' + item.purchaseOrderProductId}
      style={{ paddingRight: '5px' }}
      className="buyer-info-icon"
      onClick={e => {
        e.stopPropagation();
        setIsShowNote(!isShowNote);
      }}
      onBlur={() => setIsShowNote(false)}
    >
      <img className="note-icon" src={warning} alt="notes" />
      <Tooltip
        placement="top"
        isOpen={isShowNote}
        autohide={false}
        target={'note' + item.purchaseOrderProductId}
        toggle={() => setIsShowNote(!isShowNote)}
      >
        {item.buyerNote}
      </Tooltip>
    </Button>
  );
}
Note.propTypes = {
  item: PropTypes.object,
};
