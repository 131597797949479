export const PAGE_NOT_FOUND = {
  PAGE_NOT_FOUND: 'Page Not Found',
};

export const UNAUTHENTICATED = {
  UNAUTHENTICATED: 'Uauthenticated user',
};

export const GENERAL_BUTTONS = {
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  OK: 'Ok',
  SAVE: 'Save',
};
