import React, { useContext } from 'react';
import { Table, Button, Row, Col } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SupplierOrderPaymentHistoryContext } from '../../../../apis/supplier/SupplierOrderPaymentHistory';
import { PAYMENT_TYPES_ENUM } from '../../../../shared/constants/constants';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';
function SupplierPaymentHistory({ isMobile, setError, setErrorPopupModal }) {
  const { t } = useTranslation();
  const supplierPayContext = useContext(SupplierOrderPaymentHistoryContext);
  const {
    getSupplierPaymentHistory,
    doSupplierConfirmPayment,
  } = supplierPayContext;
  const { supplierPaymentHistoryResponse } = supplierPayContext.state;

  const doConfirm = order => {
    doSupplierConfirmPayment(order.id)
      .then(res => {
        if (res?.message || res?.description) {
          setError({
            title: 'Confirm Failure',
            description: res?.description || res?.message,
          });
          setErrorPopupModal(prevProp => !prevProp);
          return;
        }
        getSupplierPaymentHistory();
      })
      .catch(err => {
        setError({
          title: 'Confirm Failure',
          description: err?.message || err,
        });
      });
  };
  return (
    <div className="supplier-payment-history">
      {supplierPaymentHistoryResponse?.res?.payments?.length > 0 && (
        <h4 className="outstanding-bal-container my-3">
          Outstanding Balance : {isMobile ? <br /> : <></>}LKR{' '}
          {commafy(
            RoundNum(
              supplierPaymentHistoryResponse?.res?.outstandingBalance,
              2,
            ),
          )}
        </h4>
      )}
      <div className="tableFixHead">
        {supplierPaymentHistoryResponse?.res?.payments?.length > 0 && (
          <Table>
            <thead>
              {!isMobile && (
                <tr>
                  <th className="text-center">{t('DATE')}</th>
                  <th>{t('PAYMENT_TYPE')}</th>
                  <th>Payment Identifier</th>
                  <th className="text-right">{t('AMOUNT')}</th>
                  <th>{t('STATUS')}</th>
                </tr>
              )}
            </thead>
            <tbody>
              {supplierPaymentHistoryResponse?.res?.payments?.map(
                (item, index) =>
                  !isMobile ? (
                    <>
                      <tr key={index}>
                        <td className="text-right">
                          {moment(item?.paymentDate).format('DD-MM-YYYY')}
                        </td>
                        <td>{PAYMENT_TYPES_ENUM?.[item?.paymentTypeId]}</td>
                        <td>{item?.supplierIdentifier || 'N/A'}</td>
                        <td className="blue-color text-right">
                          {item?.amount
                            ? commafy(RoundNum(item?.amount, 2))
                            : 'Pending'}
                        </td>
                        <td width="20%" className="text-center">
                          {item.status === 1 ? (
                            <Button
                              className="p-0 delete-items btn-color"
                              color="link"
                              onClick={() => doConfirm(item)}
                            >
                              To be Confirmed
                            </Button>
                          ) : (
                            'Confirmed'
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr key={index}>
                        <td className="d-flex flex-column">
                          <small>
                            <Row className="order-first-row pt-1 pb-1">
                              <Col xs="4" className="col-title">
                                {moment(item?.paymentDate).format('DD-MM-YYYY')}
                              </Col>
                              <Col xs="8" className="col-title text-right">
                                {item?.supplierIdentifier || 'N/A'}
                              </Col>
                            </Row>
                            <Row className="pt-1">
                              <Col xs="4">
                                {item?.amount
                                  ? commafy(RoundNum(item?.amount, 2))
                                  : 'Pending'}
                              </Col>
                              <Col xs="4" className=" text-center">
                                {PAYMENT_TYPES_ENUM?.[item?.paymentTypeId]}
                              </Col>
                              <Col xs="4" className="text-right">
                                {item.status === 1 ? (
                                  <Button
                                    style={{ fontSize: '14px' }}
                                    className="p-0  delete-items btn-color"
                                    color="link"
                                    onClick={() => doConfirm(item)}
                                  >
                                    To be Confirmed
                                  </Button>
                                ) : (
                                  <span style={{ fontSize: '14px' }}>
                                    Confirmed
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </small>
                        </td>
                      </tr>
                    </>
                  ),
              )}
            </tbody>
          </Table>
        )}

        {(supplierPaymentHistoryResponse?.res?.payments.length === 0 ||
          supplierPaymentHistoryResponse?.err !== null) && (
          <div className="py-5 custom-empty-message">
            <p>No records available</p>
          </div>
        )}
      </div>
    </div>
  );
}

SupplierPaymentHistory.propTypes = {
  isMobile: PropTypes.bool,
  initiatePayment: PropTypes.func,
  setError: PropTypes.func,
  setErrorPopupModal: PropTypes.func,
};

export default SupplierPaymentHistory;
