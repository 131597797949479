export const COGNITO = {
  NEW_PASSWORD_CREATE_REQUEST: 'NEW_PASSWORD_REQUIRED',
  INCORRECT_USERNAME_PASSWORD: 'Incorrect username or password',
  PASSWORD_ATTEMPT_EXCEED: 'Password attempts exceeded',
  TEMP_PASSWORD_EXPIRED:
    'Temporary password has expired and must be reset by an administrator',
  SESSION_EXPIRED: 'Invalid session for the user, session is expired',
  INVALID_VERIFICATION_CODE:
    'Invalid verification code provided, please try again',
  INVALID_OTP_CODE: 'Invalid code provided, please request a code again.',
  NUMBER_OF_REQUEST_EXCEEDED:
    'Attempt limit exceeded, please try after some time',
};
export const COGNITO_POLICY = {
  PHONE_NUMBER_MIN_LENGTH: 9,
  PHONE_NUMBER_MAX_LENGTH: 9,
  PASSWORD_MAX_LENGTH: 50,
  PASSWORD_DEFAULT_MIN_LENGTH: 6,
};
export const LOGIN_ISSUE_CONTACT_NUMBER = '0111234567';
export const SRILANKAN_COUNTRY_CODE = '+94';
export const USER_IDLE_LOGOUT_TIME = 36000000;

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  BUYER: 'BUYER',
  SUPPLIER: 'SUPPLIER',
};
export const CUSTOM_ERROR_CODES = {
  NOT_FOUND: 'NOT_FOUND',
  CONFLICT: 'CONFLICT',
};

export const PERMISSION_LEVELS = {
  FULL_ACCESS: 'FULL_ACCESS',
  READ_ONLY: 'READ_ONLY',
  NONE: 'NONE',
};

export const REQUIRED_FIELDS_COUNT = {
  STEP_ONE_REQUIRED_COUNT: 4,
  STEP_TWO_REQUIRED_COUNT: 5,
};

export const segmentArray = [
  { key: 31, value: 4 },
  { key: 30, value: 3 },
  { key: 28, value: 2 },
  { key: 8, value: 1 },
  { key: 25, value: 3 },
];

export const REGEX = {
  PORT_RANGE: /^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
  IPV4: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  IPV4_CIDR: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|2[0-9]|[01]?[0-9]?)$/,
};

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'dd/MM/yyyy hh:mm a';
export const DEFAULT_DATE_TIME_FORMAT_SS = 'DD/MM/YYYY HH:mm:ss';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const DEFAULT_TIME_FORMAT_SS = 'HH:mm:ss';
export const DATE_TIME_FORMAT_API = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const DATE_FORMAT_DD_MMM_YYYY = 'dd/MMM/yyyy';
export const DD_MMM_YY_HH_MM_24 = 'DD MMM YY | HH:mm';
export const DD_MMM_YYYY_HH_MM_24 = 'DD MMM YYYY | HH:mm';
export const DD_MMM_YYYY = 'DD-MMM-YYYY';
export const DD_MMM_YY_HH_MM_24_Z = 'DD MMM YY | HH:mm:Z';
export const DYNAMODB_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const UK_DATE_FORMAT = 'DD/MM/YYYY';
export const SL_DATE_FORMAT = 'DD-MM-YYYY';

export const SAFE_IP = 'safe_ip';
export const REJECT = 'REJECT';
export const MAX_PLAY_SPEED = 2.5;

export const NO_COUNT = 'NO_COUNT';

export const HTTP_CODES = {
  CONFLICT: 409,
  NOT_FOUND: 400,
  PAGE_NOT_FOUND: 404,
  LOCKED: 423,
  SERVER_ERROR: 500,
  UNAUTHENTICATED: 401,
};

export const HTTP_RESPONSE_CODES = {
  CONFLICT: 'CONFLICT',
};

export const SEVERITY_COLORS = {
  HIGH: 'red',
  MEDIUM: ' darkorange',
  LOW: 'yellow',
  NONE: 'green',
};

export const VIEW_PORT_BREAK = {
  MOBILE: 576,
  TABLET: 768,
  DESKTOP: 992,
  LARGE_DESKTOP: 1200,
};

export const PACKAGING_STATUS = {
  NOT_REQUESTED: { id: 1, value: 'NotRequested' },
  REQUESTED: { id: 2, value: 'Requested' },
  SENT_BY_SUPPLIER: { id: 3, value: 'SentBySupplier' },
  RECEIVED: { id: 4, value: 'Received' },
  SCANNED: { id: 5, value: 'Scanned' },
  LOADED: { id: 6, value: 'Loaded' },
  UNLOAD_SCANNED: { id: 7, value: 'UnloadScanned' },
  UNLOADED: { id: 8, value: 'Unloaded' },
};

export const PURCHASE_ORDER_PRODUCT_STATUS = {
  PENDING: { id: 1, value: 'Pending' },
  FINALIZED: { id: 2, value: 'Finalized' },
  SUBMITTED: { id: 3, value: 'Submitted' },
  CANCELLED: { id: 4, value: 'Cancelled' },
  READY_FOR_DISTPATCH: { id: 5, value: 'ReadyForDispatch' },
  PROCESSING: { id: 6, value: 'Processing' },
  SUPPLIER_QUALITY_CHECK_FAILED: { id: 7, value: 'SupplierQualityCheckFailed' },
  SUPPLIER_QUALITY_CHECK_PASSED: { id: 8, value: 'SupplierQualityCheckPassed' },
  REQUESTED_FOR_LOADING: { id: 9, value: 'RequestedForLoading' },
  SENT_FOR_LOADING: { id: 10, value: 'SentForLoading' },
  LOADED: { id: 11, value: 'Loaded' },
  UNLOADED: { id: 12, value: 'Unloaded' },
  MANUAL_PROC_SELECTED_UNAVAILABLE: {
    id: 13,
    value: 'ManualProcSelectedUnAvailable',
  },
  SUPPLIER_READY_ITEM: { id: 14 },
};

export const PROCUREMENT_VEHICLE_ORDER_STATUS = {
  SENT_FOR_LOADING: 'SentBySupplier',
  SEND_FOR_LOADING: 'Requested',
  NOT_REQUESTED: 'NotRequested',
};
export const PAYMENT_TYPES = ['Cash', 'Cheque', 'Transfer'];
export const GET_PAYMENT_TYPE = type => {
  return PAYMENT_TYPES.indexOf(type) + 1;
};
export const ORDER_STATUS = {
  GOOD_RECEIVED: 'Goods received',
};
export const PURCHASE_ORDER_STATUS = {
  1: 'Pending',
  2: 'Finalized ',
  3: 'Submitted',
  4: 'Cancelled',
  5: 'PendingSubmitted',
  6: 'Loaded',
  7: 'Unloaded',
  8: 'BuyerConfirmed',
  9: 'Complete',
};
// export const PURCHASE_ORDER_STATUS = {
//   1: 'Pending',
//   2: 'Finalized ',
//   3: 'Submitted',
//   4: 'Cancelled',
//   5: 'InProgress',
//   6: 'ReadyForDispatch',
//   7: 'Processing',
//   8: 'SupplierQualityCheckFailed',
//   9: 'SupplierQualityCheckPassed',
//   10: 'RequestedForLoading',
//   11: 'SentForLoading',
//   12: 'Loaded',
//   13: 'Unloaded',
//   14: 'ManualProcurementPending',
//   15: 'ManualProcSelectedUnAvailable',
// };
export const BUYER_PAYMENT_STATUS = {
  1: 'Pending',
  2: 'Confirmed',
};
export const SUPPLIER_PAYMENT_STATUS = {
  1: 'Pending',
  2: 'Confirmed',
};
export const PAYMENT_TYPES_ENUM = { 1: 'Cash', 2: 'Cheque', 3: 'Transfer' };

export const CONTACT_US = {
  BUYER_TEL: '0777105814',
  BUYER_EMAIL: 'Buyer@sillara-agri.com',
  SUPPLIER_TEL: '0777105814',
  SUPPLIER_EMAIL: 'Supplier@sillara-agri.com',
};

export const RECOMMENDATION_ORDER_CUTOFF_POINT_IN_MIN = 5;
