import React, { useContext, useRef, useState, useEffect } from 'react';
import { Button, Card, Col } from 'reactstrap';
import { AdminContext } from '../../../apis/admin';
import { excelIcon } from '../../assets/img/';
import closeIcon from '../../assets/icons/Close.svg';
import { CustomModal } from '../../../shared/components';
import InnerBody from '../../../content/InnerBody';
import { Session } from '../../../app/config';
import { useTranslation } from 'react-i18next';
import { SessionContext } from '../../../apis/authentication';

function SillaraMasterSheet() {
  const [file, setFile] = useState(null);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const ref = useRef();
  const adminContext = useContext(AdminContext);
  const sessionContext = useContext(SessionContext);
  const { user } = sessionContext.state;
  const { uploadFile, setUploadFileResponse } = adminContext;
  const { uploadFileResponse } = adminContext.state;
  const [isOpen, setIsOpen] = useState(false);
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  const { t } = useTranslation();
  const handleClick = () => {
    ref.current.click();
  };

  useEffect(() => {
    if (uploadFileResponse?.res) {
      setIsOpen(true);
      setUploadFileResponse({ err: null, res: null });
    } else if (uploadFileResponse?.err) {
      setErrorPopupModal(prevProp => !prevProp);
      setUploadFileResponse({ err: null, res: null });
    }
    ref.current.value = null;
    removeFile();
  }, [uploadFileResponse]);

  const handleChangeFile = e => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (file?.name) {
      var ext = getExtension(file?.name);
      switch (ext.toLowerCase()) {
        case 'xlsx':
        case 'xls':
          setIsInvalidFile(false);
          break;
        default:
          setIsInvalidFile(true);
      }
    } else {
      setIsInvalidFile(false);
    }
  }, [file]);

  const getExtension = filename => {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  };

  const removeFile = () => {
    setFile(null);
  };
  const toggleErrorPopup = () => {
    setErrorPopupModal(!errorPopupModal);
  };
  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={'Upload failure'}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {uploadFileResponse?.err}
    </CustomModal>
  );

  const toggle = () => setIsOpen(!isOpen);

  const upload = encodedFile => {
    const user = Session.getUser();
    if (encodedFile) {
      uploadFile({
        fileName: file.name,
        masterFileType: 'ProductMaster',
        userId: user.username,
        excelBase64: encodedFile,
      });
    }
  };

  const handleUploadFile = () => {
    getBase64(file);
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const newBaseString = reader.result.split('base64,');
      upload(btoa(newBaseString[1]));
      removeFile();
    };
    reader.onerror = function() {
      //return null;
    };
  }

  return (
    <InnerBody topic="Sillara Master List" isDisable={true}>
      <Col md={5} xs={12}>
        <Card className="upload-card text-center shadow">
          <div className="rounded upload-card-body">
            {file ? (
              <>
                <div className="upload-card-icon-container">
                  <img
                    src={excelIcon}
                    alt="Excel Icon"
                    className="m-auto upload-card-icon"
                  />
                  <img
                    src={closeIcon}
                    alt="close icon"
                    className="upload-card-close-icon"
                    onClick={removeFile}
                  />
                </div>
                <p className="upload-card-file-name">{file.name}</p>
              </>
            ) : (
              <div className="upload-card-icon-container">
                <img
                  src={excelIcon}
                  alt="Excel Icon"
                  className="m-auto upload-card-icon"
                />
              </div>
            )}
            {!isInvalidFile ? (
              <p className="upload-card-text">
                Upload Sillara Master List Here
              </p>
            ) : (
              <p className="upload-card-text text-danger">
                Invalid file format
              </p>
            )}
            {!file ? (
              <>
                <Button
                  className="upload-card-button btn-primary"
                  onClick={handleClick}
                >
                  Browse
                </Button>
                <input
                  ref={ref}
                  type="file"
                  style={{ display: 'none' }}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleChangeFile}
                />
              </>
            ) : (
              <Button
                className="btn-primary"
                onClick={handleUploadFile}
                disabled={isInvalidFile}
              >
                Upload product list
              </Button>
            )}
            {errorPopup()}
            <CustomModal
              isOpen={isOpen}
              toggle={toggle}
              isScrollable={true}
              enableFooter={false}
              title="Upload Confirmation"
              modalstyleClassName="modal-style"
              modalContentContainer="modal-container"
              modalHeaderClassName="modal-header"
              modalheaderTopic="modal-header-topic mt-0 mb-3"
              modalFooterClassName="modal-footer"
              modalBodyClassName="small"
            >
              {t('PRODUCT_LIST_UPLOADED_AFTER_COMPLETED_YOU_WILL_RECEIVE_SMS', {
                PHONE_NUMBER: user?.attributes?.phone_number,
              })}
              <p>
                <Button
                  className="my-3 btn-primary"
                  id="modal-footer-btn-save"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {t('OK')}
                </Button>
              </p>
            </CustomModal>
          </div>
        </Card>
      </Col>
    </InnerBody>
  );
}

export default SillaraMasterSheet;
