import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { IconMenu, refresh } from '../app/assets/icons';
import PropTypes from 'prop-types';

function InnerNavContainer({
  isDisable,
  isRefreshDisable,
  rightSideElement,
  onHandleBack,
  onRefresh,
}) {
  let navigate = useNavigate();
  return (
    <div
      className={`inner-nav-container-header ${
        rightSideElement
          ? 'd-flex justify-content-between align-items-center'
          : ''
      } `}
    >
      <div>
        <Nav>
          <NavItem className={isDisable && 'back-button-disable display-none'}>
            <NavLink
              disabled={isDisable}
              onClick={onHandleBack ? onHandleBack : () => navigate(-1)}
            >
              <img src={IconMenu} width="20" height="20" alt="back icon" />
            </NavLink>
          </NavItem>
          {onRefresh && (
            <NavItem className={isRefreshDisable && 'display-none'}>
              <NavLink onClick={onRefresh}>
                <img src={refresh} width="20" height="20" alt="back icon" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>
      {rightSideElement}
    </div>
  );
}

InnerNavContainer.propTypes = {
  isDisable: PropTypes.bool,
  isRefreshDisable: PropTypes.bool,
  rightSideElement: PropTypes.any,
  onHandleBack: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default InnerNavContainer;
