import React, { useEffect } from 'react';
import DropdownSelector from './DropdownSelector';
import { Button } from 'reactstrap';
import { search, closeX } from '../../../app/assets/icons';
import { useDeviceDetect } from '../../hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
function SearchBar({
  filterCategories,
  dropDownPlaceholder,
  dropdownDisplayValue,
  onSearchProduct,
  onSelectFilter,
  searchTerm,
  onChangeSearch,
  buyerSearchSuggestionResponse,
  selectedValues,
}) {
  const { t } = useTranslation();

  const { isMobile, isTablet, isLargeDesktop } = useDeviceDetect();
  useEffect(() => {}, [isMobile, isTablet, isLargeDesktop, selectedValues]);
  const onSearchItems = searchTerm => {
    onSearchProduct(searchTerm);
  };

  const onKeyPress = event => {
    if (event.charCode === 13) {
      onSearchItems(searchTerm);
    }
  };

  return (
    <div className="search-bar">
      <div style={isMobile ? { width: '12%' } : { width: '22.5%' }}>
        <DropdownSelector
          isMobile={isMobile}
          isTablet={isTablet}
          isLargeDesktop={isLargeDesktop}
          filterCategories={filterCategories}
          displayValue={dropdownDisplayValue}
          placeholder={isMobile ? '☰' : dropDownPlaceholder}
          onSelect={item => {
            onSelectFilter(item);
          }}
          selectedValues={selectedValues?.name ? [selectedValues] : []}
        />
      </div>
      <div
        style={isMobile ? { width: '88%' } : { width: '82.5%' }}
        className="search-container"
      >
        <div className="search-inner">
          <span className="divider">|</span>
          <div className="input-group" style={{ marginBottom: '0px' }}>
            <input
              type="text"
              value={searchTerm}
              onChange={e => {
                onChangeSearch(e);
              }}
              placeholder={t('SEARCH_AREA')}
              onKeyPress={onKeyPress}
              className="form-control border-end-0 border"
              id="shared-search-input"
            />
            <span
              style={{ paddingRight: '5px' }}
              className="input-group-append border"
            >
              <img
                width="15"
                height="100%"
                src={closeX}
                alt="close icon "
                className="cursor-pointer"
                onClick={() => {
                  let e = {
                    target: {
                      value: '',
                    },
                  };
                  onChangeSearch(e);
                }}
              />
            </span>
          </div>

          <Button onClick={() => onSearchItems(searchTerm)}>
            {isMobile ? (
              <img width="12.5" src={search} />
            ) : (
              <>
                {t('SEARCH')}&nbsp;&nbsp;
                <img width="12.5" src={search} />
              </>
            )}
          </Button>
        </div>
        <div className="dropdown">
          {buyerSearchSuggestionResponse?.err == null &&
            searchTerm?.length > 2 &&
            buyerSearchSuggestionResponse?.res?.length > 0 &&
            buyerSearchSuggestionResponse?.res.slice(0, 10)?.map(item => (
              <div
                onClick={() => {
                  onSearchItems(item?.productName);
                }}
                className="dropdown-row"
                key={item?.id}
              >
                {item?.productName}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
SearchBar.propTypes = {
  filterCategories: PropTypes.array,
  dropDownPlaceholder: PropTypes.string,
  dropdownDisplayValue: PropTypes.string,
  onSearchProduct: PropTypes.func,
  onSelectFilter: PropTypes.func,
  onChangeSearch: PropTypes.func,
  searchTerm: PropTypes.string,
  buyerSearchSuggestionResponse: PropTypes.any,
  selectedValues: PropTypes.object,
};

export default SearchBar;
