export const BASE_URL = 'https://api.sillara-agri.com';
export const WS_BASE_URL = 'wss://ws-dev.sillara.co.uk/';
export const WEB_BASE_URL = '.sillara.co.uk';
export const cognito = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_4NavOjj5O',
  USER_POOL_WEB_CLIENT_ID: '61mk6o01fc437uttj2coc3pkq2',
  COGNITO_PASSWORD_POLICIES: 'https://api.sillara-agri.com/password-policies',
};

export const firebase = {
  API_KEY: 'AIzaSyBYCJ13o39tEbR9jI57MXf2FaBUEI8VcXM',
  AUTH_DOMAIN: 'sillara-web-app.firebaseapp.com',
  PROJECT_ID: 'sillara-web-app',
  STORAGE_BUCKET: 'sillara-web-app.appspot.com',
  MESSAGING_SENDER_ID: '549127862738',
  APP_ID: '1:549127862738:web:95140f7dbe4ae07fbf700b',
  VAPID_KEY:
    'BAsO_X-kgeHl5UEMX2y5dnaKOjd1HIbJcMZSyvcqpdxdU6E417rUV3TENIyUVzNQ7sJ50NeUkE6UgFpNwL4yolU',
};

export const TIME_OUT = 30000;
export const APP_VERSION = '0.0.1V';
export const CRYPTO_PRIVATE_KEY = '7ewewewwsdsdsds';
export const S3_BUCKET_URL =
  'https://sillara-product-images-prod.s3.ap-south-1.amazonaws.com/';
