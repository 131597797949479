import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const BuyerContext = React.createContext();
const BuyerProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [buyerDetailsResponse, setBuyerDetailsResponse] = useState({
    res: null,
    err: null,
  });
  const [deliverySlotsResponse, setDeliverySlotsResponse] = useState(null);
  const [
    sillaraCategoryListResponse,
    SetSillaraCategoryListResponse,
  ] = useState({ res: [], err: null });
  const [
    buyerProductCategoryListResponse,
    SetBuyerProductCategoryListResponse,
  ] = useState({ res: [], err: null });
  const [sillaraProductListResponse, setSillaraProductListResponse] = useState({
    res: [],
    err: null,
  });
  const [buyerProductListResponse, setBuyerProductListResponse] = useState({
    res: [],
    err: null,
  });
  const [
    updateProductCartItemResponse,
    setUpdateProductCartItemResponse,
  ] = useState({ res: [], err: null });
  const [addProductToCartResponse, setAddProductToCartResponse] = useState({
    res: [],
    err: null,
  });
  const [
    getCartProductItemsResponse,
    setGetCartProductItemsResponse,
  ] = useState({
    res: [],
    err: null,
  });
  const [
    deleteCartProductItemsResponse,
    setDeleteCartProductItemsResponse,
  ] = useState({
    res: [],
    err: null,
  });
  const [confirmCartResponse, setConfirmCartResponse] = useState({
    res: null,
    err: null,
  });
  const [orderResponse, setOrderResponse] = useState(null);

  const [buyerSearchSuggestionResponse, setSearchSuggestionResponse] = useState(
    {
      res: [],
      err: null,
    },
  );
  const [
    deletePurchaseOrderResponse,
    setDeletePurchaseOrderResponse,
  ] = useState({
    res: [],
    err: null,
  });
  const handleBuyerDetails = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const supplierDetails = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const data = await supplierDetails.json();
      if (data.message) {
        setLoading(false);
        setBuyerDetailsResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setBuyerDetailsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setBuyerDetailsResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getDeliverySlots = async deliveryDate => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          '/delivery-slots?&isOnlyFutureSlots=true&deliveryDate=' +
          deliveryDate,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.error) {
        setLoading(false);
        setDeliverySlotsResponse({
          err: data.error,
        });
      } else {
        setLoading(false);
        setDeliverySlotsResponse({
          res: data,
          err: null,
        });
      }
    } catch (err) {
      setLoading(false);
      setDeliverySlotsResponse({ err: err?.message || err });
    }
  };

  const getSillaraCategoriyList = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const productCatogories = await fetch(url + '/product-categories', {
        method: 'GET',
        headers: headers,
      });
      const data = await productCatogories.json();
      if (data.message) {
        setLoading(false);
        SetSillaraCategoryListResponse({
          res: [],
          err: data.message,
        });
        return {
          res: [],
          err: data.message,
        };
      } else {
        setLoading(false);
        SetSillaraCategoryListResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      SetSillaraCategoryListResponse({ res: [], err: err?.message || err });
      return { res: [], err: err?.message || err };
    }
  };

  const getBuyerProductCategoryList = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const productCatogories = await fetch(
        url + 'product-categories?isRootCategoryOnly=false',
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await productCatogories.json();
      if (data.message) {
        setLoading(false);
        SetBuyerProductCategoryListResponse({
          res: [],
          err: data.message,
        });
        return {
          res: [],
          err: data.message,
        };
      } else {
        setLoading(false);
        SetBuyerProductCategoryListResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      SetBuyerProductCategoryListResponse({ res: [], err: err?.message });
      return { res: [], err: err?.message };
    }
  };

  const getOrder = async orderId => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(url + `/orders/${orderId}`, {
        method: 'GET',
        headers: headers,
      });
      const data = await deliverySlots.json();
      if (data.error) {
        setLoading(false);
        setOrderResponse({
          err: data.error,
        });
      } else {
        setLoading(false);
        setOrderResponse({
          res: data,
          err: null,
        });
      }
    } catch (err) {
      setLoading(false);
      setOrderResponse({ err: err?.message || err });
    }
  };

  const getSillaraProductList = async (
    ProductCatagoryId,
    SearchString,
    deliveryDate,
    deliveryEndTime,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const browseProductList = await fetch(
        url +
          'master-product-list?productCategoryId=' +
          ProductCatagoryId +
          '&searchString=' +
          SearchString +
          '&deliveryDate=' +
          deliveryDate +
          '&deliveryEndTime=' +
          deliveryEndTime,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await browseProductList.json();
      if (data.message) {
        setLoading(false);
        setSillaraProductListResponse({ res: [], err: data.message });
        return { res: [], err: data.message };
      } else {
        setLoading(false);
        setSillaraProductListResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setSillaraProductListResponse({ res: [], err: err?.message || err });
      return { res: [], err: err?.message || err };
    }
  };

  const getBuyerProductList = async (
    ProductCatagoryId,
    SearchString,
    deliveryDate,
    deliveryEndTime,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const browseProductList = await fetch(
        url +
          'product-list?productCategoryId=' +
          ProductCatagoryId +
          '&searchString=' +
          SearchString +
          '&deliveryDate=' +
          deliveryDate +
          '&deliveryEndTime=' +
          deliveryEndTime,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await browseProductList.json();
      if (data.message) {
        setLoading(false);
        setBuyerProductListResponse({
          res: [],
          err: data.message,
        });
        return {
          res: [],
          err: data.message,
        };
      } else {
        setLoading(false);
        setBuyerProductListResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setBuyerProductListResponse({
        res: [],
        err: err?.message || err,
      });
      return {
        res: [],
        err: err?.message || err,
      };
    }
  };

  const updateCart = async (ExpectedDeliveryDate, ExpectedDeliverySlotId) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const updateCartCall = await fetch(url + 'cart', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ ExpectedDeliveryDate, ExpectedDeliverySlotId }),
      });
      const data = await updateCartCall.json();
      if (data.message) {
        setLoading(false);
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      return { res: null, err: err?.message || err };
    }
  };

  const addProductToCart = async productData => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const productCartItem = await fetch(url + 'cart/items', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(productData),
      });
      const data = await productCartItem.json();
      if (data.message) {
        setLoading(false);
        setAddProductToCartResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setAddProductToCartResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setAddProductToCartResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const updateProductCartItem = async (productId, item) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const productCartItem = await fetch(url + 'cart/items/' + productId, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(item),
      });
      const data = await productCartItem.json();
      if (data.message) {
        setLoading(false);
        setUpdateProductCartItemResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setUpdateProductCartItemResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdateProductCartItemResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getCartProductItems = async (continueLoading = false) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const productCartItem = await fetch(url + 'cart/items', {
        method: 'GET',
        headers: headers,
      });
      const data = await productCartItem.json();
      if (data.message) {
        if (!continueLoading) setLoading(false);
        setGetCartProductItemsResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        if (!continueLoading) setLoading(false);
        setGetCartProductItemsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      if (!continueLoading) setLoading(false);
      setGetCartProductItemsResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const deleteCartProductItems = async item => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const productCartItem = await fetch(url + 'cart/items', {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify(item),
      });
      const data = await productCartItem.json();
      if (data.message) {
        setLoading(false);
        setDeleteCartProductItemsResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setDeleteCartProductItemsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setDeleteCartProductItemsResponse({
        res: null,
        err: err?.message || err,
      });
      return {
        res: null,
        err: err?.message || err,
      };
    }
  };

  const confirmCart = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const productCartItem = await fetch(url + 'order', {
        method: 'POST',
        headers: headers,
        body: null,
      });
      const data = await productCartItem.json();
      if (data.message) {
        setLoading(false);
        setConfirmCartResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setConfirmCartResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setConfirmCartResponse({
        res: null,
        err: err?.message || err,
      });
      return {
        res: null,
        err: err?.message || err,
      };
    }
  };

  const submitPurchaseOrder = async (
    orderId,
    purchaseOrderId,
    isPreOrder = false,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const submitPurchaseOrderCall = await fetch(
        url + `/orders/${orderId}/purchase-orders/${purchaseOrderId}`,
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify([
            { op: 'replace', path: 'StatusId', value: isPreOrder ? '5' : '3' },
          ]),
        },
      );
      const res = await submitPurchaseOrderCall.json();
      if (res.error) {
        setLoading(false);
        return false;
      } else {
        setLoading(false);
        return true;
      }
    } catch (err) {
      setLoading(false);
      return false;
    }
  };

  const cancelPurchaseOrder = async (orderId, purchaseOrderId) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const submitPurchaseOrderCall = await fetch(
        url + `/orders/${orderId}/purchase-orders/${purchaseOrderId}`,
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify([
            { op: 'replace', path: 'StatusId', value: '4' },
          ]),
        },
      );
      const res = await submitPurchaseOrderCall.json();
      if (res.error) {
        setLoading(false);
        return false;
      } else {
        setLoading(false);
        return true;
      }
    } catch (err) {
      setLoading(false);
      return false;
    }
  };

  const getSillaraProductListSearchSuggestion = async (
    ProductCatagoryId,
    SearchString,
    deliveryDate,
    deliveryEndTime,
  ) => {
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const browseProductList = await fetch(
        url +
          'master-product-list?productCategoryId=' +
          ProductCatagoryId +
          '&searchString=' +
          SearchString +
          '&deliveryDate=' +
          deliveryDate +
          '&deliveryEndTime=' +
          deliveryEndTime,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await browseProductList.json();
      if (data?.message) {
        setSearchSuggestionResponse({ res: [], err: data.message });
        return { res: [], err: data.message };
      } else {
        setSearchSuggestionResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setSearchSuggestionResponse({ res: [], err: err?.message || err });
    }
  };

  const getBuyerProductSearchSuggestion = async (
    ProductCatagoryId,
    SearchString,
    deliveryDate,
    deliveryEndTime,
  ) => {
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('buyers');
      const browseProductList = await fetch(
        url +
          'product-list?productCategoryId=' +
          ProductCatagoryId +
          '&searchString=' +
          SearchString +
          '&deliveryDate=' +
          deliveryDate +
          '&deliveryEndTime=' +
          deliveryEndTime,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await browseProductList.json();
      if (data?.message) {
        setSearchSuggestionResponse({
          res: [],
          err: data.message,
        });
        return {
          res: [],
          err: data.message,
        };
      } else {
        setSearchSuggestionResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setSearchSuggestionResponse({
        res: [],
        err: err?.message || err,
      });
    }
  };

  const deletePurchaseOrder = async orderId => {
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const browseProductList = await fetch(
        url + `/orders/${orderId}/purchase-orders`,
        {
          method: 'DELETE',
          headers: headers,
        },
      );
      const data = await browseProductList.json();
      if (data?.message) {
        setDeletePurchaseOrderResponse({
          res: [],
          err: data.message,
        });
        return {
          res: [],
          err: data.message,
        };
      } else {
        setDeletePurchaseOrderResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setDeletePurchaseOrderResponse({
        res: [],
        err: err?.message || err,
      });
    }
  };
  return (
    <BuyerContext.Provider
      value={{
        state: {
          buyerDetailsResponse: buyerDetailsResponse,
          deliverySlotsResponse: deliverySlotsResponse,
          sillaraCategoryListResponse: sillaraCategoryListResponse,
          buyerProductCategoryListResponse: buyerProductCategoryListResponse,
          sillaraProductListResponse: sillaraProductListResponse,
          buyerProductListResponse: buyerProductListResponse,
          orderResponse: orderResponse,
          addProductToCartResponse: addProductToCartResponse,
          updateProductCartItemResponse: updateProductCartItemResponse,
          getCartProductItemsResponse: getCartProductItemsResponse,
          deleteCartProductItemsResponse: deleteCartProductItemsResponse,
          confirmCartResponse: confirmCartResponse,
          buyerSearchSuggestionResponse: buyerSearchSuggestionResponse,
          deletePurchaseOrderResponse: deletePurchaseOrderResponse,
        },
        handleBuyerDetails: handleBuyerDetails,
        getDeliverySlots: getDeliverySlots,
        getSillaraCategoriyList: getSillaraCategoriyList,
        getBuyerProductCategoryList: getBuyerProductCategoryList,
        getSillaraProductList: getSillaraProductList,
        getBuyerProductList: getBuyerProductList,
        getOrder: getOrder,
        addProductToCart: addProductToCart,
        updateProductCartItem: updateProductCartItem,
        getCartProductItems: getCartProductItems,
        deleteCartProductItems: deleteCartProductItems,
        submitPurchaseOrder: submitPurchaseOrder,
        cancelPurchaseOrder: cancelPurchaseOrder,
        confirmCart: confirmCart,
        getSillaraProductListSearchSuggestion: getSillaraProductListSearchSuggestion,
        getBuyerProductSearchSuggestion: getBuyerProductSearchSuggestion,
        setSearchSuggestionResponse: setSearchSuggestionResponse,
        setOrderResponse: setOrderResponse,
        updateCart: updateCart,
        deletePurchaseOrder: deletePurchaseOrder,
      }}
    >
      {props.children}
    </BuyerContext.Provider>
  );
};
BuyerProvider.propTypes = {
  children: PropTypes.any,
};

export { BuyerProvider, BuyerContext };
