import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Table,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { CalendarBlue } from '../../../../assets/icons';
import { CustomCalendar, Text } from '../../../../../shared/components';
import { useDeviceDetect } from '../../../../../shared/hooks';
import InnerBody from '../../../../../content/InnerBody';
import { ManualProcurementContext } from '../../../../../apis/admin';
import ManualProcurementQualityOrderDispatch from './ManualProcurementQualityOrderDispatch';
import { LoadingContext } from '../../../../../apis/LoadingContext';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import {
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../../shared/constants/constants';
function ManualProcurementQualityCheck() {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [openCalendar, setIsOpenCalendar] = useState(false);
  const [isBaseNameDropdown, setIsBaseNameDropdown] = useState(false);
  const [isDeliverySlot, setIsDeliverySlot] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [error, setError] = useState({
    isSelectedDateError: false,
    isDeliverySlotError: false,
    isBaseNameError: false,
  });
  const [stage, setStage] = useState('step1');
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;

  const mpQualityCheckContext = useContext(ManualProcurementContext);
  const {
    getDeliverySlotList,
    getDeliveryBasesList,
    getManualProcAdminsOrderSummaryHandler,
  } = mpQualityCheckContext;
  const {
    deliverySlotListRes,
    deliveryBasesListRes,
    mpAdminsOrderSummaryForDelivery,
  } = mpQualityCheckContext.state;

  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const deliverySlotToggle = () => setIsDeliverySlot(!isDeliverySlot);
  const baseNameToggle = () => setIsBaseNameDropdown(!isBaseNameDropdown);

  const [orderClickedItem, setOrderClickedItem] = useState(null);

  useEffect(() => {}, [isMobile, mpAdminsOrderSummaryForDelivery]);

  useEffect(() => {
    if (selectedDate) {
      getDeliverySlotList(
        formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT),
      );
      setSelectedDeliverySlot(null);
      setSelectedBaseName(null);
      setError('');
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDeliverySlot?.id) {
      getDeliveryBasesList(selectedDeliverySlot.id);
      setSelectedBaseName(null);
      setError('');
    }
  }, [selectedDeliverySlot]);

  useEffect(() => {
    if (selectedDate) {
      if (deliverySlotListRes?.res?.length === 0) {
        setError({ isDeliverySlotError: true });
      } else {
        setError({ isDeliverySlotError: false });
      }
    }
  }, [deliverySlotListRes]);

  useEffect(() => {
    if (selectedDeliverySlot) {
      if (deliveryBasesListRes?.res?.length === 0) {
        setError({ isBaseNameError: true });
      } else {
        setError({ isBaseNameError: false });
      }
    }
  }, [deliveryBasesListRes]);

  const loadAdminOrders = baseNameId => {
    const queryParam = `deliverySlotId=${
      selectedDeliverySlot?.id
    }&date=${formatDate(selectedDate, null, SL_DATE_FORMAT, UK_DATE_FORMAT)}`;
    getManualProcAdminsOrderSummaryHandler(queryParam, baseNameId);
  };

  return (
    <InnerBody
      topic="Manual Procurement - Quality Control - Base Summary"
      isDisable={stage === 'step1'}
      onHandleBack={() => setStage('step1')}
      className="manural-procurement-quality-check"
    >
      <div className="quality-check-container">
        {stage === 'step1' ? (
          <>
            <Row
              className="px-2"
              style={!isMobile ? { marginLeft: '-20px' } : {}}
            >
              <div className="col-lg-4 col-sm-12">
                <Text
                  type="text"
                  placeholder={t('SELECT_A_DELIVERY_DATE')}
                  className="delivery-date cursor-pointer"
                  value={
                    selectedDate && moment(selectedDate).format('DD/MM/YYYY')
                  }
                  rightIcon={CalendarBlue}
                  rightIconHandle={() =>
                    setIsOpenCalendar(prevProps => !prevProps)
                  }
                  id="calendar"
                  field="calendar"
                  onClick={() => setIsOpenCalendar(prevProps => !prevProps)}
                  rightIconGroupClassName="position-relative cursor-pointer"
                  rightIconClassName="calendar-icon"
                  showErrors={false}
                  readOnly
                />
                {error?.isSelectedDateError && (
                  <small className="error-message">
                    {t('SELECT_A_DELIVERY_DATE')}
                  </small>
                )}
              </div>
              {openCalendar && (
                <CustomCalendar
                  onClose={() => setIsOpenCalendar(false)}
                  onChange={e => {
                    setSelectedDate(e);
                    setIsOpenCalendar(false);
                    setError('');
                  }}
                  value={selectedDate && new Date(selectedDate)}
                />
              )}

              <Dropdown
                disabled={
                  selectedDate === '' || deliverySlotListRes?.res?.length === 0
                }
                isOpen={isDeliverySlot}
                toggle={deliverySlotToggle}
                direction="down"
                className="col-lg-4 col-sm-12 mb-3"
                style={
                  selectedDate === '' || deliverySlotListRes?.res?.length === 0
                    ? { opacity: '0.6' }
                    : {}
                }
              >
                <DropdownToggle
                  caret
                  className={
                    selectedDeliverySlot === null ? 'grey-text' : 'black-text'
                  }
                >
                  {selectedDeliverySlot === null
                    ? t('SELECT_A_DELIVER_SLOT')
                    : `${selectedDeliverySlot?.startTime} - ${selectedDeliverySlot?.endTime}`}
                </DropdownToggle>
                <DropdownMenu>
                  {deliverySlotListRes?.res?.map(item => (
                    <DropdownItem
                      key={item?.id}
                      onClick={() => {
                        setSelectedDeliverySlot(item);
                      }}
                    >
                      {`${item?.startTime} - ${item?.endTime}`}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
                {error?.isDeliverySlotError && (
                  <small className="error-message mt-3 ">
                    A delivery slot is not available for the selected date.
                  </small>
                )}
              </Dropdown>

              <Dropdown
                disabled={
                  selectedDeliverySlot === null ||
                  deliveryBasesListRes?.res?.length === 0
                }
                isOpen={isBaseNameDropdown}
                toggle={baseNameToggle}
                direction="down"
                style={
                  selectedDeliverySlot === null ||
                  deliveryBasesListRes?.res?.length === 0
                    ? { opacity: '0.6' }
                    : {}
                }
                className={
                  isMobile ? 'mb-3 col-lg-4 col-sm-12' : 'col-lg-4 col-sm-12 '
                }
              >
                <DropdownToggle
                  caret
                  className={
                    selectedBaseName === null ? 'grey-text' : 'black-text'
                  }
                >
                  {selectedBaseName === null
                    ? t('SELECT_A_BASE_NAME')
                    : selectedBaseName.name}
                </DropdownToggle>
                <DropdownMenu>
                  {deliveryBasesListRes?.res?.map(item => (
                    <DropdownItem
                      key={item?.id}
                      onClick={() => {
                        setSelectedBaseName(item);
                        loadAdminOrders(item?.id);
                      }}
                    >
                      {item?.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
                {error?.isBaseNameError && (
                  <small className="error-message mt-3">
                    A base name is not available for the selected slot.
                  </small>
                )}
              </Dropdown>
            </Row>
            {(selectedBaseName?.length === 0 || selectedBaseName === null) && (
              <div className="py-5 custom-empty-message">
                <p>{t('SELECT_A_BASE_TO_VIEW_THE_AVAILABLE_RECORDS')}</p>
              </div>
            )}
            {selectedBaseName !== null &&
              mpAdminsOrderSummaryForDelivery?.res?.length > 0 && (
                <div className="tableFixHead">
                  <Table bordered>
                    {!isMobile && (
                      <thead>
                        <tr>
                          <th>Manual procurement admin name</th>
                          <th>Ready</th>
                          <th>Preparing</th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {mpAdminsOrderSummaryForDelivery?.res?.map(
                        (order, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                setOrderClickedItem(order);
                                setStage('step2');
                              }}
                              className={
                                order.readyForDispatchOrderCount > 0
                                  ? 'lighter-blue-bg cursor-pointer'
                                  : 'cursor-pointer'
                              }
                              style={{
                                borderTopLeftRadius: '15px',
                                borderTopRightRadius: '15px',
                              }}
                            >
                              {!isMobile ? (
                                <>
                                  <td>{order?.manualProcurementAdminName}</td>
                                  <td>{order.readyForDispatchOrderCount}</td>
                                  <td>{order.processingOrderCount}</td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <small className="d-block p-0 pb-3">
                                      {order?.manualProcurementAdminName}
                                    </small>
                                    <small className="d-block p-0">Ready</small>
                                    <span className="blue-color p-0">
                                      {order.readyForDispatchOrderCount}
                                    </span>
                                  </td>
                                  <td
                                    style={{
                                      display: 'tableCell',
                                      verticalAlign: 'bottom',
                                    }}
                                  >
                                    <small className="d-block p-0 pt-3">
                                      Preparing
                                    </small>
                                    <small className="blue-color d-block p-0">
                                      {order.processingOrderCount}
                                    </small>
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
            {!loading &&
              selectedBaseName !== null &&
              mpAdminsOrderSummaryForDelivery?.res?.length === 0 && (
                <div className="py-5 custom-empty-message">
                  <p>{t('NO_ORDERS_AVAILABLE')}</p>
                </div>
              )}
          </>
        ) : (
          <>
            <ManualProcurementQualityOrderDispatch
              isMobile={isMobile}
              adminOrder={orderClickedItem}
              deliverySlotId={selectedDeliverySlot?.id}
              deliveryDate={selectedDate}
              selectedBaseName={selectedBaseName}
            />
          </>
        )}
      </div>
    </InnerBody>
  );
}

export default ManualProcurementQualityCheck;
