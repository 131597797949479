import React, { useEffect } from 'react';
import { S3_BUCKET_URL } from '../../../../environment';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import CustomTooltip from '../../../../shared/components/CustomTooltip';

function ProductCard({ id, src, title, unit }) {
  // const [isShowTitle, setIsShowTitle] = useState(true);
  useEffect(() => {
    /*document
      .getElementById('delete')
      .addEventListener('touchstart', function(e) {
        e.stopPropagation();
      });
    document.getElementById('delete').addEventListener('touchend', function(e) {
      e.stopPropagation();
    });
    document
      .getElementById('delete')
      .addEventListener('mouseleave', function(e) {
        e.stopPropagation();
      });
    document
      .getElementById('delete')
      .addEventListener('mousemove', function(e) {
        e.stopPropagation();
      });
    document.getElementById('delete').addEventListener('mouseup', function(e) {
      e.stopPropagation();
    });*/
  }, []);

  return (
    <Card id={id} className="admin-product-card">
      <div className="product-card-img-container">
        <img
          className="product-card-img "
          alt={title}
          src={S3_BUCKET_URL + 'Products/' + src}
        />
      </div>
      <CardBody>
        <CardTitle tag="h5" id={'title' + id}>
          {title.length > 10 ? title.substring(0, 9) + '...' : title}
        </CardTitle>
        <CustomTooltip
          placement="bottom"
          targetId={'title' + id}
          displayMessage={title}
        />
        <CardSubtitle className="mb-2 text-muted" tag="h6">
          {unit}
        </CardSubtitle>
      </CardBody>
    </Card>
  );
}

ProductCard.propTypes = {
  id: PropTypes.number,
  src: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  price: PropTypes.string,
  onHandleCheck: PropTypes.func,
};
export default ProductCard;
