import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';
import Errors, { HasErrors } from './Errors';
import { Validate } from '../../app/config';

function Switch(props) {
  const {
    children,
    validation = {},
    disabled,
    label,
    subLabel,
    description,
    suffix,
    required,
    labelClassName = '',
    groupClassName = '',
    showErrors = true,
    id,
    field,
    checked,
    ...rest
  } = props;

  const { value = false, state = {} } = validation;
  var fieldName =
    typeof field === 'string' ? field : `${field[0]}[${field[1]}]`;
  const isRequired =
    required || (validation && validation.rules && validation.rules.required);

  let dynamicProps = {};
  if (suffix) {
    dynamicProps['aria-labelledby'] = `${id}-desc`;
  }

  return (
    <FormGroup
      switch
      className={`${groupClassName} ${disabled ? 'disabled-input' : ''} ${
        isRequired ? 'required-input' : ''
      }`.trim()}
    >
      {label || subLabel || description ? (
        <Label check className={labelClassName} id={`${id}-label`} htmlFor={id}>
          {label}
          {isRequired ? (
            <React.Fragment>
              <span aria-hidden="true"></span>
            </React.Fragment>
          ) : (
            ''
          )}{' '}
          {subLabel && subLabel.length > 0 ? <small>{subLabel}</small> : ''}
          {description && description.length > 0 ? (
            <span className="description">{description}</span>
          ) : (
            ''
          )}
        </Label>
      ) : (
        ''
      )}
      <Input
        aria-describedby={
          HasErrors(validation)
            ? null
            : `error-${fieldName.replace('[', '-').replace(']', '')}-validation`
        }
        value={value}
        invalid={Validate.isInvalid(state)}
        valid={Validate.isValid(state)}
        checked={checked}
        disabled={disabled}
        required={required}
        id={id}
        name={fieldName}
        type="switch"
        {...dynamicProps}
        {...rest}
      />

      {children}
      {showErrors ? <Errors validation={validation} field={field} /> : ''}
    </FormGroup>
  );
}

Switch.propTypes = {
  /**
   * Any children of the component
   */
  children: PropTypes.element,
  /**
   * Text description text
   */
  description: PropTypes.string,
  /**
   * Text descriptionExample text
   */
  descriptionExample: PropTypes.string,
  /**
   * Text input disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Input field names
   */
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  /**
   * Style class name for group
   */
  groupClassName: PropTypes.string,
  /**
   * Date ID
   */
  id: PropTypes.string.isRequired,
  /**
   * Style class name for input group
   */
  inputGroupClassName: PropTypes.string,
  /**
   * A prefix for the input
   */
  inputPrefix: PropTypes.string,
  /**
   * A suffix for the input
   */
  inputSuffix: PropTypes.string,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Style class name for label
   */
  labelClassName: PropTypes.string,
  /**
   * Input required or not
   */
  required: PropTypes.bool,
  /**
   * Show errors or not
   */
  showErrors: PropTypes.bool,
  /**
   * Sub label text
   */
  subLabel: PropTypes.string,
  /**
   * Suffix text
   */
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Input validation
   */
  validation: PropTypes.object,
  /**
   * Check box
   */
  checked: PropTypes.bool,
};

export default Switch;
