import React from 'react';
import PropTypes from 'prop-types';
import { Session } from '../../app/config';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, userRole }) {
  if (
    Session?.getUser()?.signInUserSession?.accessToken?.payload?.[
      'cognito:groups'
    ]?.[0] !== userRole
  ) {
    return <Navigate to="/auth/login" />;
  }
  return Session?.isAuthorized() ? children : <Navigate to="/auth/login" />;
}

PrivateRoute.propTypes = {
  location: PropTypes.any,
  component: PropTypes.any,
  children: PropTypes.any,
  userRole: PropTypes.string,
};

export default PrivateRoute;
