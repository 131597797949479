import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'reactstrap';
import { CustomModal } from '../../../../../shared/components';
import { useTranslation } from 'react-i18next';

function ProgressAdminDetailModal({ order, setIsModalOpen, setSelectedOrder }) {
  const { t } = useTranslation();

  const onHandleOk = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div className="progress-admin-details-modal">
      <CustomModal
        isOpen={true}
        showClose={true}
        hideCancel={false}
        title="This Order in Progress"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
      >
        <Row>
          <p>
            {t('THE_SELECTED_ORDER_IS_IN_PROGRESS', {
              ADMIN_PHONE_NUMBER: order?.buyerQualityCheckAdmin?.contactNumber,
            })}
          </p>
        </Row>
        <Button
          color="primary"
          type="submit"
          className="form-submit mx-auto"
          onClick={() => onHandleOk()}
        >
          {t('OK')}
        </Button>
      </CustomModal>
    </div>
  );
}
ProgressAdminDetailModal.propTypes = {
  order: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  setSelectedOrder: PropTypes.func,
};
export default ProgressAdminDetailModal;
