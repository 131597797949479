import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  //Image,
} from '@react-pdf/renderer';
import moment from 'moment';
import { sillara } from '../../../../app/assets/img';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

const borderColor = '#E3F3EF';
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontSize: 12,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  invoiceInformationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  invoiceDetailCol: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '25px',
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
  },
  description: {
    fontWeight: 300,
    fontSize: 12,
  },
  card: {
    margin: '25px  0px 5px 0',
    marginRight: '10px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    borderRadius: '20px',
    border: '1px solid #BCE0EE',
  },
  cardHeader: {
    color: '#383e92',
    border: '0',
    marginBottom: '0',
    textAlign: 'left',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    fontSize: 14,
  },
  cardBody: {
    padding: '0 10px 10px 10px',
  },
  totalValueHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  table: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 0,

    marginBottom: 10,
    borderWidth: 1,
    borderRightWidth: 0,
    borderColor: borderColor,
  },
  thead: {
    flexDirection: 'row',
    borderTopColor: borderColor,
    backgroundColor: '#e6f4ff',
    borderTopWidth: 1,
    alignItems: 'center',
    textAlign: 'left',
    fontStyle: 'bold',
    flexGrow: 1,
  },
  tr: {
    flexDirection: 'row',
    borderTopColor: borderColor,
    borderTopWidth: 1,
    alignItems: 'center',
    fontStyle: 'bold',
  },
  bold: {
    fontWeight: 800,
    fontStyle: 'bold',
  },
  textRight: {
    textAlign: 'right',
  },
  th: {
    backgroundColor: '#e6f4ff',
    borderRight: '1px solid ' + borderColor,
    fontSize: 13,
    fontStyle: 'bold',
    verticalAlign: 'center',
    paddingRight: '7px',
    paddingLeft: '7px',
    paddingTop: 5,
    paddingBottom: 2.5,
  },
  td: {
    paddingTop: 5,
    paddingBottom: 2.5,
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 400,
    borderRight: '1px solid ' + borderColor,
    verticalAlign: 'center',
    paddingLeft: 7,
    paddingRight: 7,
  },
  width40: {
    width: '40%',
  },
  width10: {
    width: '10%',
  },
  width60: {
    width: '60%',
  },
  width20: {
    width: '20%',
  },
  width35: {
    width: '35%',
  },
  width15: {
    width: '15%',
  },
  width12: {
    width: '12.5%',
  },
  blue: {
    color: '#383e92',
  },
  sillaraFooter: {
    top: '0mm',
    width: '100%',
    backgroundColor: '#c5efd7',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    left: 0,
  },
  footerImgContainer: {
    width: 110,
    marginTop: '-15px',
    marginBottom: '-10px',
    //height: 95,
  },
  footerText: {
    fontSize: 14,
    maxWidth: '45%',
    color: '#00966b',
  },
});

function PdfDocBuyerDetailInvoice({ invoice, deleiveryDate }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.sillaraFooter}>
          <Image style={styles.footerImgContainer} src={sillara} />
          <View style={styles.footerText}>
            <Text>Sillara Agri Tech(Pvt) Ltd</Text>
            <Text> 42/5, Main Road, Battaramulla</Text>
          </View>
        </View>

        <View
          style={[{ padding: '10px', paddingTop: '25px' }, styles.pageBreak]}
        >
          <View style={styles.invoiceInformationRow}>
            <View style={styles.invoiceDetailCol}>
              <Text style={styles.title}>Invoice Number :</Text>
              <Text style={styles.description}>{invoice.invoiceNumber}</Text>
            </View>
            <View style={styles.invoiceDetailCol}>
              <Text style={styles.title}>Delivery Date :</Text>
              <Text style={styles.description}>
                {moment(deleiveryDate).format('DD-MM-YYYY')}{' '}
              </Text>
            </View>
            <View style={styles.invoiceDetailCol}>
              <Text style={styles.title}>Client :</Text>
              <Text style={styles.description}>{invoice.buyerCompanyName}</Text>
            </View>
            <View style={styles.invoiceDetailCol}>
              <Text style={styles.title}>Client Address :</Text>
              <Text style={styles.description}>{invoice.address}</Text>
            </View>
          </View>
          {/* Table  */}
          <View style={styles.card}>
            <Text style={styles.cardHeader}>Product Details</Text>
            <View style={styles.cardBody}>
              <View style={styles.table}>
                <View style={styles.thead}>
                  <Text style={[styles.width40, styles.th]}>Product Name</Text>
                  <Text style={[styles.width20, styles.th]}>Quantity</Text>
                  <Text style={[styles.width20, styles.th, styles.textRight]}>
                    Unit Price
                  </Text>
                  <Text style={[styles.width20, styles.th, styles.textRight]}>
                    Total
                  </Text>
                </View>

                {invoice?.products?.map((item, index) => (
                  <View key={index} style={styles.tr}>
                    <Text style={[styles.width40, styles.td]}>
                      {item?.productName}
                    </Text>
                    <Text style={[styles.width20, styles.td, styles.blue]}>
                      {item?.quantity || 'Pending'}{' '}
                      {item?.quantity ? item?.unitTypeName : ''}
                    </Text>
                    <Text
                      style={[
                        styles.width20,
                        styles.td,
                        styles.textRight,
                        styles.blue,
                      ]}
                    >
                      {item.statusId === 13
                        ? 'N/A'
                        : item?.unitPrice
                        ? commafy(RoundNum(item?.unitPrice, 2))
                        : 'Pending'}{' '}
                    </Text>
                    <Text
                      style={[
                        styles.width20,
                        styles.td,
                        styles.textRight,
                        styles.blue,
                      ]}
                    >
                      {item.statusId === 13
                        ? 'N/A'
                        : item?.totalPrice
                        ? commafy(RoundNum(item?.totalPrice, 2))
                        : 'Pending'}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
          {/* End of Table  */}
          {/* Table  */}
          {invoice?.returnedProducts?.length > 0 && (
            <View style={styles.card}>
              <Text style={styles.cardHeader}>Returns</Text>
              <View style={styles.cardBody}>
                <View style={styles.table}>
                  <View style={styles.thead}>
                    <Text style={[styles.width40, styles.th]}>
                      Product Name
                    </Text>
                    <Text style={[styles.width35, styles.th]}>Reason</Text>
                    <Text style={[styles.width12, styles.th, styles.textRight]}>
                      Quantity
                    </Text>
                    <Text style={[styles.width12, styles.th, styles.textRight]}>
                      Total
                    </Text>
                  </View>

                  {invoice?.returnedProducts?.map((item, index) => (
                    <View key={index} style={styles.tr}>
                      <Text style={[styles.width40, styles.td]}>
                        {item?.productName}
                      </Text>
                      <Text style={[styles.width35, styles.td]}>
                        {item?.buyerQCRejectedReasons?.length > 0 && (
                          <>
                            <Text style={styles.bold}>Buyer Check: </Text>
                            {item?.buyerQCRejectedReasons?.map(
                              (buyerReason, index) => (
                                <Text key={'buyer' + buyerReason}>
                                  {buyerReason}{' '}
                                  {index !==
                                    item?.buyerQCRejectedReasons.length - 1 &&
                                    ', '}
                                </Text>
                              ),
                            )}
                            <br />
                          </>
                        )}
                        {item?.supplierQCRejectedReasons?.length > 0 && (
                          <>
                            <Text style={styles.bold}>Supplier Check: </Text>
                            {item?.supplierQCRejectedReasons?.map(
                              (supplierReason, index) => (
                                <Text key={'supplier' + supplierReason}>
                                  {supplierReason}
                                  {index !==
                                    item?.supplierQCRejectedReasons.length -
                                      1 && ', '}
                                </Text>
                              ),
                            )}
                          </>
                        )}
                      </Text>
                      <Text
                        style={[
                          styles.width12,
                          styles.td,
                          styles.textRight,
                          styles.blue,
                        ]}
                      >
                        {item?.quantity || 'N/A'}{' '}
                        {item?.quantity ? item?.unitTypeName : ''}
                      </Text>
                      <Text
                        style={[
                          styles.width12,
                          styles.td,
                          styles.textRight,
                          styles.blue,
                        ]}
                      >
                        {item?.totalPrice
                          ? commafy(RoundNum(item?.totalPrice, 2))
                          : 'N/A'}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}
          {/* End of Table  */}
          {/* Table  */}
          {invoice?.purchaseOrder !== null &&
            Object.keys(invoice?.purchaseOrder)?.length !== 0 && (
              <View style={styles.card}>
                <View style={styles.totalValueHeader}>
                  <Text style={styles.cardHeader}>Total </Text>
                  <Text style={[styles.cardHeader, { fontSize: 12 }]}>
                    LKR{' '}
                    {invoice?.purchaseOrder?.total
                      ? commafy(RoundNum(invoice?.purchaseOrder?.total, 2))
                      : ''}
                    &nbsp;&nbsp;
                  </Text>
                </View>

                <View style={styles.cardBody}>
                  <View style={styles.table}>
                    <View style={styles.tr}>
                      <Text style={[styles.width60, styles.td]}>Sub Total</Text>
                      <Text
                        style={[
                          styles.width40,
                          styles.td,
                          styles.textRight,
                          styles.blue,
                        ]}
                      >
                        LKR&nbsp;
                        {commafy(RoundNum(invoice?.purchaseOrder?.subTotal, 2))}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={[styles.width60, styles.td]}>
                        Service Fee
                      </Text>
                      <Text
                        style={[
                          styles.width40,
                          styles.td,
                          styles.textRight,
                          styles.blue,
                        ]}
                      >
                        LKR&nbsp;
                        {commafy(
                          RoundNum(invoice?.purchaseOrder?.serviceFee, 2),
                        )}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={[styles.width60, styles.td]}>
                        Loading Fee
                      </Text>
                      <Text
                        style={[
                          styles.width40,
                          styles.td,
                          styles.textRight,
                          styles.blue,
                        ]}
                      >
                        LKR&nbsp;
                        {commafy(
                          RoundNum(invoice?.purchaseOrder?.loadingFee, 2),
                        )}
                      </Text>
                    </View>
                    <View style={styles.tr}>
                      <Text style={[styles.width60, styles.td]}>
                        Delivery Fee
                      </Text>
                      <Text
                        style={[
                          styles.width40,
                          styles.td,
                          styles.textRight,
                          styles.blue,
                        ]}
                      >
                        LKR&nbsp;
                        {commafy(
                          RoundNum(invoice?.purchaseOrder?.logisticFee, 2),
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
          {/* End of Table  */}
        </View>
      </Page>
    </Document>
  );
}

PdfDocBuyerDetailInvoice.propTypes = {
  invoice: PropTypes.any,
  deleiveryDate: PropTypes.string,
};
export default PdfDocBuyerDetailInvoice;
