import React from 'react';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { dislike } from '../../../../assets/img';
// import moment from 'moment';
import { useEffect } from 'react';
import { BuyerManagementContext } from '../../../../../apis/admin';
import { useContext } from 'react';
import { useState } from 'react';
import QualityCheckReviewModal from './QualityCheckReviewModal';
import { REVIEW_VALUES } from './constant';
import { t } from 'i18next';

function DetailViewOfOrder({
  selectedPurchaseOrder,
  setSelectedPurchaseOrder,
}) {
  // const { t } = useTranslation();
  const buyerManagementContext = useContext(BuyerManagementContext);
  const {
    getDetailViewOfOrder,
    addBuyerComplainReason,
  } = buyerManagementContext;
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [reviewDetails, setReviewDetails] = useState({
    quality: null,
    quantity: null,
    tagging: null,
    amount: 0,
  });

  useEffect(() => {
    setReviewDetails({
      quality: null,
      quantity: null,
      tagging: null,
      amount: 0,
    });
    getDetailViewOfOrder(selectedPurchaseOrder?.purchaseOrderId).then(res => {
      if (res.err) {
        return;
      } else {
        setPurchaseOrderDetails(res.res);
        setIsDataAvailable(res.res?.purchaseOrderProducts?.length > 0);
      }
    });
  }, []);

  const onClickDisLike = order => {
    setSelectedOrder(order);
    setReviewDetails({
      quality: order.rejectedReason.includes(REVIEW_VALUES.QUALITY.id),
      quantity: order.rejectedReason.includes(REVIEW_VALUES.QUANTITY.id),
      tagging: order.rejectedReason.includes(REVIEW_VALUES.TAGGING.id),
      amount: order.amountDeducted,
    });
    setIsModalOpen(true);
  };

  const getReviews = order => {
    let result = [];
    if (order.rejectedReason.includes(REVIEW_VALUES.QUALITY.id))
      result.push(<p>{REVIEW_VALUES.QUALITY.value}</p>);
    if (order.rejectedReason.includes(REVIEW_VALUES.QUANTITY.id))
      result.push(<p>{REVIEW_VALUES.QUANTITY.value}</p>);
    if (order.rejectedReason.includes(REVIEW_VALUES.TAGGING.id))
      result.push(<p>{REVIEW_VALUES.TAGGING.value}</p>);

    return result;
  };

  const onHandleSave = () => {
    let tempReasons = [];
    purchaseOrderDetails?.purchaseOrderProducts.forEach(product => {
      let tempReason = {
        PurchaseOrderProductId: product.purchaseOrderProductId,
        AmountToBeDeducted: parseFloat(product.amountDeducted),
        RejectedReasonId: product.rejectedReason,
      };
      tempReasons.push(tempReason);
    });
    addBuyerComplainReason(
      selectedPurchaseOrder?.purchaseOrderId,
      tempReasons,
    ).then(res => {
      if (res.err) {
        return;
      } else {
        setSelectedPurchaseOrder(null);
      }
    });
  };

  return (
    <div>
      <div className="detail-view-of-order-container">
        <>
          {isDataAvailable ? (
            <>
              <p>
                {t('DELIVERY_SLOT')}: {purchaseOrderDetails.deliverySlot}
              </p>
              <p>
                {t('VEHICLE_NUMBER_PLATE')}:{' '}
                {purchaseOrderDetails.vehicleNumberPlate}
              </p>
              <p>
                {t('BASE_NAME')}: {purchaseOrderDetails.baseName}
              </p>
              <p>
                {t('BUYER_NAME')}: {purchaseOrderDetails.buyerName}
              </p>
              <p>
                {t('TAG_NUMBER')}: {purchaseOrderDetails.tagNumber}
              </p>
              {/* {selectedOrder && ( */}

              <div className="tableFixHead">
                <Table bordered>
                  <thead>
                    <tr>
                      <th className="head product-name-head">{t('PRODUCT')}</th>
                      <th className="head quantity-head">{t('QUANTITY')}</th>
                      <th className="head review-head">{t('REVIEW')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrderDetails?.purchaseOrderProducts?.map(order => {
                      return (
                        <tr key={'order' + order.purchaseOrderProductId}>
                          <td className="product-name">
                            {order.productName}
                            <span className="d-sm-none quantity">
                              <br />
                              {order.quantity + ' ' + order.unitMeasure}
                            </span>
                          </td>
                          <td className="quantity quantity-hide">
                            {order.quantity + ' ' + order.unitMeasure}
                          </td>
                          <td className="review">
                            {order.rejectedReason.length > 0 ? (
                              <div
                                onClick={() => {
                                  onClickDisLike(order);
                                }}
                              >
                                {getReviews(order)}
                              </div>
                            ) : (
                              <Button
                                onClick={() => {
                                  onClickDisLike(order);
                                }}
                                color="p-0 btn-link"
                                className="dislike-button"
                              >
                                <img width="25" src={dislike} alt="dislike" />
                              </Button>
                            )}

                            {selectedOrder === order && isModalOpen && (
                              <QualityCheckReviewModal
                                order={selectedOrder}
                                setPurchaseOrderDetails={
                                  setPurchaseOrderDetails
                                }
                                purchaseOrderDetails={purchaseOrderDetails}
                                setIsModalOpen={setIsModalOpen}
                                reviewDetails={reviewDetails}
                                setReviewDetails={setReviewDetails}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Button
                color="primary"
                type="submit"
                className="form-submit"
                onClick={() => onHandleSave()}
              >
                Save
              </Button>
            </>
          ) : (
            <div className="py-5 custom-empty-message">
              <p>{t('NO_ORDERS_AVAILABLE')}</p>
            </div>
          )}
        </>
        {/* )}  */}
      </div>
    </div>
  );
}

DetailViewOfOrder.propTypes = {
  selectedPurchaseOrder: PropTypes.object,
  setSelectedPurchaseOrder: PropTypes.func,
};

export default DetailViewOfOrder;
