import React, { useState, useRef, useEffect, useContext } from 'react';
import InnerBody from '../../../../content/InnerBody';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  Button,
} from 'reactstrap';
import { CustomModal } from '../../../../shared/components';
import { useTranslation } from 'react-i18next';
import { BuyerManagementContext } from '../../../../apis/admin';
import { Session } from '../../../config';
import { excelIcon } from '../../../assets/img/';
import closeIcon from '../../../assets/icons/Close.svg';
import { SessionContext } from '../../../../apis/authentication';

function BuyerAccountManagement() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const [buyerId, setBuyerId] = useState(null);
  const buyerMgmContext = useContext(BuyerManagementContext);
  const { uploadFile, getBuyers } = buyerMgmContext;
  const sessionContext = useContext(SessionContext);
  const { user } = sessionContext.state;
  const { buyerList, uploadFileResponse } = buyerMgmContext.state;
  const [errorPopupModal, setErrorPopupModal] = useState(false);
  useEffect(() => {
    getBuyers();
  }, []);

  useEffect(() => {
    if (uploadFileResponse?.res) {
      setIsModalOpen(true);
    } else if (uploadFileResponse?.err) {
      setErrorPopupModal(prevProp => !prevProp);
    }
    ref.current.value = null;
    setBuyerId(null);
    removeFile();
  }, [uploadFileResponse]);

  const handleChangeFile = e => {
    setFile(e?.target?.files[0]);
  };

  const toggleErrorPopup = () => {
    setErrorPopupModal(!errorPopupModal);
  };

  const errorPopup = () => (
    <CustomModal
      isOpen={errorPopupModal}
      toggle={toggleErrorPopup}
      isScrollable={true}
      title={'Upload failure'}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {uploadFileResponse?.err}
    </CustomModal>
  );

  const removeFile = () => {
    setFile(null);
    ref.current.value = null;
  };

  const handleUploadFile = () => {
    if (buyerId === null) {
      setError(t('PLEASE_SELECT_A_BUYER_ID'));
      return;
    }
    setError(null);
    getBase64(file);
  };

  const upload = encodedFile => {
    const user = Session.getUser();
    let buyer;
    buyerList?.res.forEach(buy => {
      if (buy?.buyerCode == buyerId) {
        buyer = buy;
      }
    });
    if (encodedFile) {
      uploadFile({
        fileName: file?.name,
        masterFileType: 'BuyerMaster',
        userId: user.username,
        buyerCompanyId: buyer.buyerCompanyId,
        excelBase64: encodedFile,
      });
    }
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const newBaseString = reader.result.split('base64,');
      upload(btoa(newBaseString[1]));
      removeFile();
    };
    reader.onerror = function() {
      //return null;
    };
  }

  const handleClick = () => {
    ref.current.click();
  };

  const modalToggle = () => setIsModalOpen(!isModalOpen);
  const dropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);
  const ref = useRef();
  return (
    <div id="buyer-account-management">
      <InnerBody isDisable={true} topic={t('BUYER_ACCOUNT_MANAGEMENT')}>
        <Col sm={12} md={6} className="mb-4">
          <small style={{ fontSize: '14px' }}>{t('BUYER_ID')}</small>
          <Dropdown
            isOpen={isDropdownOpen}
            toggle={dropdownToggle}
            direction="down"
            className="mt-2"
          >
            <DropdownToggle
              caret
              className={buyerId === null ? 'grey-text' : 'black-text'}
            >
              {buyerId === null ? t('SELECT_A_BUYER_ID') : buyerId}
            </DropdownToggle>
            <DropdownMenu>
              {buyerList?.res?.map((buyer, index) => (
                <DropdownItem
                  onClick={e => {
                    setBuyerId(e.currentTarget.textContent);
                  }}
                  key={index}
                >
                  {buyer?.buyerCode}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {!buyerId && error && (
            <small className="error-message dropdown-text">{error}</small>
          )}
        </Col>
        <Row>
          <Col md={6} xs={12} style={{ minHeight: '250px' }}>
            <Card className="upload-card text-center shadow">
              <div className="rounded upload-card-body">
                {file ? (
                  <>
                    <div className="upload-card-icon-container">
                      <img
                        src={excelIcon}
                        alt="Excel Icon"
                        className="m-auto upload-card-icon"
                      />
                      <img
                        src={closeIcon}
                        alt="home icon"
                        className="upload-card-close-icon"
                        onClick={removeFile}
                      />
                    </div>
                    <p className="upload-card-file-name">{file.name}</p>
                  </>
                ) : (
                  <div className="upload-card-icon-container">
                    <img
                      src={excelIcon}
                      alt="Excel Icon"
                      className="m-auto upload-card-icon"
                    />
                  </div>
                )}
                {!file && (
                  <p className="upload-card-text">
                    Upload Buyer Product List Here
                  </p>
                )}
                <div className="d-flex flex-column">
                  <Button className="browse-card-button" onClick={handleClick}>
                    {t('BROWSE')}
                  </Button>
                  <input
                    ref={ref}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".xlsx"
                    onChange={handleChangeFile}
                  />

                  <Button
                    disabled={!file}
                    className="mt-2 upload-card-button btn-primary"
                    onClick={handleUploadFile}
                  >
                    {t('UPLOAD_PRODUCT_LIST')}
                  </Button>
                </div>
                {errorPopup()}
                <CustomModal
                  isOpen={isModalOpen}
                  toggle={modalToggle}
                  isScrollable={true}
                  enableFooter={false}
                  title="Upload Confirmation"
                  modalstyleClassName="modal-style"
                  modalContentContainer="modal-container"
                  modalHeaderClassName="modal-header"
                  modalheaderTopic="modal-header-topic mt-0 mb-3"
                  modalFooterClassName="modal-footer"
                  modalBodyClassName="small"
                >
                  {t(
                    'PRODUCT_LIST_UPLOADED_AFTER_COMPLETED_YOU_WILL_RECEIVE_SMS',
                    {
                      PHONE_NUMBER: user?.attributes?.phone_number,
                    },
                  )}
                  <p>
                    <Button
                      className="my-3 btn-primary"
                      id="modal-footer-btn-save"
                      onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                      {t('OK')}
                    </Button>
                  </p>
                </CustomModal>
              </div>
            </Card>
          </Col>
        </Row>
      </InnerBody>
    </div>
  );
}
export default BuyerAccountManagement;
