import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const ReportsContext = React.createContext();
const ReportsProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [excelBase64Response, setExcelBase64Response] = useState({
    res: null,
    err: null,
  });
  const [deliveryBasesListRes, setDeliveryBasesListRes] = useState({
    res: [],
    err: null,
  });

  const getExcelReportByDataAndBaseName = async query => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const uploadDetails = await fetch(
        url + `/reports/bill-transaction-fees?${query}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await uploadDetails.json();

      if (data.error || data?.message) {
        setExcelBase64Response({ err: data?.message, res: null });
      } else {
        setExcelBase64Response({ err: null, res: data });
      }

      setLoading(false);
    } catch (err) {
      setExcelBase64Response({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getDeliveryBasesListByDate = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(url + `/reports/bases`, {
        method: 'GET',
        headers: headers,
      });
      const data = await summaryList.json();

      if (data.error || data?.message) {
        setDeliveryBasesListRes({ err: data?.message, res: null });
      } else {
        setDeliveryBasesListRes({ err: null, res: data });
      }
      setLoading(false);
    } catch (err) {
      setDeliveryBasesListRes({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        state: {
          excelBase64Response: excelBase64Response,
          deliveryBasesListRes: deliveryBasesListRes,
        },
        getExcelReportByDataAndBaseName: getExcelReportByDataAndBaseName,
        getDeliveryBasesListByDate: getDeliveryBasesListByDate,
      }}
    >
      {props.children}
    </ReportsContext.Provider>
  );
};
ReportsProvider.propTypes = {
  children: PropTypes.any,
};

export { ReportsProvider, ReportsContext };
