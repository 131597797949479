import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hidePassword, showPassword } from '../../../assets/img';
import { Form, Button } from 'reactstrap';
import { AuthContext } from '../../../../apis/authentication';
import {
  COGNITO,
  COGNITO_POLICY,
  LOGIN_ISSUE_CONTACT_NUMBER,
  SRILANKAN_COUNTRY_CODE,
} from '../../../../shared/constants/constants';
import { Validate } from '../../../config';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../shared';
import { alterPhoneNumber } from '../../../utilities/utils';
import PropTypes from 'prop-types';

function SignUpForm(props) {
  const { setStage } = props;
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  const signInContext = useContext(AuthContext);
  const { signIn, logout } = signInContext;
  const { signInResponse } = signInContext.state;
  const { t } = useTranslation();

  const [form, setForm] = useState({
    username: {
      value: '',
      title: t('PHONE_NUMBER'),
      rules: {
        title: t('ENTER_YOUR_PHONE_NUMBER'),
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
        range: {
          min: COGNITO_POLICY.PHONE_NUMBER_MIN_LENGTH,
          max: COGNITO_POLICY.PHONE_NUMBER_MAX_LENGTH,
          message: t('PHONE_NUMBER_ENTERED_INVALID_MESSAGE'),
        },
      },
    },
    countryCode: {
      value: SRILANKAN_COUNTRY_CODE,
      title: t('COUNTRY_CODE'),
      rules: {
        stop: true,
        title: t('COUNTRY_CODE'),
      },
    },
    password: {
      value: '',
      title: t('PASSWORD'),
      rules: {
        stop: true,
        title: t('ENTER_YOUR_PASSWORD'),
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
      },
    },
  });

  useEffect(() => {
    if (signInResponse?.err !== null || signInResponse?.res !== null) {
      handleResponse();
    }
  }, [signInResponse]);

  const handleResponse = () => {
    if (signInResponse?.err) {
      const { err } = signInResponse;
      if (err?.includes(COGNITO.INCORRECT_USERNAME_PASSWORD)) {
        setError(t('PHONE_NUMBER_ENTERED_INCORRECT'));
        return;
      }
      if (err?.includes(COGNITO.PASSWORD_ATTEMPT_EXCEED)) {
        setError(t('WRONG_PASSWORD_ATTEMPT_EXCEEDED'));
        return;
      }
      if (err?.includes(COGNITO.TEMP_PASSWORD_EXPIRED)) {
        setError(t('TEMP_PASSWORD_EXPIRED_CALL_ADMIN'));
        return;
      }
      if (err.startsWith('2 validation errors detected: Value at')) {
        setError('Please check the phone number');
        return;
      }
      setError(err);
      return;
    }
    if (
      signInResponse.res?.['challengeName'] ===
      COGNITO.NEW_PASSWORD_CREATE_REQUEST
    ) {
      setStage('Step2');
    } else {
      logout();
      setTimeout(() => {
        navigate('/auth/login', { replace: true });
      }, 1000);
    }
  };

  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const onBlur = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const handleSignIn = async e => {
    setError(null);
    e.preventDefault();
    const formValidation = Validate.form(form);
    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }
    const { username, password } = form;
    signIn(alterPhoneNumber(username.value), password.value);
  };

  return (
    <div>
      <Form onSubmit={handleSignIn}>
        <div className="mobile-center-view">
          <div className="username-container">
            <Text
              label={form.username.title}
              prefix={SRILANKAN_COUNTRY_CODE}
              groupClassName="form-group"
              labelClassName="form-label"
              id="username"
              field="username"
              placeholder={form.username.rules.title}
              onChange={onChange}
              onBlur={onBlur}
              rightIconGroupClassName="username-input-container"
              validation={form.username}
              maxLength={COGNITO_POLICY.PHONE_NUMBER_MAX_LENGTH}
            />
          </div>
          <Text
            groupClassName="form-group"
            labelClassName="form-label"
            type={isRevealPwd ? 'text' : 'password'}
            label={form.password.title}
            id="password"
            field="password"
            placeholder={form.password.rules.title}
            onChange={onChange}
            onBlur={onBlur}
            validation={form.password}
            rightIconClassName="auth-fa-eye"
            rightIconGroupClassName="auth-text-input-container"
            rightIcon={isRevealPwd ? showPassword : hidePassword}
            rightIconHandle={() => setIsRevealPwd(prevState => !prevState)}
          />

          {error !== null && <small className="error-message">{error}</small>}
        </div>
        <div className="form-footer">
          <Button color="primary" type="submit" className="form-submit">
            {t('SIGN_UP')}
          </Button>
          <small className="help">
            {t('DO_YOU_HAVE_LOGIN_ISSUES_CALL_US_ON')}{' '}
            <a href={`tel:${LOGIN_ISSUE_CONTACT_NUMBER}`}>
              {' '}
              {LOGIN_ISSUE_CONTACT_NUMBER}
            </a>
          </small>
        </div>
      </Form>
    </div>
  );
}

SignUpForm.propTypes = {
  setStage: PropTypes.func,
};

export default SignUpForm;
