import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../../../../../apis/LoadingContext';
import PropTypes from 'prop-types';
import { ManualProcurementContext } from '../../../../../apis/admin';
import { formatDate } from '../../../../../shared/utility/dateFormats';
import {
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../../shared/constants/constants';

function ManualProcurementQualityOrderDispatch({
  adminOrder,
  isMobile,
  deliverySlotId,
  deliveryDate,
  selectedBaseName,
}) {
  const { t } = useTranslation();

  const mpQualityCheckContext = useContext(ManualProcurementContext);
  const { getManualProcOrderReadyForDispatchAdmin } = mpQualityCheckContext;
  const { adminOrderDispatchManuProcList } = mpQualityCheckContext.state;
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;

  useEffect(() => {
    getManualProcOrderReadyForDispatchAdmin(
      `deliverySlotId=${deliverySlotId}&deliveryDate=${formatDate(
        deliveryDate,
        null,
        SL_DATE_FORMAT,
        UK_DATE_FORMAT,
      )}&baseId=${selectedBaseName?.id}`,
    );
  }, []);
  const isProceedSupplierQualityCheck = item => {
    if (item.itemCount > 0) {
      navigate(
        `/admin/manualprocurementqualitycheck/selectedorderOfsupplier/${adminOrder?.manualProcurementAdminId}/${item.purchaseOrderId}/${deliverySlotId}?manualProcurement=true`,
      );
    }
  };
  return (
    <div>
      <h5 className="blue-color" style={{ fontSize: '1.25rem' }}>
        {adminOrder?.manualProcurementAdminName}
      </h5>
      {adminOrderDispatchManuProcList?.res?.length > 0 && (
        <div className="tableFixHead">
          <Table bordered>
            {!isMobile && (
              <thead>
                <tr>
                  <th>{t('TAG_NUMBER')}</th>
                  <th>Buyer company name</th>
                  <th>No. of items ready for dispatch</th>
                </tr>
              </thead>
            )}
            <tbody>
              {adminOrderDispatchManuProcList?.res?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      borderTopLeftRadius: '15px',
                      borderTopRightRadius: '15px',
                    }}
                    className={item.itemCount > 0 ? 'cursor-pointer' : ''}
                    onClick={() => isProceedSupplierQualityCheck(item)}
                  >
                    {!isMobile ? (
                      <>
                        <td>{item?.tagNumber}</td>
                        <td>{item.buyerName}</td>
                        <td>{item.itemCount}</td>
                      </>
                    ) : (
                      <>
                        <td>
                          <small className="d-block p-0 pb-3">
                            {item.buyerName}
                          </small>
                          <small className="d-block p-0">Tag Number</small>
                          <span className="blue-color p-0">
                            {item?.tagNumber}
                          </span>
                        </td>
                        <td
                          style={{
                            display: 'tableCell',
                            verticalAlign: 'bottom',
                          }}
                        >
                          <small className="d-block p-0 pt-3">
                            Ready for dispatch
                          </small>
                          <small className="blue-color d-block p-0">
                            {item.itemCount}
                          </small>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      {!loading && adminOrderDispatchManuProcList?.res?.length === 0 && (
        <div className="py-5 custom-empty-message">
          <p>{t('NO_ORDERS_AVAILABLE')}</p>
        </div>
      )}
    </div>
  );
}

ManualProcurementQualityOrderDispatch.propTypes = {
  isMobile: PropTypes.bool,
  adminOrder: PropTypes.any,
  deliverySlotId: PropTypes.any,
  deliveryDate: PropTypes.any,
  selectedBaseName: PropTypes.any,
};

export default ManualProcurementQualityOrderDispatch;
