import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const ManualProcurementContext = React.createContext();
const ManualProcurementProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [sortedSummaryListRes, setSortedSummaryListRes] = useState({
    res: [],
    err: null,
  });
  const [deliverySlotListRes, setDeliverySlotListRes] = useState({
    res: [],
    err: null,
  });
  const [deliveryBasesListRes, setDeliveryBasesListRes] = useState({
    res: [],
    err: null,
  });
  const [sortingDetailsViewRes, setSortingDetailViewRes] = useState({
    res: [],
    err: null,
  });
  const [orderLoadingListRes, setOrderLoadingListRes] = useState({
    res: [],
    err: null,
  });
  const [
    mpAdminsOrderSummaryForDelivery,
    setMpAdminsOrderSummaryForDelivery,
  ] = useState({
    res: [],
    err: null,
  });

  const [adminDetails, setAdminDetails] = useState({
    res: [],
    err: null,
  });

  const [
    adminOrderDispatchManuProcList,
    setAdminOrderDispatchManuProcList,
  ] = useState({
    res: [],
    err: null,
  });
  const getOrderLoadingList = async query => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(
        url +
          `/vehicle-allocations/manual-proc-order-loading-summary-list?${query}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await summaryList.json();

      if (data.error || data?.message) {
        setOrderLoadingListRes({ err: data?.message, res: null });
      } else {
        setOrderLoadingListRes({
          err: null,
          res: data,
        });
      }
      setLoading(false);
    } catch (err) {
      setOrderLoadingListRes({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getDeliveryBasesList = async query => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(
        url + `/delivery-bases?deliverySlotId=${query}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await summaryList.json();

      if (data.error || data?.message) {
        setDeliveryBasesListRes({ err: data?.message, res: null });
      } else {
        setDeliveryBasesListRes({ err: null, res: data });
      }
      setLoading(false);
    } catch (err) {
      setDeliveryBasesListRes({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getSortedSummaryItems = async query => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(url + `/manual-proc-products?${query}`, {
        method: 'GET',
        headers: headers,
      });
      const data = await summaryList.json();

      if (data.error || data?.message) {
        setSortedSummaryListRes({ err: data?.message, res: null });
      } else {
        setSortedSummaryListRes({
          err: null,
          res: data,
        });
      }
      setLoading(false);
    } catch (err) {
      setSortedSummaryListRes({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getDeliverySlotList = async (
    deliveryDate,
    isOnlyFutureSlots = false,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(
        url +
          `/delivery-slots?deliveryDate=${deliveryDate}&isOnlyFutureSlots=${isOnlyFutureSlots}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await summaryList.json();

      if (data.error || data?.message) {
        setDeliverySlotListRes({ err: data?.message, res: null });
      } else {
        setDeliverySlotListRes({ err: null, res: data });
      }
      setLoading(false);
    } catch (err) {
      setDeliverySlotListRes({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getSortingDetailView = async param => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(
        url +
          `/purchase-orders?manualProcurement=true&deliveryBaseId=${param?.deliveryBaseId}&deliveryDate=${param?.deliveryDate}&deliverySlot=${param?.deliverySlot}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await summaryList.json();
      if (data.error || data?.message) {
        setSortingDetailViewRes({ err: data?.message, res: null });
      } else {
        setSortingDetailViewRes({
          err: null,
          res: data,
          /*[
            {
              orderId: 0,
              purchaseOrderId: 125,
              orderStatus: null,
              tagNo: '48',
              isAffiliatedSupplier: true,
              buyerCompany: 'Buyer1',
              totalPrice: null,
              orderItems: [
                {
                  purchaseOrderProductId: 185,
                  productId: 11,
                  productName: 'Beans',
                  productImage: 'Beans.jpg',
                  unitMeasure: 'Kg',
                  note: null,
                  productStatus: 'Ready',
                  quantity: 2,
                  unitPrice: 543,
                },
                {
                  purchaseOrderProductId: 186,
                  productId: 8,
                  productName: 'Lime',
                  productImage: 'Lime.jpg',
                  unitMeasure: 'Kg',
                  note: null,
                  productStatus: 'Ready',
                  quantity: 1.5,
                  unitPrice: 225,
                },
                {
                  purchaseOrderProductId: 189,
                  productId: 244,
                  productName: 'Angula Karawala',
                  productImage: 'Angula Karawala.jpg',
                  unitMeasure: 'Kg',
                  note: null,
                  productStatus: 'In Progress',
                  quantity: 0.5,
                  unitPrice: 12,
                },
                {
                  purchaseOrderProductId: 184,
                  productId: 3,
                  productName: 'Leeks',
                  productImage: 'Leeks.jpg',
                  unitMeasure: 'Kg',
                  note: 'L23',
                  productStatus: 'Ready',
                  quantity: 3,
                  unitPrice: null,
                },
                {
                  purchaseOrderProductId: 187,
                  productId: 132,
                  productName: 'Chillie Sauce - Edinborough - 350g',
                  productImage: 'Chillie Sauce - Edinborough - 350g.jpg',
                  unitMeasure: 'Unit',
                  note: null,
                  productStatus: 'In Progress',
                  quantity: 1,
                  unitPrice: null,
                },
                {
                  purchaseOrderProductId: 188,
                  productId: 136,
                  productName: 'Light Soy Sauce -Edinborough - 1l',
                  productImage: 'Light Soy Sauce -Edinborough - 1l.jpg',
                  unitMeasure: 'Unit',
                  note: null,
                  productStatus: 'In Progress',
                  quantity: 1,
                  unitPrice: null,
                },
                {
                  purchaseOrderProductId: 183,
                  productId: 1,
                  productName: 'Carrot',
                  productImage: 'Carrot.jpg',
                  unitMeasure: 'Kg',
                  note: 'S123',
                  productStatus: 'In Progress',
                  quantity: 2,
                  unitPrice: null,
                },
              ],
            },
            {
              orderId: 0,
              purchaseOrderId: 125,
              orderStatus: null,
              tagNo: '49',
              isAffiliatedSupplier: true,
              buyerCompany: 'Buyer2',
              totalPrice: null,
              orderItems: [
                {
                  purchaseOrderProductId: 185,
                  productId: 11,
                  productName: 'Beans',
                  productImage: 'Beans.jpg',
                  unitMeasure: 'Kg',
                  note: null,
                  productStatus: 'Ready',
                  quantity: 2,
                  unitPrice: 543,
                },
                {
                  purchaseOrderProductId: 186,
                  productId: 8,
                  productName: 'Lime',
                  productImage: 'Lime.jpg',
                  unitMeasure: 'Kg',
                  note: null,
                  productStatus: 'Ready',
                  quantity: 1.5,
                  unitPrice: 225,
                },
                {
                  purchaseOrderProductId: 189,
                  productId: 244,
                  productName: 'Angula Karawala',
                  productImage: 'Angula Karawala.jpg',
                  unitMeasure: 'Kg',
                  note: null,
                  productStatus: 'Ready',
                  quantity: 0.5,
                  unitPrice: 12,
                },
                {
                  purchaseOrderProductId: 184,
                  productId: 3,
                  productName: 'Leeks',
                  productImage: 'Leeks.jpg',
                  unitMeasure: 'Kg',
                  note: 'L23',
                  productStatus: 'Ready',
                  quantity: 3,
                  unitPrice: null,
                },
              ],
            },
          ],
          */
        });
      }
      setLoading(false);
    } catch (err) {
      setSortingDetailViewRes({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getAdminDetails = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.getTokenBaseUrl('admins');
      const summaryList = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const data = await summaryList.json();

      if (data.error || data?.message) {
        setAdminDetails({ err: data?.message, res: null });
      } else {
        setAdminDetails({ err: null, res: data });
      }
      setLoading(false);
    } catch (err) {
      setAdminDetails({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const readyForDispatchOrderDetail = async (update, id) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      setLoading(false);
      const res = await fetch(url + `/purchase-orders/${id}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(update),
      });
      return await res.json();
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const sendForOrderLoading = async (packageIds, packageStatus) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(url + '/vehicles/package-status', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          packageId: packageIds,
          packageStatus: packageStatus,
        }),
      });
      const res = await summaryList.json();
      setLoading(false);
      return res;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const getManualProcAdminsOrderSummaryHandler = async (queryParam, baseId) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(
        url +
          `/delivery-bases/${baseId}/manual-procurement-admins-order-summary?${queryParam}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const res = await summaryList.json();
      if (res?.error || res?.message) {
        setMpAdminsOrderSummaryForDelivery({
          err: res?.message,
          res: null,
        });
      } else {
        setMpAdminsOrderSummaryForDelivery({
          err: null,
          res: res,
        });
      }
      setLoading(false);
    } catch (err) {
      setMpAdminsOrderSummaryForDelivery({
        err: err,
        res: null,
      });
      setLoading(false);
    }
  };

  const getManualProcOrderReadyForDispatchAdmin = async param => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const summaryList = await fetch(
        url + `/manual-proc-products/dispatch-summary?${param}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const res = await summaryList.json();
      if (res?.error || res?.message) {
        setAdminOrderDispatchManuProcList({
          err: res?.message,
          res: null,
        });
      } else {
        setAdminOrderDispatchManuProcList({
          err: null,
          res: res,
        });
      }
      setLoading(false);
    } catch (err) {
      setAdminOrderDispatchManuProcList({
        err: err,
        res: null,
      });
      setLoading(false);
    }
  };

  return (
    <ManualProcurementContext.Provider
      value={{
        state: {
          sortedSummaryListRes: sortedSummaryListRes,
          deliverySlotListRes: deliverySlotListRes,
          deliveryBasesListRes: deliveryBasesListRes,
          sortingDetailsViewRes: sortingDetailsViewRes,
          adminDetails: adminDetails,
          orderLoadingListRes: orderLoadingListRes,
          mpAdminsOrderSummaryForDelivery: mpAdminsOrderSummaryForDelivery,
          adminOrderDispatchManuProcList: adminOrderDispatchManuProcList,
        },
        setSortingDetailViewRes: setSortingDetailViewRes,
        getSortedSummaryItems: getSortedSummaryItems,
        getDeliverySlotList: getDeliverySlotList,
        getDeliveryBasesList: getDeliveryBasesList,
        getSortingDetailView: getSortingDetailView,
        getAdminDetails: getAdminDetails,
        getOrderLoadingList: getOrderLoadingList,
        readyForDispatchOrderDetail: readyForDispatchOrderDetail,
        sendForOrderLoading: sendForOrderLoading,
        getManualProcAdminsOrderSummaryHandler: getManualProcAdminsOrderSummaryHandler,
        getManualProcOrderReadyForDispatchAdmin: getManualProcOrderReadyForDispatchAdmin,
      }}
    >
      {props.children}
    </ManualProcurementContext.Provider>
  );
};
ManualProcurementProvider.propTypes = {
  children: PropTypes.any,
};

export { ManualProcurementProvider, ManualProcurementContext };
