import React, { useEffect, useState, useContext } from 'react';
import InnerBody from '../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import { Form, Button, Row, Col } from 'reactstrap';
import { Validate } from '../../../config';
import { Text } from '../../../../shared/components';
import Scanner from './Scanner';
import { CustomModal } from '../../../../shared/components';
import { ValidateQrCodeContext } from '../../../../apis/admin';
import { useDeviceDetect } from '../../../../shared/hooks';
function AdminValidateQr() {
  const { t } = useTranslation();
  const validateContext = useContext(ValidateQrCodeContext);
  const { validateQrCodePackages, transferQrCodePackages } = validateContext;
  const { isMobile } = useDeviceDetect();
  const [isStartScan, setIsStartScan] = useState(false);
  const [currentQrCode, setCurrentQrCode] = useState(null);
  const [scannedQrCode, setScannedQrCode] = useState(null);
  const [transferRes, setTransferRes] = useState({ title: '', desc: '' });
  const modalToggle = () => {
    setIsStartScan(false);
  };
  const [transferPopupOpen, setTransferPopupOpen] = useState(false);
  const modalToggleTransferPopup = () => {
    setTransferPopupOpen(false);
  };
  const [form, setForm] = useState({
    qrCodeNo: {
      value: '',
      title: 'Enter the QR code no',
      rules: {
        title: '',
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
      },
    },
  });

  const [newQrform, setNewQrForm] = useState({
    newQrCodeNo: {
      value: '',
      title: 'New QR Code',
      rules: {
        title: '',
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
      },
    },
  });

  const setNewQrCode = value => {
    setScannedQrCode(value);
    const updatedForm = Validate.input('newQrCodeNo', value, newQrform, false);
    setNewQrForm(updatedForm);
  };

  const onChange = e => {
    setCurrentQrCode(null);
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
    setNewQrCode('');
  };

  const searchQrcode = e => {
    setCurrentQrCode(null);
    setNewQrCode('');
    e.preventDefault();
    const formValidation = Validate.form(form);
    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }
    validateQrCodePackages(form.qrCodeNo.value)
      .then(data => {
        if (data?.message || data?.error) {
          setCurrentQrCode({ err: data?.message, res: null });
          return;
        }
        setCurrentQrCode({
          res: data,
          err: null,
        });
      })
      .catch(err => {
        setCurrentQrCode({
          res: null,
          err: err,
        });
      });
  };
  const onBlur = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };
  const transferQrCode = e => {
    e.preventDefault();
    const req = [
      {
        op: 'replace',
        path: 'QRCodeId',
        value: newQrform.newQrCodeNo?.value,
      },
    ];
    transferQrCodePackages(req, currentQrCode?.res?.packageId)
      .then(res => {
        if (res?.description || res?.error) {
          setTransferRes({ title: 'Transfer Failure', desc: res?.description });
          setTransferPopupOpen(true);
          return;
        }
        setTransferRes({
          title: 'Successful',
          desc: 'You have successfully transferred the QR code details',
        });
        setNewQrCode('');
        let tempForm = form;
        tempForm.qrCodeNo.value = '';
        setForm(tempForm);
        setCurrentQrCode(null);
        setTransferPopupOpen(true);
      })
      .catch(err => {
        setTransferRes({ title: 'Transfer Failure', desc: err });
        setTransferPopupOpen(true);
      });
  };

  const onHandleScan = qrValue => {
    setNewQrCode(qrValue);
  };

  useEffect(() => {}, [isMobile]);
  return (
    <InnerBody topic={'Unrecognized QR Codes'} isDisable={true}>
      <div className="qr-code-validation-container">
        {currentQrCode?.err !== null && (
          <p className="warning">{currentQrCode?.err}</p>
        )}
        {currentQrCode?.err === null && currentQrCode?.res === null && (
          <p className="warning">Qr code not found</p>
        )}

        <Form className="enter-qrcode-from-container" onSubmit={searchQrcode}>
          <Text
            label={form.qrCodeNo.title}
            groupClassName="form-group"
            rightIconGroupClassName="input-group-qr-val"
            labelClassName="form-label mb-0"
            autoComplete="off"
            showErrors={false}
            validation={form.qrCodeNo}
            id="qrCodeNo"
            field="qrCodeNo"
            onChange={onChange}
            onBlur={onBlur}
          />
          <Button color="primary" type="submit" className="form-submit">
            Search
          </Button>
        </Form>
        {currentQrCode?.res && (
          <>
            <p>The order details related to {form.qrCodeNo.value}</p>
            <ul>
              <li>
                Supplier code :
                {currentQrCode?.res?.supplierCompany?.supplierCode}
              </li>
              <li>Tag No : {currentQrCode?.res?.purchaseOrder?.tagNumber}</li>
              {currentQrCode?.res?.isPrimaryPackage ? (
                <>
                  <li>
                    Primary pack type : {currentQrCode?.res?.packageType?.name}
                  </li>
                  <li>No of items : {currentQrCode?.res?.numberOfItems}</li>
                </>
              ) : (
                <li>Item No : {currentQrCode?.res?.itemNo}</li>
              )}
            </ul>

            <b>Transfer the details</b>
            <Form
              className="enter-qrcode-from-container"
              onSubmit={transferQrCode}
            >
              <div className="container-fluid p-0">
                <Row className="row">
                  <Col
                    xs="12"
                    md="4"
                    style={isMobile ? {} : { paddingLeft: '0px' }}
                  >
                    <Text
                      label={'To'}
                      groupClassName="form-group"
                      // rightIconGroupClassName="input-group-qr-val"
                      labelClassName="form-label"
                      onChange={setNewQrCode}
                      placeholder={newQrform.newQrCodeNo.title}
                      showErrors={false}
                      validation={newQrform.newQrCodeNo}
                      id="newQrCodeNo"
                      name="newQrCodeNo"
                      field="newQrCodeNo"
                      disabled={true}
                      rightIconGroupClassName="row"
                    />
                  </Col>
                  <Col
                    xs="12"
                    md="4"
                    style={isMobile ? {} : { paddingRight: '0px' }}
                  >
                    <Button
                      style={{ height: '38px' }}
                      className="btn-outline-primary row"
                      onClick={() => setIsStartScan(!isStartScan)}
                    >
                      Scan New QR Code
                    </Button>
                  </Col>
                  <Col xs="12" md="4">
                    {scannedQrCode && (
                      <Button
                        style={isMobile ? { width: '77%' } : {}}
                        color="primary"
                        type="submit"
                        className="row"
                      >
                        Transfer
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Form>
          </>
        )}
        <CustomModal
          isOpen={isStartScan}
          toggle={modalToggle}
          isScrollable={true}
          title="QR Scanner"
          modalstyleClassName="modal-style"
          modalContentContainer="modal-container"
          modalHeaderClassName="modal-header"
          modalheaderTopic="modal-header-topic"
          modalBodyClassName="small"
        >
          <Scanner
            setStartScan={setIsStartScan}
            onHandleScan={onHandleScan}
            isSuccessSound={true}
          />
        </CustomModal>

        <CustomModal
          isOpen={transferPopupOpen}
          toggle={modalToggleTransferPopup}
          showClose={false}
          isScrollable={true}
          title={transferRes?.title}
          modalstyleClassName="modal-style"
          modalContentContainer="modal-container"
          modalHeaderClassName="modal-header"
          modalheaderTopic="modal-header-topic"
          modalBodyClassName="small"
        >
          <>
            <p>{transferRes?.desc}</p>
            <Button
              className="my-3 btn-primary"
              id="modal-footer-btn-save"
              onClick={() => {
                setTransferRes({
                  title: '',
                  desc: '',
                });
                setTransferPopupOpen(false);
              }}
            >
              {t('OK')}
            </Button>
          </>
        </CustomModal>
      </div>
    </InnerBody>
  );
}
export default AdminValidateQr;
