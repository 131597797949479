import React from 'react';
// import { useEffect } from 'react';
// import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Row } from 'reactstrap';
import { CheckBox, CustomModal } from '../../../../../shared/components';
import { REVIEW_VALUES } from './constant';
import { useTranslation } from 'react-i18next';

function QualityCheckReviewModal({
  order,
  setPurchaseOrderDetails,
  purchaseOrderDetails,
  setIsModalOpen,
  reviewDetails,
  setReviewDetails,
}) {
  const { t } = useTranslation();

  const onChangeCheckBox = e => {
    const { name, checked } = e.target;
    setReviewDetails(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const onChangeInput = e => {
    if (
      (e.target.name === 'amount' && !isNaN(e.target.value)) ||
      e.target.name !== 'amount'
    ) {
      const { name, value } = e.target;
      setReviewDetails(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onHandleSave = () => {
    let temPurchaseOrderDetails = purchaseOrderDetails;
    let tempRejectedReason = [];
    if (reviewDetails.quality)
      tempRejectedReason.push(REVIEW_VALUES.QUALITY.id);
    if (reviewDetails.quantity)
      tempRejectedReason.push(REVIEW_VALUES.QUANTITY.id);
    if (reviewDetails.tagging)
      tempRejectedReason.push(REVIEW_VALUES.TAGGING.id);

    let purchaseOrderProducts = temPurchaseOrderDetails.purchaseOrderProducts.filter(
      item => item.purchaseOrderProductId === order.purchaseOrderProductId,
    )[0];
    (purchaseOrderProducts.rejectedReason = tempRejectedReason),
      (purchaseOrderProducts.amountDeducted = reviewDetails.amount);
    setPurchaseOrderDetails(temPurchaseOrderDetails);
    setIsModalOpen(false);
    setReviewDetails({
      quality: null,
      quantity: null,
      tagging: null,
      amount: 0,
    });
  };

  return (
    <div className="quality-check-review-modal">
      <CustomModal
        isOpen={true}
        showClose={true}
        toggle={() => setIsModalOpen(false)}
        hideCancel={false}
        title={t('QUALITY_CHECK_REVIEW')}
        modalstyleClassName="modal-style quality-check-review-modal"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
      >
        <Row>
          <p className="issue-types-text">{t('ISSUE_TYPES')}</p>
        </Row>
        <Row className="mb-3">
          <CheckBox
            name="quality"
            labelClassName="checkbox-label"
            label={REVIEW_VALUES.QUALITY.value}
            groupClassName="check-box"
            id="select-unselect-All"
            field="selectUnselectAll"
            checked={reviewDetails?.quality}
            onChange={e => onChangeCheckBox(e)}
          />
        </Row>
        <Row className="mb-3">
          <CheckBox
            name="quantity"
            labelClassName="checkbox-label"
            label={REVIEW_VALUES.QUANTITY.value}
            groupClassName="check-box"
            id="select-unselect-All"
            field="selectUnselectAll"
            checked={reviewDetails?.quantity}
            onChange={e => onChangeCheckBox(e)}
          />
        </Row>
        <Row>
          <CheckBox
            name="tagging"
            labelClassName="checkbox-label"
            label={REVIEW_VALUES.TAGGING.value}
            groupClassName="check-box"
            id="select-unselect-All"
            field="selectUnselectAll"
            checked={reviewDetails?.tagging}
            onChange={e => onChangeCheckBox(e)}
          />
        </Row>
        <Row>
          <p className="amount-to-be-deducted-text">
            {t('AMOUNT_TO_BE_DEDUCTED')}
          </p>
          <Input
            name="amount"
            value={reviewDetails?.amount}
            className="amount-to-be-deducted-input"
            placeholder="Amount to be deducted"
            onChange={e => onChangeInput(e)}
          />
        </Row>
        {/* <Row> */}
        <Button
          color="primary"
          type="submit"
          className="form-submit"
          onClick={() => onHandleSave()}
        >
          {t('CONFIRM')}
        </Button>
        {/* </Row>/\ */}
      </CustomModal>
    </div>
  );
}
QualityCheckReviewModal.propTypes = {
  order: PropTypes.object,
  setPurchaseOrderDetails: PropTypes.func,
  purchaseOrderDetails: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  reviewDetails: PropTypes.object,
  setReviewDetails: PropTypes.func,
};
export default QualityCheckReviewModal;
