import React, { useState } from 'react';
import UserProfile from '../../../../content/UserProfile';
import InnerBody from '../../../../content/InnerBody';
import { useEffect } from 'react';
import { AdminContext } from '../../../../apis/admin';
import { useContext } from 'react';

function AdminProfile() {
  const adminContext = useContext(AdminContext);
  const { handleAdminDetails } = adminContext;
  const [adminData, setAdminData] = useState(null);

  useEffect(() => {
    handleAdminDetails().then(res => {
      if (res.err) {
        return;
      } else {
        setAdminData(res.res);
      }
    });
  }, []);

  return (
    <InnerBody topic="User Profile" isDisable={true}>
      <UserProfile
        role={adminData && adminData.role + ' Role'}
        firstName={adminData && adminData.firstName}
        lastName={adminData && adminData.lastName}
        contactNumber={adminData && adminData.contactNumber}
        email={adminData && adminData.email}
      />
    </InnerBody>
  );
}

export default AdminProfile;
