import approve from 'approvejs';
import moment from 'moment';

var compare = {
  expects: ['comparison', 'field'],
  message: '{title} must be the same as {field}.',
  validate: function(value, pars) {
    const { comparison } = pars;
    if (typeof comparison === 'function') {
      return value === comparison();
    }
    return value === comparison;
  },
};

const compareStartDate = {
  expects: ['comparison', 'field'],
  message: '{title} must be less than or equal to the {field}.',
  validate: function(value, pars) {
    const { comparison } = pars;
    const fromSec = moment(value, 'DD/MM/YYYY').unix();
    const toSec = moment(comparison(), 'DD/MM/YYYY').unix();

    if (typeof comparison === 'function') {
      return toSec >= fromSec;
    }
    return toSec >= fromSec;
  },
};

const phone = {
  expects: [],
  message: '{title} must be a valid phone number.',
  validate: function(value) {
    let reg = new RegExp(/^[0+](?!(.*\d){16})([0-9\s]{0,}[0-9]+)$/);
    return reg.test(value);
  },
};

const postcode = {
  expects: [],
  message: 'Please enter your postcode in a valid UK postcode format.',
  validate: function(value) {
    let reg = new RegExp(
      /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/,
    );
    return reg.test(value);
  },
};

const sortcode = {
  expects: [],
  message: '',
  validate: function(value) {
    let reg = new RegExp(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/);
    return reg.test(value);
  },
};

const country = {
  expects: [],
  message: '',
  validate: function(value) {
    let reg = new RegExp(/^[A-ZÀ-ÿa-z ]*$/);
    return reg.test(value);
  },
};

const creditcard = {
  expects: [],
  message: '',
  validate: function(value) {
    let reg = new RegExp(
      /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
    );
    return reg.test(value);
  },
};

const debitcard = {
  expects: [],
  message: '',
  validate: function(value) {
    let reg = new RegExp(
      /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
    );
    return reg.test(value);
  },
};

const accno = {
  expects: [],
  message: '',
  validate: function(value) {
    let reg = new RegExp(/^[0-9]{8}$/);
    return reg.test(value);
  },
};

const cardExpiryDate = {
  expects: [],
  message: '',
  validate: function(value) {
    let reg = new RegExp(/^(0[1-9]|10|11|12)\/(1|2|3)[0-9]$/);
    return reg.test(value);
  },
};

const securityCode = {
  expects: [],
  message: '',
  validate: function(value) {
    let reg = new RegExp(/^[0-9]{3}$/);
    return reg.test(value);
  },
};

const expiryDate = {
  expected: [],
  message: 'must be in the future',
  validate: function(value) {
    const month = value.substr(0, 2) - 0;
    const year = value.substr(3, 2) - 0;
    const now = new Date();
    const current = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const century = Math.floor(current.getFullYear() / 100);
    const date = new Date(century * 100 + year, month, 0);
    return date >= current;
  },
};

const compareEndDate = {
  expects: ['comparison', 'field'],
  message: '{title} must be greater than or equal to the {field}.',
  validate: function(value, pars) {
    const { comparison } = pars;
    const fromSec = moment(comparison(), 'DD/MM/YYYY').unix();
    const toSec = moment(value, 'DD/MM/YYYY').unix();

    if (typeof comparison === 'function') {
      return toSec >= fromSec;
    }
    return toSec >= fromSec;
  },
};

approve.addTest(expiryDate, 'expiryDate');
approve.addTest(compareStartDate, 'compareStartDate');
approve.addTest(compareEndDate, 'compareEndDate');
approve.addTest(compare, 'compare');
approve.addTest(phone, 'phone');
approve.addTest(postcode, 'postcode');
approve.addTest(sortcode, 'sortcode');
approve.addTest(creditcard, 'creditcard');
approve.addTest(debitcard, 'debitcard');
approve.addTest(accno, 'accno');
approve.addTest(country, 'country');
approve.addTest(securityCode, 'securityCode');
approve.addTest(cardExpiryDate, 'cardExpiryDate');
