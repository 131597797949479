import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'reactstrap';
import { AdminPaymentContext } from '../../../../apis/admin';
import InnerBody from '../../../../content/InnerBody';
import { useDeviceDetect } from '../../../../shared/hooks';
import moment from 'moment';
import AdminSupplierPaymentInvoice from './AdminSupplierPaymentInvoice';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function AdminSupplierPaymentManagement() {
  const { t } = useTranslation();
  const adminPaymentContext = useContext(AdminPaymentContext);
  const { getSupplierPayments, getSuppliers } = adminPaymentContext;
  const { supplierPaymentsResponse } = adminPaymentContext.state;
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useDeviceDetect();
  const [stage, setStage] = useState('step1');

  const backHandle = () => {
    if (stage === 'step2') {
      setStage('step1');
      getSupplierPayments()
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    getSupplierPayments()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {}, [isMobile]);
  useEffect(() => {
    if (stage === 'step2') {
      getSuppliers();
    }
  }, [stage]);

  return (
    <InnerBody
      topic={stage === 'step2' ? 'Supplier Payables' : 'Payment History'}
      isDisable={stage === 'step1'}
      onHandleBack={backHandle}
      className="adminSupplierPayments"
    >
      {supplierPaymentsResponse?.err !== null && (
        <p className="warning">{supplierPaymentsResponse?.err}</p>
      )}
      <div className="admin-payment-management-container">
        {stage === 'step2' ? (
          <AdminSupplierPaymentInvoice
            key="admin-supplier-payment-invoice"
            isMobile={isMobile}
          />
        ) : (
          <div>
            <div className="tableFixHead admin-supplier-pay-mangement">
              {supplierPaymentsResponse?.res?.length > 0 && (
                <Table>
                  <thead>
                    {!isMobile && (
                      <tr>
                        <th className="text-center">{t('DATE')}</th>
                        <th>{t('PAYMENT_TYPE')}</th>
                        <th>{t('SUPPLIER_ID')}</th>
                        <th className="text-right">{t('AMOUNT')}</th>
                        <th>Reference No</th>
                        <th>Status</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {supplierPaymentsResponse?.res?.map(item => {
                      return !isMobile ? (
                        <>
                          <tr>
                            <td className="text-right">
                              {moment(item?.createdDateTime).format(
                                'DD-MM-YYYY',
                              )}
                            </td>
                            <td>{item?.paymentType}</td>
                            <td>{item?.supplierId}</td>
                            <td className="blue-color text-right">
                              {commafy(RoundNum(item?.amount, 2))}
                            </td>
                            <td>{item?.supplierIdentifier}</td>
                            <td>{item?.confirmation}</td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <td>
                              <div className="pt-2 d-flex flex-row justify-content-between">
                                <small
                                  style={{ width: '25%' }}
                                  className="d-block p-0"
                                >
                                  {moment(item?.createdDateTime).format(
                                    'DD/MM/YYYY',
                                  )}
                                </small>
                                <div className="px-2" style={{ width: '55%' }}>
                                  <small className="d-block p-0">
                                    {' '}
                                    {item?.paymentType}
                                  </small>
                                </div>
                                <div
                                  style={{ width: '20%', paddingLeft: '15px' }}
                                >
                                  <small className="d-block p-0">
                                    {item?.confirmation}
                                  </small>
                                </div>
                              </div>
                              <div className="pt-2 d-flex flex-row justify-content-between">
                                <small
                                  style={{ width: '25%' }}
                                  className="d-block p-0"
                                >
                                  {' '}
                                  {item?.supplierId}
                                </small>
                                <div className="px-2" style={{ width: '55%' }}>
                                  <small className="d-block">Ref No</small>
                                  <small className="d-block ">
                                    {item?.supplierIdentifier}
                                  </small>
                                </div>
                                <div
                                  style={{ width: '20%', paddingLeft: '15px' }}
                                >
                                  <small className="d-block">Amt</small>
                                  <small className="d-block blue-color">
                                    {commafy(RoundNum(item?.amount, 2))}
                                  </small>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              )}
              {supplierPaymentsResponse?.res?.length === 0 &&
                supplierPaymentsResponse?.err === null &&
                !isLoading && (
                  <div className="py-5 custom-empty-message">
                    <p>{t('ITEMS_ARE_UNAVAILABLE')}</p>
                  </div>
                )}
            </div>
            <div
              style={
                isMobile
                  ? { marginTop: '20px', textAlign: 'center' }
                  : { textAlign: 'right' }
              }
            >
              <Button
                style={isMobile ? { width: '100%' } : {}}
                color="primary"
                onClick={() => {
                  setStage('step2');
                }}
              >
                Initiate Payment
              </Button>
            </div>
          </div>
        )}
      </div>
    </InnerBody>
  );
}
export default AdminSupplierPaymentManagement;
