import React, { useState } from 'react';
import UserProfile from '../../../../content/UserProfile';
import InnerBody from '../../../../content/InnerBody';
import { useEffect } from 'react';
import { BuyerContext } from '../../../../apis/buyer';
import { useContext } from 'react';

function BuyerProfile() {
  const buyerContext = useContext(BuyerContext);
  const { handleBuyerDetails } = buyerContext;
  const [buyerData, setBuyerData] = useState(null);

  useEffect(() => {
    handleBuyerDetails().then(res => {
      if (res.err) {
        return;
      } else setBuyerData(res.res);
    });
  }, []);

  return (
    <InnerBody topic="User Profile" isDisable={true}>
      <UserProfile
        topic={buyerData && buyerData.buyerCompany.name}
        brn={buyerData && buyerData.buyerCompany.brn}
        firstName={buyerData && buyerData.firstName}
        lastName={buyerData && buyerData.lastName}
        contactNumber={buyerData && buyerData.contactNumber}
        email={buyerData && buyerData.email}
        address={buyerData && buyerData.buyerCompany.address}
      />
    </InnerBody>
  );
}

export default BuyerProfile;
