import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../apis/authentication';

const IsPermitted = props => {
  const authContext = useContext(AuthContext);
  const [permitted, setPermitted] = useState(false);
  const { permissions } = authContext.state;
  const { componentName, level = '1' } = props;

  useEffect(() => {
    if (permissions && permissions.permission) {
      switch (level) {
        case '1':
          {
            const keys = Object.keys(permissions.permission);
            if (keys.length > 0 && keys.includes(componentName)) {
              setPermitted(true);
            }
          }
          break;
        default:
      }
      if (componentName === 'userGuides') {
        setPermitted(true);
      }
    }
  }, [permissions, authContext, componentName]);

  return <>{permitted ? props.children : ''}</>;
};

IsPermitted.propTypes = {
  children: PropTypes.any,
  componentName: PropTypes.any,
  onStateChange: PropTypes.any,
  subComponent: PropTypes.any,
  level: PropTypes.any,
};

export default IsPermitted;
