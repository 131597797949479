import React, { useEffect } from 'react';
import './App.scss';
import { Content } from './content';
import { SessionProvider } from './apis/authentication';
import { LoadingProvider } from './apis/LoadingContext';
import './app/assets/locale/i18n';
import { messaging } from './firebase-config';
import { onMessage } from 'firebase/messaging';
import { Logger } from 'aws-amplify';
import { BuyerProvider } from './apis/buyer';
export const logger = new Logger('Sillara-Logger');
function App() {
  const onMessageListener = payload => {
    if (Notification.permission !== 'denied') {
      Notification.requestPermission();
    }

    if (Notification.permission === 'granted') {
      addToast(true, {
        title: payload.notification.title || payload.data.title,
        body: payload.notification.body || payload.data.body,
      });
      setTimeout(() => {
        addToast(false, '');
      }, 5000);
    }
  };
  onMessage(messaging, payload => {
    onMessageListener(payload);
  });

  const addToast = (show, message) => {
    const root = document.getElementById('root');
    const el = `<div class="toast bg-success fade ${
      show ? 'show' : ''
    }" role="alert">
        <div class='title' toast-body">
          ${message.title}
        </div>
        <b class='body' toast-body">
        ${message.body}
      </div>
      </div>`;
    const divToast = document.getElementById('notification-toast');
    divToast.innerHTML = el;
    if (root) {
      root.appendChild(divToast);
    }
  };

  useEffect(() => {}, [messaging]);
  return (
    <LoadingProvider>
      <BuyerProvider>
        <SessionProvider>
          <Content />
        </SessionProvider>
      </BuyerProvider>
    </LoadingProvider>
  );
}

export default App;
