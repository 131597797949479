import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

function UpdateConfirmationModal({
  close,
  onConfirm,
  subTitle,
  itemAvailability,
  cancelHandle,
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <p
        style={{
          margin: '10px 0',
        }}
      >
        {subTitle}
      </p>
      {!itemAvailability && (
        <div className="d-flex flex-row justify-content-center">
          <Button
            color="primary"
            type="submit"
            className="form-submit"
            onClick={() => {
              onConfirm();
              close();
            }}
          >
            {t('CONFIRM_TEXT')}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            color="primary"
            type="submit"
            className="btn-second"
            onClick={cancelHandle}
          >
            {t('CANCEL')}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}

UpdateConfirmationModal.propTypes = {
  onConfirm: PropTypes.func,
  close: PropTypes.func,
  subTitle: PropTypes.string,
  itemAvailability: PropTypes.bool,
  cancelHandle: PropTypes.func,
};
export default UpdateConfirmationModal;
