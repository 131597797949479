import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CustomModal } from '../../shared/components';
import { AuthContext } from '../../apis/authentication';
import {
  GENERAL_BUTTONS,
  UNAUTHENTICATED,
} from '../../shared/constants/messages';

export default function Unauthenticated() {
  const authContext = useContext(AuthContext);
  const { logOut } = authContext;

  const [buttonLabel, setButtonLabel] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [authentication, setAuthentication] = useState(false);

  useEffect(() => {
    toggle();
    setButtonLabel(GENERAL_BUTTONS.YES);
    setModalTitle(UNAUTHENTICATED.UNAUTHENTICATED);
  }, []);

  const toggle = () => {
    setAuthentication(!authentication);
  };

  const onAction = () => {
    logOut();
    setAuthentication(!authentication);
  };

  return (
    <div>
      <CustomModal
        negativeBtnTitle="na"
        positiveBtnTitle={buttonLabel}
        title={modalTitle}
        isConfirmation={true}
        isOpen={authentication}
        action={onAction}
        toggle={toggle}
        hideCancel={true}
        enableFooter={true}
        size="m"
      >
        <label className="ml-3">Redirect to Login</label>
      </CustomModal>
    </div>
  );
}

Unauthenticated.propTypes = {
  statusError: PropTypes.any,
};
