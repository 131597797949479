import React, { useState, useEffect, useContext } from 'react';
import InnerBody from '../../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import {
  BuyerManagementContext,
  AdminContext,
} from '../../../../../apis/admin';
import { CustomModal } from '../../../../../shared/components';
import { useDeviceDetect } from '../../../../../shared/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingContext } from '../../../../../apis/LoadingContext';

import { formatDate } from '../../../../../shared/utility/dateFormats';
import { UK_DATE_FORMAT } from '../../../../../shared/constants/constants';

function SelectedSupplierOrders() {
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  const { isMobile } = useDeviceDetect();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [showInProgressModal, setShowInProgressModal] = useState(false);
  const {
    supplierCompanyId,
    deliverySlotId,
    supplierCompanyName,
    selectedBase,
    deliverySlot,
    selectedDate,
  } = useParams();
  const buyerManagementContext = useContext(BuyerManagementContext);
  const adminContext = useContext(AdminContext);
  const {
    getOrdersBySupplier,
    updatePurchaseOrderAdmin,
  } = buyerManagementContext;
  const { handleAdminDetails } = adminContext;
  const [state, setState] = useState({
    selectedDate: null,
    deliverySlot: null,
    base: null,
  });

  useEffect(() => {
    setState({
      selectedDate: selectedDate,
      deliverySlot: deliverySlot,
      base: selectedBase,
    });
    getOrdersBySupplier(
      supplierCompanyId,
      deliverySlotId,
      formatDate(selectedDate, null, null, UK_DATE_FORMAT),
    ).then(res => {
      setOrders(res);
    });
    handleAdminDetails().then(res => {
      setUserDetails(res.res);
    });
  }, []);

  const checkOrder = item => {
    getOrdersBySupplier(
      supplierCompanyId,
      deliverySlotId,
      formatDate(selectedDate, null, null, UK_DATE_FORMAT),
    ).then(res => {
      const tempOrder = res.filter(x => x.orderId == item.orderId)[0];
      if (tempOrder) {
        if (
          userDetails &&
          (tempOrder.supplierQualityCheckAdminId == null ||
            tempOrder.supplierQualityCheckAdminId == 0 ||
            tempOrder.supplierQualityCheckAdminId == userDetails.id)
        ) {
          updatePurchaseOrderAdmin(
            userDetails.id,
            tempOrder.supplierCompanyId,
            tempOrder.purchaseOrderId,
          ).then(() => {
            navigate(
              `/admin/supplierqualitycheck/selectedorderOfsupplier/${item.supplierCompanyId}/${item.purchaseOrderId}/${deliverySlotId}`,
            );
          });
        } else {
          setShowInProgressModal(true);
          setOrders(res);
        }
      }
    });
  };
  const handleGoBack = () => {
    history.pushState(state, null, navigate('/admin/supplierqualitycheck/'));
  };

  return (
    <InnerBody
      topic={t('QUALITY_CONTROL_SUPPLIER_SUMMARY')}
      className="supplierQualityCheck"
      onHandleBack={handleGoBack}
    >
      <div className="selected-supplier-orders-container">
        <p className="shop-name p-0">{supplierCompanyName}</p>
        {orders && (
          <>
            {orders.length > 0 ? (
              <div className="tableFixHead">
                <Table bordered>
                  {!isMobile && (
                    <thead>
                      <tr>
                        <th>{t('TAG_NUMBER')}</th>
                        <th>{t('BUYER_NAME')}</th>
                        <th>{t('ITEM_COUNT')}</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {orders?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className="cursor-pointer"
                          onClick={() => checkOrder(item)}
                        >
                          {!isMobile ? (
                            <>
                              <td>{item.tagNumber}</td>
                              <td>{item.buyerCompanyName}</td>
                              <td>{item.readyForDispatchProductsCount}</td>
                            </>
                          ) : (
                            <td>
                              <div className="mb-1">
                                {item.buyerCompanyName}
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <div className="small">Tag Number</div>
                                  {item.tagNumber}
                                </div>
                                <div>
                                  <div className="small">
                                    Ready for dispatch
                                  </div>
                                  {item.readyForDispatchProductsCount}
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              !loading && (
                <div className="py-5 custom-empty-message">
                  <p>{t('NO_ORDERS_AVAILABLE')}</p>
                </div>
              )
            )}
          </>
        )}
      </div>
      <CustomModal
        isOpen={showInProgressModal}
        toggle={() => setShowInProgressModal(false)}
        hideCancel={false}
        isScrollable={true}
        enableFooter={false}
        title={'This order is in progress'}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        <>
          <p className="my-4">{t('QUALITY_CONTROL_IN_PROGRESS_PENDING')}.</p>
          <Button
            className="form-submit w-40"
            type="submit"
            color="primary"
            onClick={() => setShowInProgressModal(false)}
          >
            {t('OK')}
          </Button>
        </>
      </CustomModal>
    </InnerBody>
  );
}

export default SelectedSupplierOrders;
