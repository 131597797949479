import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const RadioGroup = props => {
  const [radioButtons, setRadioButtons] = useState([]);
  const {
    name,
    onClick,
    selectedOption,
    options,
    radioButtonInputClassName,
    radioButtonContainerClassName,
    isDisable = false,
    rootDivClassName = '',
  } = props;

  useEffect(() => {
    if (options) {
      renderRadioGroup();
    }
  }, [options]);

  const renderRadioGroup = () => {
    const radioButtons = [];
    options.forEach(element => {
      radioButtons.push(
        <div key={options.indexOf(element)}>
          <div className={radioButtonContainerClassName}>
            <input
              className={radioButtonInputClassName}
              type="radio"
              id={name + '_' + element.value}
              name={`${name}`}
              value={element.value}
              onClick={onClick}
              defaultChecked={selectedOption === element.value}
              disabled={isDisable}
            />
            <label
              htmlFor={name + '_' + element.value}
              className="radio-text-right"
            >
              {element.displayValue}
            </label>
          </div>
        </div>,
      );
    });
    setRadioButtons(radioButtons);
  };

  return <div className={rootDivClassName}>{radioButtons}</div>;
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  selectedOption: PropTypes.string,
  options: PropTypes.array,
  radioButtonInputClassName: PropTypes.string,
  radioButtonContainerClassName: PropTypes.string,
  isDisable: PropTypes.bool,
  rootDivClassName: PropTypes.string,
};

export default RadioGroup;
