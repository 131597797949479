import React, { useContext, useEffect } from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDeviceDetect } from '../../../../shared/hooks';
import PropTypes from 'prop-types';
import { SupplierOrderPaymentHistoryContext } from '../../../../apis/supplier/SupplierOrderPaymentHistory';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';

function SupplierOrderHistory({ onClickOrder }) {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const supplierOrderContext = useContext(SupplierOrderPaymentHistoryContext);
  const { getSupplierOrderHistory } = supplierOrderContext;
  const { supplierOrderHistoryResponse } = supplierOrderContext.state;
  useEffect(() => {
    getSupplierOrderHistory();
  }, []);

  return (
    <div className="supplier-order-history ">
      {supplierOrderHistoryResponse?.res?.purchaseOrders?.length > 0 && (
        <h4 className="outstanding-bal-container my-3">
          Outstanding Balance : {isMobile ? <br /> : <></>}LKR{' '}
          {commafy(
            RoundNum(supplierOrderHistoryResponse?.res?.outstandingBalance, 2),
          )}
        </h4>
      )}
      <div>
        <div className="tableFixHead">
          {supplierOrderHistoryResponse?.res?.purchaseOrders?.length > 0 && (
            <Table>
              {!isMobile && (
                <thead>
                  <tr>
                    <th className="text-center">{t('DATE')}</th>
                    <th>Invoice Number</th>
                    <th>Status</th>
                    <th className="text-right">Invoice Value (LKR)</th>
                    <th className="text-right">Amount Due (LKR)</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {supplierOrderHistoryResponse?.res?.purchaseOrders?.map(
                  (item, index) => (
                    <tr key={index} onClick={() => onClickOrder(item)}>
                      {!isMobile ? (
                        <>
                          <td className="text-right">
                            {moment(item?.orderSortingCompletionDate).format(
                              'DD-MM-YYYY',
                            )}
                          </td>
                          <td>{item?.supplierInvoiceNumber}</td>
                          <td>{item?.status}</td>
                          <td className="text-right blue-color">
                            {commafy(RoundNum(item?.supplierInvoiceValue, 2))}
                          </td>
                          <td className="text-right blue-color">
                            {commafy(RoundNum(item?.amountDue, 2))}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            className="d-flex flex-row justify-content-between cursor-pointer"
                            style={{ borderWidth: '0' }}
                          >
                            <div style={{ width: '50%' }}>
                              <small className="order-first-row d-block p-1 ">
                                <span className="col-title">
                                  {moment(
                                    item?.orderSortingCompletionDate,
                                  ).format('DD-MM-YYYY')}
                                </span>
                              </small>
                              <small className="d-block p-1">
                                {item?.supplierInvoiceNumber}
                              </small>
                              <small className="d-block p-1 btn-color">
                                {item?.status}
                              </small>
                            </div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                              <small className="order-first-row  d-block p-1">
                                <b className="col-title ">Inv. Value</b>{' '}
                                <span className="blue-color">
                                  {commafy(
                                    RoundNum(item?.supplierInvoiceValue, 2),
                                  )}
                                </span>
                              </small>
                              <small className=" d-block p-1">
                                <b className="col-title">Amt. Due</b>{' '}
                                <span className="blue-color">
                                  {commafy(RoundNum(item?.amountDue, 2))}
                                </span>
                              </small>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <div>
        {(supplierOrderHistoryResponse?.res?.purchaseOrders?.length === 0 ||
          supplierOrderHistoryResponse?.err !== null) && (
          <div className="py-5 custom-empty-message">
            <p>No records available</p>
          </div>
        )}
      </div>
    </div>
  );
}

SupplierOrderHistory.propTypes = {
  onClickOrder: PropTypes.func,
};

export default SupplierOrderHistory;
