import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, Nav } from 'reactstrap';
// import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import downArrow from '../app/assets/icons/Down-Arrow.svg';
import orderFulFillment from '../app/assets/icons/orderFulfillment.svg';
import inventoryUpdates from '../app/assets/icons/inventoryUpdates.svg';
import orderAndPaymentHistory from '../app/assets/icons/orderAndPaymentHistory.svg';
import sillaraMasterSheetIcon from '../app/assets/icons/Sillara Master Sheet.svg';
import buyerAccoutManagementIcon from '../app/assets/icons/Buyer Account Management.svg';
import supplierAccoutManagementIcon from '../app/assets/icons/Supplier Account Management.svg';
import processManagementIcon from '../app/assets/icons/Process Management.svg';
import paymentManagementIcon from '../app/assets/icons/Payment Management.svg';
import qualityCheckIcon from '../app/assets/icons/Quality Check.svg';
import loadingUploadingIcon from '../app/assets/icons/Loading-Uploading.svg';
import reportsIcon from '../app/assets/icons/Reports.svg';
import makeaNewOrderIcon from '../app/assets/icons/Make a New Order.svg';
import orderAndPaymentHistoryIcon from '../app/assets/icons/orderAndPaymentHistory.svg';
import vendorManagementIcon from '../app/assets/icons/Vendor Management.svg';
import validateQrcode from '../app/assets/icons/ValidateQrcode.svg';
import {
  BuyerAccountManagementActive,
  InventoryUpdatesActive,
  LoadingUploadingActive,
  MakeaNewOrderActive,
  OrderAndPaymentHistoryActive,
  PaymentManagementActive,
  ProcessManagementActive,
  QualityCheckActive,
  ReportsActive,
  SillaraMasterSheetActive,
  SupplierAccountManagementActive,
  VendorManagementActive,
  OrderFulFillmentActive,
  ValidateQrcodeActive,
} from '../app/assets/icons/sidebarActiveIcons';

// var ps;

function Sidebar({ routes, userRole }) {
  const sidebar = React.useRef();
  const [backgroundColor] = React.useState('black');

  useEffect(() => {
    var nodes = document.querySelectorAll('.sidebar-topic');
    if (userRole) {
      Array.prototype?.forEach?.call(nodes, function(node) {
        node.textContent = node.textContent
          .split(' ')
          .map(function(word) {
            return word[0].toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
      });
    }
  }, [userRole]);
  const getSideBarText = role => {
    if (role === 'SUPPLIER') {
      return 'Order Management';
    }
    return role;
  };
  return (
    <div className="sidebar" data-color={backgroundColor}>
      <div className="topic">
        <p className="sidebar-topic">{getSideBarText(userRole)}</p>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {routes.map((prop, key) => {
            return <SubMenu route={prop} key={key} />;
          })}
        </Nav>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.array,
  userRole: PropTypes.string,
};

export default Sidebar;

const SubMenu = ({ route }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState(true);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const getIcon = iconName => {
    switch (iconName) {
      case 'sillaraMasterSheetIcon':
        return sillaraMasterSheetIcon;
      case 'buyerAccoutManagementIcon':
        return buyerAccoutManagementIcon;
      case 'supplierAccoutManagementIcon':
        return supplierAccoutManagementIcon;
      case 'processManagementIcon':
        return processManagementIcon;
      case 'paymentManagementIcon':
        return paymentManagementIcon;
      case 'qualityCheckIcon':
        return qualityCheckIcon;
      case 'loadingUploadingIcon':
        return loadingUploadingIcon;
      case 'reportsIcon':
        return reportsIcon;
      case 'orderfulfillmentIcon':
        return orderFulFillment;
      case 'inventoryupdatesIcon':
        return inventoryUpdates;
      case 'orderpaymenthistoryIcon':
        return orderAndPaymentHistory;
      case 'makeaNewOrderIcon':
        return makeaNewOrderIcon;
      case 'orderAndPaymentHistoryIcon':
        return orderAndPaymentHistoryIcon;
      case 'vendorManagementIcon':
        return vendorManagementIcon;
      case 'validateQrIcon':
        return validateQrcode;
      default:
        return buyerAccoutManagementIcon;
    }
  };
  const getActiveIcon = iconName => {
    switch (iconName) {
      case 'sillaraMasterSheetIcon':
        return SillaraMasterSheetActive;
      case 'buyerAccoutManagementIcon':
        return BuyerAccountManagementActive;
      case 'supplierAccoutManagementIcon':
        return SupplierAccountManagementActive;
      case 'processManagementIcon':
        return ProcessManagementActive;
      case 'paymentManagementIcon':
        return PaymentManagementActive;
      case 'qualityCheckIcon':
        return QualityCheckActive;
      case 'loadingUploadingIcon':
        return LoadingUploadingActive;
      case 'reportsIcon':
        return ReportsActive;
      case 'orderfulfillmentIcon':
        return OrderFulFillmentActive;
      case 'inventoryupdatesIcon':
        return InventoryUpdatesActive;
      case 'orderpaymenthistoryIcon':
        return OrderAndPaymentHistoryActive;
      case 'makeaNewOrderIcon':
        return MakeaNewOrderActive;
      case 'orderAndPaymentHistoryIcon':
        return OrderAndPaymentHistoryActive;
      case 'vendorManagementIcon':
        return VendorManagementActive;
      case 'validateQrIcon':
        return ValidateQrcodeActive;
      default:
        return BuyerAccountManagementActive;
    }
  };

  const activeRoute = routeName => {
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  const activeChildRoute = parentRoute => {
    const result = parentRoute.children.filter(
      element => location.pathname.indexOf(element.path) > -1,
    );
    return result.length > 0 ? 'active' : '';
  };

  const getArrowClassName = route => {
    if (activeRoute(route.path) == 'active' && route.children.length == 0) {
      return 'sidebar-icon-arrow-active';
    } else {
      if (route.children.length > 0 && !collapsed) {
        return 'sidebar-icon-arrow-active-submenu';
      }
    }
  };
  const closeSidebar = e => {
    e.stopPropagation();
    //  document.documentElement.classList.toggle('nav-open');
    // document
    //  .getElementById('navbar-toggler-button')
    //   .classList.toggle('toggled');
  };

  return (
    <>
      <li
        className={activeRoute(route.path) + (route.pro ? ' active-pro' : '')}
        key={route.path}
      >
        {route.children.length > 0 ? (
          <NavLink
            onClick={toggle}
            id="routeparent"
            className={
              activeChildRoute(route) == 'active'
                ? 'activeRoute border-bottom border-2'
                : 'border-bottom border-2'
            }
          >
            <img
              src={
                activeChildRoute(route) == 'active'
                  ? getActiveIcon(route.icon)
                  : getIcon(route.icon)
              }
              width="18"
              height="18"
              className="sidebar-icon"
              alt="menu-icon"
            />
            <span>{route.name}</span>
            <div className="sidebar-icon-arrow">
              <img
                src={downArrow}
                width="18"
                height="18"
                className={getArrowClassName(route)}
                alt="arrow-icon"
              />
            </div>
          </NavLink>
        ) : (
          <NavLink
            onClick={e => closeSidebar(e)}
            to={route.layout + route.path}
            className={
              ('nav-link',
              activeRoute(route.path) == 'active'
                ? 'activeRoute '
                : 'border-bottom border-2')
            }
          >
            <img
              src={
                activeRoute(route.path) == 'active'
                  ? getActiveIcon(route.icon)
                  : getIcon(route.icon)
              }
              width="18"
              height="18"
              className="sidebar-icon"
              alt="menu-icon-active"
            />
            <span>{route.name}</span>
            {activeRoute(route.path) == 'active' && (
              <div className="sidebar-icon-arrow">
                <img
                  src={downArrow}
                  width="18"
                  height="18"
                  className={getArrowClassName(route)}
                  alt="arrow-icon-active"
                />
              </div>
            )}
          </NavLink>
        )}
      </li>
      {route.children.length > 0 && (
        <Collapse isOpen={!collapsed} navbar>
          {route.children.map(ele => {
            return (
              <li
                className={
                  (activeRoute(ele.path) + (ele.pro ? ' active-pro ' : ''),
                  'sub-menu')
                }
                key={ele.path}
              >
                <NavLink
                  onClick={e => closeSidebar(e)}
                  className={
                    ('nav-link',
                    activeRoute(ele.path) == 'active'
                      ? ''
                      : 'border-bottom border-2')
                  }
                  to={ele.layout + ele.path}
                >
                  <span> {ele.name}</span>
                  {activeRoute(ele.path) == 'active' && (
                    <div className="sidebar-icon-arrow sidebar-icon-arrow-submenu">
                      <img
                        src={downArrow}
                        width="18"
                        height="18"
                        className="sidebar-icon-arrow-active"
                        alt="arrow-down"
                      />
                    </div>
                  )}
                </NavLink>
              </li>
            );
          })}
        </Collapse>
      )}
    </>
  );
};

SubMenu.propTypes = {
  route: PropTypes.object,
};
