import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LoadingContext = React.createContext();
const LoadingProvider = props => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider
      value={{
        state: {
          loading: loading,
        },
        setLoading: setLoading,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
};
LoadingProvider.propTypes = {
  children: PropTypes.any,
};

export { LoadingProvider, LoadingContext };
