import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { S3_BUCKET_URL } from '../../../../environment';
function ProductCardMobile({ id, src, title, unit }) {
  useEffect(() => {}, []);

  return (
    <Card id={id} className="admin-product-card">
      <div className="col-3 product-card-img-container">
        <img
          className="product-card-img"
          alt={title}
          src={S3_BUCKET_URL + 'Products/' + src}
        />
      </div>
      <CardBody className="col-9">
        <CardTitle className="col-8">{title}</CardTitle>
        <CardSubtitle className="col-3 mb-2 text-muted" tag="h6">
          {unit}
        </CardSubtitle>
      </CardBody>
    </Card>
  );
}

ProductCardMobile.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
  price: PropTypes.string,
  onHandleCheck: PropTypes.func,
};
export default ProductCardMobile;
