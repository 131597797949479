import React, { useEffect, useState, useContext } from 'react';
import { hidePassword, showPassword } from '../../../assets/img';
import { Form, Button, Row, Col } from 'reactstrap';
import {
  LOGIN_ISSUE_CONTACT_NUMBER,
  COGNITO,
  COGNITO_POLICY,
  SRILANKAN_COUNTRY_CODE,
} from '../../../../shared/constants/constants';
import { USER_ROLES } from '../../../../shared/constants/constants';
import { useTranslation } from 'react-i18next';
import { AuthContext, SessionContext } from '../../../../apis/authentication';
import { Validate } from '../../../config';
import { Text, CheckBox } from '../../../../shared/components';
import { alterPhoneNumber } from '../../../utilities/utils';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetect } from '../../../../shared/hooks';

function Login() {
  const { t } = useTranslation();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const signInContext = useContext(AuthContext);
  const { signIn, getPermissions, logout, setPermissionsRes } = signInContext;

  const sessionContext = useContext(SessionContext);
  const { roleAvailability } = sessionContext.state;
  const { signInResponse, permissionsRes } = signInContext.state;

  const {
    //getauthenticatedUser,
    setSessionDetails,
  } = sessionContext;

  const { isMobile } = useDeviceDetect();
  const [form, setForm] = useState({
    username: {
      value: '',
      title: t('PHONE_NUMBER'),
      rules: {
        title: t('ENTER_YOUR_PHONE_NUMBER'),
        stop: true,
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
        range: {
          min: COGNITO_POLICY.PHONE_NUMBER_MIN_LENGTH,
          max: COGNITO_POLICY.PHONE_NUMBER_MAX_LENGTH,
          message: t('PHONE_NUMBER_ENTERED_INVALID_MESSAGE'),
        },
      },
    },
    password: {
      value: '',
      title: t('PASSWORD'),
      rules: {
        stop: true,
        title: t('ENTER_YOUR_PASSWORD'),
        required: {
          message: t('THIS_IS_A_REQUIRED_FIELD'),
        },
      },
    },
    countryCode: {
      value: SRILANKAN_COUNTRY_CODE,
      title: t('COUNTRY_CODE'),
      rules: {
        stop: true,
        title: t('COUNTRY_CODE'),
      },
    },
    remember: {
      title: t('REMEMBER_ME'),
      value: false,
      rules: {
        stop: true,
        title: t('REMEMBER_ME'),
      },
    },
  });
  useEffect(() => {
    setPermissionsRes({ res: null, err: null });
    if (signInResponse?.err !== null || signInResponse?.res !== null) {
      handleLoginResponse();
    }
  }, [signInResponse]);

  const handleLoginSubmit = e => {
    e.preventDefault();
    setError(null);
    const formValidation = Validate.form(form);
    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }
    const { username, password, remember } = form;
    signIn(alterPhoneNumber(username.value), password.value, remember.value);
  };

  const switchLandingPage = role => {
    switch (role) {
      case USER_ROLES.ADMIN:
        navigate('/admin');
        break;
      case USER_ROLES.BUYER:
        navigate('/buyer/home');
        break;
      //return <Navigate to="/buyer/home" />;
      case USER_ROLES.SUPPLIER:
        navigate('/supplier/home');
        break;
      default:
    }
  };

  useEffect(() => {
    if (permissionsRes?.res) {
      setSessionDetails(switchLandingPage);
    }
    if (permissionsRes?.err) {
      setError(permissionsRes?.err);
      logout();
    }
  }, [permissionsRes]);

  const handleLoginResponse = () => {
    if (signInResponse?.err) {
      const { err } = signInResponse;
      if (err?.includes(COGNITO.INCORRECT_USERNAME_PASSWORD)) {
        setError(t('PHONE_NUMBER_ENTERED_INCORRECT'));
        return;
      }
      if (err?.includes(COGNITO.PASSWORD_ATTEMPT_EXCEED)) {
        setError(t('WRONG_PASSWORD_ATTEMPT_EXCEEDED'));
        return;
      }
      if (err.startsWith('2 validation errors detected: Value at')) {
        setError('Please check the phone number');
        return;
      }
      setError(err);
      return;
    }
    getPermissions({
      sub: signInResponse?.res?.username,
      token: signInResponse?.res?.signInUserSession?.idToken?.jwtToken || '',
    });
  };

  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const onChangeCheckBox = e => {
    const { name } = e.target;
    const updatedForm = Validate.input(name, !form.remember.value, form, false);
    setForm(updatedForm);
  };

  const onBlur = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };
  return (
    <React.Fragment>
      {roleAvailability === 'unavailable' ? (
        <p>
          <small className="error-message">
            {t('auth.signin.roleUnavailable')}
          </small>
        </p>
      ) : (
        ''
      )}
      <Form onSubmit={handleLoginSubmit}>
        <div className="mobile-center-view">
          <div className="username-container">
            <Text
              label={form.username.title}
              prefix={SRILANKAN_COUNTRY_CODE}
              groupClassName="form-group"
              labelClassName="form-label"
              placeholder={form.username.rules.title}
              id="username"
              field="username"
              onChange={onChange}
              onBlur={onBlur}
              validation={form.username}
              rightIconGroupClassName="username-input-container"
              maxLength={COGNITO_POLICY.PHONE_NUMBER_MAX_LENGTH}
            />
          </div>
          <Text
            groupClassName="form-group"
            labelClassName="form-label"
            type={isRevealPwd ? 'text' : 'password'}
            label={form.password.title}
            id="password"
            field="password"
            placeholder={form.password.rules.title}
            onChange={onChange}
            onBlur={onBlur}
            validation={form.password}
            rightIconClassName="auth-fa-eye"
            rightIconGroupClassName="auth-text-input-container"
            rightIcon={isRevealPwd ? showPassword : hidePassword}
            rightIconHandle={() => setIsRevealPwd(prevState => !prevState)}
          />
          <Row>
            <div
              className={!isMobile ? 'auth-text-input-container m-auto' : ' '}
              style={{
                width: '300px',
                justifyContent: 'space-around',
                display: 'flex',
                margin: 'auto',
              }}
            >
              <Col sm={6}>
                <div style={isMobile ? {} : {}} className="form-group">
                  <CheckBox
                    labelClassName="checkbox-label"
                    label={form.remember.title}
                    id="remember"
                    field="remember"
                    onChange={onChangeCheckBox}
                    showErrors={false}
                    checked={form.remember.value}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-group">
                  <a href="/auth/forgot-password">
                    <b>{t('FORGOT_PASSWORD_TEXT')}?</b>
                  </a>
                </div>
              </Col>
            </div>
            {error !== null && <small className="error-message">{error}</small>}
          </Row>
        </div>
        <div className="form-footer">
          <Button color="primary" type="submit" className="form-submit">
            {t('SIGN_IN')}
          </Button>
          <small className="help">
            {t('DO_YOU_HAVE_LOGIN_ISSUES_CALL_US_ON')}{' '}
            <a href={`tel:${LOGIN_ISSUE_CONTACT_NUMBER}`}>
              {' '}
              {LOGIN_ISSUE_CONTACT_NUMBER}
            </a>
          </small>
        </div>
      </Form>
    </React.Fragment>
  );
}

export default Login;
