import moment from 'moment';
import {
  DATE_TIME_FORMAT_API,
  DATE_FORMAT_API,
  DD_MMM_YY_HH_MM_24,
} from '../constants/constants';

export function formatDate(date, withTime, currentFormat, outputFormat) {
  if (withTime) {
    return moment(date).format(DATE_TIME_FORMAT_API);
  }
  if (outputFormat) {
    if (currentFormat) {
      return moment(moment(date, currentFormat)).format(outputFormat);
    }
    return moment(moment(date)).format(outputFormat);
  }
  return moment(date).format(DATE_FORMAT_API);
}

export function dateToUTCformat(date, dateFormat = DATE_FORMAT_API) {
  return moment.utc(date).format(dateFormat);
}

export function dateUTCToLocalFormat(
  utcDateTime,
  utcDateTimeFormat = DD_MMM_YY_HH_MM_24,
  outFormat = DD_MMM_YY_HH_MM_24,
) {
  if (utcDateTime instanceof Date) {
    return moment
      .utc(utcDateTime)
      .local()
      .format(outFormat);
  }
  return moment
    .utc(utcDateTime, utcDateTimeFormat)
    .local()
    .format(outFormat);
}
