import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
function RightIconButton({ text, onClick, icon, isDisabled }) {
  return (
    <Button
      disabled={isDisabled}
      className="delete-items"
      color="link"
      onClick={onClick}
    >
      {text}
      <img
        width="15px"
        height="20px"
        className="mx-1 icon"
        src={icon}
        alt="right-icon"
      />
    </Button>
  );
}

RightIconButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};
export default RightIconButton;
