import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';
const SupplierManagementContext = React.createContext();
const SupplierManagementProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [supplierList, setSupplierList] = useState({
    res: [],
    err: null,
  });

  const [qrCodes, setQrCodes] = useState({
    res: [],
    err: null,
  });

  const [uploadFileResponse, setUploadFileResponse] = useState({
    res: null,
    err: null,
  });
  const [existingQrCodeResponse, setExistingQrCodeResponse] = useState({
    res: null,
    err: null,
  });
  const [qrCodeDetails, setQrCodeDetails] = useState({
    res: null,
    err: null,
  });

  const [isFetching, setIsFetching] = useState(false);

  const uploadFile = async file => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const uploadDetails = await fetch(url + `/bulk-product-upload`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(file),
      });
      const data = await uploadDetails.json();
      if (data?.message) {
        setUploadFileResponse({ res: null, err: data?.message });
        return;
      }
      setUploadFileResponse({
        res: data,
        err: null,
      });
      setIsFetching(false);
      setLoading(false);
    } catch (err) {
      setIsFetching(false);
      setLoading(false);
      setUploadFileResponse({ res: null, err: err?.message || err });
    }
  };

  const getSuppliers = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('suppliers');
      const uploadDetails = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const data = await uploadDetails.json();
      setSupplierList({
        res: data,
        err: null,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setSupplierList({ err: err?.message || err });
    }
  };

  const getQRCodes = async (supplierId, body) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const qrCodes = await fetch(
        url + `/supplier-companies/${supplierId}/qr-codes`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        },
      );
      const data = await qrCodes.json();
      if (data?.message) {
        setQrCodes({ err: data?.message, res: null });
        return;
      }
      setQrCodes({
        res: data?.base64QRPDF,
        err: null,
      });
      setLoading(false);
    } catch (err) {
      setQrCodes({ err: err?.message || err, res: null });
      setLoading(false);
    }
  };

  const getPreviousQRCodeCount = async companyId => {
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');

      const qrCodes = await fetch(
        url + `/supplier-companies/${companyId}/existing-supplier-qr-codes`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await qrCodes.json();
      if (data?.message) {
        setExistingQrCodeResponse({ err: data?.message, res: null });
        return;
      }
      setExistingQrCodeResponse({
        res: data?.existingSupplierQRCodeCount,
        err: null,
      });
    } catch (err) {
      setExistingQrCodeResponse({ err: err?.message || err, res: null });
    }
  };

  const getDamagedQRCodeValidation = async qrCode => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const qrCodeDetail = await fetch(
        url + `/primary-packages?qrcode=${qrCode}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await qrCodeDetail.json();
      if (data.message) {
        setLoading(false);
        setQrCodeDetails({
          res: null,
          err: data.error,
        });
      } else {
        setLoading(false);
        setQrCodeDetails({
          res: data,
          err: null,
        });
      }
    } catch (err) {
      setLoading(false);
      setQrCodeDetails({
        res: null,
        err: err?.message || err,
      });
    }
  };

  return (
    <SupplierManagementContext.Provider
      value={{
        state: {
          supplierList: supplierList,
          isFetching: isFetching,
          qrCodes: qrCodes,
          uploadFileResponse: uploadFileResponse,
          existingQrCodeResponse: existingQrCodeResponse,
          qrCodeDetails: qrCodeDetails,
        },
        getSuppliers: getSuppliers,
        getQRCodes: getQRCodes,
        uploadFile: uploadFile,
        getPreviousQRCodeCount: getPreviousQRCodeCount,
        getDamagedQRCodeValidation: getDamagedQRCodeValidation,
      }}
    >
      {props.children}
    </SupplierManagementContext.Provider>
  );
};
SupplierManagementProvider.propTypes = {
  children: PropTypes.any,
};

export { SupplierManagementProvider, SupplierManagementContext };
