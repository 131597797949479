import React, { useEffect, useState, useContext } from 'react';
import InnerBody from '../../../../content/InnerBody';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { CalendarBlue } from '../../../assets/icons';
import { CustomCalendar, Text } from '../../../../shared/components';
import { useDeviceDetect } from '../../../../shared/hooks';
import { excelIcon } from '../../../assets/img/';
import { useTranslation } from 'react-i18next';
import { LoadingContext } from '../../../../apis/LoadingContext';
import { ReportsContext } from '../../../../apis/admin/ReportsContext';
import { formatDate } from '../../../../shared/utility/dateFormats';
import {
  SL_DATE_FORMAT,
  UK_DATE_FORMAT,
} from '../../../../shared/constants/constants';
function ReportAccounting() {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const [openCalendar, setIsOpenCalendar] = useState(false);
  const [isBaseNameDropdown, setIsBaseNameDropdown] = useState(false);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const [selectedDeliDate, setSelectedDeliDate] = useState('');
  const [disbleDwnBtn, setDisableDwnBtn] = useState(true);
  const baseNameToggle = () => setIsBaseNameDropdown(!isBaseNameDropdown);

  useEffect(() => {}, [isMobile]);
  useEffect(() => {
    if (selectedBaseName === null || selectedDeliDate == (null || '')) {
      setDisableDwnBtn(true);
    }
    if (selectedBaseName && selectedDeliDate) {
      setDisableDwnBtn(false);
    }
  }, [selectedDeliDate, selectedBaseName]);

  const loadingContext = useContext(LoadingContext);
  const reportContext = useContext(ReportsContext);
  const { setLoading } = loadingContext;
  const {
    getDeliveryBasesListByDate,
    getExcelReportByDataAndBaseName,
  } = reportContext;
  const { deliveryBasesListRes, excelBase64Response } = reportContext.state;

  useEffect(() => {
    getDeliveryBasesListByDate();
  }, []);

  useEffect(() => {
    if (excelBase64Response?.res && selectedBaseName && selectedDeliDate) {
      setLoading(true);
      saveAsXlsxFile(excelBase64Response?.res);
    }
  }, [excelBase64Response]);

  function saveAsXlsxFile(base64) {
    var mediaType =
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    var a = document.createElement('a');
    a.href = mediaType + base64;
    const date = formatDate(
      selectedDeliDate,
      null,
      UK_DATE_FORMAT,
      SL_DATE_FORMAT,
    );
    a.download = `Accounting-Sillara-${selectedBaseName?.baseName}-${date}.xlsx`;
    a.click();
    setLoading(false);
    setSelectedBaseName(null);
    setSelectedDeliDate('');
  }

  return (
    <InnerBody topic={'Accounting'} isDisable={true} className="admin-reports">
      <div className="report-accounting">
        {excelBase64Response?.err != null && excelBase64Response?.err?.message}
        <Row>
          <div className="col-lg-5 col-sm-12">
            <Text
              type="text"
              placeholder={'Select a date'}
              className="delivery-date cursor-pointer"
              value={
                selectedDeliDate &&
                moment(selectedDeliDate).format('DD/MM/YYYY')
              }
              rightIcon={CalendarBlue}
              rightIconHandle={() => setIsOpenCalendar(prevProps => !prevProps)}
              id="calendar"
              field="calendar"
              onClick={() => setIsOpenCalendar(prevProps => !prevProps)}
              rightIconGroupClassName="position-relative cursor-pointer"
              rightIconClassName="calendar-icon"
              showErrors={false}
              style={{ cursor: 'default' }}
              readOnly
            />
          </div>
          {openCalendar && (
            <CustomCalendar
              onClose={() => setIsOpenCalendar(false)}
              onChange={e => {
                setSelectedDeliDate(e);
                setIsOpenCalendar(false);
              }}
              value={selectedDeliDate && new Date(selectedDeliDate)}
            />
          )}

          <Dropdown
            isOpen={isBaseNameDropdown}
            toggle={baseNameToggle}
            direction="down"
            className="col-lg-5 col-sm-12 mb-3"
          >
            <DropdownToggle
              caret
              className={selectedBaseName === null ? 'grey-text' : 'black-text'}
            >
              {selectedBaseName === null
                ? t('SELECT_A_BASE_NAME')
                : selectedBaseName.baseName}
            </DropdownToggle>
            <DropdownMenu>
              {deliveryBasesListRes?.res?.map(item => (
                <DropdownItem
                  key={item?.baseId}
                  onClick={() => {
                    setSelectedBaseName(item);
                  }}
                >
                  {item?.baseName}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Row>
        <div className="mt-3">
          <img
            src={excelIcon}
            width="45"
            height="35"
            alt="Excel Icon"
            className="m-auto upload-card-icon"
          />
          <Button
            id="download-admin-excel-report"
            disabled={disbleDwnBtn}
            style={disbleDwnBtn ? { opacity: '0.6' } : { opacity: '1' }}
            className="upload-card-button btn-primary"
            onClick={() => {
              getExcelReportByDataAndBaseName(
                'baseId=' +
                  selectedBaseName?.baseId +
                  '&selectedDate=' +
                  formatDate(
                    selectedDeliDate,
                    null,
                    SL_DATE_FORMAT,
                    UK_DATE_FORMAT,
                  ),
              );
            }}
          >
            Download
          </Button>
        </div>
      </div>
    </InnerBody>
  );
}
export default ReportAccounting;
