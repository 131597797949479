import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Col, Row } from 'reactstrap';

function StatusContainer() {
  const { t } = useTranslation();

  const statusArray = [
    {
      name: 'SentBySupplier',
      text: t('SENT_FOR_LOADING'),
    },
    {
      name: 'Requested',
      text: t('SEND_FOR_LOADING'),
    },
    {
      name: 'NotRequested',
      text: t('NOT_REQUESTED'),
    },
  ];

  return (
    <div className="order-loading-summary">
      {/* <Row> */}
      {statusArray.map(status => (
        <p className="d-flex flex-row align-items-center" key={status.name}>
          {status.text} &nbsp;&nbsp;&nbsp;&nbsp;:
          <div
            style={{ width: '10px', height: '10px' }}
            className={`mx-1 ${status.name}`}
          ></div>
        </p>
      ))}
      {/* </Row> */}
    </div>
  );
}

export default StatusContainer;
