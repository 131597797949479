import React, { Component } from 'react';
import PropTypes from 'prop-types';
class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      config: {
        days: {
          show:
            this.props.config && this.props.config.days
              ? this.props.config.days.show
              : true,
          hideSpan:
            this.props.config && this.props.config.days
              ? this.props.config.days.hideSpan
              : false,
        },
        hours: {
          show:
            this.props.config && this.props.config.hours
              ? this.props.config.hours.show
              : true,
          hideSpan:
            this.props.config && this.props.config.hours
              ? this.props.config.hours.hideSpan
              : false,
        },
        minute: {
          show:
            this.props.config && this.props.config.minute
              ? this.props.config.minute.show
              : true,
          hideSpan:
            this.props.config && this.props.config.minute
              ? this.props.config.minute.hideSpan
              : false,
        },
        second: {
          show:
            this.props.config && this.props.config.second
              ? this.props.config.second.show
              : true,
          hideSpan:
            this.props.config && this.props.config.second
              ? this.props.config.second.hideSpan
              : false,
        },
      },
    };
  }

  componentDidMount() {
    this.doCountDown();
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.startTime.isSame(this.props.startTime)) {
      this.stop();
      this.doCountDown();
    }
  }
  doCountDown() {
    const startTime = this.props.startTime
      ? this.props.startTime
      : new Date().setSeconds(new Date().getSeconds() + 30);
    this.setState({
      config: {
        days: {
          show:
            this.props.config && this.props.config.days
              ? this.props.config.days.show
              : false,
          hideSpan:
            this.props.config && this.props.config.days
              ? this.props.config.days.hideSpan
              : true,
        },
        hours: {
          show:
            this.props.config && this.props.config.hours
              ? this.props.config.hours.show
              : true,
          hideSpan:
            this.props.config && this.props.config.hours
              ? this.props.config.hours.hideSpan
              : true,
        },
        minute: {
          show:
            this.props.config && this.props.config.minute
              ? this.props.config.minute.show
              : true,
          hideSpan:
            this.props.config && this.props.config.minute
              ? this.props.config.minute.hideSpan
              : true,
        },
        second: {
          show:
            this.props.config && this.props.config.second
              ? this.props.config.second.show
              : true,
          hideSpan:
            this.props.config && this.props.config.second
              ? this.props.config.second.hideSpan
              : true,
        },
      },
    });
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(startTime);
      if (!date.finished) {
        this.setState(date);
      } else if (date.finished) {
        this.setState(date);
        this.props.onComplete();
        this.stop();
      }
    }, 1000);
  }
  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
    // clear countdown when date is reached
    if (diff < 0)
      return {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
        millisec: 0,
        finished: true,
      };

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }

    if (this.props.isShowDays && diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {
    const countDown = this.state;

    return (
      <div className="countdown">
        {this.state.config.days.show && (
          <div className="countdown-col">
            <div className="countdown-col-element">
              <div className="countdown-counter-element">
                <span>{this.addLeadingZeros(countDown.days)}</span>
              </div>
              {!this.state.config.days.hideSpan && (
                <div className="countdown-span-element">
                  {countDown.days === 1 ? 'Day' : 'Days'}
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.config.hours.show &&
          this.addLeadingZeros(countDown.hours) != '00' && (
            <>
              <div className="countdown-col">
                <div className="countdown-col-element">
                  <div className="countdown-counter-element">
                    <span>{this.addLeadingZeros(countDown.hours)}</span>
                  </div>
                  {!this.state.config.hours.hideSpan && (
                    <div className="countdown-span-element">Hours</div>
                  )}
                </div>
              </div>
              :
            </>
          )}
        {this.state.config.minute.show && (
          <div className="countdown-col">
            <div className="countdown-col-element">
              <div className="countdown-counter-element">
                <span>{this.addLeadingZeros(countDown.min)}</span>
              </div>
              {!this.state.config.hours.hideSpan && (
                <div className="countdown-span-element">Min</div>
              )}
            </div>
          </div>
        )}
        :
        {this.state.config.second.show && (
          <div className="countdown-col">
            <div className="countdown-col-element">
              <div className="countdown-counter-element">
                <span>{this.addLeadingZeros(countDown.sec)}</span>
              </div>
              {!this.state.config.hours.hideSpan && (
                <div className="countdown-span-element">Sec</div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Countdown.propTypes = {
  config: PropTypes.any,
  startTime: PropTypes.any,
  onComplete: PropTypes.func,
  isShowDays: PropTypes.bool,
};

export default Countdown;
