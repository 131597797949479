import React from 'react';
import PropTypes from 'prop-types';

function ProgressBar({
  steps = [],
  currentStepIndex,
  setCurrentStep,
  changeStepOnClick,
  className,
}) {
  return (
    <div className={className}>
      <div
        // className="d-flex align-items-center"
        style={{ height: '50px', marginBottom: '10px' }}
      >
        <ul className="steps">
          {steps.map((step, index) => {
            return (
              <React.Fragment key={index}>
                <li
                  onClick={
                    changeStepOnClick ? () => setCurrentStep(index) : () => {}
                  }
                  className={` ${
                    currentStepIndex == index ? 'present' : 'past' //currentStepIndex > index
                  }`}
                >
                  <span>
                    <strong>{step}</strong>
                  </span>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  steps: PropTypes.array,
  currentStepIndex: PropTypes.number,
  className: PropTypes.string,
  changeStepOnClick: PropTypes.bool,
  setCurrentStep: PropTypes.func,
};

export default ProgressBar;
