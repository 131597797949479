import React, { useEffect } from 'react';
//import Multiselect from 'multiselect-react-dropdown';
import PropTypes from 'prop-types';
//import { dropdownCaret } from '../../../assets/icons';
import DropdownSelector from '../../../../shared/components/searchBar/DropdownSelector';
function DropdownSelectorInventory({
  placeholder,
  filterCategories,
  onSelect,
  isMobile,
}) {
  useEffect(() => {
    document.getElementById('search_input').setAttribute('readonly', true);
    document
      .getElementsByClassName('icon_down_dir')[0]
      .classList.add('hide-before');
    if (isMobile) {
      document.getElementsByClassName('icon_down_dir')[0].style.display =
        'none';
    }
  }, [isMobile]);

  return (
    <DropdownSelector
      isMobile={isMobile}
      filterCategories={filterCategories}
      displayValue={'name'}
      placeholder={isMobile ? '☰' : placeholder}
      onSelect={onSelect}
    />
  );
}

DropdownSelectorInventory.propTypes = {
  isMobile: PropTypes.bool,
  placeholder: PropTypes.string,
  filterCategories: PropTypes.array,
  onSelect: PropTypes.func,
};
{
  /*}
    <Multiselect
      displayValue=C
      onRemove={onSelect}
      onSelect={onSelect}
      isSearchable={false}
      showArrow={false}
      customArrow={!isMobile ? <img width="10" src={dropdownCaret} /> : null}
      options={filterCategories}
      placeholder={placeholder}
      showCheckbox
      hideSelectedList={false}
      selectedValueDecorator={text => (isMobile ? '☰' : text)}
      style={{
        searchBox: {
          borderRight: 'none',
        },
      }}
      singleSelect={true}
    />
    */
}
export default DropdownSelector;
