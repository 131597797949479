import React from 'react';
import PropTypes from 'prop-types';
import { QrReader } from 'react-qr-reader';
import { successMp3, successWav, successOgg } from '../../../assets/audio/';
const Scanner = ({ setStartScan, onHandleScan }) => {
  const onHandleResult = result => {
    if (result) {
      var audioElement = document.getElementById('qrScannerAudioControl');
      audioElement.play();
      onHandleScan(result?.text);
      setStartScan(false);
    }
  };

  return (
    <>
      <audio className="d-none" id="qrScannerAudioControl" controls>
        <source src={successMp3} type="audio/mpeg" />
        <source src={successOgg} type="audio/ogg" />
        <source src={successWav} type="audio/wav" />
      </audio>

      <QrReader
        onResult={result => onHandleResult(result)}
        style={{ width: '100%' }}
        constraints={{
          facingMode: 'environment',
        }}
      />
    </>
  );
};

Scanner.propTypes = {
  setStartScan: PropTypes.object,
  onHandleScan: PropTypes.func,
};

export default Scanner;
