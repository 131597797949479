import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Connection } from '../../app/config';
import { LoadingContext } from '../LoadingContext';

const BuyerManagementContext = React.createContext();
const BuyerManagementProvider = props => {
  const loadingContext = useContext(LoadingContext);
  const { setLoading } = loadingContext;
  const [buyerList, setBuyerList] = useState({
    res: [],
    err: null,
  });
  const [orderbuyerList, setOrderBuyerList] = useState({
    res: [],
    err: null,
  });
  const [uploadFileResponse, setUploadFileResponse] = useState({
    res: null,
    err: null,
  });
  const [deliverySlotsResponse, setDeliverySlotsResponse] = useState(null);
  const [baseNamesResponse, setBaseNamesResponse] = useState(null);
  const [purchaseOrdersResponse, setPurchaseOrdersResponse] = useState(null);
  const [suppliersByBaseResponse, setSuppliersByBaseResponse] = useState(null);
  const [getVehicleListResponse, setGetVehicleListResponse] = useState({
    res: null,
    err: null,
  });
  const [
    getOrdersOfEachVehicleResponse,
    setGetOrdersOfEachVehicleResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updateStatusOfaPackageResponse,
    setUpdateStatusOfaPackageResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updatePackageStatusResponse,
    setUpdatePackageStatusResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updatePackageStatusInLoadingResponse,
    setUpdatePackageStatusInLoadingResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [getUnloadPackagesResponse, setGetUnloadPackagesResponse] = useState({
    res: null,
    err: null,
  });
  const [
    getVehiclesQualityCheckSummaryResponse,
    setGetVehiclesQualityCheckSummaryResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updatePurchaseOrderProductResponse,
    setUpdatePurchaseOrderProductResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [detailViewOfOrderResponse, setDetailViewOfOrderResponse] = useState({
    res: null,
    err: null,
  });
  const [patchPurchaseOrderResponse, setPatchPurchaseOrderResponse] = useState({
    res: null,
    err: null,
  });
  const [
    addBuyerComplainReasonResponse,
    setAddBuyerComplainReasonResponse,
  ] = useState({
    res: null,
    err: null,
  });
  const [
    updateAllProductOfOrderResponse,
    setUpdateAllProductOfOrderResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const [
    supplierQualityCheckItemCountResponse,
    setSupplierQualityCheckItemCountResponse,
  ] = useState({
    res: null,
    err: null,
  });

  const transferQrCodePackages = async (req, qrCode) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const qrCodePack = await fetch(url + `/primary-packages/${qrCode}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(req),
      });
      const data = await qrCodePack.json();
      setLoading(false);
      if (data.message || data.description) {
        setLoading(false);
        return {
          res: null,
          err: data.message,
        };
      }
      setLoading(false);
      return {
        res: data,
        err: null,
      };
    } catch (err) {
      setLoading(false);

      return { res: null, err: err?.message || err };
    }
  };

  const itemCountQrScanValidation = async reqObject => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const packageStatusRes = await fetch(url + '/primary-packages/items', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(reqObject),
      });
      const data = await packageStatusRes.json();
      if (data.message) {
        setLoading(false);
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);

      return { res: null, err: err?.message || err };
    }
  };

  const uploadFile = async file => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const uploadDetails = await fetch(url + `/bulk-product-upload`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(file),
      });
      const data = await uploadDetails.json();
      setLoading(false);
      if (data?.message) {
        setUploadFileResponse({
          res: null,
          err: data?.message,
        });
        return;
      }
      setUploadFileResponse({
        res: data,
        err: null,
      });
    } catch (err) {
      setUploadFileResponse({ res: null, err: err?.message || err });
      setLoading(false);
    }
  };

  const getBuyers = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('buyers');
      const buyerDetails = await fetch(url, {
        method: 'GET',
        headers: headers,
      });
      const data = await buyerDetails.json();
      if (data.error) {
        setLoading(false);
        setBuyerList({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setBuyerList({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setBuyerList({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };
  const getOrderBuyers = async (
    vehicleId,
    baseId,
    deliverySlotId,
    selectedDate,
    manualProcurement,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const buyerDetails = await fetch(
        url +
          `/vehicles/${vehicleId}/order-buyers?baseId=${baseId}&deliverySlotId=${deliverySlotId}&deliveryDate=${selectedDate}&manualProcurement=${manualProcurement}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await buyerDetails.json();
      if (data.error) {
        setLoading(false);
        setOrderBuyerList({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setOrderBuyerList({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setOrderBuyerList({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getDeliverySlots = async (deliveryDate, isOnlyFutureSlots = false) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          '/delivery-slots?deliveryDate=' +
          deliveryDate +
          '&isOnlyFutureSlots=' +
          isOnlyFutureSlots,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.error) {
        setLoading(false);
        setDeliverySlotsResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setDeliverySlotsResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setDeliverySlotsResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getBaseNameByDeliverySlot = async deliverySlotId => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url + '/delivery-bases?deliverySlotId=' + deliverySlotId,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.error || data?.message) {
        setLoading(false);
        setBaseNamesResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setBaseNamesResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setBaseNamesResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getPurchaseOrders = async (deliverySlotId, baseId, date) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          `/purchase-order-products?deliverySlotId=${deliverySlotId}&date=${date}&baseId=${baseId}&isRequiringVerificationNeeded=true`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.error || data?.message) {
        setLoading(false);
        setPurchaseOrdersResponse({
          err: data?.message,
        });
      } else {
        setLoading(false);
        setPurchaseOrdersResponse({
          res: data,
          err: null,
        });
      }
    } catch (err) {
      setLoading(false);
      setPurchaseOrdersResponse({ err: err?.message || err });
    }
  };

  const updatePurchaseOrder = async (update, id) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const buyerDetails = await fetch(url + `/purchase-order-products/${id}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(update),
      });
      const data = await buyerDetails.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const updatePurchaseOrderAdmin = async (userId, orderId, purchaseOrderId) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const buyerDetails = await fetch(
        url +
          `/supplier-companies/${orderId}/purchase-orders/${purchaseOrderId}`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({
            supplierQCAdminId: userId,
          }),
        },
      );
      const data = await buyerDetails.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const getSuppliersByBase = async (deliveryDate, deliverySlotId, baseId) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          `/delivery-bases/${baseId}/suppliers-order-summary?deliveryDate=${deliveryDate}&deliverySlotId=${deliverySlotId}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.error) {
        setLoading(false);
        setSuppliersByBaseResponse({
          err: data.error,
        });
      } else {
        setLoading(false);
        setSuppliersByBaseResponse({
          res: data,
          err: null,
        });
      }
    } catch (err) {
      setLoading(false);
      setSuppliersByBaseResponse({ err: err?.message || err });
    }
  };

  const getVehicleList = async (deliverySlotId, baseId, date) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          `/delivery-bases/${baseId}/vehicles?deliverySlotId=${deliverySlotId}&date=${date}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setGetVehicleListResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setGetVehicleListResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setGetVehicleListResponse({ res: null, err: err?.message || err });
      return { res: null, err: err?.message || err };
    }
  };

  const getOrdersOfEachVehicle = async (
    vehicleId,
    baseId,
    deliverySlotId,
    deliveryDate,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url +
          `/vehicles/${vehicleId}/packages?baseId=${baseId}&deliverySlotId=${deliverySlotId}&deliveryDate=${deliveryDate}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setGetOrdersOfEachVehicleResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setGetOrdersOfEachVehicleResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setGetOrdersOfEachVehicleResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const getOrdersBySupplier = async (
    supplierCompanyId,
    deliverySlotId,
    selectedDate,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const ordersBySupplier = await fetch(
        url +
          `/supplier-companies/${supplierCompanyId}/supplier-order-summary?deliverySlotId=${deliverySlotId}&deliveryDate=${selectedDate}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await ordersBySupplier.json();
      if (data.error) {
        setLoading(false);
        return data.error;
      } else {
        setLoading(false);
        return data;
      }
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const getSelectedOrderOfySupplier = async (
    supplierCompanyId,
    purchaseOrderId,
    manualProcurement = false,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const ordersBySupplier = await fetch(
        url +
          `/purchase-orders/${purchaseOrderId}?supplierCompanyId=${supplierCompanyId}&manualProcurement=${manualProcurement}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await ordersBySupplier.json();
      if (data.error || data.message) {
        setLoading(false);
        setSupplierQualityCheckItemCountResponse({
          res: null,
          err: data.message,
        });
        return data.error;
      } else {
        setLoading(false);
        setSupplierQualityCheckItemCountResponse({
          res: data,
          err: null,
        });
        return data;
      }
    } catch (err) {
      setLoading(false);
      setSupplierQualityCheckItemCountResponse({
        res: null,
        err: err,
      });
      return err;
    }
  };

  const getPackageTypes = async () => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const ordersBySupplier = await fetch(url + `/packages-types`, {
        method: 'GET',
        headers: headers,
      });
      const data = await ordersBySupplier.json();
      if (data.error) {
        setLoading(false);
        return data.error;
      } else {
        setLoading(false);
        return data;
      }
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const uploadVehicleFile = async file => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const uploadDetails = await fetch(url + `/bulk-vehicle-upload`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(file),
      });
      const data = await uploadDetails.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const addComplainReason = async (
    sadOrderStatusChecked,
    issueTypeChecked,
    purchaseOrderId,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const uploadDetails = await fetch(
        url + `/purchase-order-products/complain-reason`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            PurchaseOrderProductId: purchaseOrderId,
            ComplianStatusId: parseInt(sadOrderStatusChecked),
            ComplianReasonId: issueTypeChecked,
          }),
        },
      );
      const data = await uploadDetails.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const updateStatusOfaPackage = async (packageId, statusId) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const deliverySlots = await fetch(
        url + `/primary-packages/${packageId}`,
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify([
            {
              op: 'replace',
              path: 'Status',
              value: statusId,
            },
          ]),
        },
      );
      const data = await deliverySlots.json();
      if (data.message) {
        setLoading(false);
        setUpdateStatusOfaPackageResponse({
          res: null,
          err: data.error,
        });
        return {
          res: null,
          err: data.error,
        };
      } else {
        setLoading(false);
        setUpdateStatusOfaPackageResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdateStatusOfaPackageResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const updatePackageStatus = async (packageIds, packageStatus) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const packageStatusRes = await fetch(url + '/vehicles/package-status', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          packageId: packageIds,
          packageStatus: packageStatus,
        }),
      });
      const data = await packageStatusRes.json();
      if (data.message) {
        setLoading(false);
        setUpdatePackageStatusResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setUpdatePackageStatusResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdatePackageStatusResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const updatePackageStatusInLoading = async (
    vehicleId,
    packageIds,
    packageStatus,
    deliveryDate,
    deliverySlotId,
    baseId,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const packageStatusRes = await fetch(url + '/vehicles/package-status', {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          vehicleId: vehicleId,
          packageId: packageIds,
          packageStatus: packageStatus,
          deliveryDate: deliveryDate,
          deliverySlotId: deliverySlotId,
          baseId: baseId,
        }),
      });
      const data = await packageStatusRes.json();
      if (data.message) {
        setLoading(false);
        setUpdatePackageStatusInLoadingResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setUpdatePackageStatusInLoadingResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdatePackageStatusInLoadingResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const getVehiclesQualityCheckSummary = async (
    baseID,
    deliverySlotId,
    date,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const getVehiclesQualityCheckSummaryres = await fetch(
        url +
          `/delivery-bases/${baseID}/buyer-quality-check-summary-for-vehicles?deliverySlotId=${deliverySlotId}&date=${date}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await getVehiclesQualityCheckSummaryres.json();
      if (data.message) {
        setLoading(false);
        setGetVehiclesQualityCheckSummaryResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setGetVehiclesQualityCheckSummaryResponse({ res: data, err: null });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setGetVehiclesQualityCheckSummaryResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };
  const getUnloadPackages = async (
    vehicleId,
    buyerCompanyId,
    isManualProcurement,
    deliverySlot,
    deliveryDate,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const packageStatusRes = await fetch(
        url +
          `/vehicles/${vehicleId}/packages-to-be-unloaded?buyerCompanyId=${buyerCompanyId}&manualProcurement=${isManualProcurement}&deliverySlotId=${deliverySlot}&deliveryDate=${deliveryDate}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await packageStatusRes.json();
      if (data.message) {
        setLoading(false);
        setGetUnloadPackagesResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setGetUnloadPackagesResponse({ res: data, err: null });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setGetUnloadPackagesResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const updatePurchaseOrderProduct = async (productId, statusId) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');

      const qrCodes = await fetch(
        url + `/purchase-order-products/${productId}`,
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify([
            {
              op: 'replace',
              path: 'statusId',
              value: statusId,
            },
          ]),
        },
      );
      const data = await qrCodes.json();
      if (data.message) {
        setLoading(false);
        setUpdatePurchaseOrderProductResponse({
          err: data.message,
          res: null,
        });
        return {
          err: data.error,
          res: null,
        };
      } else {
        setLoading(false);
        setUpdatePurchaseOrderProductResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdatePurchaseOrderProductResponse({ err: err?.message || err });
    }
  };

  const getDetailViewOfOrder = async purchaseOrderId => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const getDetailViewOfOrderRes = await fetch(
        url + `/purchase-orders/${purchaseOrderId}/details`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await getDetailViewOfOrderRes.json();
      if (data.message) {
        setLoading(false);
        setDetailViewOfOrderResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setDetailViewOfOrderResponse({ res: data, err: null });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setDetailViewOfOrderResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const updateAllProductOfOrder = async (
    purchaseOrderId,
    supplierCompanyId,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const qrCodes = await fetch(
        url + `/purchase-orders/${purchaseOrderId}/products`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({
            SupplierCompanyId: supplierCompanyId,
          }),
        },
      );
      const data = await qrCodes.json();
      if (data.message) {
        setLoading(false);
        setUpdateAllProductOfOrderResponse({
          err: data.message,
          res: null,
        });
        return {
          err: data.error,
          res: null,
        };
      } else {
        setLoading(false);
        setUpdateAllProductOfOrderResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setUpdateAllProductOfOrderResponse({ err: err?.message || err });
    }
  };

  const patchPurchaseOrder = async (orderId, purchaseOrderId, adminID) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');

      const getDetailViewOfOrderRes = await fetch(
        url + `/orders/${orderId}/purchase-orders/${purchaseOrderId}`,
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify([
            {
              op: 'replace',
              path: 'BuyerQualityCheckAdminId',
              value: adminID,
            },
          ]),
        },
      );
      const data = await getDetailViewOfOrderRes.json();
      if (data.message) {
        setLoading(false);
        setPatchPurchaseOrderResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setPatchPurchaseOrderResponse({
          res: data,
          err: null,
        });
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      setPatchPurchaseOrderResponse({ err: err?.message || err });
    }
  };

  const getProductCount = async (
    purchaseOrderId,
    supplierCompanyId = '',
    manualProcAdminId = '',
    reducePackageCount,
  ) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const count = await fetch(
        url +
          `/purchase-orders/${purchaseOrderId}/product-count?supplierCompanyId=${supplierCompanyId}&reducePackageCount=${reducePackageCount}&manualProcAdminId=${manualProcAdminId}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await count.json();
      if (data.message) {
        setLoading(false);
        return {
          err: data.error,
          res: null,
        };
      } else {
        setLoading(false);
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const addBuyerComplainReason = async (purchaseOrderId, reasones) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const addBuyerComplainReasonRes = await fetch(
        url + `/purchase-order-products/buyer-complain-reason`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            PurchaseOrderId: purchaseOrderId,
            BuyerRejectedReasons: reasones,
          }),
        },
      );
      const data = await addBuyerComplainReasonRes.json();
      if (data.message) {
        setLoading(false);
        setAddBuyerComplainReasonResponse({
          res: null,
          err: data.message,
        });
        return {
          res: null,
          err: data.message,
        };
      } else {
        setLoading(false);
        setAddBuyerComplainReasonResponse({
          res: data,
          err: null,
        });
        return { res: data, err: null };
      }
    } catch (err) {
      setAddBuyerComplainReasonResponse({
        res: null,
        err: err?.message || err,
      });
      return { res: null, err: err?.message || err };
    }
  };

  const assignQrCode = async (purchaseOrderId, payload) => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const count = await fetch(
        url + `/purchase-orders/${purchaseOrderId}/packages`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload),
        },
      );
      const data = await count.json();
      if (data.message || data.description) {
        setLoading(false);
        return {
          err: data.message,
          res: null,
        };
      } else {
        setLoading(false);
        return {
          res: data,
          err: null,
        };
      }
    } catch (err) {
      setLoading(false);
      return {
        res: null,
        err: err,
      };
    }
  };

  const validateQrCodePackages = async qrCode => {
    setLoading(true);
    try {
      const headers = Connection.headers();
      const url = Connection.baseUrl('base');
      const qrCodePack = await fetch(
        url + `/primary-packages?qrcode=${qrCode.toString()}`,
        {
          method: 'GET',
          headers: headers,
        },
      );
      const data = await qrCodePack.json();
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  return (
    <BuyerManagementContext.Provider
      value={{
        state: {
          buyerList: buyerList,
          uploadFileResponse: uploadFileResponse,
          deliverySlotsResponse: deliverySlotsResponse,
          baseNamesResponse: baseNamesResponse,
          purchaseOrdersResponse: purchaseOrdersResponse,
          suppliersByBaseResponse: suppliersByBaseResponse,
          getVehicleListResponse: getVehicleListResponse,
          getOrdersOfEachVehicleResponse: getOrdersOfEachVehicleResponse,
          updateStatusOfaPackageResponse: updateStatusOfaPackageResponse,
          updatePackageStatusResponse: updatePackageStatusResponse,
          updatePackageStatusInLoadingResponse: updatePackageStatusInLoadingResponse,
          getVehiclesQualityCheckSummaryResponse: getVehiclesQualityCheckSummaryResponse,
          detailViewOfOrderResponse: detailViewOfOrderResponse,
          patchPurchaseOrderResponse: patchPurchaseOrderResponse,
          addBuyerComplainReasonResponse: addBuyerComplainReasonResponse,
          getUnloadPackagesResponse: getUnloadPackagesResponse,
          updatePurchaseOrderProductResponse: updatePurchaseOrderProductResponse,
          updateAllProductOfOrderResponse: updateAllProductOfOrderResponse,
          orderbuyerList: orderbuyerList,
          supplierQualityCheckItemCountResponse: supplierQualityCheckItemCountResponse,
        },
        transferQrCodePackages: transferQrCodePackages,
        validateQrCodePackages: validateQrCodePackages,
        itemCountQrScanValidation: itemCountQrScanValidation,
        getBuyers: getBuyers,
        uploadFile: uploadFile,
        getDeliverySlots: getDeliverySlots,
        getBaseNameByDeliverySlot: getBaseNameByDeliverySlot,
        getPurchaseOrders: getPurchaseOrders,
        updatePurchaseOrder: updatePurchaseOrder,
        uploadVehicleFile: uploadVehicleFile,
        getSuppliersByBase: getSuppliersByBase,
        getVehicleList: getVehicleList,
        getOrdersOfEachVehicle: getOrdersOfEachVehicle,
        getOrdersBySupplier: getOrdersBySupplier,
        getSelectedOrderOfySupplier: getSelectedOrderOfySupplier,
        updateStatusOfaPackage: updateStatusOfaPackage,
        updatePackageStatus: updatePackageStatus,
        updatePackageStatusInLoading: updatePackageStatusInLoading,
        getVehiclesQualityCheckSummary: getVehiclesQualityCheckSummary,
        getDetailViewOfOrder: getDetailViewOfOrder,
        patchPurchaseOrder: patchPurchaseOrder,
        addBuyerComplainReason: addBuyerComplainReason,
        getUnloadPackages: getUnloadPackages,
        updatePurchaseOrderProduct: updatePurchaseOrderProduct,
        updateAllProductOfOrder: updateAllProductOfOrder,
        updatePurchaseOrderAdmin: updatePurchaseOrderAdmin,
        addComplainReason: addComplainReason,
        getPackageTypes: getPackageTypes,
        getProductCount: getProductCount,
        assignQrCode: assignQrCode,
        getOrderBuyers: getOrderBuyers,
      }}
    >
      {props.children}
    </BuyerManagementContext.Provider>
  );
};
BuyerManagementProvider.propTypes = {
  children: PropTypes.any,
};

export { BuyerManagementProvider, BuyerManagementContext };
