import React, { useContext, useEffect, useState } from 'react';
import {
  Navbar,
  NavbarText,
  // NavbarBrand,
  Container,
  NavLink,
  NavItem,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { sillara } from '../app/assets/img';
import { useNavigate } from 'react-router-dom';
import homeIcon from '../app/assets/icons/Home.svg';
import {
  profile,
  cartIcon,
  contactUs,
  logout,
  account,
} from '../app/assets/icons';
import { Link } from 'react-router-dom';
import { SessionContext } from '../apis/authentication';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { USER_ROLES } from '../shared/constants/constants';
import { BuyerContext } from '../apis/buyer';
// import { useNavigate } from 'react-router-dom';

function OuterNavContainer({ userRole }) {
  const sidebarToggle = React.useRef();
  const [profileOpen, setProfileOpen] = useState(false);
  const [homePathName, setHomePathName] = useState('');
  const [cartPathName, setCartPathName] = useState('');
  const [currentPath, setCurrentPath] = useState('');
  // const navigate = useNavigate();
  const sessionContext = useContext(SessionContext);
  const { logOut } = sessionContext;
  const buyerContext = useContext(BuyerContext);
  const { getCartProductItems } = buyerContext;
  const { getCartProductItemsResponse } = buyerContext.state;
  const profileToggle = () => {
    document.documentElement.classList.remove('nav-open');
    sidebarToggle.current.classList.remove('toggled');
    setProfileOpen(prevState => !prevState);
  };
  const navigate = useNavigate();

  const signout = () => {
    logOut();
  };

  useEffect(() => {
    setHomePathName(
      userRole &&
        (userRole == USER_ROLES.ADMIN
          ? userRole.toLowerCase() + '/sillaramastersheet'
          : userRole.toLowerCase() + '/home'),
    );
    setCartPathName(
      userRole && userRole.toLowerCase() + '/makeaneworder?step=3',
    );
  }, [userRole]);

  useEffect(() => {
    if (userRole == USER_ROLES.BUYER) {
      getCartProductItems();
    }
  }, [userRole]);

  const openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    sidebarToggle.current.classList.toggle('toggled');
  };

  const handleAccount = () => {
    navigate(userRole.toLowerCase() + '/profile');
  };
  const handleContactUs = () => {
    navigate(userRole.toLowerCase() + '/contact-us');
  };

  useEffect(() => {
    setCurrentPath(window.location.pathname + window.location.search);
  });

  const closeSidebar = () => {
    document.documentElement.classList.remove('nav-open');
    sidebarToggle.current.classList.remove('toggled');
  };
  useEffect(() => {
    const root = document.querySelector('#content-container');

    root.addEventListener('click', event => {
      if (
        !event.target?.className?.toString()?.includes('sidebar') &&
        !event.target?.className?.toString()?.includes('navbar-toggler-bar')
      ) {
        if (
          document.documentElement.classList.toString()?.includes('nav-open') &&
          event.target.id !== 'routeparent'
        ) {
          closeSidebar();
        }
      }
    });

    return () => {
      root.removeEventListener('click', () => {
        openSidebar();
      });
    };
  }, []);

  return (
    <Container className="outer-nav-container">
      <Navbar className="p-0">
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              id="navbar-toggler-button"
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <Link className="navbar-brand" to={homePathName}>
            <img src={sillara} width="100" height="100" alt="sillara logo" />
          </Link>
        </div>
        <Nav>
          <NavbarText>
            <NavItem>
              <Link className="home-icon nav-icon" to={homePathName}>
                <Button className="home-icon-btn nav-icon-btn">
                  <img src={homeIcon} width="16" height="14" alt="home icon" />
                </Button>
              </Link>
            </NavItem>
          </NavbarText>
          {userRole == USER_ROLES.BUYER &&
            currentPath != '/buyer/makeaneworder?step=1' &&
            currentPath != '/buyer/makeaneworder' &&
            currentPath != '/buyer/home' && (
              <NavbarText>
                <NavItem>
                  <Link className="navigation-link nav-icon" to={cartPathName}>
                    <Button
                      className={`cart-icon-btn nav-icon-btn ${getCartProductItemsResponse
                        ?.res?.cartItems?.length > 0 && 'cart-icon-value-btn'}`}
                      style={{ padding: '6px 8px 6px 8px', marginTop: '9px' }}
                      value={
                        getCartProductItemsResponse?.res?.cartItems
                          ? getCartProductItemsResponse?.res?.cartItems?.length
                          : 0
                      }
                    >
                      <img
                        src={cartIcon}
                        width="16"
                        height="14"
                        alt="cart icon"
                      />
                    </Button>
                  </Link>
                </NavItem>
              </NavbarText>
            )}

          <NavbarText>
            <NavItem>
              <NavLink className="navigation-link profile-icon nav-icon">
                <Dropdown
                  isOpen={profileOpen}
                  toggle={() => {
                    if (!profileOpen) {
                      profileToggle();
                      return;
                    }
                    setTimeout(() => {
                      if (profileOpen) {
                        setProfileOpen(false);
                        return;
                      }
                    }, 10);
                  }}
                  direction={'down'}
                >
                  <DropdownToggle
                    className="outer-nav-container-dropdown-toggle"
                    size="sm"
                    split={false}
                  >
                    <div
                      className="profile-icon-btn nav-icon-btn"
                      style={{ padding: '6px 8px 6px 8px', marginTop: '0px' }}
                    >
                      <img
                        width="16"
                        height="14"
                        src={profile}
                        alt="user profile"
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu container="body">
                    <DropdownItem
                      className="justify-content-flex-start align-items-center"
                      onClick={() => handleAccount()}
                    >
                      <img
                        style={{ marginRight: '2px', fontWeight: 'bold' }}
                        className="align-middle"
                        width="15"
                        height="15"
                        src={account}
                        alt="Account"
                      />
                      <span> {t('ACCOUNT')}</span>
                    </DropdownItem>

                    <DropdownItem
                      className="flex-row justify-content-flex-start align-items-center"
                      onClick={() => signout()}
                    >
                      <img
                        style={{ marginRight: '5px', fontWeight: 'bold' }}
                        className="align-middle"
                        width="15"
                        height="15"
                        src={logout}
                        alt="logout"
                      />
                      <span>{t('LOGOUT')}</span>
                    </DropdownItem>
                    {userRole !== USER_ROLES.ADMIN && (
                      <DropdownItem
                        className="justify-content-flex-start align-items-center"
                        onClick={() => handleContactUs()}
                      >
                        <img
                          style={{ marginRight: '2px', fontWeight: 'bold' }}
                          className="align-middle"
                          width="15"
                          height="15"
                          src={contactUs}
                          alt="contactUs"
                        />
                        <span> {t('CONTACT_US')}</span>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </NavLink>
            </NavItem>
          </NavbarText>
        </Nav>
      </Navbar>
    </Container>
  );
}

OuterNavContainer.propTypes = {
  userRole: PropTypes.string,
};

export default OuterNavContainer;
