import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  Button,
  Form,
  Col,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CheckBox } from '../../../../shared/components';
import { Text } from '../../../../shared/components';
import { Validate } from '../../../config';
import { BuyerOrderPaymentHistoryContext } from '../../../../apis/buyer/BuyerOrderPaymentHistory';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';
function BuyerInvoiceBasedPayment({
  isMobile,
  setError,
  setErrorPopupModal,
  redirectPayHis,
}) {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);

  const [payesInvoices, setPayesInvoices] = useState([]);

  const buyerPayContext = useContext(BuyerOrderPaymentHistoryContext);
  const {
    getBuyerInvoiceBasedPayment,
    getPaymentTypes,
    doPaymentToInvoices,
  } = buyerPayContext;
  const {
    buyerInvoiceBasedPayResponse,
    paymentTypesResponse,
  } = buyerPayContext.state;
  useEffect(() => {
    getPaymentTypes();
    getBuyerInvoiceBasedPayment();
  }, []);

  const [form, setForm] = useState({
    amount: {
      value: '',
      title: t('AMOUNT'),
      rules: {
        title: t('AMOUNT'),
        stop: true,
      },
    },
    paymentType: {
      value: '',
      title: 'Payment Type',
      rules: {
        stop: true,
        title: t('ENTER_PER_UNIT_PRICE'),
        required: {
          message: t(''),
        },
      },
    },
    buyerIdentifier: {
      value: '',
      title: 'Buyer Identifier',
      rules: {
        stop: true,
        title: t('AVAILABILITY'),
      },
    },
  });

  const handleUpdatePayment = e => {
    e.preventDefault();
    const formValidation = Validate.form(form);
    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }

    const req = {
      InvoiceIds: payesInvoices,
      Amount: parseFloat(form.amount.value),
      PaymentTypeId: parseInt(form.paymentType.value.id),
      BuyerIdentifier: form.buyerIdentifier.value,
    };
    doPaymentToInvoices(req)
      .then(res => {
        if (res?.message || res?.description) {
          setError({
            title: 'Pay Failure',
            description: res?.description || res?.message,
          });
          setErrorPopupModal(prevProp => !prevProp);
          return;
        }
        setPayesInvoices([]);
        redirectPayHis();
      })
      .catch(err => {
        setError({ title: 'Pay Failure', description: err?.description });
        setErrorPopupModal(prevProp => !prevProp);
      });
  };
  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };

  const onChecked = id => {
    if (!payesInvoices.includes(id)) {
      setPayesInvoices(prevProps => [...prevProps, id]);
    } else {
      setPayesInvoices(prev => prev.filter(item => item !== id));
    }
  };
  useEffect(() => {
    let dueAmt = 0;
    if (buyerInvoiceBasedPayResponse?.res?.length > 0) {
      buyerInvoiceBasedPayResponse.res.forEach(element => {
        if (payesInvoices.includes(element?.invoiceId)) {
          dueAmt += element?.amountDue || 0;
        }
      });
      let updatedForm;
      if (dueAmt === 0) {
        updatedForm = Validate.input('amount', '', form, false);
      } else {
        updatedForm = Validate.input(
          'amount',
          RoundNum(dueAmt, 2),
          form,
          false,
        );
      }
      setForm(updatedForm);
    }
  }, [payesInvoices]);

  return (
    <div className="buyer-invoice-based-payment">
      <div className="tableFixHead">
        {buyerInvoiceBasedPayResponse?.res?.length > 0 && (
          <Table>
            <thead>
              {!isMobile && (
                <tr>
                  <th></th>
                  <th className="text-center" width="20%">
                    {t('DATE')}
                  </th>
                  <th width="40%">Invoice No</th>
                  <th className="text-right" width="20%">
                    Invoice Value (LKR)
                  </th>
                  <th className="text-right" width="20%">
                    Amount Due (LKR)
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {buyerInvoiceBasedPayResponse?.res?.map((item, index) => (
                <tr key={index}>
                  {!isMobile ? (
                    <>
                      <td>
                        <CheckBox
                          groupClassName="check-box"
                          field="selectInvoice"
                          checked={payesInvoices.includes(item?.invoiceId)}
                          onChange={() => {
                            onChecked(item?.invoiceId);
                          }}
                        />
                      </td>
                      <td className="text-right">
                        {moment(item?.actualDeliveryDate).format('DD-MM-YYYY')}
                      </td>
                      <td>{item?.invoiceNo} </td>
                      <td className="blue-color text-right">
                        {commafy(RoundNum(item?.invoiceValue, 2))}
                      </td>
                      <td className="blue-color text-right">
                        {commafy(RoundNum(item?.amountDue, 2))}
                      </td>
                    </>
                  ) : (
                    <td className="d-flex flex-column px-3 pt-2">
                      <Row>
                        <Col xs="11">
                          <small>
                            <Row className="order-first-row pt-1 pb-1">
                              <Col xs="4" className="col-title">
                                {moment(item?.actualDeliveryDate).format(
                                  'DD-MM-YYYY',
                                )}
                              </Col>
                              <Col xs="8" className="col-title text-right">
                                {item?.invoiceNo}
                              </Col>
                            </Row>
                            <Row className="pt-1">
                              <Col xs="6">
                                <span className="col-title">Inv. Value:</span>{' '}
                                <span className="blue-color">
                                  {' '}
                                  {commafy(RoundNum(item?.invoiceValue, 2))}
                                </span>
                              </Col>

                              <Col xs="6" className="text-right">
                                <span className="col-title">Amt. Due:</span>{' '}
                                <span className="blue-color">
                                  {commafy(RoundNum(item?.amountDue, 2))}
                                </span>
                              </Col>
                            </Row>
                          </small>
                        </Col>
                        <Col xs="1" className="m-auto">
                          <CheckBox
                            groupClassName="check-box"
                            field="selectInvoice"
                            checked={payesInvoices.includes(item?.invoiceId)}
                            onChange={() => {
                              onChecked(item?.invoiceId);
                            }}
                          />
                        </Col>
                      </Row>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div>
          {(buyerInvoiceBasedPayResponse?.res?.length === 0 ||
            buyerInvoiceBasedPayResponse?.err !== null) && (
            <div className="py-5 custom-empty-message">
              <p>No records available</p>
            </div>
          )}
        </div>
      </div>
      {buyerInvoiceBasedPayResponse?.res?.length > 0 && (
        <div className="payment-selection-container">
          <Form onSubmit={handleUpdatePayment}>
            <Row>
              <Col style={!isMobile ? {} : { marginTop: '5px' }} sm={8}>
                <Text
                  disabled={true}
                  showErrors={false}
                  value={form.amount.value}
                  placeholder={form.amount.title}
                  groupClassName="update-form-group"
                  labelClassName="form-label"
                  id="payable-amount"
                  autoComplete="off"
                  field="payableAmount"
                  rightIconGroupClassName="form-container"
                  validation={form.amount}
                />

                <Dropdown
                  isOpen={isDropdownOpen}
                  toggle={dropdownToggle}
                  direction="down"
                  style={!isMobile ? {} : { marginTop: '5px' }}
                  sm={12}
                  className={isMobile ? 'col-12 my-3' : 'col-8 mb-3'}
                >
                  <DropdownToggle
                    caret
                    className={
                      form.paymentType.value === '' ? 'grey-text' : 'black-text'
                    }
                  >
                    {form.paymentType.value === ''
                      ? 'Payment Type'
                      : form.paymentType.value?.type}
                  </DropdownToggle>
                  <DropdownMenu>
                    {paymentTypesResponse?.res?.map((item, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          const updatedForm = Validate.input(
                            'paymentType',
                            item,
                            form,
                            false,
                          );
                          setForm(updatedForm);
                        }}
                      >
                        {item.type}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <div
                  className="footer"
                  style={isMobile ? {} : { marginBottom: '-1rem' }}
                >
                  <Text
                    autoComplete="off"
                    showErrors={false}
                    placeholder={form.buyerIdentifier.title}
                    validation={form.buyerIdentifier}
                    value={form.buyerIdentifier.value}
                    groupClassName="update-form-group"
                    labelClassName="form-label"
                    id="buyer-identifier"
                    field="buyerIdentifier"
                    onChange={onChange}
                    rightIconGroupClassName="form-container"
                    disabled={false}
                  />
                  <Button
                    disabled={
                      payesInvoices?.length === 0 ||
                      form.paymentType.value === '' ||
                      null
                    }
                    className="btn-primary"
                  >
                    Pay
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}
BuyerInvoiceBasedPayment.propTypes = {
  isMobile: PropTypes.bool,
  setError: PropTypes.func,
  redirectPayHis: PropTypes.func,
  setErrorPopupModal: PropTypes.func,
};
export default BuyerInvoiceBasedPayment;
