/* eslint-disable no-empty-pattern */
import React, { useEffect, useContext, useState } from 'react';
import { Button, Table, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from '../../../../../shared/hooks';
import InnerBody from '../../../../../content/InnerBody';
import {
  BuyerManagementContext,
  AdminContext,
} from '../../../../../apis/admin';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { CustomModal, Scanner } from '../../../../../shared/components';
import { LoadingContext } from '../../../../../apis/LoadingContext';
import { warning } from '../../../../assets/img';
import CustomTooltip from '../../../../../shared/components/CustomTooltip';
import { Text } from '../../../../../shared/components';
import { successMp3, successWav, successOgg } from '../../../../assets/audio';
function SupplierQualityCheckAdminConsolidateScan() {
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;
  const { isMobile } = useDeviceDetect();
  const buyerManagementContext = useContext(BuyerManagementContext);
  const { supplierCompanyId, purchaseOrderId, deliverySlotId } = useParams();
  const [order, setOrder] = useState(null);
  const [productsCount, setProductsCount] = useState(0);
  const [isStartScan, setIsStartScan] = useState(false);
  const [currentQrCode, setCurrentQrCode] = useState(null);
  const [error, setError] = useState(null);
  const [scannedPackageItems, setScannedPackageItems] = useState([]);
  const [popUpResponse, setPopUpResponse] = useState(null);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [tempHref, setTempHref] = useState('');
  const navigate = useNavigate();
  const {
    getProductCount,
    getSelectedOrderOfySupplier,
    itemCountQrScanValidation,
    validateQrCodePackages,
    transferQrCodePackages,
    updatePurchaseOrderAdmin,
  } = buyerManagementContext;
  const {
    supplierQualityCheckItemCountResponse,
  } = buyerManagementContext.state;

  const pathQuery = new URLSearchParams(useLocation().search);
  const adminContext = useContext(AdminContext);
  const { handleAdminDetails } = adminContext;
  var audioElement = document.getElementById('qrScannerAudioControl');

  const getManualProcuremntId = () => {
    if (pathQuery.get('manualProcurement') === 'true') {
      return supplierCompanyId;
    }
    return '';
  };
  const isManualProcurement = () => {
    if (pathQuery.get('manualProcurement') === 'true') {
      return true;
    }
    return false;
  };
  const getSupplierId = () => {
    if (pathQuery.get('manualProcurement') === 'true') {
      return '';
    }
    return supplierCompanyId;
  };
  const validateQrCode = qrcode => {
    validateQrCodePackages(qrcode)
      .then(data => {
        if (data?.message || data?.error) {
          openResponsePopup('Unable to Validate the Qr', data?.message);
          return true;
        }
        if (data === null) {
          if (productsCount === 0) {
            setError('You have reached maximum scanning count');
            setTimeout(() => {
              setError(null);
            }, 10000);
            return;
          }
          addQrCodeToList(qrcode);
          audioElement.play();
          return false;
        }
        setCurrentQrCode(data);
        setTransformFunctionPopUp(true);
        return true;
      })
      .catch(err => {
        openResponsePopup('Unable to Validate the Qr', err);
        return true;
      });
  };
  useEffect(
    () => () => {
      if (!isManualProcurement()) {
        updatePurchaseOrderAdmin(0, supplierCompanyId, purchaseOrderId)
          .then(() => {})
          .catch(() => {});
      }
    },
    [],
  );

  const handleUpdatePurchaseOrderAdmin = () => {
    setExitModalOpen(false);
    if (!isManualProcurement()) {
      updatePurchaseOrderAdmin(0, supplierCompanyId, purchaseOrderId)
        .then(() => {
          if (tempHref) {
            // history.pushState(null, null, tempHref);
            navigate(tempHref);
          } else {
            history.go(-1);
            history.go(-1);
          }
        })
        .catch(() => {});
    } else {
      if (tempHref) {
        // history.pushState(null, null, tempHref);
        navigate(tempHref);
      } else {
        history.go(-1);
        history.go(-1);
      }
    }
  };

  useEffect(() => {
    if (!isManualProcurement()) {
      handleAdminDetails().then(res => {
        updatePurchaseOrderAdmin(
          res.res.id,
          supplierCompanyId,
          purchaseOrderId,
        );
      });
    }
    getSelectedOrderOfySupplier(
      getSupplierId(),
      purchaseOrderId,
      isManualProcurement(),
    ).then(res => {
      setOrder(res);
      getProductCount(
        purchaseOrderId,
        getSupplierId(),
        getManualProcuremntId(),
        true,
      ).then(res => {
        setProductsCount(res?.res?.productsCount);
        setScannedPackageItems([]);
      });
    });
  }, []);

  const onHandleScan = qrValue => {
    let isFountDuplicate = false;
    for (const pack of scannedPackageItems) {
      if (pack.QrCodeId === qrValue) {
        setCurrentQrCode({ qrCodeId: qrValue, itemNo: pack.ItemNo });
        setIsDeleteFunctionPopUp(true);
        isFountDuplicate = true;
        break;
      }
    }

    if (isFountDuplicate) {
      return;
    }

    validateQrCode(qrValue);
  };

  const openResponsePopup = (title, des) => {
    setPopUpResponse({
      title: title,
      description: des,
    });
    setResponsePopupModal(true);
  };

  const addQrCodeToList = qrValue => {
    let temp = [...scannedPackageItems];
    const req = {
      IsPrimaryPackaging: false,
      IsManualProcurement: isManualProcurement(),
      PurchaseOrderId: parseInt(purchaseOrderId),
      NoOfItems: 1,
      ItemNo: scannedPackageItems.length + 1,
      SupplierCompanyId: isManualProcurement()
        ? null
        : parseInt(supplierCompanyId),
      QrCodeId: qrValue,
    };
    temp.push(req);
    setScannedPackageItems(temp);
    setProductsCount(productsCount - 1);
  };

  const transferQrCode = newCode => {
    const req = [
      {
        op: 'replace',
        path: 'QRCodeId',
        value: newCode,
      },
    ];
    transferQrCodePackages(req, currentQrCode?.packageId)
      .then(res => {
        setTransformNewQrCode('');
        setCurrentQrCode(null);
        if (res?.err) {
          openResponsePopup('Transfer Failure', res?.err);
          return;
        }
        setTransformNewQrCode('');
        openResponsePopup(
          'Successful',
          'You have successfully transferred the QR code details',
        );
      })
      .catch(err => {
        setTransformNewQrCode('');
        setCurrentQrCode(null);
        openResponsePopup('Transfer Failure', err.err);
      });
  };
  const [isTransformFunctionPopUp, setTransformFunctionPopUp] = useState(false);
  const [transformNewQrCode, setTransformNewQrCode] = useState('');
  const qrTransformFunctionPopUp = () => {
    return (
      <CustomModal
        isOpen={isTransformFunctionPopUp}
        isScrollable={true}
        enableFooter={false}
        title={'Already Scanned'}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        <>
          <p>The package has been already scanned.</p>
          <p style={{ textAlign: 'left' }}>The order details</p>
          <ul style={{ textAlign: 'left' }}>
            {!isManualProcurement() && (
              <li>
                Supplier Code : {currentQrCode?.supplierCompany?.supplierCode}
              </li>
            )}
            <li>Tag No : {currentQrCode?.purchaseOrder?.tagNumber}</li>
            {currentQrCode?.isPrimaryPackage ? (
              <>
                <li>Primary pack type : {currentQrCode?.packageType?.name}</li>
                <li>No of items : {currentQrCode?.numberOfItems}</li>
              </>
            ) : (
              <li>Item No : {currentQrCode?.itemNo}</li>
            )}
          </ul>
          <b>Transfer the details</b>
          <Text
            label={'From'}
            groupClassName="form-group"
            rightIconGroupClassName="input-group-qr-val"
            labelClassName="form-label"
            value={currentQrCode?.qrCodeId}
            showErrors={false}
            id="fromQrCodeNo"
            name="fromQrCodeNo"
            field="fromQrCodeNo"
            disabled={true}
          />
          <Text
            label={'To'}
            groupClassName="form-group"
            rightIconGroupClassName="input-group-qr-val"
            labelClassName="form-label"
            validation={{ value: transformNewQrCode }}
            def
            onChange={e => {
              setTransformNewQrCode(e.target.value);
            }}
            placeholder={'New QR code number'}
            showErrors={false}
            id="newQrCodeNo"
            name="newQrCodeNo"
            field="newQrCodeNo"
          />
          <Col
            className="d-flex justify-content-evenly"
            style={{ width: '100%' }}
          >
            <Button
              className="btn-outline-primary"
              onClick={() => {
                transferQrCode(transformNewQrCode);
                setTransformFunctionPopUp(false);
                return;
              }}
            >
              Transfer
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                setCurrentQrCode(null);
                setTransformNewQrCode('');
                setTransformFunctionPopUp(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </>
      </CustomModal>
    );
  };

  const [isDeleteFunctionPopUp, setIsDeleteFunctionPopUp] = useState(false);

  const deleteFunctionPopUp = () => {
    return (
      <CustomModal
        isOpen={isDeleteFunctionPopUp}
        isScrollable={true}
        enableFooter={false}
        title={'Already Scanned'}
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic m-auto"
        modalFooterClassName="modal-footer"
        modalBodyClassName="small"
      >
        <>
          <p>The package has been already scanned.</p>
          <p style={{ textAlign: 'left' }}>The order details</p>
          <ul style={{ textAlign: 'left' }}>
            {!isManualProcurement() && (
              <li>
                Supplier Code :{' '}
                {order?.supplierName.substring(0, 3) + '-' + order?.supplierId}
              </li>
            )}
            <li>Tag No : {order?.tagNumber}</li>
            {currentQrCode?.isPrimaryPackage ? (
              <>
                <li>Primary pack type : {currentQrCode?.packageType?.name}</li>
                <li>No of items : {currentQrCode?.numberOfItems}</li>
              </>
            ) : (
              <li>Item No : {currentQrCode?.itemNo}</li>
            )}
          </ul>
          <Col
            className="d-flex justify-content-evenly"
            style={{ width: '100%' }}
          >
            <Button
              className="btn-outline-primary"
              onClick={() => {
                let temp = scannedPackageItems.filter(
                  pack => pack.QrCodeId !== currentQrCode.qrCodeId,
                );

                let newList = [];
                temp.forEach((pack, index) => {
                  pack.ItemNo = index + 1;
                  newList.push(pack);
                });

                setScannedPackageItems(newList);
                setIsDeleteFunctionPopUp(false);
                openResponsePopup(
                  'Successful',
                  'You have successfully deleted the QR code details',
                );
                setCurrentQrCode(null);
                setProductsCount(productsCount + 1);
                return;
              }}
            >
              Delete
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                setCurrentQrCode(null);
                setIsDeleteFunctionPopUp(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </>
      </CustomModal>
    );
  };

  const [responePopupModal, setResponsePopupModal] = useState(false);
  const errorPopup = () => (
    <CustomModal
      isOpen={responePopupModal}
      isScrollable={true}
      title={popUpResponse?.title || ''}
      modalstyleClassName="modal-style"
      modalContentContainer="modal-container"
      modalHeaderClassName="modal-header"
      modalheaderTopic="modal-header-topic"
      modalBodyClassName="small"
    >
      {popUpResponse?.description}
      <div>
        <Button
          color="primary mt-2"
          type="submit"
          className="form-submit"
          onClick={() => {
            setPopUpResponse(null);
            setResponsePopupModal(false);
          }}
        >
          {'OK'}
        </Button>
      </div>
    </CustomModal>
  );
  /*
  useEffect(() => {
    history.pushState(null, null, location.href);
    setTimeout(() => {
      window.onpopstate = function() {
        const el = document.getElementById('back-confirm-modal');
        const el2 = el?.closest('.modal');
        if (!el || !el2?.classList?.contains('show')) {
          history.go(1);
          setExitModalOpen(true);
        }
        // }
      };
    }, 100);

    var elements = document.getElementsByTagName(`a`);
    for (var i = 0, len = elements.length; i < len; i++) {
      if (!elements[i]?.classList?.contains('active')) {
        elements[i].onclick = function(e) {
          if (
            location.pathname ==
            `/admin/supplierqualitycheck/consolidateScan/${supplierCompanyId}/${purchaseOrderId}/${deliverySlotId}`
          ) {
            e.preventDefault();
            const e2 = e?.target?.closest(`a`);
            console.log(e2.href, location);
            setTempHref(e2.href.split('3000')[1]);
            setExitModalOpen(true);
          }
        };
      }
    }
    return () => {
      window.onpopstate = () => {};
    };
  }, []);
*/
  const backEventFired = () => {
    setExitModalOpen(true);
  };
  return (
    <InnerBody onHandleBack={backEventFired} topic={t('ITEM_COUNT_SCAN')}>
      {deleteFunctionPopUp()}
      {errorPopup()}
      {qrTransformFunctionPopUp()}
      <div className="supplier-quality-check-admin-item-count">
        <div className="sub-title">
          <div className="blue-color d-md-flex">
            <div className="me-3">
              Supplier <span className="text-green">{order?.supplierName}</span>
            </div>
            <div className="me-3">
              Tag Number <span className="text-green">{order?.tagNumber}</span>{' '}
            </div>
            <div className="me-3">
              Buyer <span className="text-green">{order?.buyerName}</span>
            </div>
          </div>
        </div>

        {!loading &&
        supplierQualityCheckItemCountResponse?.res?.orderProducts?.length >
          0 ? (
          <>
            {error && <p className="warning">{error}</p>}
            <div className="d-md-flex">
              <Card className="mt-3 mb-0 flex-grow-1">
                <CardHeader>Total Number of Items: {productsCount}</CardHeader>
                <CardBody>
                  <div className="tableFixHead">
                    <Table bordered>
                      <thead className="table-header">
                        <tr>
                          <th>Product Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supplierQualityCheckItemCountResponse?.res?.orderProducts.map(
                          (item, index) => {
                            return (
                              <tr key={index}>
                                <>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {item.buyerNote && (
                                        <div className="me-2">
                                          {item.buyerNote?.length > 0 ? (
                                            <>
                                              <img
                                                width="25"
                                                className="notes-img"
                                                src={warning}
                                                alt="notes"
                                                id={`DisabledAutoHideNote-${index}`}
                                              />
                                              <CustomTooltip
                                                placement="top"
                                                targetId={`DisabledAutoHideNote-${index}`}
                                                displayMessage={item.buyerNote}
                                              />
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )}
                                      {item.productName}
                                    </div>
                                  </td>
                                  <td>
                                    {item.quantity + ' ' + item.unitMeasure}
                                  </td>
                                </>
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </div>
          </>
        ) : (
          !loading && (
            <div className="py-5 custom-empty-message">
              <p>{t('NO_ORDERS_AVAILABLE')}</p>
            </div>
          )
        )}

        {!loading && (
          <>
            <div className="mt-3 action-button d-flex justify-content-center">
              <Button
                className="btn-outline-primary me-3"
                onClick={() => setIsStartScan(true)}
                disabled={productsCount < 0}
              >
                Scan
              </Button>
              {!isMobile ? <>&nbsp;&nbsp;</> : ''}
              <Button
                disabled={productsCount != 0 || productsCount < 0}
                className="btn-primary"
                onClick={() => {
                  itemCountQrScanValidation({
                    PacKageItems: scannedPackageItems,
                  })
                    .then(res => {
                      if (res.err) {
                        openResponsePopup('Failed', res.err);
                        return;
                      }
                      navigate(
                        `/admin/${
                          isManualProcurement()
                            ? 'manualprocurementqualitycheck'
                            : 'supplierqualitycheck'
                        }/consolidateScan/${supplierCompanyId}/${purchaseOrderId}/${deliverySlotId}?manualProcurement=${isManualProcurement()}`,
                      );
                    })
                    .catch(res => {
                      openResponsePopup('Failed', res.err);
                    });
                }}
              >
                Complete
              </Button>
            </div>
          </>
        )}
      </div>
      <CustomModal
        isOpen={isStartScan}
        toggle={() => setIsStartScan(false)}
        isScrollable={true}
        title="QR Scanner"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
      >
        <Scanner
          setStartScan={setIsStartScan}
          onHandleScan={onHandleScan}
          isSuccessSound={false}
        />
      </CustomModal>
      <CustomModal
        isOpen={exitModalOpen}
        toggle={() => {
          setExitModalOpen(false);
          setTempHref('');
        }}
        isScrollable={true}
        title="Leave Page"
        modalstyleClassName="modal-style"
        modalContentContainer="modal-container"
        modalHeaderClassName="modal-header"
        modalheaderTopic="modal-header-topic"
        modalBodyClassName="small"
        id="back-confirm-modal"
      >
        <p>
          Are you sure you want to leave the page without completing the quality
          check?
        </p>
        <div className="d-flex justify-content-around mx-5">
          <Button
            outline
            color="primary"
            type="submit"
            className="form-submit"
            style={{ width: '75px' }}
            onClick={handleUpdatePurchaseOrderAdmin}
          >
            {t('YES')}
          </Button>
          <Button
            color="primary"
            type="submit"
            className="form-submit"
            style={{ width: '75px' }}
            onClick={() => {
              setExitModalOpen(false);
              setTempHref('');
            }}
          >
            {t('NO')}
          </Button>
        </div>
      </CustomModal>
      <audio className="d-none" id="qrScannerAudioControl" controls>
        <source src={successMp3} type="audio/mpeg" />
        <source src={successOgg} type="audio/ogg" />
        <source src={successWav} type="audio/wav" />
      </audio>
    </InnerBody>
  );
}
export default SupplierQualityCheckAdminConsolidateScan;
