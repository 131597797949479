export const BROWSE_PAGE_RADIO_BUTTON_VALUE = {
  ALL_ITEMS: 'allItems',
  YOUR_ITEMS: 'yourItems',
};

export const PRODUCT_UNIT_TYPE = {
  KG: 'Kg',
  LITRE: 'Litre',
  Unit: 'Unit',
};
