import { useTranslation } from 'react-i18next';
import React, { useEffect, useContext, useState, useRef } from 'react';
import InnerBody from '../../../../content/InnerBody';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import {
  VendorManagementContext,
  VendorManagementProvider,
} from '../../../../apis/buyer';
import ScrollTable from '../shared/ScrollTable';
import { Note } from '../../../assets/icons';
import moment from 'moment';
import { useDeviceDetect } from '../../../../shared/hooks';
import CustomTooltip from '../../../../shared/components/CustomTooltip';
import { arrowDownGreen, arrowUpGreen } from '../../../assets/icons/';
import CustomTooltipContainer from '../../../../shared/components/CustomTooltipContainer';

function VendorManagement() {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [isVisibleProdDet, setIsVisibleProdDet] = useState(true);
  const [isVisibleBaseDet, setIsVisibleBaseDet] = useState(false);
  const vendorManagement = useContext(VendorManagementContext);
  const defaultBaseDetails = useRef();
  const productDetails = useRef();
  const {
    productDetailsResponse,
    defaultBaseDetailsResponse,
  } = vendorManagement.state;
  const { getProductDetails, getDefaultBaseDetails } = vendorManagement;

  useEffect(() => {
    getProductDetails();
    getDefaultBaseDetails();
  }, []);
  useEffect(() => {}, [productDetailsResponse]);

  const operationalDeliverySlot = slots => {
    let list = '';
    slots?.forEach((slot, index) => {
      list =
        list +
        slot?.dayOfWeek.substring(0, 2) +
        '/' +
        moment(slot?.startTime, ['HH:mm']).format('hh:mm a') +
        '-' +
        moment(slot?.endTime, ['HH:mm']).format('hh:mm a') +
        (index + 1 < slots.length ? ', ' : '');
    });
    return list;
  };

  return (
    <VendorManagementProvider>
      <InnerBody topic={t('VENDOR_MANAGEMENT')} isDisable={true}>
        <div id="vendor-management" className="pb-3">
          <Card>
            <CardHeader onClick={() => setIsVisibleProdDet(!isVisibleProdDet)}>
              {t('PRODUCT_DETAILS')}
              <img
                className={isVisibleProdDet ? 'mt-2' : 'm-0'}
                width={'20'}
                height={'20'}
                src={isVisibleProdDet ? arrowUpGreen : arrowDownGreen}
                alt="arrow-up"
              />{' '}
            </CardHeader>
            <CardBody className={!isVisibleProdDet ? 'd-none' : ''}>
              <div
                className={`tableFixHead ${
                  productDetails?.current?.clientHeight <
                  productDetails?.current?.scrollHeight
                    ? ''
                    : 'me-0 pe-0'
                } `}
                ref={productDetails}
              >
                <ScrollTable
                  isMobile={isMobile}
                  headers={[
                    'Product Name',
                    'Default Base',
                    'Affiliate Supplier',
                    'Notes',
                  ]}
                  tBody={
                    <>
                      {productDetailsResponse?.res?.map((product, index) => (
                        <tr className="table-row" key={index}>
                          {!isMobile ? (
                            <>
                              <td className="table-data">
                                {product?.productName}
                              </td>
                              <td className="table-data">
                                {product?.defaultBaseName}
                              </td>
                              <td className="table-data">
                                {product?.supplierCompanyName || 'N/A'}
                              </td>
                              <td className="table-data img-data-con">
                                {product?.notes?.length > 0 ? (
                                  <CustomTooltipContainer
                                    placement="left"
                                    targetId={`DisabledAutoHideNote-${index}`}
                                    displayMessage={product?.notes}
                                    width="25"
                                    height="25"
                                    className="notes-img"
                                    src={Note}
                                    alt="notes"
                                  />
                                ) : (
                                  ''
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="table-data">
                                {product?.productName}
                                <small className="d-block p-0 ">
                                  Affiliate Supplier{' '}
                                  <span className="text-green px-0">
                                    {product?.supplierCompanyName || 'N/A'}
                                  </span>
                                </small>
                                <small className="d-block p-0">
                                  Default Base{' '}
                                  <span className="text-green px-0">
                                    {product?.defaultBaseName}
                                  </span>
                                </small>
                              </td>
                              <td>
                                <td className="table-data img-data-con">
                                  {product?.notes?.length > 0 ? (
                                    <CustomTooltipContainer
                                      placement="left"
                                      targetId={`DisabledAutoHideNote-${index}`}
                                      displayMessage={product?.notes}
                                      width="25"
                                      height="25"
                                      className="notes-img"
                                      src={Note}
                                      alt="notes"
                                    />
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card className="mt-5">
            <CardHeader onClick={() => setIsVisibleBaseDet(!isVisibleBaseDet)}>
              {t('DEFAULT_BASE_DETAILS')}{' '}
              <img
                className={isVisibleBaseDet ? 'mt-2' : 'm-0'}
                width={'20'}
                height={'20'}
                src={isVisibleBaseDet ? arrowUpGreen : arrowDownGreen}
                alt="arrow-up"
              />{' '}
            </CardHeader>
            <CardBody className={!isVisibleBaseDet ? 'd-none' : ''}>
              <div
                className={`tableFixHead ${
                  defaultBaseDetails?.current?.clientHeight <
                  defaultBaseDetails?.current?.scrollHeight
                    ? ''
                    : 'me-0 pe-0'
                } `}
                ref={defaultBaseDetails}
              >
                <Table>
                  {!isMobile && (
                    <thead className="table-header">
                      <tr>
                        <th width="20%" className="title">
                          Default Base Name
                        </th>
                        <th width="10%">Lead Time (Hrs)</th>
                        <th
                          width="70%"
                          className="title text-center img-data-con"
                        >
                          Operational Delivery Slots
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tBody>
                    <>
                      {defaultBaseDetailsResponse?.res?.map(
                        (product, index) => (
                          <tr className="table-row" key={index}>
                            {!isMobile ? (
                              <>
                                <td className="table-data">{product?.name}</td>
                                <td className="table-data">
                                  {product?.leadTimeHr}
                                </td>
                                <td className="table-data">
                                  {operationalDeliverySlot(
                                    product?.operationalDeliverySlots,
                                  )}
                                </td>
                              </>
                            ) : (
                              <>
                                <td className="table-data">
                                  {product?.name}
                                  <small className="d-block p-0 pb-2">
                                    Lead Time{' '}
                                    <small className="text-green">
                                      {product?.leadTimeHr}&nbsp;(Hrs)
                                    </small>
                                  </small>
                                  <small className="d-block p-0">
                                    Operational Delivery Slots
                                    <p className="text-green">
                                      {operationalDeliverySlot(
                                        product?.operationalDeliverySlots,
                                      )}
                                    </p>
                                  </small>
                                </td>
                              </>
                            )}
                          </tr>
                        ),
                      )}
                    </>
                  </tBody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <div className="img-data-con text-center">
            <img
              id="DisabledAutoHideContact"
              src={Note}
              width="22"
              height="22"
              className="notes-img"
            />
            <CustomTooltip
              placement="bottom"
              targetId="DisabledAutoHideContact"
              displayMessage={t(
                'PLEASE_CONTACT_SILLARA_ADMIN_IF_YOU_WANT_UPDATE_OR_BASE_SETTINGS',
              )}
            />
          </div>
        </div>
      </InnerBody>
    </VendorManagementProvider>
  );
}

export default VendorManagement;
