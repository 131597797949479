import React from 'react';
import { useTranslation } from 'react-i18next';

function StatusContainer() {
  const { t } = useTranslation();

  const statusArray = [
    {
      name: 'Received',
      text: t('ALREADY_RECEIVED_AND_SCANNED'),
    },
    {
      name: 'SentBySupplier',
      text: t('SENT_BY_SUPPLIER'),
    },
    {
      name: 'Requested',
      text: t('ALREADY_REQUESTED'),
    },
    {
      name: 'NotRequested',
      text: t('NOT_REQUESTED'),
    },
    {
      name: 'not-ready-for-loading-color',
      text: t('NOT_READY_FOR_LOADING'),
    },
  ];

  return (
    <div className="loading-status-container">
      {statusArray.map(status => (
        <p className="d-flex flex-row align-items-center" key={status.name}>
          {status.text} &nbsp;&nbsp;&nbsp;&nbsp;:
          <div
            style={{ width: '10px', height: '10px' }}
            className={`mx-1 ${status.name}`}
          ></div>
        </p>
      ))}
    </div>
  );
}

export default StatusContainer;
