import React, { useState, useContext } from 'react';
import InnerBody from '../../../../../content/InnerBody';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
// import { BuyerManagementContext } from '../../../../../apis/admin';
import VehicleList from './VehicleList';
import OrdersOfVehicle from './OrdersOfVehicle';
import { LoadingContext } from '../../../../../apis/LoadingContext';

function Loading() {
  const { t } = useTranslation();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [deliverySlotOptions, setDeliverySlotOptions] = useState([]);
  const [baseNameOptions, setBaseNameOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(null);
  const [selectedBaseName, setSelectedBaseName] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const loadingContext = useContext(LoadingContext);
  const { loading } = loadingContext.state;

  return (
    <InnerBody
      topic={t('LOADING')}
      isDisable={selectedVehicle === null}
      isRefreshDisable={selectedVehicle === null}
      onHandleBack={() => setSelectedVehicle(null)}
      onRefresh={() => setIsRefresh(true)}
    >
      {!selectedVehicle ? (
        <VehicleList
          loading={loading}
          setSelectedVehicle={setSelectedVehicle}
          deliverySlotOptions={deliverySlotOptions}
          setDeliverySlotOptions={setDeliverySlotOptions}
          baseNameOptions={baseNameOptions}
          setBaseNameOptions={setBaseNameOptions}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedDeliverySlot={selectedDeliverySlot}
          setSelectedDeliverySlot={setSelectedDeliverySlot}
          selectedBaseName={selectedBaseName}
          setSelectedBaseName={setSelectedBaseName}
          vehicleList={vehicleList}
          setVehicleList={setVehicleList}
          setIsRefresh={setIsRefresh}
        />
      ) : (
        <OrdersOfVehicle
          selectedVehicle={selectedVehicle}
          isRefresh={isRefresh}
          setIsRefresh={setIsRefresh}
          selectedBaseName={selectedBaseName}
          selectedDate={selectedDate}
          selectedDeliverySlot={selectedDeliverySlot}
        />
      )}
    </InnerBody>
  );
}

export default Loading;
