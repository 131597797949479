import { cognito } from './environment';

const amplifyConfig = {
  Auth: {
    region: cognito.REGION,
    userPoolId: cognito.USER_POOL_ID,
    userPoolWebClientId: cognito.USER_POOL_WEB_CLIENT_ID,
  },
};
export default amplifyConfig;
