import React, { useState } from 'react';
import { Text } from '../../../../shared/components';
import { Validate } from '../../../config';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Col,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { RoundNum } from '../../../../shared/utility/roundingNumber';
import { commafy } from '../../../../shared/utility/commafyValue';

function PaymentInvoiceUpdatePopUp({
  payableAmount,
  supplier,
  isMobile,
  onPay,
  paymentConfirmation,
}) {
  const { t } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownToggle = () => setIsDropdownOpen(!isDropdownOpen);
  const [paymentType, setPaymentType] = useState(null);

  const [form, setForm] = useState({
    amount: {
      value: payableAmount,
      title: t('AMOUNT'),
      rules: {
        title: t('AMOUNT'),
        stop: true,
      },
    },
    paymentType: {
      value: '',
      title: 'Payment Type',
      rules: {
        stop: true,
        title: t('ENTER_PER_UNIT_PRICE'),
        required: {
          message: t(''),
        },
      },
    },
    supplierComppanyIdentifier: {
      value: paymentConfirmation?.supplierCompanyIdentifier,
      title: 'Reference No',
      rules: {
        stop: true,
        title: t('AVAILABILITY'),
      },
    },
  });

  const handleUpdatePayment = e => {
    e.preventDefault();

    const formValidation = Validate.form(form);

    setForm(formValidation);
    if (!formValidation.approved) {
      return;
    }
  };
  const onChange = e => {
    const { name, value } = e.target;
    const updatedForm = Validate.input(name, value, form, false);
    setForm(updatedForm);
  };
  return (
    <div className="supplier-invoice-update-popup">
      <div>
        <p style={{ textAlign: 'left' }}>
          {t('SUPPLIER_ID')} : {supplier?.supplierCode}
        </p>
        <Form onSubmit={handleUpdatePayment}>
          <Row>
            <Col style={!isMobile ? {} : { marginTop: '5px' }} sm={12}>
              <Text
                disabled={true}
                showErrors={false}
                value={commafy(RoundNum(form.amount.value, 2))}
                groupClassName="update-form-group"
                labelClassName="form-label"
                id="payable-amount"
                autoComplete="off"
                field="payableAmount"
                rightIconGroupClassName="form-container"
                validation={form.amount}
              />

              <Dropdown
                isOpen={isDropdownOpen}
                toggle={dropdownToggle}
                direction="down"
                style={!isMobile ? {} : { marginTop: '5px' }}
                sm={12}
                className={isMobile ? 'col-12 my-3' : 'col-12 mb-3'}
              >
                <DropdownToggle caret>
                  {paymentType === null ? 'Payment Type' : paymentType?.type}
                </DropdownToggle>
                <DropdownMenu>
                  {paymentConfirmation?.paymentTypes?.map((item, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => {
                        setPaymentType(item);
                      }}
                    >
                      {item?.type}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>

              <Text
                autoComplete="off"
                showErrors={false}
                disabled={true}
                value={form.supplierComppanyIdentifier.value}
                groupClassName="update-form-group"
                labelClassName="form-label"
                id="supplier-company-identifier"
                field="supplierCompanyIdentifier"
                onChange={onChange}
                rightIconGroupClassName="form-container"
              />
            </Col>
          </Row>

          <Button
            style={
              !isMobile
                ? { float: 'right', width: '100px' }
                : { width: '100%', marginTop: '10px' }
            }
            disabled={paymentType === null}
            color="primary"
            className="form-submit"
            onClick={() => {
              onPay(supplier, paymentType);
            }}
          >
            Confirm
          </Button>
        </Form>
      </div>
    </div>
  );
}
export default PaymentInvoiceUpdatePopUp;
PaymentInvoiceUpdatePopUp.propTypes = {
  isMobile: PropTypes.bool,
  supplier: PropTypes.any,
  payableAmount: PropTypes.any,
  onPay: PropTypes.func,
  paymentConfirmation: PropTypes.any,
};
