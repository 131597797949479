import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
function ScrollTable({ headers, tBody, isMobile }) {
  useEffect(() => {}, []);
  return (
    <Table>
      {!isMobile && (
        <thead className="table-header">
          <tr>
            {headers.map((header, index) => (
              <th
                className={
                  index === 3 ? 'title text-center img-data-con' : 'title'
                }
                key={index}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody>{tBody}</tbody>
    </Table>
  );
}

ScrollTable.propTypes = {
  isMobile: PropTypes.bool,
  headers: PropTypes.array,
  data: PropTypes.any,
  tBody: PropTypes.any,
};

export default ScrollTable;
